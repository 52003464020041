import React, { useState, useEffect } from 'react';
import { Triangle } from 'react-loader-spinner';
import Head from '../components/Head/Head';
import axios from 'axios';
import '../App.css';
import { useNavigate, useParams } from 'react-router-dom';
import { secondsToMMSS } from '../utils/helperFunctions';
import {
	TextField,
	Button,
	FormControl,
	FormLabel,
	Radio,
	RadioGroup,
	InputLabel,
	Select,
	Slider,
	MenuItem,
	Container,
	Grid,
	Typography,
	Checkbox,
	FormControlLabel,
	Tabs,
	Tab,
	Box,
} from '@mui/material';
import { SportsKabaddi } from '@mui/icons-material';
import Autocomplete from '@mui/material/Autocomplete';

const Dropdown = ({ label, options, darkMode, value, handleChange, name }) => {
	return (
		<FormControl
			sx={{
				// Change outline color when hovered but not focused
				'&:hover .MuiOutlinedInput-notchedOutline:not(.Mui-focused)': {
					borderColor: darkMode ? 'white!important' : 'grey',
				},
				// Change label color when hovered but not focused
				'&:hover .MuiInputLabel-root:not(.Mui-focused)': {
					color: darkMode ? 'white' : '#000',
				},
				// For text color
				'&& input': {
					color: darkMode ? '#fff' : '#000',
				},
				// Default outline color
				'& .MuiOutlinedInput-notchedOutline': {
					borderColor: darkMode ? 'grey!important' : 'grey',
				},
				// Focused outline color
				'& .MuiOutlinedInput-notchedOutline.Mui-focused': {
					borderColor: darkMode ? 'rgb(47,199,112)' : '#000',
				},
				// Background color
				'& .MuiOutlinedInput-root': {
					backgroundColor: darkMode ? '#000' : '#f7f7f7',
				},
				'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
					borderColor: 'rgb(47, 199, 112)!important',
				},
				// For label text color
				'& .MuiInputLabel-root': {
					color: darkMode ? 'grey' : 'grey',
				},
				// Focused label color
				'& .MuiInputLabel-outlined.Mui-focused': {
					color: 'rgb(47, 180, 112)',
				},
			}}
			fullWidth
			variant='outlined'
			style={{ marginBottom: '20px' }}
		>
			<InputLabel>{label}</InputLabel>
			<Select label={label} value={value} onChange={handleChange} name={name}>
				{options.map((option) => (
					<MenuItem key={option} value={option || ''}>
						{option}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

const API_URL = process.env.REACT_APP_API_URL;

function EditData({ user, darkMode, data }) {
	const styles = {
		input: {
			color: darkMode ? '#fff' : '#000',
		},
		'& .MuiOutlinedInput-root': {
			backgroundColor: darkMode ? '#000' : '#f7f7f7', // Set default background color
		},
		'& .MuiOutlinedInput-root.Mui-focused': {
			backgroundColor: darkMode ? '#000' : '#f7f7f7', // Set background color when focused
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			borderColor: 'rgb(47, 199, 112)',
		},
		'& .MuiInputLabel-outlined.Mui-focused': {
			color: 'rgb(47, 180, 112)',
		},
		// Change outline color when hovered but not focused
		'&:hover .MuiOutlinedInput-notchedOutline:not(.Mui-focused)': {
			borderColor: darkMode ? 'white!important' : 'grey',
		},
		// Change label color when hovered but not focused
		'&:hover .MuiInputLabel-root:not(.Mui-focused)': {
			color: darkMode ? 'white' : '#000',
		},
		// For text color
		'&& input': {
			color: darkMode ? '#fff' : '#000',
		},
		// Default outline color
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: darkMode ? 'grey!important' : 'grey',
		},
		// Focused outline color
		'& .MuiOutlinedInput-notchedOutline.Mui-focused': {
			borderColor: darkMode ? 'rgb(47,199,112)' : '#000',
		},

		// For label text color
		'& .MuiInputLabel-root': {
			color: darkMode ? 'grey' : 'grey',
		},
	};

	const checkedStyles = {
		color: 'grey',
		'&.Mui-checked': { color: darkMode ? '#fff' : 'black' },
	};

	const formLabelStyles = {
		color: darkMode ? '#fff' : 'black',
		'&.Mui-focused': {
			color: darkMode ? '#fff' : 'black',
		},
	};

	const radioStyles = {
		color: 'grey',
		'&.Mui-checked': { color: darkMode ? '#fff' : 'black' },
	};

	const { id } = useParams();
	const [loading, setLoading] = useState(true);
	const [dataToEdit, setDataToEdit] = useState({
		match: '',
		matchWinner: '',
		age: '',
		matchLength: 0,
		experience: '',
		overtimeLength: 0,
		floLink: '',
		floEmbed: '',
		youtubeLink: '',
		youtubeEmbed: '',
		ufcLink: '',
		ufcEmbed: '',
		enigmaLink: '',
		enigmaEmbed: '',
		sourceOfTimestamps: '',
		sport: 'No Gi BJJ',
		round: '',
		player1: '',
		player1Affiliation: '',
		player1Height: 0,
		player1Wingspan: 0,
		player1Inseam: 0,
		player1Chest: 0,
		player2: '',
		player2Affiliation: '',
		player2Height: 0,
		player2Wingspan: 0,
		player2Inseam: 0,
		player2Chest: 0,
		ruleSet: '',
		gender: '',
		organization: '',
		weightDivision: '',
		dateOfMatch: '',
		year: 0,
		region: '',
		finish: false,
		pointDiscrepancy: 0,
		matchEndedBeforePoints: false,
		firstToScore: 'N/A',
		overtime: false,
		winByDecision: false,
		winByPoints: false,
		submissionType: '',
		submissionVariation: '',
		submissionSide: '',
		submissionPosition: '',
		submissionPositionInitiation: '',
		submissionGrip: '',
		submissionMechanics: '',
		submissionResult: '',
		positionVariationUpper: '',
		positionVariationLower: '',
		timeOfFinish: 0,
		winnerInitiation: '',
		notes: [],
		player1Points: 0,
		player1Advantages: 0,
		player1Penalties: 0,
		player2Points: 0,
		player2Advantages: 0,
		player2Penalties: 0,
		organizationDetail: '',
		winByAdvantage: false,
		user: '',
	});
	const [tempTimeOfFinish, setTempTimeOfFinish] = useState('');
	const [submissionOptions, setSubmissionOptions] = useState([]);
	const [submissionPositionVariations, setSubmissionPositionVariations] = useState([]);
	const [loadingPlayer1Stats, setLoadingPlayer1Stats] = useState(true);
	const [loadingPlayer2Stats, setLoadingPlayer2Stats] = useState(true);
	const navigate = useNavigate();

	const handleTimeInputChange = (e) => {
		setTempTimeOfFinish(e.target.value);
	};

	const nameMapping = {
		'Submission Variation': 'submissionVariation',
		'Submission Grip': 'submissionGrip',
		'Submission Mechanics': 'submissionMechanics',
		'Submission Result': 'submissionResult',
	};

	const [dateError, setDateError] = useState(false);

	const handleDateInput = (e) => {
		const regex = /^\d{2}\/\d{2}\/\d{4}$/; // Checks for mm/dd/yyyy format
		if (!regex.test(e.target.value)) {
			setDateError(true);
		} else {
			setDateError(false);
		}
		handleChange(e);
	};

	const [showLinksAndEmbeds, setShowLinksAndEmbeds] = useState({
		showFlo: false,
		showYoutube: false,
		showUFC: false,
		showEnigma: false,
	});

	const toggleFieldVisibility = (field) => {
		setShowLinksAndEmbeds((prevState) => ({
			...prevState,
			[field]: !prevState[field],
		}));
	};

	const handleChange = (event, newValue) => {
		let name, value;
		if (event.target) {
			// This is a TextField or Select change
			if (event.target.type === 'checkbox') {
				name = event.target.name;
				value = event.target.checked;
			} else if (event.target.name === 'submission') {
				name = 'submissionType';
				value = event.target.value;
			} else if (name === 'timeOfFinish') {
				handleTimeInputChange(event);
			} else if (event.target.name === 'notes') {
				name = 'notes';
				value = event.target.value;
			} else {
				name = event.target.name;
				value = event.target.value;
			}
		} else {
			// This is a Slider change
			name = newValue.name;
			value = newValue.value;
		}

		if (name === 'organization') {
			if (value === 'ADCC') {
				setDataToEdit((prev) => ({ ...prev, ruleSet: 'ADCC', organization: value }));
				return;
			} else if (value === 'IBJJF') {
				setDataToEdit((prev) => ({ ...prev, ruleSet: 'Points', organization: value }));
				return;
			} else if (value === 'Other') {
				setDataToEdit((prev) => ({ ...prev, ruleSet: '', organization: value }));
				return;
			}
		}

		if (name === 'timeOfFinish') {
			const [minutes, seconds] = event.target.value.split(':').map(Number);
			value = minutes * 60 + seconds;
		} else {
			value = event.target.value;
		}

		// Check if the name of the field is 'notes'
		if (name === 'notes') {
			value = event.target.value;
		}

		if (name === 'winByPoints') {
			value = event.target.checked;
		}

		if (name === 'winByDecision') {
			value = event.target.checked;
		}

		if (name === 'finish') {
			value = event.target.checked;
		}

		if (name === 'overtime') {
			value = event.target.checked;
		}

		setDataToEdit({
			...dataToEdit,
			[name]: value,
		});
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		setLoading(true);
		// Convert tempTimeOfFinish to seconds
		const [minutes, seconds] = tempTimeOfFinish.split(':').map(Number);
		const totalSeconds = minutes * 60 + seconds;

		dataToEdit.timeOfFinish = totalSeconds;

		// if ruleSet === ADCC & timeOfFinish === < 1/2 of matchLength, matchEndedBeforePoints = true
		if (dataToEdit.ruleSet === 'ADCC' && dataToEdit.timeOfFinish !== 0 && dataToEdit.timeOfFinish < (dataToEdit.matchLength * 60) / 2) {
			dataToEdit.matchEndedBeforePoints = true;
		}
		// if matchEnededBeforePoints === true, firstToScore = 'N/A'
		if (dataToEdit.matchEndedBeforePoints === true) {
			dataToEdit.firstToScore = 'N/A';
		}

		// if organizationDetail.length > 0, organization = organizationDetail && delete organizationDetail
		// if dataToEdit.organization !== "Other" delete organizationDetail
		if (dataToEdit.organization !== 'IBJJF' && dataToEdit.organization !== 'ADCC' && dataToEdit.organizationDetail && dataToEdit.organizationDetail.length > 0) {
			dataToEdit.organization = dataToEdit.organizationDetail;
			delete dataToEdit.organizationDetail;
		} else if (dataToEdit.organization !== 'Other') {
			delete dataToEdit.organizationDetail;
		}

		// calculate point discrepancy
		const player1Points = dataToEdit.player1Points;
		const player1Penalties = dataToEdit.player1Penalties;
		const player1Advantages = dataToEdit.player1Advantages;
		const player2Points = dataToEdit.player2Points;
		const player2Penalties = dataToEdit.player2Penalties;
		const player2Advantages = dataToEdit.player2Advantages;

		const player1Total = player1Points - player1Penalties;
		const player2Total = player2Points - player2Penalties;

		const pointDiscrepancy = Math.abs(player1Total - player2Total);
		const advantageDiscrepancy = Math.abs(player1Advantages - player2Advantages);

		if (player1Total === player2Total && advantageDiscrepancy > 0) {
			dataToEdit.winByAdvantage = true;
		} else {
			dataToEdit.winByAdvantage = false;
		}

		dataToEdit.pointDiscrepancy = pointDiscrepancy;

		if (dataToEdit.organization !== 'ADCC') dataToEdit.region = '';

		if (typeof dataToEdit.notes === 'string' && dataToEdit.notes.length !== 0) {
			// If it's a string, split it into an array
			const notesArray = dataToEdit.notes
				.split(/,|\n/)
				.map((note) => note.trim())
				.filter((note) => note);
			dataToEdit.notes = notesArray;
		}
		// post to database
		fetch(`${API_URL}/data/${dataToEdit._id}`, {
			method: 'PUT',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(dataToEdit),
		})
			.then((res) => res.json())
			.then((data) => {
				navigate(`/add-sequences/${data?._id}`);
			})
			.catch((err) => console.error(err));
	};

	const [value, setValue] = useState(0);
	const handleTabChange = (event, newValue) => {
		setValue(newValue);
	};

	useEffect(() => {
		setTempTimeOfFinish(secondsToMMSS(dataToEdit?.timeOfFinish));
	}, [dataToEdit?.timeOfFinish]);

	useEffect(() => {
		const checkPlayerStats = async () => {
			if (!dataToEdit.player1 || !dataToEdit.player2) return;

			try {
				const response1 = await fetch(`${API_URL}/data/check-for-player-stats`, {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({ player: dataToEdit.player1 }),
				});
				const data1 = await response1.json();

				const response2 = await fetch(`${API_URL}/data/check-for-player-stats`, {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({ player: dataToEdit.player2 }),
				});
				const data2 = await response2.json();

				setDataToEdit((prevState) => ({
					...prevState,
					player1Height: data1.exists ? data1.playerData.height : 0,
					player1Chest: data1.exists ? data1.playerData.chest : 0,
					player1Inseam: data1.exists ? data1.playerData.inseam : 0,
					player1Wingspan: data1.exists ? data1.playerData.wingspan : 0,
					player2Height: data2.exists ? data2.playerData.height : 0,
					player2Chest: data2.exists ? data2.playerData.chest : 0,
					player2Inseam: data2.exists ? data2.playerData.inseam : 0,
					player2Wingspan: data2.exists ? data2.playerData.wingspan : 0,
				}));

				setLoadingPlayer1Stats(false);
				setLoadingPlayer2Stats(false);
			} catch (error) {
				console.error('Error checking player data:', error);
			}
		};

		checkPlayerStats();
	}, [dataToEdit.player1, dataToEdit.player2]);

	useEffect(() => {
		const matchString = `${dataToEdit?.player1} vs ${dataToEdit?.player2}`;
		setDataToEdit((prevState) => ({
			...prevState,
			match: matchString,
		}));
	}, [dataToEdit?.player1, dataToEdit?.player2]);

	useEffect(() => {
		const uid = user?.uid;
		fetch(`${API_URL}/user/${uid}`, {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
		})
			.then((res) => res.json())
			.then((data) => {
				setDataToEdit((prev) => ({ ...prev, user: data._id }));
			})
			.catch((err) => console.error(err));
	}, [user]);

	useEffect(() => {
		if (dataToEdit?.dateOfMatch !== '') {
			const date = new Date(dataToEdit?.dateOfMatch);
			const year = date.getFullYear();
			setDataToEdit((prev) => ({ ...prev, year: year }));
		}
	}, [dataToEdit?.dateOfMatch]);

	useEffect(() => {
		//scroll to top of page on initial load
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			if (!user) return;

			try {
				// Define both fetch requests as promises
				const fetchSubmissionOptionsPromise = fetch(`${API_URL}/submission-options`, {
					method: 'GET',
					headers: { 'Content-Type': 'application/json' },
				});

				const fetchSubmissionPositionVariationsPromise = fetch(`${API_URL}/submission-position-variations`, {
					method: 'GET',
					headers: { 'Content-Type': 'application/json' },
				});

				// Use Promise.all to wait for both promises to resolve
				const [submissionOptionsResponse, submissionPositionVariationsResponse] = await Promise.all([fetchSubmissionOptionsPromise, fetchSubmissionPositionVariationsPromise]);

				// Convert responses to JSON
				const submissionOptionsData = await submissionOptionsResponse.json();
				const submissionPositionVariationsData = await submissionPositionVariationsResponse.json();

				// Set state with fetched data
				setSubmissionOptions(submissionOptionsData);
				setSubmissionPositionVariations(submissionPositionVariationsData);

				// Fetch data specific to the ID in URL
				const response = await axios.get(`${API_URL}/data/${id}`);
				const fetchedData = response.data;

				if (fetchedData.organization !== 'ADCC' && fetchedData.organization !== 'IBJJF') {
					fetchedData.organizationDetail = fetchedData.organization;
					fetchedData.organization = 'Other';
				}

				setDataToEdit({
					...fetchedData,
					player1Height: fetchedData.player1Height || 0,
					player1Chest: fetchedData.player1Chest || 0,
					player1Inseam: fetchedData.player1Inseam || 0,
					player1Wingspan: fetchedData.player1Wingspan || 0,
					player2Height: fetchedData.player2Height || 0,
					player2Chest: fetchedData.player2Chest || 0,
					player2Inseam: fetchedData.player2Inseam || 0,
					player2Wingspan: fetchedData.player2Wingspan || 0,
				});
			} catch (error) {
				console.error('Error fetching data:', error);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, id]);

	if (loading) {
		return (
			<>
				<Head title='Edit Page' />
				<Container maxWidth='md' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<div className='loader'>
						<Triangle color='rgb(47, 199, 112)' size={100} />
						<Typography variant='h5' sx={{ color: 'rgb(47, 199, 112)', marginTop: '1rem' }}>
							Loading...
						</Typography>
					</div>
				</Container>
			</>
		);
	}

	return (
		<>
			<Head title='Edit Page' />
			<Container maxWidth='md' sx={{ marginBottom: '4rem', marginTop: '2rem' }}>
				{dataToEdit && (
					<Container>
						<Typography variant='h5' align='center' gutterBottom>
							Edit Match
						</Typography>
						<Tabs
							value={value}
							onChange={handleTabChange}
							centered
							sx={{
								'& .MuiTab-root.Mui-selected': {
									color: 'black',
								},
								'& .MuiTabs-indicator': {
									backgroundColor: 'black',
								},
								marginBottom: '16px',
							}}
						>
							<Tab label='Required Info' />
						</Tabs>
						<form onSubmit={handleSubmit}>
							{value === 0 && (
								<Box>
									<Grid container spacing={3}>
										<Grid item xs={12} sm={6}>
											<Autocomplete
												options={data?.allAthletes}
												getOptionLabel={(option) => option}
												value={dataToEdit?.player1}
												onInputChange={(event, newValue) => {
													setDataToEdit((prev) => ({ ...prev, player1: newValue }));
												}}
												freeSolo
												renderInput={(params) => <TextField {...params} sx={{ ...styles }} fullWidth required id='player1' label='Player 1' />}
											/>
										</Grid>

										<Grid item xs={12} sm={6}>
											<Autocomplete
												options={data?.allAthletes}
												getOptionLabel={(option) => option}
												value={dataToEdit?.player2}
												onInputChange={(event, newValue) => {
													setDataToEdit((prev) => ({ ...prev, player2: newValue }));
												}}
												freeSolo
												renderInput={(params) => <TextField {...params} sx={{ ...styles }} fullWidth required id='player2' label='Player 2' />}
											/>
										</Grid>

										<Grid item xs={12}>
											<TextField disabled sx={{ ...styles, marginBottom: '16px' }} fullWidth required id='match' name='match' label='match' value={dataToEdit?.match} />
										</Grid>

										<>
											<Grid item xs={12}>
												<TextField
													autoComplete='off'
													sx={{
														...styles,
														flex: 1,
														marginRight: '8px',
													}}
													required
													id='dateOfMatch'
													name='dateOfMatch'
													label='Date of Match'
													value={dataToEdit?.dateOfMatch}
													onChange={handleChange}
													onInput={handleDateInput}
													error={dateError}
													helperText={dateError ? 'Please use the format mm/dd/yyyy' : 'mm/dd/yyyy'}
												/>
											</Grid>
											<Grid item xs={12} sm={6}>
												<Autocomplete
													options={data?.affiliations}
													getOptionLabel={(option) => option}
													value={dataToEdit?.player1Affiliation}
													onInputChange={(event, newValue) => {
														setDataToEdit((prev) => ({ ...prev, player1Affiliation: newValue }));
													}}
													freeSolo
													renderInput={(params) => (
														<TextField
															autoComplete='off'
															{...params}
															sx={{
																...styles,
															}}
															fullWidth
															required
															id='player1Affiliation'
															label='Player 1 Affiliation'
														/>
													)}
												/>
											</Grid>

											<Grid item xs={12} sm={6}>
												<Autocomplete
													options={data?.affiliations}
													getOptionLabel={(option) => option}
													value={dataToEdit?.player2Affiliation}
													onInputChange={(event, newValue) => {
														setDataToEdit((prev) => ({ ...prev, player2Affiliation: newValue }));
													}}
													freeSolo
													renderInput={(params) => (
														<TextField
															autoComplete='off'
															{...params}
															sx={{
																...styles,
															}}
															fullWidth
															required
															id='player2Affiliation'
															label='Player 2 Affiliation'
														/>
													)}
												/>
											</Grid>

											<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
												<FormControl
													sx={{
														...styles,
														flex: 1,
														marginRight: '8px',
													}}
												>
													<InputLabel required id='matchWinner-label' sx={{ '&.MuiInputLabel-shrink': { transform: 'translate(14px, -16px) scale(0.75)' } }}>
														Match Winner
													</InputLabel>
													<Select required labelId='matchWinner-label' id='matchWinner' name='matchWinner' value={dataToEdit?.matchWinner} onChange={handleChange}>
														<MenuItem value={`${dataToEdit?.player1}`}>{dataToEdit?.player1}</MenuItem>
														<MenuItem value={`${dataToEdit?.player2}`}>{dataToEdit?.player2}</MenuItem>
														<MenuItem value='Draw/Tie'>Draw/Tie</MenuItem>
													</Select>
												</FormControl>

												<FormControl
													sx={{
														...styles,
														flex: 1,
													}}
												>
													<InputLabel required id='sport-label' sx={{ '&.MuiInputLabel-shrink': { transform: 'translate(14px, -16px) scale(0.75)' } }}>
														Sport
													</InputLabel>
													<Select required labelId='sport-label' id='sport' name='sport' value={dataToEdit?.sport} onChange={handleChange}>
														<MenuItem value='No Gi BJJ'>No Gi BJJ</MenuItem>
													</Select>
												</FormControl>
											</Grid>

											{dataToEdit?.matchWinner !== 'Draw/Tie' && (
												<Grid item xs={12}>
													<FormControl fullWidth sx={{ ...styles }}>
														<InputLabel required id='winnerInitiation-label' sx={{ '&.MuiInputLabel-shrink': { transform: 'translate(14px, -16px) scale(0.75)' } }}>
															Winner Initiation
														</InputLabel>
														<Select required labelId='winnerInitiation-label' id='winnerInitiation' name='winnerInitiation' value={dataToEdit?.winnerInitiation} onChange={handleChange}>
															<MenuItem value='Guard Pull'>Guard Pull</MenuItem>
															<MenuItem value='Standing vs Guard Pull'>Standing vs Guard Pull</MenuItem>
															<MenuItem value='Takedown Offense'>Takedown Offense</MenuItem>
															<MenuItem value='Takedown Defense'>Takedown Defense</MenuItem>
														</Select>
													</FormControl>
												</Grid>
											)}

											<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
												<TextField
													autoComplete='off'
													sx={{
														...styles,
														flex: 1,
														marginRight: '8px',
													}}
													required
													id='matchLength'
													name='matchLength'
													label='Match Length (scheduled for how many minutes)'
													value={dataToEdit?.matchLength}
													onChange={(e) => {
														if (e.target.value < 0) e.target.value = 0;
														handleChange(e);
													}}
													type='number'
													inputProps={{ min: 0 }}
												/>
												<TextField
													autoComplete='off'
													sx={{
														...styles,
														flex: 1,
													}}
													required
													id='overtimeLength'
													name='overtimeLength'
													label='Overtime Length (schedule for)'
													value={dataToEdit?.overtimeLength}
													onChange={(e) => {
														if (e.target.value < 0) e.target.value = 0;
														handleChange(e);
													}}
													type='number'
													inputProps={{ min: 0 }}
												/>
											</Grid>

											<Grid item xs={12}>
												<FormControl fullWidth component='fieldset'>
													<FormLabel required component='legend' sx={{ ...formLabelStyles }}>
														Gender
													</FormLabel>
													<RadioGroup row aria-label='gender' name='gender' value={dataToEdit?.gender} onChange={handleChange} required>
														<FormControlLabel value='male' control={<Radio sx={{ ...radioStyles }} />} label='Male' sx={{ color: darkMode ? '#fff' : 'black' }} />
														<FormControlLabel value='female' control={<Radio sx={{ ...radioStyles }} />} label='Female' sx={{ color: darkMode ? '#fff' : 'black' }} />
													</RadioGroup>
												</FormControl>
											</Grid>

											<Grid item xs={12}>
												<FormControl fullWidth component='fieldset'>
													<FormLabel required component='legend' sx={{ ...formLabelStyles }}>
														Organization
													</FormLabel>
													<RadioGroup row aria-label='organization' name='organization' value={dataToEdit?.organization} onChange={handleChange} required>
														<FormControlLabel value='ADCC' control={<Radio sx={{ ...radioStyles }} />} label='ADCC' sx={{ color: darkMode ? '#fff' : 'black' }} />
														<FormControlLabel value='IBJJF' control={<Radio sx={{ ...radioStyles }} />} label='IBJJF' sx={{ color: darkMode ? '#fff' : 'black' }} />
														<FormControlLabel value='Other' control={<Radio sx={{ ...radioStyles }} />} label='Other' sx={{ color: darkMode ? '#fff' : 'black' }} />
													</RadioGroup>
												</FormControl>
												{dataToEdit?.organization === 'Other' && (
													<TextField
														autoComplete='off'
														sx={{ ...styles }}
														style={{ marginTop: '1rem', marginBottom: '8px' }}
														fullWidth
														id='organizationDetail'
														name='organizationDetail'
														label='Specify Organization (EBI...WNO...etc)'
														value={dataToEdit?.organizationDetail}
														onChange={handleChange}
													/>
												)}
											</Grid>

											{dataToEdit?.organization === 'ADCC' && (
												<FormControl
													fullWidth
													sx={{
														...styles,
														marginTop: '8px',
														marginBottom: '8px',
													}}
												>
													<InputLabel required id='region-label' sx={{ '&.MuiInputLabel-shrink': { transform: 'translate(14px, -16px) scale(0.75)' } }}>
														Region
													</InputLabel>
													<Select labelId='region-label' id='region' name='region' value={dataToEdit?.region} onChange={handleChange}>
														<MenuItem key='Opens-Brazil' value='Opens: Brazil'>
															Opens: Brazil
														</MenuItem>
														<MenuItem key='Opens-Canada' value='Opens: Canada'>
															Opens: Canada
														</MenuItem>
														<MenuItem key='Opens-Mexico' value='Opens: Mexico'>
															Opens: Mexico
														</MenuItem>
														<MenuItem key='Opens-USA' value='Opens: USA'>
															Opens: USA
														</MenuItem>
														<MenuItem key='Trials-West Coast' value='Trials: West Coast'>
															Trials: West Coast
														</MenuItem>
														<MenuItem key='Trials-East Coast' value='Trials: East Coast'>
															Trials: East Coast
														</MenuItem>
														<MenuItem key='Trials-South American' value='Trials: South American'>
															Trials: South American
														</MenuItem>
														<MenuItem key='Trials-European Middle East and Africa' value='Trials: European Middle East and Africa'>
															Trials: European Middle East and Africa
														</MenuItem>
														<MenuItem key='Trials-Asia & Oceania' value='Trials: Asia & Oceania'>
															Trials: Asia & Oceania
														</MenuItem>
														<MenuItem key='ADCC-Event' value='ADCC Event'>
															ADCC Event
														</MenuItem>
													</Select>
												</FormControl>
											)}

											<Grid item xs={12}>
												{dataToEdit?.organization ? (
													<FormControl fullWidth component='fieldset'>
														<FormLabel
															component='legend'
															sx={{
																color: darkMode ? '#fff' : 'black',
																'&.Mui-focused': {
																	color: darkMode ? '#fff' : 'black',
																},
															}}
														>
															Rule set
														</FormLabel>
														<RadioGroup row aria-label='ruleSet' name='ruleSet' value={dataToEdit?.ruleSet} onChange={handleChange} required>
															{dataToEdit?.organization === 'ADCC' && (
																<FormControlLabel value='ADCC' control={<Radio sx={{ ...radioStyles }} />} label='ADCC' sx={{ color: darkMode ? '#fff' : 'black' }} />
															)}
															{dataToEdit?.organization === 'IBJJF' && (
																<FormControlLabel value='Points' control={<Radio sx={{ ...radioStyles }} />} label='Points' sx={{ color: darkMode ? '#fff' : 'black' }} />
															)}
															{dataToEdit?.organization === 'Other' && (
																<>
																	<FormControlLabel value='ADCC' control={<Radio sx={{ ...checkedStyles }} />} label='ADCC' sx={{ color: darkMode ? '#fff' : 'black' }} />
																	<FormControlLabel value='Points' control={<Radio sx={{ ...checkedStyles }} />} label='Points' sx={{ color: darkMode ? '#fff' : 'black' }} />
																	<FormControlLabel
																		value='Submission Only'
																		control={<Radio sx={{ ...checkedStyles }} />}
																		label='Submission Only'
																		sx={{ color: darkMode ? '#fff' : 'black' }}
																	/>
																	<FormControlLabel value='Other' control={<Radio sx={{ ...checkedStyles }} />} label='Other' sx={{ color: darkMode ? '#fff' : 'black' }} />
																</>
															)}
														</RadioGroup>
													</FormControl>
												) : null}
											</Grid>

											<FormControl fullWidth sx={{ ...styles, marginTop: '8px', marginBottom: '8px' }}>
												<InputLabel required id='age-label' sx={{ '&.MuiInputLabel-shrink': { transform: 'translate(14px, -16px) scale(0.75)' } }}>
													Age
												</InputLabel>
												<Select required labelId='age-label' id='age' name='age' value={dataToEdit?.age} onChange={handleChange}>
													{dataToEdit?.organization === 'ADCC'
														? [
																<MenuItem key='Adult' value='Adult'>
																	Adult
																</MenuItem>,
																<MenuItem key='Masters' value='Masters'>
																	Masters
																</MenuItem>,
																<MenuItem key='Masters 2' value='Masters 2'>
																	Masters 2
																</MenuItem>,
																<MenuItem key='Masters 3' value='Masters 3'>
																	Masters 3
																</MenuItem>,
														  ]
														: null}

													{dataToEdit?.organization === 'IBJJF'
														? [
																<MenuItem key='Adult' value='Adult'>
																	Adult
																</MenuItem>,
																<MenuItem key='Master 1' value='Master 1'>
																	Master 1
																</MenuItem>,
																<MenuItem key='Master 2' value='Master 2'>
																	Master 2
																</MenuItem>,
																<MenuItem key='Master 3' value='Master 3'>
																	Master 3
																</MenuItem>,
																<MenuItem key='Master 4' value='Master 4'>
																	Master 4
																</MenuItem>,
																<MenuItem key='Master 5' value='Master 5'>
																	Master 5
																</MenuItem>,
																<MenuItem key='Master 6' value='Master 6'>
																	Master 6
																</MenuItem>,
																<MenuItem key='Master 7' value='Master 7'>
																	Master 7
																</MenuItem>,
														  ]
														: null}
													{dataToEdit?.organization === 'Other'
														? [
																<MenuItem key='Adult' value='Adult'>
																	Adult
																</MenuItem>,
														  ]
														: null}
												</Select>
											</FormControl>

											<FormControl fullWidth sx={{ ...styles, marginTop: '8px', marginBottom: '8px' }}>
												<InputLabel required id='experience-label' sx={{ '&.MuiInputLabel-shrink': { transform: 'translate(14px, -16px) scale(0.75)' } }}>
													Experience
												</InputLabel>
												<Select labelId='experience-label' id='experience' name='experience' value={dataToEdit?.experience} onChange={handleChange} required>
													{dataToEdit?.organization === 'ADCC'
														? [
																<MenuItem key='Beginner' value='Beginner'>
																	Beginner
																</MenuItem>,
																<MenuItem key='Intermediate' value='Intermediate'>
																	Intermediate
																</MenuItem>,
																<MenuItem key='Advanced' value='Advanced'>
																	Advanced
																</MenuItem>,
														  ]
														: [
																<MenuItem key='White' value='White'>
																	White
																</MenuItem>,
																<MenuItem key='Blue' value='Blue'>
																	Blue
																</MenuItem>,
																<MenuItem key='Purple' value='Purple'>
																	Purple
																</MenuItem>,
																<MenuItem key='Brown' value='Brown'>
																	Brown
																</MenuItem>,
																<MenuItem key='Black' value='Black'>
																	Black
																</MenuItem>,
														  ]}
												</Select>
											</FormControl>

											{dataToEdit?.organization === 'Other' ? null : (
												<FormControl
													fullWidth
													sx={{
														...styles,
														marginTop: '8px',
														marginBottom: '8px',
													}}
												>
													<InputLabel required id='round-label' sx={{ '&.MuiInputLabel-shrink': { transform: 'translate(14px, -16px) scale(0.75)' } }}>
														Round
													</InputLabel>
													<Select labelId='round-label' id='round' name='round' value={dataToEdit?.round} onChange={handleChange}>
														{dataToEdit?.organization === 'IBJJF' && [
															<MenuItem key='32' value='32'>
																32
															</MenuItem>,
															<MenuItem key='16' value='16'>
																16
															</MenuItem>,
															<MenuItem key='8' value='8'>
																8
															</MenuItem>,
															<MenuItem key='4' value='4'>
																4
															</MenuItem>,
															<MenuItem key='2' value='2'>
																2
															</MenuItem>,
														]}
														{dataToEdit?.organization === 'ADCC' && [
															<MenuItem key='256' value='256'>
																256
															</MenuItem>,
															<MenuItem key='128' value='128'>
																128
															</MenuItem>,
															<MenuItem key='64' value='64'>
																64
															</MenuItem>,
															<MenuItem key='32' value='32'>
																32
															</MenuItem>,
															<MenuItem key='16' value='16'>
																16
															</MenuItem>,
															<MenuItem key='8' value='8'>
																8
															</MenuItem>,
															<MenuItem key='4' value='4'>
																4
															</MenuItem>,
															<MenuItem key='2' value='2'>
																2
															</MenuItem>,
														]}
													</Select>
												</FormControl>
											)}

											<FormControl fullWidth sx={{ ...styles, marginTop: '8px' }}>
												<InputLabel required id='weight-division-label' sx={{ '&.MuiInputLabel-shrink': { transform: 'translate(14px, -16px) scale(0.75)' } }}>
													Weight Division
												</InputLabel>
												<Select required labelId='weight-division-label' id='weight-division' name='weightDivision' value={dataToEdit?.weightDivision} onChange={handleChange}>
													{dataToEdit?.organization === 'IBJJF' && [
														<MenuItem key='Rooster' value='Rooster'>
															Rooster
														</MenuItem>,
														<MenuItem key='Light Feather' value='Light Feather'>
															Light Feather
														</MenuItem>,
														<MenuItem key='Feather' value='Feather'>
															Feather
														</MenuItem>,
														<MenuItem key='Light' value='Light'>
															Light
														</MenuItem>,
														<MenuItem key='Middle' value='Middle'>
															Middle
														</MenuItem>,
														<MenuItem key='Medium Heavy' value='Medium Heavy'>
															Medium Heavy
														</MenuItem>,
														<MenuItem key='Heavy' value='Heavy'>
															Heavy
														</MenuItem>,
														<MenuItem key='Super Heavy' value='Super Heavy'>
															Super Heavy
														</MenuItem>,
														<MenuItem key='Ultra Heavy' value='Ultra Heavy'>
															Ultra Heavy
														</MenuItem>,
														<MenuItem key='Open Class' value='Open Class'>
															Open Class
														</MenuItem>,
													]}

													{dataToEdit?.organization === 'ADCC' &&
														(dataToEdit?.gender === 'female'
															? dataToEdit?.region === 'Opens: Brazil'
																? [
																		<MenuItem key='-50KG' value='-50KG'>
																			-50KG
																		</MenuItem>,
																		<MenuItem key='-55KG' value='-55KG'>
																			-55KG
																		</MenuItem>,
																		<MenuItem key='-60KG' value='-60KG'>
																			-60KG
																		</MenuItem>,
																		<MenuItem key='-65KG' value='-65KG'>
																			-65KG
																		</MenuItem>,
																		<MenuItem key='-70KG' value='-70KG'>
																			-70KG
																		</MenuItem>,
																		<MenuItem key='+70KG' value='+70KG'>
																			+70KG
																		</MenuItem>,
																  ]
																: [
																		<MenuItem key='-55KG' value='-55KG'>
																			-55KG
																		</MenuItem>,
																		<MenuItem key='-60KG' value='-60KG'>
																			-60KG
																		</MenuItem>,
																		<MenuItem key='+60KG' value='+60KG'>
																			+60KG
																		</MenuItem>,
																		<MenuItem key='-65KG' value='-65KG'>
																			-65KG
																		</MenuItem>,
																		<MenuItem key='+65KG' value='+65KG'>
																			+65KG
																		</MenuItem>,
																  ]
															: dataToEdit?.region.includes('Opens')
															? [
																	<MenuItem key='-60KG' value='-60KG'>
																		-60KG
																	</MenuItem>,
																	<MenuItem key='-65KG' value='-65KG'>
																		-65KG
																	</MenuItem>,
																	<MenuItem key='-70KG' value='-70KG'>
																		-70KG
																	</MenuItem>,
																	<MenuItem key='-76KG' value='-76KG'>
																		-76KG
																	</MenuItem>,
																	<MenuItem key='-83KG' value='-83KG'>
																		-83KG
																	</MenuItem>,
																	<MenuItem key='-91KG' value='-91KG'>
																		-91KG
																	</MenuItem>,
																	<MenuItem key='-100KG' value='-100KG'>
																		-100KG
																	</MenuItem>,
																	<MenuItem key='+100KG' value='+100KG'>
																		+100KG
																	</MenuItem>,
																	<MenuItem key='Absolute' value='Absolute'>
																		Absolute
																	</MenuItem>,
															  ]
															: [
																	<MenuItem key='-66KG' value='-66KG'>
																		-66KG
																	</MenuItem>,
																	<MenuItem key='-77KG' value='-77KG'>
																		-77KG
																	</MenuItem>,
																	<MenuItem key='-88KG' value='-88KG'>
																		-88KG
																	</MenuItem>,
																	<MenuItem key='-99KG' value='-99KG'>
																		-99KG
																	</MenuItem>,
																	<MenuItem key='+99KG' value='+99KG'>
																		+99KG
																	</MenuItem>,
																	<MenuItem key='Absolute' value='Absolute'>
																		Absolute
																	</MenuItem>,
															  ])}
													{dataToEdit?.organization === 'Other' && [
														<MenuItem key='Catch' value='Catch Weight'>
															Catch Weight
														</MenuItem>,
														<MenuItem key='Other' value='Other'>
															Other
														</MenuItem>,
														<MenuItem key='Rooster' value='Rooster'>
															Rooster
														</MenuItem>,
														<MenuItem key='Light Feather' value='Light Feather'>
															Light Feather
														</MenuItem>,
														<MenuItem key='Feather' value='Feather'>
															Feather
														</MenuItem>,
														<MenuItem key='Light' value='Light'>
															Light
														</MenuItem>,
														<MenuItem key='Middle' value='Middle'>
															Middle
														</MenuItem>,
														<MenuItem key='Medium Heavy' value='Medium Heavy'>
															Medium Heavy
														</MenuItem>,
														<MenuItem key='Heavy' value='Heavy'>
															Heavy
														</MenuItem>,
														<MenuItem key='Super Heavy' value='Super Heavy'>
															Super Heavy
														</MenuItem>,
														<MenuItem key='Ultra Heavy' value='Ultra Heavy'>
															Ultra Heavy
														</MenuItem>,
														<MenuItem key='Open Class' value='Open Class'>
															Open Class
														</MenuItem>,
														<MenuItem key='-55KG' value='-55KG'>
															-55KG
														</MenuItem>,
														<MenuItem key='-60KG' value='-60KG'>
															-60KG
														</MenuItem>,
														<MenuItem key='+60KG' value='+60KG'>
															+60KG
														</MenuItem>,
														<MenuItem key='-65KG' value='-65KG'>
															-65KG
														</MenuItem>,
														<MenuItem key='+65KG' value='+65KG'>
															+65KG
														</MenuItem>,
														<MenuItem key='-66KG' value='-66KG'>
															-66KG
														</MenuItem>,
														<MenuItem key='-77KG' value='-77KG'>
															-77KG
														</MenuItem>,
														<MenuItem key='-88KG' value='-88KG'>
															-88KG
														</MenuItem>,
														<MenuItem key='-99KG' value='-99KG'>
															-99KG
														</MenuItem>,
														<MenuItem key='+99KG' value='+99KG'>
															+99KG
														</MenuItem>,
														<MenuItem key='Absolute' value='Absolute'>
															Absolute
														</MenuItem>,
													]}
												</Select>
											</FormControl>

											<Grid item xs={12} sx={{ paddingTop: '12px!important' }}>
												<FormControlLabel
													sx={{
														color: darkMode ? '#fff' : 'grey',
														'&.Mui-checked': { color: darkMode ? '#fff' : 'black' },
													}}
													control={
														<Checkbox
															sx={{ color: 'grey', '&.Mui-checked': { color: darkMode ? '#fff' : 'black' } }}
															checked={dataToEdit?.finish}
															onChange={handleChange}
															name='finish'
														/>
													}
													label='Submission Finish'
													disabled={dataToEdit?.winByDecision || dataToEdit?.winByPoints}
												/>
												<FormControlLabel
													sx={{
														color: darkMode ? '#fff' : 'grey',
														'&.Mui-checked': { color: darkMode ? '#fff' : 'black' },
													}}
													control={
														<Checkbox
															sx={{ color: 'grey', '&.Mui-checked': { color: darkMode ? '#fff' : 'black' } }}
															checked={dataToEdit?.overtime}
															onChange={handleChange}
															name='overtime'
														/>
													}
													label='Match Went to Overtime'
												/>
												<FormControlLabel
													sx={{
														color: darkMode ? '#fff' : 'grey',
														'&.Mui-checked': { color: darkMode ? '#fff' : 'black' },
													}}
													control={
														<Checkbox
															sx={{ color: 'grey', '&.Mui-checked': { color: darkMode ? '#fff' : 'black' } }}
															checked={dataToEdit?.winByPoints}
															onChange={handleChange}
															name='winByPoints'
														/>
													}
													label='Match Won Via Points'
													disabled={dataToEdit?.finish || dataToEdit?.winByDecision}
												/>
												<FormControlLabel
													sx={{
														color: darkMode ? '#fff' : 'grey',
														'&.Mui-checked': { color: darkMode ? '#fff' : 'black' },
													}}
													control={
														<Checkbox
															sx={{ color: 'grey', '&.Mui-checked': { color: darkMode ? '#fff' : 'black' } }}
															checked={dataToEdit?.winByDecision}
															onChange={handleChange}
															name='winByDecision'
														/>
													}
													label='Ref Decision'
													disabled={dataToEdit?.winByPoints || dataToEdit?.finish}
												/>
											</Grid>
											{dataToEdit?.ruleSet !== 'Submission Only' ? (
												<Grid>
													<FormLabel component='legend' sx={{ ...formLabelStyles }}>
														First To Score Wins
													</FormLabel>
													<FormControl>
														<RadioGroup row aria-label='firstToScore' name='firstToScore' value={dataToEdit?.firstToScore} onChange={handleChange}>
															<FormControlLabel
																value='Yes'
																control={
																	<Radio
																		sx={{
																			color: 'grey',
																			'&.Mui-checked': { color: darkMode ? '#fff' : 'black' },
																		}}
																	/>
																}
																label='Yes'
																sx={{ color: darkMode ? '#fff' : 'black' }}
															/>
															<FormControlLabel
																value='No'
																control={
																	<Radio
																		sx={{
																			color: 'grey',
																			'&.Mui-checked': { color: darkMode ? '#fff' : 'black' },
																		}}
																	/>
																}
																label='No'
																sx={{ color: darkMode ? '#fff' : 'black' }}
															/>
															<FormControlLabel
																value='Tie'
																control={
																	<Radio
																		sx={{
																			color: 'grey',
																			'&.Mui-checked': { color: darkMode ? '#fff' : 'black' },
																		}}
																	/>
																}
																label='Tie'
																sx={{ color: darkMode ? '#fff' : 'black' }}
															/>
															<FormControlLabel
																value='N/A'
																control={
																	<Radio
																		sx={{
																			color: 'grey',
																			'&.Mui-checked': { color: darkMode ? '#fff' : 'black' },
																		}}
																	/>
																}
																label='N/A'
																sx={{ color: darkMode ? '#fff' : 'black' }}
															/>
														</RadioGroup>
													</FormControl>
												</Grid>
											) : null}
											{dataToEdit?.finish && (
												<>
													<Grid item xs={12} sx={{ marginBottom: '8px' }}>
														<TextField
															autoComplete='off'
															sx={{
																...styles,
																marginBottom: '8px',
															}}
															fullWidth
															required
															id='timeOfFinish'
															name='timeOfFinish'
															label='Time Left On Clock (mm:ss)'
															value={tempTimeOfFinish}
															onChange={handleTimeInputChange}
															helperText='Format: mm:ss'
														/>
													</Grid>

													<FormControl
														fullWidth
														sx={{
															...styles,
															marginTop: '8px',
															marginBottom: '8px',
														}}
													>
														<InputLabel
															required
															id='submission-label'
															sx={{
																'&.MuiInputLabel-shrink': { transform: 'translate(14px, -16px) scale(0.75)' },
															}}
														>
															Submission
														</InputLabel>
														<Select
															labelId='submission-label'
															id='submission'
															name='submission'
															value={dataToEdit?.submissionType || ''}
															onChange={handleChange}
															sx={{
																color: darkMode ? '#fff' : '#000',
																backgroundColor: darkMode ? '#2E2E2E' : '#fff',
															}}
														>
															{submissionOptions &&
																submissionOptions.map((submission) => (
																	<MenuItem
																		sx={{
																			backgroundColor: darkMode ? '#2E2E2E' : '#fff',
																			color: darkMode ? '#fff' : '#000',
																			'&.Mui-selected': {
																				backgroundColor: darkMode ? '#3E474F' : '#f7f7f7',
																			},
																			'&:hover': { backgroundColor: darkMode ? 'grey' : '#f7f7f7' },
																			'&.Mui-selected:hover': {
																				backgroundColor: darkMode ? 'grey' : 'grey',
																				color: darkMode ? '#fff' : '#000',
																			},
																		}}
																		key={submission._id}
																		value={submission.name}
																	>
																		{submission.name}
																	</MenuItem>
																))}
														</Select>
													</FormControl>
													<FormControl
														fullWidth
														sx={{
															...styles,
															marginTop: '8px',
															marginBottom: '8px',
														}}
													>
														<InputLabel
															required
															id='submission-position-label'
															sx={{
																'&.MuiInputLabel-shrink': { transform: 'translate(14px, -16px) scale(0.75)' },
															}}
														>
															Submission Position
														</InputLabel>
														<Select
															labelId='submission-position-label'
															id='submission-position'
															name='submissionPosition'
															value={dataToEdit?.submissionPosition}
															onChange={handleChange}
															sx={{
																color: darkMode ? '#fff' : '#000',
																backgroundColor: darkMode ? '#2E2E2E' : '#fff',
															}}
														>
															{[
																'Back',
																'Mount',
																'Side Control',
																'Half Guard',
																'Closed Guard',
																'Open Guard',
																'North South',
																'Cross Ashi',
																'50/50',
																'Inside Ashi',
																'Outside Ashi',
																'Irimi Ashi',
																'Clamp',
																'Front Headlock',
																'Turtle',
																'Standing',
															]
																.sort()
																.map((position) => (
																	<MenuItem
																		sx={{
																			backgroundColor: darkMode ? '#2E2E2E' : '#fff',
																			color: darkMode ? '#fff' : '#000',
																			'&.Mui-selected': {
																				backgroundColor: darkMode ? '#3E474F' : '#f7f7f7',
																			},
																			'&:hover': { backgroundColor: darkMode ? 'grey' : '#f7f7f7' },
																			'&.Mui-selected:hover': {
																				backgroundColor: darkMode ? 'grey' : 'grey',
																				color: darkMode ? '#fff' : '#000',
																			},
																		}}
																		key={position}
																		value={position}
																	>
																		{position}
																	</MenuItem>
																))}
														</Select>
													</FormControl>

													{dataToEdit?.submissionType && submissionOptions[dataToEdit?.submissionType] ? (
														<FormControl
															fullWidth
															sx={{
																...styles,
																marginTop: '8px',
																marginBottom: '8px',
															}}
														>
															<InputLabel
																required
																id='submission-side-label'
																sx={{
																	'&.MuiInputLabel-shrink': {
																		transform: 'translate(14px, -16px) scale(0.75)',
																	},
																}}
															>
																Side
															</InputLabel>
															<Select
																labelId='submission-side-label'
																id='submission-side'
																name='submissionSide'
																value={dataToEdit?.submissionSide}
																onChange={handleChange}
																sx={{
																	color: darkMode ? '#fff' : '#000',
																	backgroundColor: darkMode ? '#2E2E2E' : '#fff',
																}}
															>
																{submissionOptions[dataToEdit?.submissionType].side.map((sideOption) => (
																	<MenuItem
																		sx={{
																			backgroundColor: darkMode ? '#2E2E2E' : '#fff',
																			color: darkMode ? '#fff' : '#000',
																			'&.Mui-selected': {
																				backgroundColor: darkMode ? '#3E474F' : '#f7f7f7',
																			},
																			'&:hover': { backgroundColor: darkMode ? 'grey' : '#f7f7f7' },
																			'&.Mui-selected:hover': {
																				backgroundColor: darkMode ? 'grey' : 'grey',
																				color: darkMode ? '#fff' : '#000',
																			},
																		}}
																		key={sideOption}
																		value={sideOption}
																	>
																		{sideOption}
																	</MenuItem>
																))}
															</Select>
														</FormControl>
													) : null}
												</>
											)}
											{dataToEdit?.ruleSet === 'Submission Only' ? null : (
												<>
													<Grid item xs={12} sx={{ marginBottom: '8px' }}>
														<TextField
															autoComplete='off'
															sx={{
																...styles,
																marginBottom: '8px',
															}}
															fullWidth
															required
															id='player1Points'
															name='player1Points'
															label={`${dataToEdit?.player1} Points`}
															value={dataToEdit?.player1Points}
															onChange={(e) => {
																if (e.target.value < 0) e.target.value = 0;
																handleChange(e);
															}}
															type='number'
															inputProps={{ min: 0 }}
														/>

														<TextField
															autoComplete='off'
															sx={{
																...styles,
																marginBottom: '8px',
															}}
															fullWidth
															required
															id='player1Penalties'
															name='player1Penalties'
															label={`${dataToEdit?.player1} Penalties`}
															value={dataToEdit?.player1Penalties}
															onChange={(e) => {
																if (e.target.value < 0) e.target.value = 0;
																handleChange(e);
															}}
															type='number'
															inputProps={{ min: 0 }}
														/>
														{dataToEdit?.ruleSet !== 'ADCC' ? (
															<TextField
																autoComplete='off'
																sx={{
																	...styles,
																	marginBottom: '8px',
																}}
																fullWidth
																required
																id='player1Advantages'
																name='player1Advantages'
																label={`${dataToEdit?.player1} Advantages`}
																value={dataToEdit?.player1Advantages}
																onChange={(e) => {
																	if (e.target.value < 0) e.target.value = 0;
																	handleChange(e);
																}}
																type='number'
																inputProps={{ min: 0 }}
															/>
														) : null}
													</Grid>

													<Grid item xs={12} sx={{ marginBottom: '8px' }}>
														<TextField
															autoComplete='off'
															sx={{
																...styles,
																marginBottom: '8px',
															}}
															fullWidth
															required
															id='player2Points'
															name='player2Points'
															label={`${dataToEdit?.player2} Points`}
															value={dataToEdit?.player2Points}
															onChange={(e) => {
																if (e.target.value < 0) e.target.value = 0;
																handleChange(e);
															}}
															type='number'
															inputProps={{ min: 0 }}
														/>
														<TextField
															autoComplete='off'
															sx={{
																...styles,
																marginBottom: '8px',
															}}
															fullWidth
															required
															id='player2Penalties'
															name='player2Penalties'
															label={`${dataToEdit?.player2} Penalites`}
															value={dataToEdit?.player2Penalties}
															onChange={(e) => {
																if (e.target.value < 0) e.target.value = 0;
																handleChange(e);
															}}
															type='number'
															inputProps={{ min: 0 }}
														/>

														{dataToEdit?.ruleSet !== 'ADCC' ? (
															<TextField
																autoComplete='off'
																sx={{
																	...styles,
																	marginBottom: '8px',
																}}
																fullWidth
																required
																id='player2Advantages'
																name='player2Advantages'
																label={`${dataToEdit?.player2} Advantages`}
																value={dataToEdit?.player2Advantages}
																onChange={(e) => {
																	if (e.target.value < 0) e.target.value = 0;
																	handleChange(e);
																}}
																type='number'
																inputProps={{ min: 0 }}
															/>
														) : null}
													</Grid>
												</>
											)}
										</>
									</Grid>

									<Box>
										<Grid item xs={12}>
											<Typography variant='h6' gutterBottom>
												Links and Embeds
											</Typography>
											<Typography variant='body2' sx={{ color: '#d20404' }} gutterBottom>
												* At least one link is required
											</Typography>
											<FormControl fullWidth sx={{ ...styles, marginBottom: '8px', marginTop: '8px' }}>
												<InputLabel required id='sourceOfTimestamps-label' sx={{ '&.MuiInputLabel-shrink': { transform: 'translate(14px, -16px) scale(0.75)' } }}>
													Source of Timestamps
												</InputLabel>
												<Select
													required
													labelId='sourceOfTimestamps-label'
													id='sourceOfTimestamps'
													name='sourceOfTimestamps'
													value={dataToEdit?.sourceOfTimestamps}
													onChange={handleChange}
												>
													<MenuItem value='Flo'>FloGrappling</MenuItem>
													<MenuItem value='Youtube'>Youtube</MenuItem>
													<MenuItem value='UFC'>UFC</MenuItem>
													<MenuItem value='Enigma'>Enigma</MenuItem>
												</Select>
											</FormControl>

											<div
												style={{
													display: 'flex',
													justifyContent: 'space-between',
													marginBottom: '16px',
													flexWrap: 'wrap',
												}}
											>
												<Button
													sx={{
														backgroundColor: 'grey',
														color: 'white',
														marginBottom: '16px',
														'&:hover': { backgroundColor: 'rgb(47, 180, 112)', color: 'white' },
													}}
													onClick={() => toggleFieldVisibility('showFlo')}
												>
													Add Flo Link
												</Button>
												<Button
													sx={{
														backgroundColor: 'grey',
														color: 'white',
														marginBottom: '16px',
														'&:hover': { backgroundColor: 'rgb(47, 180, 112)', color: 'white' },
													}}
													onClick={() => toggleFieldVisibility('showYoutube')}
												>
													Add Youtube Link
												</Button>
												<Button
													sx={{
														backgroundColor: 'grey',
														color: 'white',
														marginBottom: '16px',
														'&:hover': { backgroundColor: 'rgb(47, 180, 112)', color: 'white' },
													}}
													onClick={() => toggleFieldVisibility('showUFC')}
												>
													Add UFC Link
												</Button>
												<Button
													sx={{
														backgroundColor: 'grey',
														color: 'white',
														marginBottom: '16px',
														'&:hover': { backgroundColor: 'rgb(47, 180, 112)', color: 'white' },
													}}
													onClick={() => toggleFieldVisibility('showEnigma')}
												>
													Add Enigma Link
												</Button>
											</div>
											{showLinksAndEmbeds.showFlo && (
												<>
													<TextField
														autoComplete='off'
														sx={{ ...styles }}
														fullWidth
														id='floLink'
														name='floLink'
														label='FloGrappling Link'
														value={dataToEdit?.floLink}
														onChange={handleChange}
													/>
												</>
											)}
											{showLinksAndEmbeds.showYoutube && (
												<>
													<TextField
														autoComplete='off'
														sx={{ ...styles, marginBottom: '8px' }}
														fullWidth
														id='youtubeLink'
														name='youtubeLink'
														label='Youtube Link'
														value={dataToEdit?.youtubeLink}
														onChange={handleChange}
													/>
												</>
											)}
											{showLinksAndEmbeds.showUFC && (
												<>
													<TextField
														autoComplete='off'
														sx={{ ...styles }}
														fullWidth
														id='ufcLink'
														name='ufcLink'
														label='UFC Fight Pass Link'
														value={dataToEdit?.ufcLink}
														onChange={handleChange}
													/>
												</>
											)}
											{showLinksAndEmbeds.showEnigma && (
												<>
													<TextField sx={{ ...styles }} fullWidth id='enigmaLink' name='enigmaLink' label='Enigma Link' value={dataToEdit?.enigmaLink} onChange={handleChange} />
												</>
											)}
										</Grid>
									</Box>
								</Box>
							)}

							<>
								<TextField
									autoComplete='off'
									name='notes'
									label='Edit freehand notes'
									multiline
									rows={4}
									sx={{
										marginBottom: '1rem',
										...styles,
									}}
									variant='outlined'
									fullWidth
									onChange={handleChange}
									value={dataToEdit?.notes}
									helperText='Separate each note with a comma.'
								/>
								<Typography sx={{ marginBottom: '16px' }} variant='h6' gutterBottom>
									Optional Info
								</Typography>
								<Box>
									<Grid container spacing={3}>
										{dataToEdit?.submissionPosition && (
											<Grid item xs={12}>
												{submissionPositionVariations
													.filter((variation) => variation.position.toLowerCase() === dataToEdit.submissionPosition.toLowerCase())
													.map(({ _id, position, positionVariationUpper, positionVariationLower }) => (
														<div key={_id} style={{ marginBottom: '0px' }}>
															<Typography variant='h6' style={{ marginBottom: '20px' }}>
																{position} Submission Position Variations
															</Typography>
															<Dropdown
																darkMode={darkMode}
																key='positionVariationUpper'
																label='Upper body'
																options={positionVariationUpper}
																handleChange={handleChange}
																name='positionVariationUpper'
																value={dataToEdit.positionVariationUpper}
															/>
															<Dropdown
																darkMode={darkMode}
																key='positionVariationLower'
																label='Lower body'
																options={positionVariationLower}
																handleChange={handleChange}
																name='positionVariationLower'
																value={dataToEdit.positionVariationLower}
															/>
														</div>
													))}
											</Grid>
										)}

										{dataToEdit?.submissionType &&
											submissionOptions[dataToEdit?.submissionType]?.options.map((option) => (
												<FormControl
													key={option.label}
													fullWidth
													sx={{
														...styles,
													}}
												>
													<InputLabel
														id={`${option.label}-label`}
														sx={{
															transform: 'translate(12px, 32px) ',
															'&.MuiInputLabel-shrink': { transform: 'translate(4px, -2px) scale(0.75)' },
														}}
													>
														{option.label}
													</InputLabel>
													<Select
														sx={{ marginTop: '16px' }}
														labelId={`${option.label}-label`}
														id={option.label}
														name={nameMapping[option.label] || ''}
														value={dataToEdit ? dataToEdit[nameMapping[option.label]] : ''}
														onChange={handleChange}
													>
														{option.values.map((value) => (
															<MenuItem key={value} value={value}>
																{value}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											))}
										<Grid item xs={12} sm={6}>
											{loadingPlayer1Stats ? (
												<Box className='loader'>
													<Triangle color='rgb(47, 199, 112)' size={30} />
												</Box>
											) : (
												<>
													<Typography gutterBottom>
														{dataToEdit?.player1} Height: {Math.floor(dataToEdit?.player1Height / 12)} feet {dataToEdit?.player1Height % 12} inches
													</Typography>
													<Slider
														sx={{
															color: '#000000', // Set the color to black
															'& .MuiSlider-thumb': {
																color: darkMode ? 'rgb(47,199,112)' : '#000000', // Thumb color
															},
															'& .MuiSlider-track': {
																color: darkMode ? 'rgb(47,199,112)' : '#000000', // Track color
															},
															'& .MuiSlider-rail': {
																color: darkMode ? 'rgb(47,199,112)' : '#000000', // Rail color
															},
														}}
														value={dataToEdit?.player1Height || 0}
														name='player1Height'
														onChange={(event, value) => handleChange({ target: { name: 'player1Height', value } })}
														aria-labelledby='player1-height-slider'
														step={1}
														min={48}
														max={84}
														valueLabelDisplay='auto'
														valueLabelFormat={(value) => `${Math.floor(value / 12)}' ${value % 12}"`}
													/>
												</>
											)}
										</Grid>

										<Grid item xs={12} sm={6}>
											{loadingPlayer2Stats ? (
												<Box className='loader'>
													<Triangle color='rgb(47, 199, 112)' size={30} />
												</Box>
											) : (
												<>
													<Typography gutterBottom>
														{dataToEdit?.player2} Height: {Math.floor(dataToEdit?.player2Height / 12)} feet {dataToEdit?.player2Height % 12} inches
													</Typography>
													<Slider
														sx={{
															color: '#000000', // Set the color to black
															'& .MuiSlider-thumb': {
																color: darkMode ? 'rgb(47,199,112)' : '#000000', // Thumb color
															},
															'& .MuiSlider-track': {
																color: darkMode ? 'rgb(47,199,112)' : '#000000', // Track color
															},
															'& .MuiSlider-rail': {
																color: darkMode ? 'rgb(47,199,112)' : '#000000', // Rail color
															},
														}}
														value={dataToEdit?.player2Height || 0}
														name='player2Height'
														onChange={(event, value) => handleChange({ target: { name: 'player2Height', value } })}
														aria-labelledby='player2-height-slider'
														step={1}
														min={48}
														max={84}
														valueLabelDisplay='auto'
														valueLabelFormat={(value) => `${Math.floor(value / 12)}' ${value % 12}"`}
													/>
												</>
											)}
										</Grid>

										<Grid item xs={12} sm={6}>
											{loadingPlayer1Stats ? (
												<Box className='loader'>
													<Triangle color='rgb(47, 199, 112)' size={30} />
												</Box>
											) : (
												<>
													<Typography gutterBottom>
														{dataToEdit?.player1} Wingspan: {dataToEdit?.player1Wingspan} inches
													</Typography>
													<Slider
														sx={{
															color: '#000000', // Set the color to black
															'& .MuiSlider-thumb': {
																color: darkMode ? 'rgb(47,199,112)' : '#000000', // Thumb color
															},
															'& .MuiSlider-track': {
																color: darkMode ? 'rgb(47,199,112)' : '#000000', // Track color
															},
															'& .MuiSlider-rail': {
																color: darkMode ? 'rgb(47,199,112)' : '#000000', // Rail color
															},
														}}
														value={dataToEdit?.player1Wingspan || 0}
														name='player1Wingspan'
														onChange={(event, value) => handleChange({ target: { name: 'player1Wingspan', value } })}
														aria-labelledby='player1-wingspan-slider'
														step={1}
														min={50}
														max={100}
														valueLabelDisplay='auto'
													/>
												</>
											)}
										</Grid>
										<Grid item xs={12} sm={6}>
											{loadingPlayer2Stats ? (
												<Box className='loader'>
													<Triangle color='rgb(47, 199, 112)' size={30} />
												</Box>
											) : (
												<>
													<Typography gutterBottom>
														{dataToEdit?.player2} Wingspan: {dataToEdit?.player2Wingspan} inches
													</Typography>
													<Slider
														sx={{
															color: '#000000', // Set the color to black
															'& .MuiSlider-thumb': {
																color: darkMode ? 'rgb(47,199,112)' : '#000000', // Thumb color
															},
															'& .MuiSlider-track': {
																color: darkMode ? 'rgb(47,199,112)' : '#000000', // Track color
															},
															'& .MuiSlider-rail': {
																color: darkMode ? 'rgb(47,199,112)' : '#000000', // Rail color
															},
														}}
														value={dataToEdit?.player2Wingspan || 0}
														name='player2Wingspan'
														onChange={(event, value) => handleChange({ target: { name: 'player2Wingspan', value } })}
														aria-labelledby='player2-wingspan-slider'
														step={1}
														min={50}
														max={100}
														valueLabelDisplay='auto'
													/>
												</>
											)}
										</Grid>
										<Grid item xs={12} sm={6}>
											{loadingPlayer1Stats ? (
												<Box className='loader'>
													<Triangle color='rgb(47, 199, 112)' size={30} />
												</Box>
											) : (
												<>
													<Typography gutterBottom>
														{dataToEdit?.player1} Inseam: {dataToEdit?.player1Inseam} inches
													</Typography>
													<Slider
														sx={{
															color: '#000000', // Set the color to black
															'& .MuiSlider-thumb': {
																color: darkMode ? 'rgb(47,199,112)' : '#000000', // Thumb color
															},
															'& .MuiSlider-track': {
																color: darkMode ? 'rgb(47,199,112)' : '#000000', // Track color
															},
															'& .MuiSlider-rail': {
																color: darkMode ? 'rgb(47,199,112)' : '#000000', // Rail color
															},
														}}
														value={dataToEdit?.player1Inseam || 0}
														name='player1Inseam'
														onChange={(event, value) => handleChange({ target: { name: 'player1Inseam', value } })}
														aria-labelledby='player1-inseam-slider'
														step={1}
														min={20}
														max={40}
														valueLabelDisplay='auto'
													/>
												</>
											)}
										</Grid>
										<Grid item xs={12} sm={6}>
											{loadingPlayer2Stats ? (
												<Box className='loader'>
													<Triangle color='rgb(47, 199, 112)' size={30} />
												</Box>
											) : (
												<>
													<Typography gutterBottom>
														{dataToEdit?.player2} Inseam: {dataToEdit?.player2Inseam} inches
													</Typography>
													<Slider
														sx={{
															color: '#000000', // Set the color to black
															'& .MuiSlider-thumb': {
																color: darkMode ? 'rgb(47,199,112)' : '#000000', // Thumb color
															},
															'& .MuiSlider-track': {
																color: darkMode ? 'rgb(47,199,112)' : '#000000', // Track color
															},
															'& .MuiSlider-rail': {
																color: darkMode ? 'rgb(47,199,112)' : '#000000', // Rail color
															},
														}}
														value={dataToEdit?.player2Inseam || 0}
														name='player2Inseam'
														onChange={(event, value) => handleChange({ target: { name: 'player2Inseam', value } })}
														aria-labelledby='player2-inseam-slider'
														step={1}
														min={20}
														max={40}
														valueLabelDisplay='auto'
													/>
												</>
											)}
										</Grid>

										<Grid item xs={12} sm={6}>
											{loadingPlayer1Stats ? (
												<Box className='loader'>
													<Triangle color='rgb(47, 199, 112)' size={30} />
												</Box>
											) : (
												<>
													<Typography gutterBottom>
														{dataToEdit?.player1} Chest: {dataToEdit?.player1Chest} inches
													</Typography>
													<Slider
														sx={{
															color: '#000000', // Set the color to black
															'& .MuiSlider-thumb': {
																color: darkMode ? 'rgb(47,199,112)' : '#000000', // Thumb color
															},
															'& .MuiSlider-track': {
																color: darkMode ? 'rgb(47,199,112)' : '#000000', // Track color
															},
															'& .MuiSlider-rail': {
																color: darkMode ? 'rgb(47,199,112)' : '#000000', // Rail color
															},
														}}
														value={dataToEdit?.player1Chest || 0}
														name='player1Chest'
														onChange={(event, value) => handleChange({ target: { name: 'player1Chest', value } })}
														aria-labelledby='player1-chest-slider'
														step={1}
														min={20}
														max={60}
														valueLabelDisplay='auto'
													/>
												</>
											)}
										</Grid>
										<Grid item xs={12} sm={6}>
											{loadingPlayer2Stats ? (
												<Box className='loader'>
													<Triangle color='rgb(47, 199, 112)' size={30} />
												</Box>
											) : (
												<>
													<Typography gutterBottom>
														{dataToEdit?.player2} Chest: {dataToEdit?.player2Chest} inches
													</Typography>
													<Slider
														sx={{
															color: '#000000', // Set the color to black
															'& .MuiSlider-thumb': {
																color: darkMode ? 'rgb(47,199,112)' : '#000000', // Thumb color
															},
															'& .MuiSlider-track': {
																color: darkMode ? 'rgb(47,199,112)' : '#000000', // Track color
															},
															'& .MuiSlider-rail': {
																color: darkMode ? 'rgb(47,199,112)' : '#000000', // Rail color
															},
														}}
														value={dataToEdit?.player2Chest || 0}
														name='player2Chest'
														onChange={(event, value) => handleChange({ target: { name: 'player2Chest', value } })}
														aria-labelledby='player2-chest-slider'
														step={1}
														min={20}
														max={60}
														valueLabelDisplay='auto'
													/>
												</>
											)}
										</Grid>
									</Grid>
								</Box>
							</>

							<Button
								type='submit'
								variant='contained'
								color='primary'
								size='large'
								startIcon={<SportsKabaddi />}
								sx={{ backgroundColor: 'rgb(47, 199, 112)', '&:hover': { backgroundColor: 'rgb(47, 180, 112)' } }}
								style={{ marginTop: '20px' }}
								disabled={loading}
							>
								Save
							</Button>
						</form>
					</Container>
				)}
			</Container>
		</>
	);
}

export default EditData;

import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';

const PieChart = ({ percentage, term, darkMode, position }) => {
	const data = {
		labels: [term, position ? 'Other Positions' : 'Other Submissions'],
		datasets: [
			{
				data: [percentage, 100 - percentage],
				backgroundColor: [darkMode ? '#f64747' : 'red', darkMode ? 'rgb(47,130,112)' : 'rgb(47,160,112)'],
				hoverBackgroundColor: [darkMode ? '#f64747' : 'red', darkMode ? 'rgb(47,130,112)' : 'rgb(47,160,112)'],
			},
		],
	};

	const options = {
		plugins: {
			tooltip: {
				callbacks: {
					label: function (context) {
						let label = context.label.padStart(context.label.length + 2) || '';
						if (label) {
							label += ': ';
						}
						label += Math.round(context.raw * 100) / 100 + '%';
						return label;
					},
				},
			},
			legend: {
				display: false, // Disable the legend
			},
		},
		cutout: '65%', // Adjust this value to control the size of the hole
	};

	return (
		<div style={{ position: 'relative', maxHeight: '200px', maxWidth: '200px' }}>
			<Doughnut data={data} options={options} />
			<div
				style={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					textAlign: 'center',
					fontWeight: '600',
				}}
			>
				<p style={{ margin: 0 }}>{term}</p>
				<p style={{ margin: 0 }}>{percentage}%</p>
			</div>
		</div>
	);
};

export default PieChart;

import React, { useState } from 'react';
import axios from 'axios';
import { useMediaQuery, Button } from '@mui/material';

const API_URL = process.env.REACT_APP_API_URL;
function CreateFeatureForm({ darkMode, setNewFeature }) {
	const isMobile = useMediaQuery('(max-width:600px)');
	const [matchId, setMatchId] = useState('');
	const [sequenceId, setSequenceId] = useState('');
	const [resourceId, setResourceId] = useState('');
	const [youtubeLink, setYoutubeLink] = useState('');
	const [date, setDate] = useState('');
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [message, setMessage] = useState('');

	const handleSubmit = async (e) => {
		e.preventDefault();
		setIsSubmitting(true);
		setMessage('');

		try {
			const payload = { matchId, sequenceId, resourceId, youtubeLink, date };
			const response = await axios.post(`${API_URL}/featured`, payload);
			if (response.status === 201) setNewFeature(true);
			// Reset form after submission
			setMatchId('');
			setYoutubeLink('');
			setSequenceId('');
			setResourceId('');
			setDate('');
			setMessage('Feature created successfully!');
		} catch (error) {
			setMessage('Failed to create feature. ' + error);
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<form onSubmit={handleSubmit} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', padding: '1rem', minHeight: '100vh' }}>
			<div>
				<label htmlFor='matchId'>Match ID:</label>
				<input id='matchId' type='text' value={matchId} onChange={(e) => setMatchId(e.target.value)} required />
			</div>
			<div>
				<label htmlFor='sequenceId'>Sequence ID:</label>
				<input id='sequenceId' type='text' value={sequenceId} onChange={(e) => setSequenceId(e.target.value)} required />
			</div>
			<div>
				<label htmlFor='resourceId'>Resource ID:</label>
				<input id='resourceId' type='text' value={resourceId} onChange={(e) => setResourceId(e.target.value)} required />
			</div>
			<div>
				<label htmlFor='youtubeLink'>YouTube Shorts Link:</label>
				<input id='youtubeLink' type='text' value={youtubeLink} onChange={(e) => setYoutubeLink(e.target.value)} required />
			</div>
			<div>
				<label htmlFor='date'>Date:</label>
				<input id='date' type='date' value={date} onChange={(e) => setDate(e.target.value)} required />
			</div>
			<Button
				type='submit'
				disabled={isSubmitting}
				sx={{
					color: darkMode ? '#000' : '#fff',
					background: 'rgb(47, 160, 112)',
					padding: '.5rem',
					borderRadius: '5px',
					outline: 'none',
					border: 'none',
					margin: '1rem 0',
					cursor: 'pointer',
					width: isMobile ? '100%' : '25%',
					'&:hover': {
						background: 'rgb(47, 180, 112)',
					},
				}}
			>
				{isSubmitting ? 'Creating...' : 'Create Weekly Feature'}
			</Button>
			{message && <p>{message}</p>}
		</form>
	);
}

export default CreateFeatureForm;

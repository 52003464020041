import { Helmet, HelmetProvider } from 'react-helmet-async';

const Head = (props) => {

    return (
        <HelmetProvider>
            <Helmet>
                <title>Outlier DB | {props.title}</title>
            </Helmet>
        </HelmetProvider>

    );
};

export default Head;
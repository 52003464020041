import { useState } from 'react';
import { Container, Grid, Typography, Button, Box, useMediaQuery } from '@mui/material';
import { Triangle } from 'react-loader-spinner';
import Head from '../components/Head/Head';
import axios from 'axios';
import '../App.css';

const API_URL = process.env.REACT_APP_API_URL;

// if environment is production, use live prices, otherwise use test prices
const live12Price = 'price_1NbSv0LmHtzU7UrE5ydczNiz';
const live20Price = 'price_1PM11qLmHtzU7UrEoUpvlndb';
const test12Price = 'price_1NekzxLmHtzU7UrEArPbq19t';
const test20Price = 'price_1P5HcZLmHtzU7UrEEHfGggOR';

const isProduction = process.env.NODE_ENV === 'production';
const twelvePrice = isProduction ? live12Price : test12Price;
const twentyPrice = isProduction ? live20Price : test20Price;

function Account({ user, authUser }) {
	const isMobile = useMediaQuery('(max-width:600px)');
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');

	const handleSubscription = async () => {
		let priceId;
		if (user?.grandfathered === true) {
			priceId = twelvePrice;
		} else {
			priceId = twentyPrice;
		}
		setLoading(true);
		const url = `${API_URL}/user/stripe-checkout`;
		try {
			const response = await axios.post(url, { priceId, email: user?.email });
			window.location.replace(response.data.sessionurl);
		} catch (error) {
			setError(`${error.response.data.error} Please try again.`);
		} finally {
			setLoading(false);
		}
	};

	const handleCustomerPortal = async () => {
		setLoading(true);

		const token = await authUser?.getIdToken();
		const url = `${API_URL}/user/create-customer-portal-session`;

		try {
			const response = await axios.post(url, null, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			window.location.replace(response.data.url);
		} catch (error) {
			setError('Error accessing customer portal.');
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<Head title='Account' />
			<Container>
				<Box sx={{ my: 3, textAlign: 'center', justifyContent: 'center' }}>
					<Typography variant='h1' sx={{ fontSize: '1.5rem' }} component='h1' gutterBottom>
						Manage Subscription
					</Typography>

					<Grid item zIndex={1000}>
						{error && <Typography color='error'>{error}</Typography>}
						{loading && (
							<div className='centered'>
								<Triangle height='80' width='80' color='rgb(47, 199, 112)' ariaLabel='triangle-loading' />
								<Typography
									variant='h4'
									sx={{
										color: 'rgb(47, 199, 112)',
									}}
								>
									Loading Stripe...
								</Typography>
							</div>
						)}
						{user?.subscriptionStatus === 'active' ? (
							<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', padding: '.5rem' }}>
								<Typography variant='body1' sx={{ marginTop: '1rem' }}>
									<strong>Subscription: </strong>
									<span style={{ color: 'rgb(47, 199, 112)' }}>Active</span>
								</Typography>

								<Button
									variant='contained'
									sx={{
										marginTop: '1rem',
										minWidth: '175px',
										backgroundColor: 'rgb(47, 160, 112)',
										'&:hover': { backgroundColor: 'rgb(47, 199, 112)' },
									}}
									onClick={handleCustomerPortal}
									fullWidth={isMobile ? true : false}
								>
									Manage Subscription
								</Button>
							</div>
						) : (
							<>
								<Typography variant='body1' gutterBottom>
									<strong>Subscription: </strong>
									<span style={{ color: '#d20404' }}>None</span>
								</Typography>

								<Typography variant='body2' sx={{ marginBottom: '1rem', marginTop: '1rem' }}>
									Use code <strong style={{ animation: 'glowing 1s infinite alternate' }}>WELCOME25</strong> for 25% off your first month
								</Typography>
								<Button
									variant='contained'
									sx={{
										backgroundColor: 'rgb(47, 160, 112)',
										minWidth: '175px',
										marginBottom: '1rem',
										'&:hover': { backgroundColor: 'rgb(47, 199, 112)' },
									}}
									onClick={handleSubscription}
								>
									Subscribe Now
								</Button>
							</>
						)}
					</Grid>
				</Box>
			</Container>
		</>
	);
}

export default Account;

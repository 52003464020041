import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Typography, Box, useMediaQuery } from '@mui/material';
import Head from '../components/Head/Head';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const ContactPage = ({ darkMode, user }) => {
	window.scrollTo(0, 0, 'smooth');
	const [message, setMessage] = useState('');
	const [loading, setLoading] = useState(false);
	const isMobile = useMediaQuery('(max-width:600px)');
	const [formData, setFormData] = useState({
		name: '',
		email: user?.email || '',
		subject: '',
		message: '',
	});

	const styles = {
		// Change outline color when hovered but not focused
		'&:hover .MuiOutlinedInput-notchedOutline:not(.Mui-focused)': {
			borderColor: darkMode ? 'white!important' : 'grey',
		},
		// Change label color when hovered but not focused
		'&:hover .MuiInputLabel-root:not(.Mui-focused)': {
			color: darkMode ? 'white' : 'rgba(0,0,0,.6)',
		},
		// For text color
		'&& input': {
			color: darkMode ? 'rgb(225,225,225)' : 'rgba(0,0,0,.6)',
		},
		// Default outline color
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: darkMode ? 'grey!important' : 'grey',
		},
		// Focused outline color
		'& .MuiOutlinedInput-notchedOutline.Mui-focused': {
			borderColor: darkMode ? 'rgb(47,199,112)' : 'rgba(0,0,0,.6)',
		},
		// Background color
		'& .MuiOutlinedInput-root': {
			backgroundColor: darkMode ? 'rgba(0,0,0,.6)' : '#f7f7f7',
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			borderColor: 'rgb(47, 199, 112)!important',
		},
		// For label text color
		'& .MuiInputLabel-root': {
			color: darkMode ? 'grey' : 'grey',
		},
		// Focused label color
		'& .MuiInputLabel-outlined.Mui-focused': {
			color: 'rgb(47, 180, 112)',
		},
	};

	const handleChange = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		try {
			await axios.post(`${API_URL}/suggestions/email`, formData);
			setMessage('Message sent successfully!');
			setFormData({ name: '', email: user?.email || '', subject: '', message: '' });
		} catch (error) {
			console.error('There was an error sending the message!', error);
		} finally {
			setLoading(false);
		}
	};

	// reset message after 5 seconds
	useEffect(() => {
		if (message) {
			const timeout = setTimeout(() => {
				setMessage('');
			}, 4000);
			return () => clearTimeout(timeout);
		}
	}, [message]);

	return (
		<Container sx={{ height: '100vh', mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
			<Head title='Contact Us' />
			<Typography variant='h5' gutterBottom>
				Contact Us
			</Typography>
			<Typography variant='h6' sx={{ textAlign: 'center' }} gutterBottom>
				Use the form below or email us at{' '}
				<Box
					component='a'
					href='mailto:limibjjdata@gmail.com'
					sx={{
						textDecoration: 'none',
						color: 'rgb(47, 160, 112)',
						'&:hover': {
							textDecoration: 'underline',
							color: 'rgb(47, 180, 112)',
						},
					}}
				>
					limibjjdata@gmail.com
				</Box>
			</Typography>

			<Box component='form' onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: isMobile ? '100%' : '60%' }}>
				<TextField sx={{ ...styles }} label='Name' name='name' value={formData.name} onChange={handleChange} required />
				<TextField sx={{ ...styles }} label='Email' name='email' type='email' value={formData.email} onChange={handleChange} required />
				<TextField sx={{ ...styles }} label='Subject' name='subject' value={formData.subject} onChange={handleChange} required />
				<TextField sx={{ ...styles }} label='Message' name='message' multiline rows={8} value={formData.message} onChange={handleChange} required />
				{message && <Typography sx={{ color: 'rgb(47, 199, 112)' }}>{message}</Typography>}
				<Button
					disabled={loading}
					variant='contained'
					sx={{ background: 'rgb(47,160,112)', mx: 'auto', color: darkMode ? '#000' : '#fff', '&:hover': { background: 'rgb(47,180,112)' }, width: isMobile ? '100%' : '350px' }}
					type='submit'
				>
					Send Message
				</Button>
			</Box>
		</Container>
	);
};

export default ContactPage;

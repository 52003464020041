import { useState, useEffect } from 'react';
import { Box, Typography, Grid, Link, Divider, Button, Tooltip } from '@mui/material';
import ArguFlowLogo from '../assets/trieve-logo.webp';
import LIMIBJJ from '../assets/TransparentLogo.svg';
import { useMediaQuery } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useNavigate } from 'react-router-dom';
import SchoolIcon from '@mui/icons-material/School';
import TimelineIcon from '@mui/icons-material/Timeline';
import SportsKabaddiIcon from '@mui/icons-material/SportsKabaddi';
import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;

function Footer({ darkMode, user, authUser }) {
	const navigate = useNavigate();
	const isMobile = useMediaQuery('(max-width:600px)');
	const [showAbout, setShowAbout] = useState(false);
	const [siteStats, setSiteStats] = useState(null);
	const toggleAbout = (e) => {
		e.preventDefault();
		setShowAbout(!showAbout);
	};

	useEffect(() => {
		const fetchSiteStats = async () => {
			try {
				const response = await axios.get(`${API_URL}/data/site-stats`);
				setSiteStats(response.data);
			} catch (error) {
				console.error('Error fetching site stats:', error);
			}
		};
		fetchSiteStats();
	}, []);

	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				padding: isMobile ? '0.5rem' : '1rem',
				background: 'rgb(86, 223, 137)',
				height: '250px',
			}}
		>
			<Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', width: '30%' }}>
				<img
					src={LIMIBJJ}
					alt='logo'
					style={{ cursor: 'pointer', height: isMobile ? '50px' : '60px', width: isMobile ? '50px' : '60px', borderRadius: '50%', background: 'rgb(0,0,0)', marginBottom: '.5rem' }}
					onClick={() => navigate('/')}
				/>
				<Typography variant='caption' sx={{ fontFamily: 'monospace', color: 'rgb(30,30,30)', mb: '.2rem', fontSize: isMobile ? '.5rem' : '0.7rem' }}>
					Copyright &copy; {new Date().getFullYear()}
				</Typography>
				<Typography
					variant='caption'
					sx={{
						fontFamily: 'monospace',
						lineHeight: '17px',
						color: 'rgb(30,30,30)',
						display: 'flex',
						mb: '.2rem',
						flexWrap: 'wrap',
						maxWidth: isMobile ? '125px' : '100%',
						fontSize: isMobile ? '.5rem' : '0.7rem',
					}}
				>
					Outlier Database. All Rights Reserved.
				</Typography>
				<a
					style={{
						textDecoration: 'none',
						fontSize: isMobile ? '0.5rem' : '10px',
						color: '#505050',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-start',
						marginTop: isMobile ? '0' : '.5rem',
					}}
					href='https://trieve.ai/'
					target='_blank'
					rel='noopener noreferrer'
				>
					<em>Search By Trieve</em>
					<img src={ArguFlowLogo} alt='logo' style={{ height: isMobile ? '30px' : '40px', width: isMobile ? '30px' : '40px', marginLeft: '1.25rem' }} />
				</a>
			</Box>

			<Box sx={{ flexGrow: 1, p: 1, position: 'relative' }}>
				<Grid container spacing={2}>
					<Grid item xs={4}>
						<Typography variant='h6' sx={{ fontSize: isMobile ? '.8rem' : '1.15rem', color: 'rgb(30,30,30)' }} gutterBottom>
							Outlier DB
						</Typography>
						<Divider color='rgb(30,30,30)' sx={{ borderBottomWidth: 2, mb: 1, width: '300%' }} />
						<Typography
							sx={{
								fontSize: isMobile ? '.6rem' : '.8rem',
								color: 'rgb(30,30,30)',
								fontFamily: 'monospace',
								textDecoration: 'none',
								mb: '.6rem',
								cursor: 'pointer',
								'&:hover': { background: 'rgb(225,225,225)' },
							}}
							href='#'
							variant='caption'
							display='block'
							gutterBottom
							onClick={(e) => toggleAbout(e)}
						>
							ABOUT
						</Typography>
						{showAbout && (
							<Box
								sx={{
									position: 'absolute', // Positioned relative to its nearest positioned ancestor (footer box)
									bottom: '100%', // Align the top of the about section with the bottom of the footer box
									left: '0', // Center the about section horizontally
									width: '50%',
									bgcolor: 'rgb(30,30,30)',
									p: 1,
									display: 'flex',
									flexDirection: 'column',
									alignContent: 'center',
									zIndex: 'tooltip', // Ensures it's above other positioned elements
								}}
							>
								{/* ... The "About" content ... */}
								<Box sx={{ paddingTop: '0.25rem' }}>
									<Typography variant='h6' sx={{ textAlign: isMobile ? 'center' : 'left', padding: '.5rem', fontSize: isMobile ? '1rem' : '1.25rem', color: 'rgb(225,225,225)' }} gutterBottom>
										Discover
									</Typography>
									<Grid container spacing={3} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
										<Grid item>
											<Card
												sx={{
													height: 100,
													width: 100,
													borderRadius: '50%',
													display: 'flex',
													flexDirection: 'column',
													justifyContent: 'center',
													alignItems: 'center',
													textAlign: 'center',
													marginBottom: '.5rem',
													backgroundColor: darkMode ? 'rgb(30,30,30)' : 'rgb(240,240,240)',
													border: '2px solid rgb(47, 199, 112)',
												}}
											>
												<CardContent>
													<Typography variant='body2' component='div' sx={{ fontSize: '0.8rem' }}>
														Matches <SportsKabaddiIcon />
													</Typography>
													<Typography sx={{ fontSize: '0.8rem' }} variant='body2'>
														{siteStats.totalMatches}
													</Typography>
												</CardContent>
											</Card>
										</Grid>
										<Grid item>
											<Card
												sx={{
													height: 100,
													width: 100,
													borderRadius: '50%',
													display: 'flex',
													flexDirection: 'column',
													justifyContent: 'center',
													alignItems: 'center',
													textAlign: 'center',
													marginBottom: '.5rem',
													backgroundColor: darkMode ? 'rgb(30,30,30)' : 'rgb(240,240,240)',
													border: '2px solid rgb(47, 199, 112)',
												}}
											>
												<CardContent>
													<Typography variant='body2' component='div' sx={{ fontSize: '0.8rem' }}>
														Resources <SchoolIcon />
													</Typography>
													<Typography sx={{ fontSize: '0.8rem' }} variant='body2'>
														{siteStats.totalResources}
													</Typography>
												</CardContent>
											</Card>
										</Grid>
										<Grid item>
											<Card
												sx={{
													height: 100,
													width: 100,
													borderRadius: '50%',
													display: 'flex',
													flexDirection: 'column',
													justifyContent: 'center',
													alignItems: 'center',
													textAlign: 'center',
													marginBottom: '.5rem',
													backgroundColor: darkMode ? 'rgb(30,30,30)' : 'rgb(240,240,240)',
													border: '2px solid rgb(47, 199, 112)',
												}}
											>
												<CardContent>
													<Typography variant='body2' component='div' sx={{ fontSize: '0.8rem' }}>
														Sequences <TimelineIcon />
													</Typography>
													<Typography sx={{ fontSize: '0.8rem' }} variant='body2'>
														{siteStats.totalSequences}
													</Typography>
												</CardContent>
											</Card>
										</Grid>
									</Grid>
								</Box>

								<Button
									variant='contained'
									sx={{
										color: 'white',
										mt: 2,
										mx: 'auto',
										backgroundColor: '#d20404',

										'&:hover': {
											backgroundColor: '#db1c1c',
										},
									}}
									onClick={(e) => toggleAbout(e)}
								>
									close
								</Button>
							</Box>
						)}

						<Typography
							sx={{
								fontSize: isMobile ? '.6rem' : '.8rem',
								color: 'rgb(30,30,30)',
								fontFamily: 'monospace',
								textDecoration: 'none',
								cursor: 'pointer',
								mb: '.6rem',
								'&:hover': { background: 'rgb(225,225,225)' },
							}}
							onClick={() => navigate('/contact')}
							variant='p'
							display='block'
							gutterBottom
						>
							CONTACT
						</Typography>
						<Typography
							sx={{
								fontSize: isMobile ? '.6rem' : '.8rem',
								color: 'rgb(30,30,30)',
								fontFamily: 'monospace',
								textDecoration: 'none',
								cursor: 'pointer',
								'&:hover': { background: 'rgb(225,225,225)' },
							}}
							component='p'
							onClick={() => navigate('/suggestions-form')}
							display='block'
							gutterBottom
						>
							FEEDBACK
						</Typography>
					</Grid>
					<Grid item xs={4}>
						<Typography variant='h6' sx={{ fontSize: isMobile ? '.8rem' : '1.15rem', color: 'rgb(30,30,30)' }} gutterBottom>
							Social
						</Typography>
						<Divider color='rgb(30,30,30)' sx={{ borderBottomWidth: 2, mb: 1 }} />
						{user?.subscriptionStatus === 'active' ? (
							<Link
								component='a'
								sx={{
									fontSize: isMobile ? '.6rem' : '.8rem',
									color: 'rgb(30,30,30)',
									fontFamily: 'monospace',
									textDecoration: 'none',
									cursor: 'pointer',
									mb: '.5rem',
									'&:hover': { background: 'rgb(225,225,225)' },
								}}
								href='https://discord.com/invite/bNjKuytcrM'
								target='_blank'
								rel='noopener noreferrer'
								variant='caption'
								display='block'
								gutterBottom
							>
								DISCORD
							</Link>
						) : (
							<Link
								component='a'
								sx={{
									fontSize: isMobile ? '.6rem' : '.8rem',
									color: 'rgb(30,30,30)',
									fontFamily: 'monospace',
									textDecoration: 'none',
									cursor: 'pointer',
									mb: '.5rem',
									'&:hover': { background: 'rgb(225,225,225)' },
								}}
								href=''
								target='_blank'
								rel='noopener noreferrer'
								variant='caption'
								display='block'
								gutterBottom
							>
								DISCORD (Member only feature)
							</Link>
						)}
						<Link
							sx={{
								fontSize: isMobile ? '.6rem' : '.8rem',
								color: 'rgb(30,30,30)',
								fontFamily: 'monospace',
								textDecoration: 'none',
								cursor: 'pointer',
								mb: '.5rem',
								'&:hover': { background: 'rgb(225,225,225)' },
							}}
							target='_blank'
							rel='noopener noreferrer'
							href='https://www.instagram.com/limibjj'
							variant='caption'
							display='block'
							gutterBottom
						>
							INSTAGRAM
						</Link>
						<Link
							sx={{
								fontSize: isMobile ? '.6rem' : '.8rem',
								color: 'rgb(30,30,30)',
								fontFamily: 'monospace',
								textDecoration: 'none',
								cursor: 'pointer',
								'&:hover': { background: 'rgb(225,225,225)' },
							}}
							href='https://www.youtube.com/@LIMIBJJ'
							target='_blank'
							rel='noopener noreferrer'
							variant='caption'
							display='block'
							gutterBottom
						>
							YOUTUBE
						</Link>
					</Grid>
					<Grid item xs={4}>
						<Typography variant='h6' sx={{ fontSize: isMobile ? '.8rem' : '1.15rem', color: 'rgb(30,30,30)' }} gutterBottom>
							Menu
						</Typography>
						<Divider color='rgb(30,30,30)' sx={{ borderBottomWidth: 2, mb: 1 }} />
						{!authUser || user?.subscriptionStatus !== 'active' ? (
							<>
								<Tooltip title='Subscription Required' placement='left' arrow>
									<Link
										sx={{
											fontSize: isMobile ? '.6rem' : '.8rem',
											color: 'rgb(30,30,30)',
											fontFamily: 'monospace',
											textDecoration: 'none',
											mb: '.5rem',
											cursor: 'pointer',
											'&:hover': { background: 'rgb(225,225,225)' },
										}}
										variant='caption'
										display='block'
										gutterBottom
									>
										MATCHES
									</Link>
								</Tooltip>
								<Tooltip title='Subscription Required' placement='left' arrow>
									<Link
										sx={{
											fontSize: isMobile ? '.6rem' : '.8rem',
											color: 'rgb(30,30,30)',
											fontFamily: 'monospace',
											textDecoration: 'none',
											cursor: 'pointer',
											mb: '.5rem',
											'&:hover': { background: 'rgb(225,225,225)' },
										}}
										variant='caption'
										display='block'
										gutterBottom
									>
										SEQUENCES
									</Link>
								</Tooltip>
								<Tooltip title='Subscription Required' placement='left' arrow>
									<Link
										sx={{
											fontSize: isMobile ? '.6rem' : '.8rem',
											color: 'rgb(30,30,30)',
											fontFamily: 'monospace',
											textDecoration: 'none',
											cursor: 'pointer',
											mb: '.5rem',
											'&:hover': { background: 'rgb(225,225,225)' },
										}}
										variant='caption'
										display='block'
										gutterBottom
									>
										RESOURCES
									</Link>
								</Tooltip>

								<Tooltip title='Subscription Required' placement='left' arrow>
									<Link
										sx={{
											fontSize: isMobile ? '.6rem' : '.8rem',
											color: 'rgb(30,30,30)',
											fontFamily: 'monospace',
											textDecoration: 'none',
											cursor: 'pointer',
											mb: '.5rem',
											'&:hover': { background: 'rgb(225,225,225)' },
										}}
										variant='caption'
										display='block'
										gutterBottom
									>
										STATISTICS
									</Link>
								</Tooltip>
							</>
						) : (
							<>
								<Typography
									sx={{
										fontSize: isMobile ? '.6rem' : '.8rem',
										color: 'rgb(30,30,30)',
										fontFamily: 'monospace',
										textDecoration: 'none',
										mb: '.5rem',
										cursor: 'pointer',
										'&:hover': { background: 'rgb(225,225,225)' },
									}}
									onClick={() => navigate('/matches')}
									variant='caption'
									display='block'
									gutterBottom
								>
									MATCHES
								</Typography>
								<Typography
									sx={{
										fontSize: isMobile ? '.6rem' : '.8rem',
										color: 'rgb(30,30,30)',
										fontFamily: 'monospace',
										textDecoration: 'none',
										cursor: 'pointer',
										mb: '.5rem',
										'&:hover': { background: 'rgb(225,225,225)' },
									}}
									onClick={() => navigate('/sequences')}
									variant='caption'
									display='block'
									gutterBottom
								>
									SEQUENCES
								</Typography>
								<Typography
									sx={{
										fontSize: isMobile ? '.6rem' : '.8rem',
										color: 'rgb(30,30,30)',
										fontFamily: 'monospace',
										textDecoration: 'none',
										cursor: 'pointer',
										mb: '.5rem',
										'&:hover': { background: 'rgb(225,225,225)' },
									}}
									onClick={() => navigate('/training-resources')}
									variant='caption'
									display='block'
									gutterBottom
								>
									RESOURCES
								</Typography>

								<Typography
									sx={{
										fontSize: isMobile ? '.6rem' : '.8rem',
										color: 'rgb(30,30,30)',
										fontFamily: 'monospace',
										textDecoration: 'none',
										cursor: 'pointer',
										'&:hover': { background: 'rgb(225,225,225)' },
									}}
									onClick={() => navigate('/statistics')}
									variant='caption'
									display='block'
									gutterBottom
								>
									STATISTICS
								</Typography>
							</>
						)}
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
}

export default Footer;

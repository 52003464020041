import React, { useState, useEffect } from 'react';
import { Button, Container, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import Head from '../components/Head/Head';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

function SuggestionsForm({ user, darkMode }) {
	const [suggestionType, setSuggestionType] = useState('');
	const [suggestedTerm, setSuggestedTerm] = useState('');
	const [badSearchTerm, setBadSearchTerm] = useState('');
	const [badSearchCategory, setBadSearchCategory] = useState('');
	const [badSearchAdditionalInfo, setbadSearchAdditionalInfo] = useState('');
	const [expectedResult, setExpectedResult] = useState('');
	const [otherSuggestion, setOtherSuggestion] = useState('');
	const [userId, setUserId] = useState('');
	const [showSuccessMessage, setShowSuccessMessage] = useState(false);

	const styles = {
		// Change outline color when hovered but not focused
		'&:hover .MuiOutlinedInput-notchedOutline:not(.Mui-focused)': {
			borderColor: darkMode ? 'white!important' : 'grey',
		},
		// Change label color when hovered but not focused
		'&:hover .MuiInputLabel-root:not(.Mui-focused)': {
			color: darkMode ? 'white' : '#000',
		},
		// For text color
		'&& input': {
			color: darkMode ? '#fff' : '#000',
		},
		// Default outline color
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: darkMode ? 'grey!important' : 'grey',
		},
		// Focused outline color
		'& .MuiOutlinedInput-notchedOutline.Mui-focused': {
			borderColor: darkMode ? 'rgb(47,199,112)' : '#000',
		},
		// Background color
		'& .MuiOutlinedInput-root': {
			backgroundColor: darkMode ? '#000' : '#f7f7f7',
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			borderColor: 'rgb(47, 199, 112)!important',
		},
		// For label text color
		'& .MuiInputLabel-root': {
			color: darkMode ? 'grey' : 'grey',
		},
		// Focused label color
		'& .MuiInputLabel-outlined.Mui-focused': {
			color: 'rgb(47, 180, 112)',
		},
	};

	// Fetch user data
	useEffect(() => {
		fetch(`${API_URL}/user/${user?.uid}`, {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
		})
			.then((res) => res.json())
			.then((data) => {
				setUserId(data);
				window.scrollTo(0, 0, 'smooth');
			})
			.catch((err) => console.error(err));
	}, [user?.uid]);

	const handleSuggestionTypeChange = (event) => {
		setSuggestionType(event.target.value);
	};

	const handleBadSearchCategoryChange = (event) => {
		setBadSearchCategory(event.target.value);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		const formData = {
			suggestionType,
			suggestedTerm,
			badSearchCategory,
			badSearchTerm,
			badSearchAdditionalInfo,
			expectedResult,
			otherSuggestion,
			userId,
		};

		try {
			await axios.post(`${API_URL}/suggestions`, formData);

			// Show success message and hide it after 3 seconds
			setShowSuccessMessage(true);
			setTimeout(() => {
				setShowSuccessMessage(false);
			}, 3000);

			// Reset form after submission
			setSuggestedTerm('');
			setBadSearchTerm('');
			setbadSearchAdditionalInfo('');
			setBadSearchCategory('');
			setExpectedResult('');
			setOtherSuggestion('');
		} catch (error) {
			console.error('Error submitting suggestion:', error);
		}
	};

	return (
		<>
			<Head title='Suggestions Form Page' />
			<Container sx={{ height: '100vh' }}>
				<h1 style={{ textAlign: 'center', marginTop: '2rem' }}>Feedback Form</h1>
				<form onSubmit={handleSubmit} style={{ marginTop: '1rem' }}>
					<FormControl fullWidth margin='normal' sx={{ ...styles }}>
						<InputLabel
							sx={{
								'&.MuiInputLabel-shrink': {
									transform: 'translate(14px, -16px) scale(0.75)',
								},
							}}
						>
							Suggestion Type
						</InputLabel>
						<Select value={suggestionType} onChange={handleSuggestionTypeChange}>
							<MenuItem value='suggestedTerm'>Suggest a Term</MenuItem>
							<MenuItem value='badSearch'>Report a Bad Search</MenuItem>
							<MenuItem value='other'>Other</MenuItem>
						</Select>
					</FormControl>

					{suggestionType === 'suggestedTerm' && (
						<TextField autoComplete='off' sx={{ ...styles }} fullWidth margin='normal' label='Suggested Term(s)' value={suggestedTerm} onChange={(e) => setSuggestedTerm(e.target.value)} />
					)}

					{suggestionType === 'badSearch' && (
						<>
							<TextField
								autoComplete='off'
								sx={{ ...styles }}
								fullWidth
								margin='normal'
								label='Search Entered...example: Gordon Ryan vs Lachlan Giles'
								value={badSearchTerm}
								onChange={(e) => setBadSearchTerm(e.target.value)}
							/>
							<FormControl fullWidth margin='normal' sx={{ ...styles }} required>
								<InputLabel>Category</InputLabel>
								<Select value={badSearchCategory} onChange={handleBadSearchCategoryChange} label='Category'>
									<MenuItem value='Footage'>Footage</MenuItem>
									<MenuItem value='Statistics'>Statistics</MenuItem>
									<MenuItem value='Sequences'>Sequences</MenuItem>
									<MenuItem value='Resources'>Resources</MenuItem>
								</Select>
							</FormControl>
							<TextField
								autoComplete='off'
								sx={{ ...styles }}
								fullWidth
								margin='normal'
								label='Additional Info...anything you think would be helpful to know (optional)'
								value={badSearchAdditionalInfo}
								onChange={(e) => setbadSearchAdditionalInfo(e.target.value)}
							/>
						</>
					)}

					{suggestionType === 'other' && (
						<TextField
							autoComplete='off'
							sx={{ ...styles }}
							fullWidth
							margin='normal'
							label='Feedback'
							placeholder='Tell Jake your thoughs...'
							multiline
							rows={4}
							value={otherSuggestion}
							onChange={(e) => setOtherSuggestion(e.target.value)}
						/>
					)}

					{showSuccessMessage && <div style={{ color: 'green', marginBottom: '10px' }}>Suggestion submitted successfully!</div>}

					<Button
						type='submit'
						variant='contained'
						sx={{
							backgroundColor: 'rgb(47, 160, 112)',
							color: 'white',
							marginTop: '1rem',
							'&:hover': { backgroundColor: 'rgb(47, 180, 112)', color: 'white' },
						}}
					>
						Submit Suggestion
					</Button>
				</form>
			</Container>
		</>
	);
}

export default SuggestionsForm;

import { useEffect, useState } from 'react';
import axios from 'axios';
import { TextField, Button, useMediaQuery, FormControlLabel, Switch } from '@mui/material';

const API_URL = process.env.REACT_APP_API_URL;

const FolderCreationForm = ({ handleNewFolder, darkMode, user, setNewFolder, isParentPublic, parentFolderId }) => {
	const [folderName, setFolderName] = useState('');
	const [isRoot, setIsRoot] = useState(true);
	const [isPublic, setIsPublic] = useState(false);
	const [error, setError] = useState(null);

	const mobile = useMediaQuery('(max-width:600px)');

	const styles = {
		width: mobile ? '100%' : '50%',
		// Change outline color when hovered but not focused
		'&:hover .MuiOutlinedInput-notchedOutline:not(.Mui-focused)': {
			borderColor: darkMode ? 'white!important' : 'grey',
		},
		// Change label color when hovered but not focused
		'&:hover .MuiInputLabel-root:not(.Mui-focused)': {
			color: darkMode ? 'white' : '#000',
		},
		// For text color
		'&& input': {
			color: darkMode ? 'rgb(225,225,225)' : '#000',
		},
		// Default outline color
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: darkMode ? 'grey!important' : 'grey',
		},
		// Focused outline color
		'& .MuiOutlinedInput-notchedOutline.Mui-focused': {
			borderColor: darkMode ? 'rgb(47,199,112)' : '#000',
		},
		// Background color
		'& .MuiOutlinedInput-root': {
			backgroundColor: darkMode ? '#000' : '#f7f7f7',
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			borderColor: 'rgb(47, 199, 112)!important',
		},
		// For label text color
		'& .MuiInputLabel-root': {
			color: darkMode ? 'grey' : 'grey',
		},
		// Focused label color
		'& .MuiInputLabel-outlined.Mui-focused': {
			color: 'rgb(47, 180, 112)',
		},
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		try {
			const response = await axios.post(`${API_URL}/folders`, {
				name: folderName,
				owner: user._id,
				username: user.username,
				parentFolderId: parentFolderId,
				isRoot,
				isPublic,
			});
			// Clear the form
			setFolderName('');
			setIsRoot(false);
			setIsPublic(false);
			// Update the folder list in the parent component
			handleNewFolder(response.data);
		} catch (error) {
			setError('You can only create 1 public folder');
		}
	};

	useEffect(() => {
		// reset error to null if error is not null after 2 seconds
		if (error) {
			setTimeout(() => {
				setError(null);
			}, 3000);
		}
	}, [error]);

	// Automatically set isPublic based on the parent folder's status
	useEffect(() => {
		if (isParentPublic !== undefined) {
			setIsPublic(isParentPublic);
		}
	}, [isParentPublic]);

	return (
		<>
			<form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '16px', width: '100%' }}>
				<TextField autoComplete='off' sx={{ ...styles }} label='Folder Name' variant='outlined' value={folderName} onChange={(e) => setFolderName(e.target.value)} required />
				{!parentFolderId && (
					<FormControlLabel
						control={
							<Switch
								sx={{
									'& .MuiSwitch-switchBase.Mui-checked': {
										color: 'grey', // Thumb color when checked
										'&:hover': {
											backgroundColor: 'rgba(128, 128, 128, 0.1)', // Ripple color when hovered
										},
									},
									'& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
										backgroundColor: 'grey', // Track color when checked
									},
									'& .MuiSwitch-switchBase': {
										color: 'lightgrey', // Thumb color when not checked
									},
									'& .MuiSwitch-track': {
										backgroundColor: 'lightgrey', // Track color when not checked
									},
								}}
								checked={isPublic}
								onChange={(e) => setIsPublic(e.target.checked)}
							/>
						}
						label={isPublic ? 'Public Folder' : 'Private Folder'}
					/>
				)}

				<Button
					variant='contained'
					sx={{
						backgroundColor: 'rgb(47, 160, 112)',
						color: 'white',
						marginBottom: '16px',
						'&:hover': { backgroundColor: 'rgb(47, 180, 112)', color: 'white' },
					}}
					type='submit'
				>
					Create Folder
				</Button>
				<Button
					variant='contained'
					sx={{
						backgroundColor: '#d20404',

						color: 'white',
						marginBottom: '16px',
						'&:hover': { backgroundColor: '#db1c1c', color: 'white' },
					}}
					type='button'
					onClick={() => setNewFolder(false)}
				>
					Cancel
				</Button>
			</form>
		</>
	);
};

export default FolderCreationForm;

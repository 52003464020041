import React, { useState, useEffect, useCallback } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, useMediaQuery, TableRow, TextField, Button, IconButton, Typography, Box, Collapse, Tooltip, Modal, List } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { secondsToMMSS } from '../utils/helperFunctions';
import CircleIcon from '@mui/icons-material/Circle';
import ResourceCard from './FeaturedResourceCard';
import SaveIcon from '@mui/icons-material/Save';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import FolderListItem from './FolderHierarchy';
import { useNavigate } from 'react-router-dom';
const API_URL = process.env.REACT_APP_API_URL;

function Row({ sequence, resources, darkMode, isMobile, onTimestampChange, isActive, handleRowClick, id, user, authUser, disableTimeStamp }) {
	const navigate = useNavigate();
	// Modal style
	const modalStyle = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,
		bgcolor: darkMode ? 'rgb(30,30,30)' : 'rgb(225, 225, 225)',
		color: darkMode ? '#fff' : '#000',
		boxShadow: 24,
		height: '85%',
		overflowY: 'auto',
		p: 4,
	};

	const [likes, setLikes] = useState(sequence.totalLikes ? sequence.totalLikes : 0);
	const [dislikes, setDislikes] = useState(sequence.totalDislikes ? sequence.totalDislikes : 0);
	const [open, setOpen] = useState(isActive);
	const [showFolderModal, setShowFolderModal] = useState(false);
	const [folders, setFolders] = useState([]);
	const [isEditingNote, setIsEditingNote] = useState(false);
	const [resourceId, setResourceId] = useState('');
	const [noteContent, setNoteContent] = useState(sequence.note ? sequence.note.content : null);
	let filteredResources = [];
	if (resources && resources.length !== 0) {
		filteredResources = resources.filter((resource) => resource.sequence_ids.includes(sequence._id));
	}

	const handleEditNote = () => {
		setIsEditingNote(true);
	};

	const handleSaveNote = async () => {
		try {
			await axios.put(`${API_URL}/sequence-note/${sequence.note._id}`, { content: noteContent });
			setIsEditingNote(false);
			setNoteContent(noteContent);
		} catch (error) {
			console.error('Error updating NOTE:', error);
		}
	};

	const handleCancelEdit = () => {
		setIsEditingNote(false);
	};

	const deleteSequence = async (e, sequence) => {
		try {
			// Make a DELETE request to delete a sequence
			// check to confirm user wants to delete
			if (window.confirm('Are you sure you want to delete this sequence?')) {
				const response = await axios.delete(`${API_URL}/sequence/${sequence._id}`);
				if (response.data) {
					// back to all sequences?
					window.location.reload();
				} else {
					throw new Error('Invalid server response');
				}
			}
		} catch (error) {
			console.error('Error deleting sequence:', error);
		}
	};

	function saveItemToFolder(itemId, itemType, folderId) {
		fetch(`${API_URL}/folders/${folderId}/addItem`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ itemId, itemType }),
		})
			.then((response) => response.json())
			.then((data) => {})
			.catch((error) => {
				console.error('Error:', error.message);
			});
	}

	const handleFolderSelect = (folderId) => {
		saveItemToFolder(sequence._id, 'Sequence', folderId);
		setShowFolderModal(false);
	};

	const handleLike = async () => {
		try {
			// Make a PATCH request to update the totalLikes in the database
			const response = await axios.patch(`${API_URL}/sequence/like/${sequence._id}`, {
				userId: user?._id,
			});

			if (response.data) {
				// Update the local state based on the updated sequence returned from the server
				setLikes(response.data.totalLikes);
				setDislikes(response.data.totalDislikes); // Update dislikes in case a previous dislike was undone
			} else {
				throw new Error('Invalid server response');
			}
		} catch (error) {
			if (error.response && error.response.status === 400) {
				// Handle specific error messages from the server
				console.error(error.response.data.message);
			} else {
				console.error('Error updating likes:', error);
			}
		}
	};

	const copyToClipboard = (id) => {
		localStorage.setItem('copiedSequenceId', id);
		alert('Sequence ID copied');
	};

	const handlePasteResource = () => {
		const pastedResourceId = localStorage.getItem('copiedResourceId');
		if (pastedResourceId) {
			setResourceId(pastedResourceId);
		} else {
			alert('No resource ID to paste!');
		}
	};

	const addResourceToSequence = async (resourceId) => {
		try {
			const response = await axios.put(`${API_URL}/training-resource/${resourceId}/add-sequence`, { sequence_id: sequence._id });
			if (response.data) {
				window.location.reload();
			}
		} catch (error) {
			console.error('Error adding resource to sequence:', error);
			alert('Failed to add resource to sequence.');
		}
	};

	const handleAddResource = (e) => {
		e.preventDefault();
		if (resourceId) {
			setResourceId('');
			addResourceToSequence(resourceId);
		} else {
			alert('Please enter a valid resource ID.');
		}
	};

	const handleRemoveResource = (resource) => {
		removeResourceFromSequence(resource);
	};

	const removeResourceFromSequence = async (resource) => {
		try {
			const response = await axios.put(`${API_URL}/training-resource/${resource._id}/remove-sequence`, { sequence_id: sequence._id });
			if (response.data) {
				alert('Resource removed from sequence successfully!');
				window.location.reload();
			}
		} catch (error) {
			console.error('Error removing resource from sequence:', error);
			alert('Failed to remove resource from sequence.');
		}
	};

	const handleDislike = async () => {
		try {
			// Make a PATCH request to update the totalDislikes in the database
			const response = await axios.patch(`${API_URL}/sequence/dislike/${sequence._id}`, {
				userId: user?._id,
			});

			if (response.data) {
				// Update the local state based on the updated sequence returned from the server
				setLikes(response.data.totalLikes); // Update likes in case a previous like was undone
				setDislikes(response.data.totalDislikes);
			} else {
				throw new Error('Invalid server response');
			}
		} catch (error) {
			if (error.response && error.response.status === 400) {
				// Handle specific error messages from the server
				console.error(error.response.data.message);
			} else {
				console.error('Error updating dislikes:', error);
			}
		}
	};

	function buildFolderTree(folders, parentId = null) {
		return folders
			.filter((folder) => folder.parentFolder === parentId)
			.map((folder) => ({
				...folder,
				subFolders: buildFolderTree(folders, folder._id),
			}));
	}

	const movements =
		sequence.sequencePlayer === sequence.player1
			? sequence.player1Movement1 || sequence.player1Movement2
				? `${sequence.player1Movement1.toUpperCase()}, ${sequence.player1Movement2.toUpperCase()}`.replace(/, $/, '')
				: 'N/A'
			: sequence.player2Movement1 || sequence.player2Movement2
			? `${sequence.player2Movement1.toUpperCase()}, ${sequence.player2Movement2.toUpperCase()}`.replace(/, $/, '')
			: 'N/A';

	const formatVariations = (...vars) => {
		const variations = vars
			.filter((v) => v)
			.join(', ')
			.toUpperCase();
		return variations || 'N/A';
	};

	const handleNewFolder = (e) => {
		navigate(`/user-folders/${user._id}`);
	};

	useEffect(() => {
		// Update the note content when the sequence changes
		setNoteContent(sequence.note ? sequence.note.content : null);
	}, [sequence.note]);

	useEffect(() => {
		if (showFolderModal) {
			axios
				.get(`${API_URL}/folders/${user._id}`)
				.then((response) => {
					const folderTree = buildFolderTree(response.data);
					setFolders(folderTree);
				})
				.catch((error) => {
					console.error('Error fetching folders:', error);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showFolderModal, user?._id]);

	useEffect(() => {
		setOpen(isActive);
	}, [isActive]);

	const handleNavigate = (e, sequence) => {
		e.preventDefault();
		navigate(`/edit-sequence/${sequence._id}`);
	};

	return (
		<>
			<TableRow
				id={id}
				sx={{
					'& > *': { borderBottom: 'unset' },
					borderRadius: '8px',
					boxShadow: isActive ? '0 2px 4px rgba(0,0,0,0.5)' : '0 2px 4px rgba(0,0,0,0.1)',
					border: isActive ? '2px solid #216d53' : '1px solid inherit',
					cursor: 'pointer',
					'&:hover': {
						backgroundColor: 'rgba(0,40,0,0.2)',
					},
				}}
				onClick={() => handleRowClick(sequence._id)}
			>
				{disableTimeStamp === true ? (
					<TableCell
						style={{
							textDecoration: 'underline',
							border: 'none',
							fontFamily: 'monospace',
							fontSize: isMobile ? '.7rem' : '.8rem',
							backgroundColor: isActive ? 'rgba(0,100,0,0.2)' : darkMode ? '#12392C' : 'inherit',
							color: darkMode ? '#269c75' : 'rgb(1, 108, 56)',
							cursor: 'pointer',
							':hover': { color: 'rgb(4, 131, 70)' },
						}}
					>
						{secondsToMMSS(sequence.startingPositionVideoTimestamp)}
					</TableCell>
				) : (
					<TableCell
						onClick={() => onTimestampChange(sequence.startingPositionVideoTimestamp)}
						style={{
							textDecoration: 'underline',
							border: 'none',
							fontFamily: 'monospace',
							fontSize: isMobile ? '.7rem' : '.8rem',

							backgroundColor: isActive ? 'rgba(0,100,0,0.2)' : darkMode ? '#12392C' : 'inherit',
							color: darkMode ? '#269c75' : 'rgb(1, 108, 56)',
							cursor: 'pointer',
							':hover': { color: 'rgb(4, 131, 70)' },
						}}
					>
						{secondsToMMSS(sequence.startingPositionVideoTimestamp)}
					</TableCell>
				)}
				<TableCell
					style={{
						border: 'none',
						fontFamily: 'monospace',
						fontSize: isMobile ? '.7rem' : '.8rem',
						backgroundColor: isActive ? 'rgba(0,100,0,0.2)' : darkMode ? '#12392C' : 'inherit',
					}}
				>
					{sequence.sequenceType.toUpperCase()}
				</TableCell>
				<TableCell
					style={{
						border: 'none',
						fontFamily: 'monospace',
						fontSize: isMobile ? '.7rem' : '.8rem',

						backgroundColor: isActive ? 'rgba(0,100,0,0.2)' : darkMode ? '#12392C' : 'inherit',
					}}
				>
					{sequence.startingPosition.toUpperCase()}
				</TableCell>
				<TableCell
					style={{
						border: 'none',
						fontFamily: 'monospace',
						fontSize: isMobile ? '.7rem' : '.8rem',

						backgroundColor: isActive ? 'rgba(0,100,0,0.2)' : darkMode ? '#12392C' : 'inherit',
					}}
				>
					{movements}
				</TableCell>
				<TableCell
					style={{
						border: 'none',
						fontFamily: 'monospace',
						fontSize: isMobile ? '.7rem' : '.8rem',

						backgroundColor: isActive ? 'rgba(0,100,0,0.2)' : darkMode ? '#12392C' : 'inherit',
					}}
				>
					{sequence.endingPosition.toUpperCase()}
				</TableCell>
				<TableCell
					style={{
						border: 'none',
						fontFamily: 'monospace',
						fontSize: isMobile ? '.7rem' : '.8rem',

						backgroundColor: isActive ? 'rgba(0,100,0,0.2)' : darkMode ? '#12392C' : 'inherit',
					}}
				>
					<IconButton aria-label='expand row' size='small' sx={{ fontSize: isMobile ? '0.7rem' : '0.9rem' }} onClick={() => setOpen(!open)}>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
						{filteredResources.length > 0 && <CircleIcon sx={{ color: darkMode ? 'green' : 'green', fontSize: isMobile ? '0.9rem' : '1rem' }} />}
						{filteredResources.length > 0 && ` x${filteredResources.length}`}
					</IconButton>
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0, paddingRight: 0, border: 'none', borderBottom: '1px solid', boxShadow: '1px 4px 4px rgba(0,0,0,0.4)' }} colSpan={7}>
					<Collapse in={open} timeout='auto' unmountOnExit>
						<Box
							sx={{
								p: 2,
								backgroundColor: isActive ? 'rgba(0,100,0,0.2)' : darkMode ? 'rgb(18, 57, 44)' : '#fff',
								display: 'flex',
								flexDirection: isMobile ? 'column' : 'row',
							}}
						>
							{filteredResources.length > 0 ? (
								<>
									<Box
										sx={{
											display: 'flex',
											width: isMobile ? '100%' : '30%',
											borderRight: '2px solid',
											flexDirection: 'column',
											alignItems: 'flex-start',
											position: 'relative',
											pb: isMobile ? 8 : 'inherit',
										}}
									>
										<Typography sx={{ mr: 2 }} variant='h6' gutterBottom component='div'>
											Sequence Details
										</Typography>

										<Typography variant='body2' style={{ fontFamily: 'monospace', fontSize: isMobile ? '.7rem' : '.8rem', marginBottom: '1rem' }} component='div'>
											Starting Variations:{' '}
											{formatVariations(
												sequence.startingPositionVariationPlayer1Lower1,
												sequence.startingPositionVariationPlayer1Lower2,
												sequence.startingPositionVariationPlayer1Upper1,
												sequence.startingPositionVariationPlayer1Upper2
											)}
										</Typography>
										<Typography variant='body2' style={{ fontFamily: 'monospace', fontSize: isMobile ? '.7rem' : '.8rem', marginBottom: '1rem' }} component='div'>
											Ending Variations:{' '}
											{formatVariations(
												sequence.endingPositionVariationPlayer1Lower1,
												sequence.endingPositionVariationPlayer1Lower2,
												sequence.endingPositionVariationPlayer1Upper1,
												sequence.endingPositionVariationPlayer1Upper2
											)}
										</Typography>

										<>
											{isEditingNote ? (
												<>
													<TextField fullWidth multiline variant='outlined' value={noteContent} onChange={(e) => setNoteContent(e.target.value)} />
													<Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
														<Button
															onClick={handleSaveNote}
															sx={{ mr: 1, background: 'rgb(47,150,112)', color: darkMode ? '#000' : '#fff', ':hover': { background: 'rgb(47,180,112)' } }}
														>
															Save
														</Button>
														<Button onClick={handleCancelEdit} sx={{ background: '#d20404', color: darkMode ? '#000' : '#fff', '&:hover': { background: '#db1c1c' } }}>
															Cancel
														</Button>
													</Box>
												</>
											) : (
												<Box sx={{ display: 'flex', alignItems: 'center', mt: -1 }}>
													<Typography variant='body2' sx={{ fontFamily: 'monospace', fontSize: isMobile ? '0.7rem' : '0.8rem' }}>
														NOTE: {noteContent || 'No notes available'}
													</Typography>

													<IconButton onClick={handleEditNote}>
														<Tooltip title='Edit Sequence' placement='top' arrow>
															<EditIcon sx={{ color: darkMode ? '#249C75' : '#008001' }} />
														</Tooltip>
													</IconButton>
												</Box>
											)}
										</>

										<Box sx={{ display: 'flex', justifyContent: 'flex-end', position: 'absolute', bottom: '0', right: '5px', mt: isMobile ? 4 : 2 }}>
											{(user?.role === 'admin' || user?.role === 'data') && (
												<Typography
													onClick={() => copyToClipboard(sequence._id)}
													variant='body2'
													sx={{
														cursor: 'pointer',
														fontWeight: 300,
														color: 'rgb(47,150,112)',
														':hover': { color: 'rgb(47,180,112)' },

														fontFamily: 'monospace',
														mr: 1,
														fontSize: '1rem',
													}}
												>
													Copy ID
												</Typography>
											)}
											{/* // edit icon */}
											<Box onClick={(e) => handleNavigate(e, sequence)} sx={{ display: 'flex', alignItems: 'center', mr: isMobile ? 1 : 2, ':hover': { cursor: 'pointer' } }}>
												<Tooltip title='Edit Sequence' placement='top' arrow>
													<EditIcon sx={{ color: darkMode ? '#249C75' : '#008001' }} />
												</Tooltip>
											</Box>
											<Box onClick={() => setShowFolderModal(true)} sx={{ display: 'flex', alignItems: 'center', mr: isMobile ? 1 : 2, ':hover': { cursor: 'pointer' } }}>
												<Tooltip title='Save Sequence to Folder' placement='top' arrow>
													<SaveIcon sx={{ color: 'rgb(255, 153, 0)', fontSize: '1.5rem' }} />
												</Tooltip>
											</Box>
											{/* // thumbs up icon */}
											<Box onClick={() => handleLike()} sx={{ display: 'flex', alignItems: 'center', mr: isMobile ? 1 : 2, ':hover': { cursor: 'pointer' } }}>
												<ThumbUpIcon sx={{ color: 'rgb(47,160,112)', fontSize: '1.5rem' }} />
												<Typography variant='body2' sx={{ ml: 1, fontSize: '1rem' }}>
													{likes}
												</Typography>
											</Box>
											{/* // thumbs down icon */}
											<Box onClick={() => handleDislike()} sx={{ display: 'flex', alignItems: 'center', mr: isMobile ? 1 : 2, ':hover': { cursor: 'pointer' } }}>
												<ThumbDownIcon sx={{ color: 'rgb(47,160,112)', fontSize: '1.5rem' }} />
												<Typography variant='body2' sx={{ ml: 1, fontSize: '1rem' }}>
													{dislikes}
												</Typography>
											</Box>

											{/* // delete icon */}
											{user?._id === sequence.createdByUser ||
												(user.role === 'admin' && (
													<Box onClick={() => deleteSequence(sequence)} sx={{ display: 'flex', alignItems: 'center', ':hover': { cursor: 'pointer' } }}>
														<DeleteIcon sx={{ color: 'red', fontSize: '1.5rem' }} />
													</Box>
												))}
										</Box>
									</Box>

									<Box sx={{ width: isMobile ? '100%' : '70%', pl: isMobile ? 0 : 2, mt: isMobile ? 2 : 0 }}>
										<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
											<Typography variant='h6' gutterBottom component='div'>
												Resources
											</Typography>
											{(user?.role === 'admin' || user?.role === 'data') && (
												<form onSubmit={handleAddResource} style={{ display: 'flex', alignItems: 'center', marginTop: '-16px' }}>
													<TextField
														label='Resource ID'
														variant='outlined'
														size='small'
														color='success'
														value={resourceId}
														onChange={(e) => setResourceId(e.target.value)}
														sx={{
															marginRight: '8px',
														}}
													/>
													<Button
														type='button'
														onClick={handlePasteResource}
														sx={{ mr: 1, background: 'rgb(47,150,112)', color: darkMode ? '#000' : '#fff', ':hover': { background: 'rgb(47,180,112)' } }}
													>
														Paste
													</Button>
													<Button type='submit' sx={{ background: 'rgb(47,150,112)', color: darkMode ? '#000' : '#fff', ':hover': { background: 'rgb(47,180,112)' } }}>
														Add
													</Button>
												</form>
											)}
										</Box>
										<Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: isMobile ? 'center' : 'space-between', flexWrap: 'wrap' }}>
											{filteredResources.length &&
												filteredResources.map((resource) => (
													<Box key={resource._id} sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-between', mb: 2 }}>
														<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
															<ResourceCard resource={resource} darkMode={darkMode} user={user} authUser={authUser} />
															{(user?.role === 'admin' || user?._id === resource.createdByUser) && (
																<Button
																	onClick={() => handleRemoveResource(resource)}
																	sx={{ mt: 1, background: 'darkRed', color: darkMode ? '#000' : '#fff', ':hover': { background: 'red' } }}
																>
																	Remove
																</Button>
															)}
														</Box>
													</Box>
												))}
										</Box>
									</Box>
								</>
							) : (
								<>
									<Box
										sx={{
											display: 'flex',
											width: isMobile ? '100%' : '70%',
											borderRight: '2px solid',
											flexDirection: 'column',
											alignItems: 'flex-start',
											position: 'relative',
										}}
									>
										<Typography variant='h6' gutterBottom component='div'>
											Sequence Details
										</Typography>

										<Typography variant='body2' style={{ fontFamily: 'monospace', fontSize: isMobile ? '.7rem' : '.8rem', marginBottom: '1rem' }} component='div'>
											Starting Variations:{' '}
											{formatVariations(
												sequence.startingPositionVariationPlayer1Lower1,
												sequence.startingPositionVariationPlayer1Lower2,
												sequence.startingPositionVariationPlayer1Upper1,
												sequence.startingPositionVariationPlayer1Upper2
											)}
										</Typography>
										<Typography variant='body2' style={{ fontFamily: 'monospace', fontSize: isMobile ? '.7rem' : '.8rem', marginBottom: '1rem' }} component='div'>
											Ending Variations:{' '}
											{formatVariations(
												sequence.endingPositionVariationPlayer1Lower1,
												sequence.endingPositionVariationPlayer1Lower2,
												sequence.endingPositionVariationPlayer1Upper1,
												sequence.endingPositionVariationPlayer1Upper2
											)}
										</Typography>
										<>
											{isEditingNote ? (
												<>
													<TextField fullWidth multiline variant='outlined' value={noteContent} onChange={(e) => setNoteContent(e.target.value)} />
													<Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
														<Button
															onClick={handleSaveNote}
															sx={{ mr: 1, background: 'rgb(47,150,112)', color: darkMode ? '#000' : '#fff', ':hover': { background: 'rgb(47,180,112)' } }}
														>
															Save
														</Button>
														<Button onClick={handleCancelEdit} sx={{ background: '#d20404', color: darkMode ? '#000' : '#fff', '&:hover': { background: '#db1c1c' } }}>
															Cancel
														</Button>
													</Box>
												</>
											) : (
												<Box sx={{ display: 'flex', alignItems: 'center', mt: -1, mb: 4 }}>
													<Typography variant='body2' sx={{ fontFamily: 'monospace', fontSize: isMobile ? '0.7rem' : '0.8rem' }}>
														NOTE: {noteContent || 'No notes available'}
													</Typography>

													<IconButton onClick={handleEditNote}>
														<Tooltip title='Edit Note' placement='top' arrow>
															<EditIcon sx={{ color: darkMode ? '#249C75' : '#008001' }} />
														</Tooltip>
													</IconButton>
												</Box>
											)}
										</>

										<Box sx={{ display: 'flex', justifyContent: 'flex-end', position: 'absolute', bottom: '0', right: '5px', mt: isMobile ? 4 : 2 }}>
											{(user?.role === 'admin' || user?.role === 'data') && (
												<Typography
													onClick={() => copyToClipboard(sequence._id)}
													variant='body2'
													sx={{
														cursor: 'pointer',
														fontWeight: 300,
														color: 'rgb(47,150,112)',
														':hover': { color: 'rgb(47,180,112)' },

														fontFamily: 'monospace',
														mr: 1,
														fontSize: '1rem',
													}}
												>
													Copy ID
												</Typography>
											)}
											<Box onClick={() => setShowFolderModal(true)} sx={{ display: 'flex', alignItems: 'center', mr: isMobile ? 1 : 2, ':hover': { cursor: 'pointer' } }}>
												<Tooltip title='Save Sequence to Folder' placement='top' arrow>
													<SaveIcon sx={{ color: 'rgb(255, 153, 0)', fontSize: '1.5rem' }} />
												</Tooltip>
											</Box>
											{/* // edit icon */}
											<Box onClick={(e) => handleNavigate(e, sequence)} sx={{ display: 'flex', alignItems: 'center', mr: isMobile ? 1 : 2, ':hover': { cursor: 'pointer' } }}>
												<Tooltip title='Edit Sequence' placement='top' arrow>
													<EditIcon sx={{ color: darkMode ? '#249C75' : '#008001' }} />
												</Tooltip>
											</Box>
											{/* // thumbs up icon */}
											<Box onClick={() => handleLike()} sx={{ display: 'flex', alignItems: 'center', mr: isMobile ? 1 : 2, ':hover': { cursor: 'pointer' } }}>
												<ThumbUpIcon sx={{ color: 'rgb(47,160,112)', fontSize: '1.5rem' }} />
												<Typography variant='body2' sx={{ ml: 1, fontSize: '1rem' }}>
													{likes}
												</Typography>
											</Box>
											{/* // thumbs down icon */}
											<Box onClick={() => handleDislike()} sx={{ display: 'flex', alignItems: 'center', mr: isMobile ? 1 : 2, ':hover': { cursor: 'pointer' } }}>
												<ThumbDownIcon sx={{ color: 'rgb(47,160,112)', fontSize: '1.5rem' }} />
												<Typography variant='body2' sx={{ ml: 1, fontSize: '1rem' }}>
													{dislikes}
												</Typography>
											</Box>
											{/* // delete icon */}
											{user?._id === sequence.createdByUser ||
												(user.role === 'admin' && (
													<Box onClick={(e) => deleteSequence(e, sequence)} sx={{ display: 'flex', alignItems: 'center', ':hover': { cursor: 'pointer' } }}>
														<DeleteIcon sx={{ color: 'red', fontSize: '1.5rem' }} />
													</Box>
												))}
										</Box>
									</Box>
									<Box sx={{ width: isMobile ? '100%' : '30%', pl: isMobile ? 0 : 2 }}>
										<Typography variant='h6' gutterBottom component='div'>
											Resources
										</Typography>
										<Typography variant='body2' style={{ fontFamily: 'monospace' }}>
											None Available
										</Typography>
										{(user?.role === 'admin' || user?.role === 'data') && (
											<form onSubmit={handleAddResource} style={{ display: 'flex', alignItems: 'center' }}>
												<TextField
													label='Resource ID'
													variant='outlined'
													color='success'
													size='small'
													value={resourceId}
													onChange={(e) => setResourceId(e.target.value)}
													sx={{ mr: 1, mt: 1 }}
												/>
												<Button
													type='button'
													onClick={handlePasteResource}
													sx={{ mt: 1, mr: 1, background: 'rgb(47,150,112)', color: darkMode ? '#000' : '#fff', ':hover': { background: 'rgb(47,180,112)' } }}
												>
													Paste
												</Button>
												<Button type='submit' sx={{ mt: 1, background: 'rgb(47,150,112)', color: darkMode ? '#000' : '#fff', ':hover': { background: 'rgb(47,180,112)' } }}>
													Add
												</Button>
											</form>
										)}
									</Box>
								</>
							)}
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
			{showFolderModal && (
				<Modal open={showFolderModal} onClose={() => setShowFolderModal(false)}>
					<Box sx={modalStyle}>
						<Box style={{ display: 'flex', justifyContent: 'space-between' }}>
							<Typography>Select a Folder</Typography>
							<Typography
								variant='button'
								onClick={(e) => handleNewFolder(e)}
								sx={{
									fontFamily: 'monospace',
									fontSize: isMobile ? '.7rem' : '.8rem',

									textDecoration: 'underline',
									pr: isMobile ? 1 : 0,
									fontWeight: 300,
									color: darkMode ? 'rgb(0,130,60)' : 'rgb(0, 109, 56)',
									':hover': { cursor: 'pointer', color: darkMode ? 'rgb(0,150,70)' : 'rgb(0,130,60)' },
								}}
							>
								New Folder
							</Typography>
						</Box>
						<List>
							{folders.map((folder) => (
								<FolderListItem key={folder._id} folder={folder} onSelect={handleFolderSelect} />
							))}
						</List>
					</Box>
				</Modal>
			)}
		</>
	);
}

export default function SequenceCardDetails({ sequences, resources, darkMode, onTimestampChange, user, authUser, disableTimeStamp, isChatbotOpen, sequenceIdToScroll }) {
	const [activeRow, setActiveRow] = useState(null);
	const [initialScrollDone, setInitialScrollDone] = useState(false);
	const isMobile = useMediaQuery('(max-width:768px)');
	const handleRowClick = (id) => {
		setActiveRow(id);
		// replace the URL sequence_id with the current id
		window.history.replaceState(null, null, `?sequence_id=${id}`);
	};

	const addToUserRecentSequences = useCallback(
		async (sequenceId) => {
			try {
				const userId = user?._id;
				await axios.put(`${API_URL}/user/add-recent-sequence`, { userId, sequenceId });
			} catch (error) {
				console.error('Error adding sequence to user recent sequences:', error);
			}
		},
		[user]
	);

	useEffect(() => {
		if ((!initialScrollDone || isChatbotOpen === true) && sequenceIdToScroll) {
			const sequenceId = sequenceIdToScroll;
			if (sequenceId) {
				addToUserRecentSequences(sequenceId);
				setActiveRow(sequenceId);
				const element = document.getElementById(sequenceId);
				if (element) {
					element.scrollIntoView({ behavior: 'smooth', block: 'center' });
					setInitialScrollDone(true);
				}
			}
		}
	}, [sequences, initialScrollDone, addToUserRecentSequences, isChatbotOpen, sequenceIdToScroll]);

	return (
		<TableContainer style={{ boxShadow: 'none', border: 'none', backgroundColor: darkMode ? '#12392C' : '#fff' }}>
			<Table stickyHeader aria-label='collapsible table' sx={{ borderCollapse: 'collapse', border: 'none' }}>
				<TableHead>
					<TableRow sx={{ '& > th': { fontWeight: 'bold', border: 'none', backgroundColor: darkMode ? '#194f3d' : '#CFFEB8' } }}>
						<TableCell sx={{ fontSize: isMobile ? '.8rem' : 'inherit' }}>Timestamp</TableCell>
						<TableCell sx={{ fontSize: isMobile ? '.8rem' : 'inherit' }}>Sequence Type</TableCell>
						<TableCell sx={{ fontSize: isMobile ? '.8rem' : 'inherit' }}>Starting Position</TableCell>
						<TableCell sx={{ fontSize: isMobile ? '.8rem' : 'inherit' }}>Movements</TableCell>
						<TableCell sx={{ fontSize: isMobile ? '.8rem' : 'inherit' }}>Ending Position</TableCell>
						<TableCell />
					</TableRow>
				</TableHead>
				<TableBody>
					{sequences.map((sequence) => (
						<Row
							key={sequence._id}
							id={sequence._id}
							sequence={sequence}
							resources={resources}
							darkMode={darkMode}
							isMobile={isMobile}
							user={user}
							disableTimeStamp={disableTimeStamp}
							authUser={authUser}
							onTimestampChange={onTimestampChange}
							isActive={activeRow === sequence._id}
							handleRowClick={handleRowClick}
						/>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Grid, Paper, Typography, Checkbox, FormControlLabel, Box, IconButton, Button, Autocomplete, TextField, Chip } from '@mui/material';
import { Triangle } from 'react-loader-spinner';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import FeaturedResourceCard from '../components/FeaturedResourceCard';
import { styled } from '@mui/system';
import useDebounce from '../customHooks/useDebounce';

import Head from '../components/Head/Head';

const API_URL = process.env.REACT_APP_API_URL;

const ResourcesPage = ({ user, darkMode, authUser }) => {
	window.scrollTo(0, 0);
	const [resources, setResources] = useState([]);
	const [displayedResources, setDisplayedResources] = useState([]);
	const [recentlyStudied, setRecentlyStudied] = useState([]);
	const [recentlyEntered, setRecentlyEntered] = useState([]);
	const [featuredResources, setFeaturedResources] = useState([]);
	const [loading, setLoading] = useState(true);
	const [loadingMore, setLoadingMore] = useState(false);
	const [filterOptions, setFilterOptions] = useState({
		instructors: [],
		seqTypes: [],
		types: [],
		positions: [],
		titles: [],
		freeCount: 0,
		allResources: 0,
		alternativeNames: {},
	});
	const [filters, setFilters] = useState({
		type: '',
		seqType: '',
		free: false,
		instructor: '',
		position: '',
		title: '',
	});
	const [showFilters, setShowFilters] = useState(false);
	const [page, setPage] = useState(1);
	const [fetching, setFetching] = useState(true);
	const [isTyping, setIsTyping] = useState(false);

	const isSmallScreen = useMediaQuery('(max-width:1024px)');
	const debouncedFilters = useDebounce(filters, 200);

	const autoCompleteStyles = {
		my: 1.5,
		'& .MuiAutocomplete-clearIndicator': {
			display: 'none',
		},
		'& .MuiOutlinedInput-root': {
			borderColor: darkMode ? 'grey' : 'lightgrey',
			'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
				borderColor: 'rgb(47, 199, 112)',
			},
			'&:hover .MuiOutlinedInput-notchedOutline': {
				borderColor: darkMode ? 'white' : 'grey',
			},
		},
		'& .MuiInputLabel-outlined': {
			color: darkMode ? 'lightgrey' : 'grey',
			'&.Mui-focused': {
				color: 'rgb(47, 199, 112)',
			},
		},
	};

	const isInitialMount = useRef(true);

	const StyledListbox = styled('ul')({
		fontFamily: 'monospace',
		padding: 0,
		margin: 0,
	});

	const StyledOption = styled('li')({
		fontFamily: 'monospace',
		width: '100%',
		fontSize: '.9rem',
		':hover': {
			backgroundColor: darkMode ? 'rgb(58, 89, 78)' : 'rgb(224, 240, 230)',
			cursor: 'pointer',
		},
	});

	const fetchRecentlyEntered = async () => {
		try {
			const recentResponse = await fetch(`${API_URL}/training-resource/recentlyEnteredResources`);
			const recentData = await recentResponse.json();
			const featuredResponse = await fetch(`${API_URL}/training-resource/featuredResources`);
			const featuredData = await featuredResponse.json();
			setRecentlyEntered(recentData);
			setFeaturedResources(featuredData);
		} catch (error) {
			console.error('Error fetching recently entered resources:', error);
		}
	};

	const fetchRecentlyStudied = async () => {
		if (user === null) return;
		const response = await fetch(`${API_URL}/training-resource/recentlyStudiedResources/${user?._id}`);
		const data = await response.json();
		setRecentlyStudied(data);
	};

	const fetchData = async () => {
		try {
			setFetching(true);
			const response = await fetch(`${API_URL}/training-resource/resourcesData`);
			const data = await response.json();
			setFilterOptions(data);
			const reqResource = localStorage.getItem('resourceWiki');
			if (reqResource) {
				setFilters({
					...filters,
					position: reqResource,
				});
				localStorage.removeItem('resourceWiki');
			}
		} catch (error) {
			console.error('Error fetching resources data:', error);
		} finally {
			setFetching(false);
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchRecentlyEntered();
		fetchRecentlyStudied();
		fetchData();

		// eslint-disable-next-line
	}, [user]);

	const isOptionEqualToValue = (option, value) => {
		if (!option || !value) {
			return false;
		}
		if (typeof option === 'string' && typeof value === 'string') {
			return option === value;
		}
		if (option.value && value.value) {
			return option.value === value.value;
		}
		return option.label === value.label;
	};

	const getOptionLabel = (option) => {
		if (fetching) return 'Updating Filters...';
		if (!option) return '';
		if (typeof option === 'string') return option;
		return option.label;
	};

	const renderOption = (props, option) => {
		return (
			<StyledOption {...props}>
				<span style={{ fontFamily: 'monospace' }}>{option.primaryLabel}</span>
				{option.alternatives && <span style={{ fontSize: '0.8em', color: 'gray', marginLeft: '5px' }}>*{option.alternatives.join(', ')}</span>}
			</StyledOption>
		);
	};

	const prepareOptions = (options, alternativeNames = {}) => {
		if (!options) return [];

		const seen = new Set();

		return options
			.filter((option) => option !== null && option !== undefined && option !== '')
			.flatMap((option) => {
				const primaryLabel = `${option}${alternativeNames[option] ? ` [${alternativeNames[option].join(', ')}]` : ''}`;
				if (seen.has(option)) return [];
				seen.add(option);

				const primaryOption = { label: primaryLabel, value: option, primaryLabel: option, alternatives: alternativeNames[option] };
				return [primaryOption];
			});
	};

	const fetchResourcesAndUpdateFilters = useCallback(
		async (reset = false) => {
			if (isTyping) return;
			if (reset) {
				setLoading(true);
				setFetching(true);
				setPage(1);
			} else {
				setLoadingMore(true);
				setFetching(true);
			}
			const currentPage = reset ? 1 : page;
			if (
				debouncedFilters.type === '' &&
				debouncedFilters.seqType === '' &&
				debouncedFilters.free === false &&
				debouncedFilters.instructor === '' &&
				debouncedFilters.position === '' &&
				debouncedFilters.title === ''
			) {
				setResources([]);
				setDisplayedResources([]);
				fetchData();
				setLoading(false);
				setLoadingMore(false);
				setPage(1); // Reset page to 1 when clearing debouncedFilters
				return;
			}
			try {
				const resourceResponse = await fetch(`${API_URL}/training-resource/resources`, {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({ ...debouncedFilters, page: currentPage, limit: 20 }),
				});
				const resourceData = await resourceResponse.json();
				const newResources = reset ? resourceData.resources : [...resources, ...resourceData.resources];
				setResources(newResources);
				setDisplayedResources(newResources.slice(0, currentPage * 20));
				setFilterOptions((prev) => ({
					instructors: resourceData.filterOptions.instructors,
					types: resourceData.filterOptions.types,
					seqTypes: resourceData.filterOptions.seqTypes,
					positions: resourceData.filterOptions.positions,
					titles: resourceData.filterOptions.titles,
					freeCount: resourceData.freeCount,
					allResources: resourceData.allResources,
					alternativeNames: prev.alternativeNames,
				}));
			} catch (error) {
				console.error('Error fetching resources or filter data:', error);
			}
			setLoading(false);
			setLoadingMore(false);
			setFetching(false);
		},
		// eslint-disable-next-line
		[page, isTyping, resources, debouncedFilters]
	);

	const loadMore = () => {
		const currentItemCount = displayedResources.length;
		const totalItemsAvailable = filterOptions.allResources;

		if (currentItemCount >= totalItemsAvailable) return;

		const nextPage = Math.ceil(currentItemCount / 20) + 1;
		setPage(nextPage); // This will trigger the useEffect below to load more data
	};

	useEffect(() => {
		const fetchMoreData = async () => {
			if (page === 1) return;
			await fetchResourcesAndUpdateFilters();
		};

		fetchMoreData();
		// eslint-disable-next-line
	}, [page]);

	useEffect(() => {
		if (isInitialMount.current) {
			if (localStorage.getItem('resourceBeingEdited') !== null) {
				localStorage.removeItem('resourceBeingEdited');
			}
			isInitialMount.current = false;
		} else {
			fetchResourcesAndUpdateFilters(true);
		}
		// eslint-disable-next-line
	}, [debouncedFilters]);

	const handleClearFilters = () => {
		setFilters({
			type: '',
			seqType: '',
			free: false,
			instructor: '',
			position: '',
			title: '',
		});
		setPage(1); // Reset page to 1 when clearing filters
	};

	const handleFilterChange = (event, newValue, reason, name) => {
		if (event.type === 'click') {
			setIsTyping(false);
		}
		setFilters((prev) => ({
			...prev,
			[name]: newValue?.value || '',
		}));
	};

	const handleCheckboxChange = (event) => {
		const { name, checked } = event.target;
		setFilters((prev) => ({
			...prev,
			[name]: checked,
		}));
	};

	const handleRemoveFilter = (filter) => {
		setFilters((prev) => ({
			...prev,
			[filter]: '',
		}));
	};

	const toggleFilters = () => {
		setShowFilters((prev) => !prev);
	};

	return (
		<Grid container sx={{ minHeight: '100vh' }}>
			<Head title='Resources' />
			{isSmallScreen ? (
				<Grid item xs={12}>
					<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2, background: darkMode ? 'rgb(18, 57, 44)' : 'rgb(231, 240, 230)' }}>
						<Typography variant='h6'>Advanced Resource Filters</Typography>
						<IconButton onClick={toggleFilters}>{showFilters ? <CloseIcon /> : <MenuIcon />}</IconButton>
					</Box>
					{showFilters && (
						<Paper style={{ overflow: 'auto', background: darkMode ? 'rgb(18, 57, 44)' : 'rgb(231, 240, 230)', p: 2, textAlign: 'center' }}>
							<Typography variant='p' gutterBottom>
								Select filters to narrow down your search.
							</Typography>
							<Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '100%', p: 3 }}>
								<Autocomplete
									onFocus={() => setIsTyping(true)}
									onBlur={() => setIsTyping(false)}
									disabled={fetching === true}
									sx={autoCompleteStyles}
									isOptionEqualToValue={isOptionEqualToValue}
									getOptionLabel={getOptionLabel}
									options={prepareOptions(filterOptions.titles, filterOptions.alternativeNames)} // Use titles option
									value={filters.title}
									onChange={(e, newValue, reason) => handleFilterChange(e, newValue, reason, 'title')} // Handle title change
									renderInput={(params) => (
										<TextField
											{...params}
											InputProps={{
												...params.InputProps,
												style: { fontFamily: 'monospace' },
											}}
											InputLabelProps={{
												...params.InputLabelProps,
												style: { fontFamily: 'monospace' },
											}}
											label='Title' // Label for title filter
											variant='outlined'
										/>
									)}
									ListboxComponent={(props) => <StyledListbox {...props} />}
									renderOption={renderOption}
								/>
								{filters?.title !== '' && <Chip label={filters.title} onDelete={() => handleRemoveFilter('title')} sx={{ mb: 1 }} />}

								<Autocomplete
									onFocus={() => setIsTyping(true)}
									onBlur={() => setIsTyping(false)}
									disabled={fetching === true}
									sx={autoCompleteStyles}
									isOptionEqualToValue={isOptionEqualToValue}
									getOptionLabel={getOptionLabel}
									options={prepareOptions(filterOptions.types, filterOptions.alternativeNames)}
									value={filters.type}
									onChange={(e, newValue, reason) => handleFilterChange(e, newValue, reason, 'type')}
									renderInput={(params) => (
										<TextField
											{...params}
											InputProps={{
												...params.InputProps,
												style: { fontFamily: 'monospace' },
											}}
											InputLabelProps={{
												...params.InputLabelProps,
												style: { fontFamily: 'monospace' },
											}}
											label='Type'
											variant='outlined'
										/>
									)}
									ListboxComponent={(props) => <StyledListbox {...props} />}
									renderOption={renderOption}
								/>
								{filters?.type !== '' && <Chip label={filters.type} onDelete={() => handleRemoveFilter('type')} sx={{ mb: 1 }} />}

								<Autocomplete
									onFocus={() => setIsTyping(true)}
									onBlur={() => setIsTyping(false)}
									disabled={fetching === true}
									sx={autoCompleteStyles}
									isOptionEqualToValue={isOptionEqualToValue}
									getOptionLabel={getOptionLabel}
									options={prepareOptions(filterOptions.seqTypes, filterOptions.alternativeNames)}
									value={filters.seqType}
									onChange={(e, newValue, reason) => handleFilterChange(e, newValue, reason, 'seqType')}
									renderInput={(params) => (
										<TextField
											{...params}
											InputProps={{
												...params.InputProps,
												style: { fontFamily: 'monospace' },
											}}
											InputLabelProps={{
												...params.InputLabelProps,
												style: { fontFamily: 'monospace' },
											}}
											label='Sequence Type'
											variant='outlined'
										/>
									)}
									ListboxComponent={(props) => <StyledListbox {...props} />}
									renderOption={renderOption}
								/>
								{filters?.seqType !== '' && <Chip label={filters.seqType} onDelete={() => handleRemoveFilter('seqType')} sx={{ mb: 1 }} />}

								<Autocomplete
									onFocus={() => setIsTyping(true)}
									onBlur={() => setIsTyping(false)}
									disabled={fetching === true}
									sx={autoCompleteStyles}
									isOptionEqualToValue={isOptionEqualToValue}
									getOptionLabel={getOptionLabel}
									options={prepareOptions(filterOptions.instructors, filterOptions.alternativeNames)}
									value={filters.instructor}
									onChange={(e, newValue, reason) => handleFilterChange(e, newValue, reason, 'instructor')}
									renderInput={(params) => (
										<TextField
											{...params}
											InputProps={{
												...params.InputProps,
												style: { fontFamily: 'monospace' },
											}}
											InputLabelProps={{
												...params.InputLabelProps,
												style: { fontFamily: 'monospace' },
											}}
											label='Instructor'
											variant='outlined'
										/>
									)}
									ListboxComponent={(props) => <StyledListbox {...props} />}
									renderOption={renderOption}
								/>
								{filters?.instructor !== '' && <Chip label={filters.instructor} onDelete={() => handleRemoveFilter('instructor')} sx={{ mb: 1 }} />}

								<Autocomplete
									onFocus={() => setIsTyping(true)}
									onBlur={() => setIsTyping(false)}
									disabled={fetching === true}
									sx={autoCompleteStyles}
									isOptionEqualToValue={isOptionEqualToValue}
									getOptionLabel={getOptionLabel}
									options={prepareOptions(filterOptions.positions, filterOptions.alternativeNames)} // Combined positions
									value={filters.position} // Combined position filter
									onChange={(e, newValue, reason) => handleFilterChange(e, newValue, reason, 'position')}
									renderInput={(params) => (
										<TextField
											{...params}
											InputProps={{
												...params.InputProps,
												style: { fontFamily: 'monospace' },
											}}
											InputLabelProps={{
												...params.InputLabelProps,
												style: { fontFamily: 'monospace' },
											}}
											label='Position'
											variant='outlined'
										/>
									)}
									ListboxComponent={(props) => <StyledListbox {...props} />}
									renderOption={renderOption}
								/>
								{filters?.position !== '' && <Chip label={filters.position} onDelete={() => handleRemoveFilter('position')} sx={{ mb: 1 }} />}

								<FormControlLabel
									sx={{ width: 'fit-content' }}
									control={
										<Checkbox
											sx={{
												color: darkMode ? 'white' : 'black', // Default (unchecked) color
												'&.Mui-checked': {
													color: darkMode ? 'white' : 'black', // Checked color
													'&:after': {
														content: '""',
														position: 'absolute',
														backgroundColor: 'black',
														width: '100%',
														height: '100%',
														zIndex: '-1',
													},
												},
											}}
											disabled={filterOptions.freeCount === 0}
											checked={filters.free}
											onChange={handleCheckboxChange}
											name='free'
										/>
									}
									label={'Free'}
								/>

								<Button
									sx={{ background: 'rgb(47,160, 112)', color: darkMode ? '#000' : '#fff', padding: '5px', mt: 2, ':hover': { background: 'rgb(47,180,112)' } }}
									variant='contained'
									onClick={handleClearFilters}
								>
									Clear Filters
								</Button>
							</Box>
						</Paper>
					)}
				</Grid>
			) : (
				<Grid item xs={3} style={{ minHeight: '100%', maxWidth: '300px' }}>
					<Paper style={{ minHeight: '100%', overflow: 'auto', background: darkMode ? 'rgb(18, 57, 44)' : 'rgb(231, 240, 230)' }}>
						<Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '100%', p: 3 }}>
							<Typography variant='p' gutterBottom sx={{ fontWeight: '600' }}>
								Advanced Resource Filters
							</Typography>
							<Typography variant='p' gutterBottom>
								Select filters to narrow down your search.
							</Typography>
							<Autocomplete
								onFocus={() => setIsTyping(true)}
								onBlur={() => setIsTyping(false)}
								disabled={fetching === true}
								sx={autoCompleteStyles}
								isOptionEqualToValue={isOptionEqualToValue}
								getOptionLabel={getOptionLabel}
								options={prepareOptions(filterOptions.titles, filterOptions.alternativeNames)} // Use titles option
								value={filters.title}
								onChange={(e, newValue, reason) => handleFilterChange(e, newValue, reason, 'title')} // Handle title change
								renderInput={(params) => (
									<TextField
										{...params}
										InputProps={{
											...params.InputProps,
											style: { fontFamily: 'monospace' },
										}}
										InputLabelProps={{
											...params.InputLabelProps,
											style: { fontFamily: 'monospace' },
										}}
										label='Title' // Label for title filter
										variant='outlined'
									/>
								)}
								ListboxComponent={(props) => <StyledListbox {...props} />}
								renderOption={renderOption}
							/>
							{filters?.title !== '' && <Chip label={filters.title} onDelete={() => handleRemoveFilter('title')} sx={{ mb: 1 }} />}

							<Autocomplete
								onFocus={() => setIsTyping(true)}
								onBlur={() => setIsTyping(false)}
								disabled={fetching === true}
								sx={autoCompleteStyles}
								isOptionEqualToValue={isOptionEqualToValue}
								getOptionLabel={getOptionLabel}
								options={prepareOptions(filterOptions.types, filterOptions.alternativeNames)}
								value={filters.type}
								onChange={(e, newValue, reason) => handleFilterChange(e, newValue, reason, 'type')}
								renderInput={(params) => (
									<TextField
										{...params}
										InputProps={{
											...params.InputProps,
											style: { fontFamily: 'monospace' },
										}}
										InputLabelProps={{
											...params.InputLabelProps,
											style: { fontFamily: 'monospace' },
										}}
										label='Type'
										variant='outlined'
									/>
								)}
								ListboxComponent={(props) => <StyledListbox {...props} />}
								renderOption={renderOption}
							/>
							{filters?.type !== '' && <Chip label={filters.type} onDelete={() => handleRemoveFilter('type')} sx={{ mb: 1 }} />}
							<Autocomplete
								onFocus={() => setIsTyping(true)}
								onBlur={() => setIsTyping(false)}
								disabled={fetching === true}
								sx={autoCompleteStyles}
								isOptionEqualToValue={isOptionEqualToValue}
								getOptionLabel={getOptionLabel}
								options={prepareOptions(filterOptions.seqTypes, filterOptions.alternativeNames)}
								value={filters.seqType}
								onChange={(e, newValue, reason) => handleFilterChange(e, newValue, reason, 'seqType')}
								renderInput={(params) => (
									<TextField
										{...params}
										InputProps={{
											...params.InputProps,
											style: { fontFamily: 'monospace' },
										}}
										InputLabelProps={{
											...params.InputLabelProps,
											style: { fontFamily: 'monospace' },
										}}
										label='Sequence Type'
										variant='outlined'
									/>
								)}
								ListboxComponent={(props) => <StyledListbox {...props} />}
								renderOption={renderOption}
							/>
							{filters?.seqType !== '' && <Chip label={filters.seqType} onDelete={() => handleRemoveFilter('seqType')} sx={{ mb: 1 }} />}
							<Autocomplete
								onFocus={() => setIsTyping(true)}
								onBlur={() => setIsTyping(false)}
								disabled={fetching === true}
								sx={autoCompleteStyles}
								isOptionEqualToValue={isOptionEqualToValue}
								getOptionLabel={getOptionLabel}
								options={prepareOptions(filterOptions.instructors, filterOptions.alternativeNames)}
								value={filters.instructor}
								onChange={(e, newValue, reason) => handleFilterChange(e, newValue, reason, 'instructor')}
								renderInput={(params) => (
									<TextField
										{...params}
										InputProps={{
											...params.InputProps,
											style: { fontFamily: 'monospace' },
										}}
										InputLabelProps={{
											...params.InputLabelProps,
											style: { fontFamily: 'monospace' },
										}}
										label='Instructor'
										variant='outlined'
									/>
								)}
								ListboxComponent={(props) => <StyledListbox {...props} />}
								renderOption={renderOption}
							/>
							{filters?.instructor !== '' && <Chip label={filters.instructor} onDelete={() => handleRemoveFilter('instructor')} sx={{ mb: 1 }} />}
							<Autocomplete
								onFocus={() => setIsTyping(true)}
								onBlur={() => setIsTyping(false)}
								disabled={fetching === true}
								sx={autoCompleteStyles}
								isOptionEqualToValue={isOptionEqualToValue}
								getOptionLabel={getOptionLabel}
								options={prepareOptions(filterOptions.positions, filterOptions.alternativeNames)} // Combined positions
								value={filters.position} // Combined position filter
								onChange={(e, newValue, reason) => handleFilterChange(e, newValue, reason, 'position')}
								renderInput={(params) => (
									<TextField
										{...params}
										InputProps={{
											...params.InputProps,
											style: { fontFamily: 'monospace' },
										}}
										InputLabelProps={{
											...params.InputLabelProps,
											style: { fontFamily: 'monospace' },
										}}
										label='Position'
										variant='outlined'
									/>
								)}
								ListboxComponent={(props) => <StyledListbox {...props} />}
								renderOption={renderOption}
							/>
							{filters?.position !== '' && <Chip label={filters.position} onDelete={() => handleRemoveFilter('position')} sx={{ mb: 1 }} />}

							<FormControlLabel
								sx={{ width: 'fit-content' }}
								control={
									<Checkbox
										sx={{
											color: darkMode ? 'white' : 'black', // Default (unchecked) color
											'&.Mui-checked': {
												color: darkMode ? 'white' : 'black', // Checked color
												'&:after': {
													content: '""',
													position: 'absolute',
													backgroundColor: 'black',
													width: '100%',
													height: '100%',
													zIndex: '-1',
												},
											},
										}}
										disabled={filterOptions.freeCount === 0}
										checked={filters.free}
										onChange={handleCheckboxChange}
										name='free'
									/>
								}
								label={'Free'}
							/>

							<Button
								sx={{ background: 'rgb(47,160, 112)', color: darkMode ? '#000' : '#fff', padding: '5px', mt: 2, ':hover': { background: 'rgb(47,180,112)' } }}
								variant='contained'
								onClick={handleClearFilters}
							>
								Clear Filters
							</Button>
						</Box>
					</Paper>
				</Grid>
			)}
			<Grid item sx={{ p: 1, width: isSmallScreen ? '100%' : '75%' }}>
				<Box sx={{ width: '100%', p: isSmallScreen ? 0 : 2 }}>
					<Typography variant='h4' gutterBottom sx={{ textAlign: isSmallScreen ? 'center' : 'inherit' }}>
						Resources
					</Typography>

					{loading && (
						<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', pt: 4 }}>
							<Triangle height='100' width='100' color='rgb(47, 199, 112)' ariaLabel='triangle-loading' />
							<Typography variant='h6' style={{ color: 'rgb(47,199,112)' }}>
								Loading...
							</Typography>
						</Box>
					)}
					{displayedResources.length !== 0 && !loading ? (
						<>
							<Typography variant='h6' gutterBottom sx={{ textAlign: isSmallScreen ? 'center' : 'inherit' }}>
								Results ({displayedResources.length} of {filterOptions.allResources})
							</Typography>
							<Box sx={{ width: '100%', mb: 2, display: 'flex', alignItems: 'center', flexDirection: isSmallScreen ? 'column' : 'row', overflowX: 'auto' }}>
								{displayedResources?.map((resource) => (
									<Box sx={{ p: 2 }} key={resource._id}>
										<FeaturedResourceCard resource={resource} user={user} authUser={authUser} darkMode={darkMode} />
									</Box>
								))}
								{displayedResources.length < filterOptions.allResources && (
									<Box sx={{ display: 'flex', alignItems: 'center', flexDirection: isSmallScreen ? 'column' : 'row', justifyContent: 'center', minWidth: 'fit-content' }}>
										{loadingMore && (
											<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mx: 2 }}>
												<Triangle height='100' width='100' color='rgb(47, 199, 112)' ariaLabel='triangle-loading' />
											</Box>
										)}
										<Button
											variant='contained'
											onClick={loadMore}
											sx={{
												backgroundColor: 'rgb(47,160, 112)',
												color: darkMode ? '#000' : '#fff',
												marginLeft: 0,
												'&:hover': { backgroundColor: 'rgb(47, 180, 112)', color: 'white' },
											}}
										>
											Load More
										</Button>
									</Box>
								)}
							</Box>
						</>
					) : (
						<>
							{recentlyStudied.length !== 0 && !loading && (
								<>
									<Typography variant='h6' gutterBottom sx={{ textAlign: isSmallScreen ? 'center' : 'inherit' }}>
										Recently Viewed
									</Typography>
									<Box
										sx={{
											width: '100%',
											mb: 4,
											display: 'flex',
											alignItems: 'center',
											flexDirection: isSmallScreen ? 'column' : 'row',
											overflowX: 'auto',
											borderBottom: isSmallScreen ? '4px solid green' : 'none',
										}}
									>
										{recentlyStudied?.map((resource) => (
											<Box sx={{ p: 2 }} key={resource._id}>
												<FeaturedResourceCard resource={resource} user={user} authUser={authUser} darkMode={darkMode} />
											</Box>
										))}
									</Box>
								</>
							)}
							{recentlyEntered.length !== 0 && featuredResources.length !== 0 && !loading && (
								<>
									<Typography variant='h6' gutterBottom sx={{ textAlign: isSmallScreen ? 'center' : 'inherit' }}>
										Recently Entered
									</Typography>
									<Box
										sx={{
											width: '100%',
											mb: 4,
											display: 'flex',
											flexDirection: isSmallScreen ? 'column' : 'row',
											overflowX: 'auto',
											alignItems: 'center',
											borderBottom: isSmallScreen ? '4px solid green' : 'none',
										}}
									>
										{recentlyEntered?.map((resource) => (
											<Box sx={{ p: 2 }} key={resource._id}>
												<FeaturedResourceCard resource={resource} user={user} authUser={authUser} darkMode={darkMode} />
											</Box>
										))}
									</Box>

									<Typography variant='h6' gutterBottom sx={{ textAlign: isSmallScreen ? 'center' : 'inherit' }}>
										Featured Resources
									</Typography>
									<Box
										sx={{
											width: '100%',
											mb: 2,
											display: 'flex',
											alignItems: 'center',
											flexDirection: isSmallScreen ? 'column' : 'row',
											overflowX: 'auto',
										}}
									>
										{featuredResources?.map((resource) => (
											<Box sx={{ p: 2 }} key={resource._id}>
												<FeaturedResourceCard resource={resource} user={user} authUser={authUser} darkMode={darkMode} />
											</Box>
										))}
									</Box>
								</>
							)}
						</>
					)}
				</Box>
			</Grid>
		</Grid>
	);
};

export default ResourcesPage;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signUpWithEmail, signInWithEmail, login, handleForgotPassword } from '../firebase';
import { Button, TextField, Container, Grid, Typography } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import TermsAndConditions from '../components/TermsAndConditions';
import { Triangle } from 'react-loader-spinner';
import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;

function UserAuth({ darkMode, setUser, authUser, setAuthUser }) {
	const navigate = useNavigate();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState(null);
	const [resetPassword, setResetPassword] = useState(false);
	const [resetMessage, setResetMessage] = useState('');
	const [showToSModal, setShowToSModal] = useState(false);
	const [newUser, setNewuser] = useState(null);
	const [loading, setLoading] = useState(false);

	const checkAndAddUserToDB = async (user) => {
		try {
			const token = await user.getIdToken();
			const checkUserResponse = await fetch(`${API_URL}/user/check-user/${user.uid}`, {
				method: 'GET',
				headers: {
					Authorization: 'Bearer ' + token,
				},
			});

			const userData = await checkUserResponse.json();
			if (!userData.exists) {
				// Add the user to MongoDB
				await fetch(`${API_URL}/user/add-user`, {
					method: 'POST',
					headers: {
						Authorization: 'Bearer ' + token,
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						uid: user.uid,
						email: user.email,
					}),
				});
			} else {
				setUser(userData.user);
				return userData;
			}
		} catch (error) {
			console.error('Error checking/adding user to DB:', error);
		}
	};

	const handleTOSAgreement = async (user) => {
		if (!user) return;

		const token = await authUser.getIdToken();
		const res = await fetch(`${API_URL}/user/update-tos-status`, {
			method: 'PUT',
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				uid: authUser.uid,
				termsOfService: true,
			}),
		});
		if (res.ok && authUser) {
			navigate('/');
		}
	};

	const checkToSAgreementAndNavigate = async (recentUser, authUser) => {
		try {
			// Check the user's ToS agreement status from the database
			const response = await fetch(`${API_URL}/user/user-tos-status/${authUser.uid}`, {
				headers: { Authorization: `Bearer ${await authUser.getIdToken()}` },
			});
			const userData = await response.json();
			if (!userData.termsOfService) {
				setShowToSModal(true); // User has not agreed to ToS, show the modal
			} else {
				setUser(userData.user);
				if (localStorage.getItem('pathAfterLogin')) {
					navigate(localStorage.getItem('pathAfterLogin'));
					localStorage.removeItem('pathAfterLogin');
				} else {
					navigate('/');
				}
			}
		} catch (error) {
			setError('Failed to check Terms of Service agreement.');
		}
	};

	const handleSignUp = async () => {
		if (email.length === 0 || password.length === 0) {
			setError('Please enter a valid email and/or password.');
			return;
		}
		try {
			setLoading(true);
			const res = await signUpWithEmail(email, password);
			setAuthUser(res.user);
			const token = await res.user.getIdToken();

			// Step 1: Add the user to the database and wait for the operation to complete.
			const addUserResponse = await fetch(`${API_URL}/user/add-user`, {
				method: 'POST',
				headers: {
					Authorization: 'Bearer ' + token,
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					uid: res.user.uid,
					email: res.user.email,
				}),
			});

			if (!addUserResponse.ok) {
				throw new Error('Failed to add user to the database');
			}

			// Only proceed to check the ToS agreement after confirming the user has been added.
			const createdUser = await checkAndAddUserToDB(res.user);
			setNewuser(createdUser);

			// Step 2: Now that we've ensured the user is added, proceed to check their ToS status.
			await checkToSAgreementAndNavigate(createdUser, res.user);
		} catch (error) {
			const friendlyMessage = getErrorMessage(error.code || error.message);
			setError(friendlyMessage);
		} finally {
			setLoading(false);
		}
	};

	const handleSignIn = () => {
		if (email.length === 0 || password.length === 0) {
			setError('Please enter a valid email and/or password.');
			return;
		}
		signInWithEmail(email, password)
			.then(() => {
				if (localStorage.getItem('pathAfterLogin')) {
					navigate(localStorage.getItem('pathAfterLogin'));
					localStorage.removeItem('pathAfterLogin');
				} else {
					navigate('/');
				}
			})
			.catch((error) => {
				setResetPassword(!resetPassword);
				const friendlyMessage = getErrorMessage(error.code);
				setError(friendlyMessage);
			});
	};

	const handleGoogleSignIn = async () => {
		try {
			setLoading(true);
			const res = await login();
			setAuthUser(res.user);

			// Wait for the user data to be fetched and the state to be updated
			const createdUser = await checkAndAddUserToDB(res.user);

			await checkToSAgreementAndNavigate(createdUser.user, res.user);
			axios
				.get(`${API_URL}/user/${res.user.uid}`)
				.then((response) => {
					setUser(response.data);
				})
				.catch((error) => {
					console.error('Error fetching user:', error);
				});
		} catch (error) {
			const friendlyMessage = getErrorMessage(error.code);
			setError(friendlyMessage);
		} finally {
			setLoading(false);
		}
	};

	// 	login()
	// 		.then(async (res) => {
	// 			setLoading(true);
	// 			setAuthUser(res.user);
	// 			const createdUser = await checkAndAddUserToDB(res.user);
	// 			console.log(createdUser, 'is created user');
	// 			await checkToSAgreementAndNavigate(createdUser, res.user);
	// 			setNewuser(createdUser.user);
	// 		})
	// 		.catch((error) => {
	// 			const friendlyMessage = getErrorMessage(error.code);
	// 			setError(friendlyMessage);

	// 			console.error('Error signing in with Google:', error);
	// 		})
	// 		.finally(() => {
	// 			setLoading(false);
	// 			navigate('/');
	// 		});
	// };

	const handlePassWordReset = () => {
		handleForgotPassword(email)
			.then(() => {
				setResetMessage(`If your email is found a password reset email will be sent to ${email}`);
				setResetPassword(!resetPassword);
			})
			.catch((error) => {
				// Handle any errors here
				console.error('Error sending password reset email:', error.message);
			});
	};

	const getErrorMessage = (errorCode) => {
		switch (errorCode) {
			case 'auth/email-already-in-use':
				return 'Email already taken. Please sign in or use a different email address.';
			case 'auth/weak-password':
				return 'The password is too weak. Please use a password that is at least 6 characters long.';
			case 'auth/user-not-found':
			case 'auth/wrong-password':
				return 'Invalid email or password. Please try again.';
			default:
				return 'An unexpected error occurred. Please try again later.';
		}
	};

	if (loading) {
		return (
			<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
				<Triangle height='100' width='100' color='rgb(47, 199, 112)' ariaLabel='triangle-loading' />
			</div>
		);
	} else {
		return (
			<Container sx={{ minHeight: '100vh' }}>
				<Grid sx={{ marginTop: '3rem' }} container spacing={2} direction='column' alignItems='center'>
					{error && (
						<Grid item xs={12}>
							<Typography variant='body1' color='error' sx={{ textAlign: 'center' }}>
								{error}
							</Typography>
							{resetMessage && (
								<Typography sx={{ fontSize: '1.2rem', textAlign: 'center' }} variant='body1' color='error'>
									{resetMessage}
								</Typography>
							)}
							{error === 'Invalid email or password. Please try again.' && (
								<Grid direction='column' textAlign='center'>
									<Typography variant='body1' color='error' sx={{ margin: '1rem' }}>
										If you previously used{' '}
										<Button
											variant='contained'
											sx={{
												color: '#000',

												backgroundColor: 'rgb(47, 160, 112)',
												'&:hover': {
													backgroundColor: 'rgb(47, 180, 112)',
													color: '#fff',
												},
											}}
											startIcon={<GoogleIcon />}
											onClick={handleGoogleSignIn}
										>
											Sign Up/In with Google
										</Button>{' '}
										try that instead.
									</Typography>
									<Grid item xs={12}>
										<Typography sx={{ fontSize: '.75rem' }} variant='body1' color='error'>
											Forgot password?
										</Typography>
										<Button
											variant='contained'
											onClick={handlePassWordReset}
											sx={{
												color: '#000',

												backgroundColor: '#bd0303',
												marginBottom: '2rem',
												'&:hover': {
													backgroundColor: '#d20404',
													color: '#fff',
												},
											}}
											style={{ cursor: 'pointer' }}
										>
											Reset Password
										</Button>
									</Grid>
								</Grid>
							)}
						</Grid>
					)}
					<Grid item xs={12} sm={6}>
						<TextField
							autoComplete='off'
							sx={{
								'& .MuiOutlinedInput-root': {
									backgroundColor: darkMode ? '#2e2e2e' : '#f7f7f7', // Set default background color
								},
								'& .MuiOutlinedInput-root.Mui-focused': {
									backgroundColor: darkMode ? '#2e2e2e' : '#f7f7f7', // Set background color when focused
								},
								'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
									borderColor: 'rgb(47, 199, 112)',
								},
								'& .MuiInputLabel-outlined.Mui-focused': {
									color: darkMode ? 'white' : 'rgb(47, 180, 112)',
								},
							}}
							fullWidth
							autoFocus
							label='Email'
							variant='outlined'
							onChange={(e) => setEmail(e.target.value)}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							autoComplete='off'
							sx={{
								'& .MuiOutlinedInput-root': {
									backgroundColor: darkMode ? '#2e2e2e' : '#f7f7f7', // Set default background color
								},
								'& .MuiOutlinedInput-root.Mui-focused': {
									backgroundColor: darkMode ? '#2e2e2e' : '#f7f7f7', // Set background color when focused
								},
								'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
									borderColor: 'rgb(47, 199, 112)',
								},
								'& .MuiInputLabel-outlined.Mui-focused': {
									color: darkMode ? 'white' : 'rgb(47, 180, 112)',
								},
							}}
							fullWidth
							label='Password'
							type='password'
							variant='outlined'
							onChange={(e) => setPassword(e.target.value)}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Button
							variant='contained'
							sx={{
								color: darkMode ? 'white' : '#000',
								marginRight: '5px',
								backgroundColor: 'rgb(47, 160, 112)',

								'&:hover': {
									backgroundColor: 'rgb(47,180, 112)',
									color: darkMode ? '#000' : '#fff',
								},
							}}
							onClick={handleSignUp}
						>
							Sign Up
						</Button>
						<Button
							variant='contained'
							sx={{
								color: darkMode ? 'white' : '#000',
								marginLeft: '5px',
								backgroundColor: 'rgb(47, 160, 112)',

								'&:hover': {
									backgroundColor: 'rgb(47, 180, 112)',
									color: darkMode ? '#000' : '#fff',
								},
							}}
							onClick={handleSignIn}
						>
							Sign In
						</Button>
					</Grid>
					<Grid item xs={12}>
						<Typography sx={{ textAlign: 'center', marginTop: '1rem' }} variant='body1'>
							OR
						</Typography>
						<Button
							variant='contained'
							sx={{
								color: darkMode ? 'white' : '#000',
								backgroundColor: 'rgb(47, 160, 112)',

								'&:hover': {
									backgroundColor: 'rgb(47, 180, 112)',
									color: darkMode ? '#000' : '#fff',
								},
							}}
							startIcon={<GoogleIcon />}
							onClick={handleGoogleSignIn}
						>
							Sign Up/In with Google
						</Button>
					</Grid>
				</Grid>
				{showToSModal && (
					<Dialog open={showToSModal} onClose={() => setShowToSModal(false)} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
						<DialogTitle id='alert-dialog-title'>{'Terms and Conditions'}</DialogTitle>
						<DialogContent>
							<TermsAndConditions />
						</DialogContent>
						<DialogActions>
							<Button onClick={() => setShowToSModal(false)} sx={{ color: 'pink' }}>
								Disagree
							</Button>
							{newUser && (
								<Button
									onClick={async () => {
										await handleTOSAgreement(newUser);
										setShowToSModal(false);
									}}
									sx={{ color: 'rgb(47, 180, 112)' }}
									autoFocus
								>
									Agree
								</Button>
							)}
						</DialogActions>
					</Dialog>
				)}
			</Container>
		);
	}
}

export default UserAuth;

import React, { useState, useEffect, useRef } from 'react';
import { Card, CardContent, Typography, useMediaQuery, Grid, Button, Tooltip, IconButton, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import '../App.css';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import LoginAndSubscribeDialog from './LoginAndSubscribeDialog';
import YouTubeLiteEmbed from './YouTubeLiteEmbed';

const ExpandMore = styled((props) => {
	const { expand, ...other } = props;
	return <ExpandMoreIcon {...other} />;
})(({ theme, expand }) => ({
	transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
	transition: theme.transitions.create('transform', {
		duration: theme.transitions.duration.shortest,
	}),
	fontSize: '1.75rem',
}));

const FeaturedMatchCard = React.memo(({ match, darkMode, user, authUser }) => {
	const navigate = useNavigate();
	const [youTubeEmbed, setYouTubeEmbed] = useState('');
	const [start, setStart] = useState(0); // State for start time
	const youTubeRef = useRef(null);
	const [open, setOpen] = useState(false);
	const [openNotes, setOpenNotes] = useState(false);

	const handleNavigate = (e, path) => {
		e.preventDefault();
		e.stopPropagation(); // Prevent event propagation to parent elements
		if (user?.subscriptionStatus !== 'active' && path !== '/account' && path !== '/auth' && path !== '/' && path !== '/logout') {
			setOpen(true); // Open dialog
		} else {
			navigate(path);
		}
	};

	const handleExpandClick = (e) => {
		e.stopPropagation();
		setOpenNotes(!openNotes);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleCloseAndLogin = () => {
		setOpen(false);
		navigate('/auth');
	};

	const handleCloseAndSubscribe = () => {
		setOpen(false);
		navigate('/account');
	};

	const handleNoteClick = (note) => {
		const timestampMatch = note.match(/(\d+):(\d+):(\d+)|(\d+):(\d+)/);
		let totalSeconds = 0;

		if (timestampMatch) {
			let hours = 0,
				minutes = 0,
				seconds = 0;
			if (timestampMatch[3]) {
				// HH:mm:ss format
				hours = parseInt(timestampMatch[1], 10);
				minutes = parseInt(timestampMatch[2], 10);
				seconds = parseInt(timestampMatch[3], 10);
			} else {
				// mm:ss format
				minutes = parseInt(timestampMatch[4], 10);
				seconds = parseInt(timestampMatch[5], 10);
			}
			totalSeconds = hours * 3600 + minutes * 60 + seconds;
		}

		if (totalSeconds && youTubeEmbed) {
			setStart(totalSeconds); // Update the start state
			window.scrollTo({ top: youTubeRef.current.offsetTop - 50, behavior: 'smooth' });
		}
	};

	const isMobile = useMediaQuery('(max-width:1275px)');

	useEffect(() => {
		const createEmbedUrlFromLink = (link) => {
			const videoIdMatch = link.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]+)/);
			if (videoIdMatch && videoIdMatch[1]) {
				return videoIdMatch[1];
			}
			return '';
		};

		if (match.youtubeLink) {
			setYouTubeEmbed(createEmbedUrlFromLink(match.youtubeLink));
		}
	}, [match.youtubeLink]);

	return (
		<Card
			id='match-card'
			style={{ height: openNotes ? 'auto' : '450px' }}
			ref={youTubeRef}
			sx={{ maxWidth: 350, minWidth: 350, position: 'relative', my: isMobile ? 2 : 0, background: darkMode ? 'rgb(18, 57, 44)' : 'rgb(229, 247, 221)' }}
		>
			{youTubeEmbed && !match.floLink && (
				<Box style={{ position: 'relative', width: '100%' }}>
					<YouTubeLiteEmbed videoId={youTubeEmbed} start={start} />
				</Box>
			)}
			{youTubeEmbed && match.floLink && (
				<Box style={{ position: 'relative', width: '100%' }}>
					<YouTubeLiteEmbed videoId={youTubeEmbed} start={start} />
				</Box>
			)}
			{match.floLink && !youTubeEmbed && (
				<Box style={{ position: 'relative', width: '100%', paddingTop: '56.25%' }}>
					<iframe
						style={{
							position: 'absolute',
							top: 0,
							left: 0,
							width: '100%',
							height: '100%',
							border: 0,
							background: '#000',
						}}
						allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
						allowFullScreen
						title='YouTube video player'
					/>
					<Tooltip title='Watch on Flograppling?' placement='top' arrow>
						<IconButton
							sx={{
								position: 'absolute',
								top: '50%',
								left: '50%',
								transform: 'translate(-50%, -50%)',
								color: darkMode ? '#fff' : 'rgb(100,100,100)',
								bgcolor: 'transparent',
								'&:hover': {
									bgcolor: 'background.default',
								},
								size: 'large',
							}}
							onClick={() => window.open(match.floLink, '_blank')}
						>
							<PlayCircleOutlineIcon sx={{ fontSize: '3rem' }} />
						</IconButton>
					</Tooltip>
				</Box>
			)}
			{match.ufcLink && !match.youtubeLink && (
				<Box style={{ position: 'relative', width: '100%', paddingTop: '56.25%' }}>
					<iframe
						style={{
							position: 'absolute',
							top: 0,
							left: 0,
							width: '100%',
							height: '100%',
							border: 0,
							background: '#000',
						}}
						allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
						allowFullScreen
						title='YouTube video player'
					/>
					<Tooltip title='Watch on UFC Fight Pass?' placement='top' arrow>
						<IconButton
							sx={{
								position: 'absolute',
								top: '50%',
								left: '50%',
								transform: 'translate(-50%, -50%)',
								color: darkMode ? '#fff' : 'rgb(100,100,100)',
								bgcolor: 'transparent',
								'&:hover': {
									bgcolor: 'background.default',
								},
								size: 'large',
							}}
							onClick={() => window.open(match.ufcLink, '_blank')}
						>
							<PlayCircleOutlineIcon sx={{ fontSize: '3rem' }} />
						</IconButton>
					</Tooltip>
				</Box>
			)}
			{match.enigmaLink && !match.youtubeLink && (
				<Box style={{ position: 'relative', width: '100%', paddingTop: '56.25%' }}>
					<iframe
						style={{
							position: 'absolute',
							top: 0,
							left: 0,
							width: '100%',
							height: '100%',
							border: 0,
							background: '#000',
						}}
						allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
						allowFullScreen
						title='YouTube video player'
					/>
					<Tooltip title='Watch on UFC Fight Pass?' placement='top' arrow>
						<IconButton
							sx={{
								position: 'absolute',
								top: '50%',
								left: '50%',
								transform: 'translate(-50%, -50%)',
								color: darkMode ? '#fff' : 'rgb(100,100,100)',
								bgcolor: 'transparent',
								'&:hover': {
									bgcolor: 'background.default',
								},
								size: 'large',
							}}
							onClick={() => window.open(match.ufcLink, '_blank')}
						>
							<PlayCircleOutlineIcon sx={{ fontSize: '3rem' }} />
						</IconButton>
					</Tooltip>
				</Box>
			)}

			<CardContent>
				<Grid container justifyContent='space-between' alignItems='center'>
					<Grid item mb={0.25}>
						<Typography variant='subtitle1' style={{ fontWeight: '600' }}>
							{match.match.toUpperCase()}
						</Typography>
					</Grid>
				</Grid>
				<Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
					<Typography variant='subtitle1'>
						{match.year} {match.organization}
					</Typography>
					{!user || user?.subscriptionStatus !== 'active' ? (
						<Typography
							variant='button'
							onClick={(e) => handleNavigate(e, `/match/${match._id}`)}
							sx={{
								fontFamily: 'monospace',
								textDecoration: 'underline',
								fontWeight: 300,
								color: darkMode ? 'rgb(0,130,60)' : 'rgb(0, 109, 56)',
								':hover': { cursor: 'pointer', color: darkMode ? 'rgb(0,150,70)' : 'rgb(0,130,60)' },
							}}
						>
							View
						</Typography>
					) : (
						<a href={`/match/${match._id}`} onClick={(e) => handleNavigate(e, `/match/${match._id}`)} style={{ textDecoration: 'none', cursor: 'pointer' }}>
							<Typography
								variant='button'
								sx={{
									fontFamily: 'monospace',
									textDecoration: 'underline',
									fontWeight: 300,
									color: darkMode ? 'rgb(0,130,60)' : 'rgb(0, 109, 56)',
									':hover': { cursor: 'pointer', color: darkMode ? 'rgb(0,150,70)' : 'rgb(0,130,60)' },
								}}
							>
								View
							</Typography>
						</a>
					)}
				</Box>
			</CardContent>
			<Card sx={{ boxShadow: 'none', border: 'none', background: 'none' }}>
				<CardContent sx={{ backgroundColor: 'inherit', p: 1 }}>
					<Box
						style={{
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
							{match.notes.length === 0 ? (
								<Typography variant='body2' component='div'>
									No notes for this match. Edit match to enter notes.
								</Typography>
							) : (
								<>
									<Typography sx={{ fontFamily: 'monospace', mr: 'auto', pl: 1, pb: 1 }} variant='h6' component='p'>
										Notes
									</Typography>

									{match.notes.length > 0 &&
										match.notes.slice(0, 3).map((note, index) => (
											<Box key={index}>
												{index === 0 ? (
													<Typography
														key={0}
														disabled={!match.youtubeLink}
														variant='body2'
														component='div'
														onClick={() => handleNoteClick(note)}
														sx={{
															pl: 1,
															pr: 2,
															pb: isMobile ? 1 : 2,
															fontFamily: 'monospace',
															cursor: match.youtubeLink ? 'pointer' : 'inherit',
															'&:hover': { textDecoration: match.youtubeLink ? 'underline' : 'inherit' },
														}}
													>
														{note}
													</Typography>
												) : (
													<>
														{user?.subscriptionStatus !== 'active' ? (
															<Typography
																sx={{ fontFamily: 'monospace', pl: 1, pr: 2, pb: isMobile ? 1 : 2, color: user?.subscriptionStatus !== 'active' ? 'grey' : 'inherit' }}
																variant='body2'
																component='p'
																key={index}
															>
																{note}
															</Typography>
														) : (
															<Typography
																disabled={!match.youtubeLink}
																variant='body2'
																key={index}
																component='div'
																onClick={() => handleNoteClick(note)}
																sx={{
																	pl: 1,
																	pr: 2,
																	pb: isMobile ? 1 : 2,
																	fontFamily: 'monospace',
																	cursor: match.youtubeLink ? 'pointer' : 'inherit',
																	'&:hover': { textDecoration: match.youtubeLink ? 'underline' : 'inherit' },
																}}
															>
																{note}
															</Typography>
														)}
													</>
												)}
											</Box>
										))}

									{!user || user?.subscriptionStatus !== 'active' ? (
										<>
											<Tooltip title='Subscription Required' placement='top' arrow>
												<Button
													variant='contained'
													sx={{
														width: isMobile ? '75%' : '250px',
														borderRadius: '5rem',
														background: 'darkGrey',
														color: darkMode ? '#000' : '#fff',
														'&:hover': { background: 'grey' },
													}}
												>
													See All Notes
												</Button>
											</Tooltip>
										</>
									) : (
										<>
											<Button
												variant='contained'
												sx={{
													width: isMobile ? '75%' : '250px',
													borderRadius: '5rem',
													background: 'rgb(47,160,112)',
													color: darkMode ? '#000' : '#fff',
													'&:hover': { background: 'rgb(47,180,112)' },
												}}
												onClick={(e) => handleNavigate(e, `/match/${match._id}`)}
											>
												See All Notes
											</Button>
										</>
									)}
								</>
							)}
						</Box>
					</Box>
				</CardContent>
				<IconButton
					onClick={handleExpandClick}
					sx={{
						p: 0,
						color: 'rgb(47,160,112)',
						position: 'absolute',
						bottom: 0,
						right: 0,
						zIndex: 1,
						'& .MuiSvgIcon-root': {
							fontSize: '1.75rem', // Larger font size
						},
					}}
				>
					<ExpandMore expand={openNotes} />
				</IconButton>
			</Card>

			<LoginAndSubscribeDialog open={open} handleClose={handleClose} authUser={authUser} user={user} handleCloseAndLogin={handleCloseAndLogin} handleCloseAndSubscribe={handleCloseAndSubscribe} />
		</Card>
	);
});

export default FeaturedMatchCard;

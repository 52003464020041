import React, { useState, useEffect } from 'react';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const SequencePositions = () => {
	const [sequencePositions, setSequencePositions] = useState([]);
	const [newSequencePosition, setNewSequencePosition] = useState('');
	const [editSequencePosition, setEditSequencePosition] = useState({ id: null, name: '', category: '' });
	const [error, setError] = useState('');

	useEffect(() => {
		fetchSequencePositions();
	}, []);

	const fetchSequencePositions = async () => {
		try {
			const response = await axios.get(`${API_URL}/sequence-positions`);
			setSequencePositions(response.data);
		} catch (error) {
			setError('Failed to fetch sequence positions');
		}
	};

	const handleAddSequencePosition = async () => {
		try {
			const response = await axios.post(`${API_URL}/sequence-positions`, { name: newSequencePosition, category: editSequencePosition.category }); // Include the category field
			setSequencePositions([...sequencePositions, response.data]);
			setNewSequencePosition('');
		} catch (error) {
			setError('Failed to add sequence position');
		}
	};

	const handleEditSequencePosition = async () => {
		try {
			const response = await axios.put(`${API_URL}/sequence-positions/${editSequencePosition.id}`, { name: editSequencePosition.name, category: editSequencePosition.category });
			const updatedSequencePositions = sequencePositions.map((position) => {
				if (position._id === editSequencePosition.id) {
					return response.data;
				}
				return position;
			});
			setSequencePositions(updatedSequencePositions);
			setEditSequencePosition({ id: null, name: '', category: '' });
		} catch (error) {
			setError('Failed to edit sequence position');
		}
	};

	const handleDeleteSequencePosition = async (id) => {
		try {
			await axios.delete(`${API_URL}/sequence-positions/${id}`);
			setSequencePositions(sequencePositions.filter((position) => position._id !== id));
		} catch (error) {
			setError('Failed to delete sequence position');
		}
	};

	return (
		<div>
			<h2>Sequence Positions</h2>
			{error && <div>Error: {error}</div>}
			<form onSubmit={handleAddSequencePosition}>
				<input type='text' value={newSequencePosition} onChange={(e) => setNewSequencePosition(e.target.value)} placeholder='Name' required />
				<select value={editSequencePosition.category} onChange={(e) => setEditSequencePosition({ ...editSequencePosition, category: e.target.value })} required>
					<option value=''>Select Category</option>
					<option value='guards'>Guards</option>
					<option value='legEntanglements'>Leg Entanglements</option>
					<option value='guardPassing'>Guard Passing</option>
					<option value='pinning'>Pinning</option>
					<option value='takedown'>Takedown</option>
					<option value='other'>Other</option>
					<option value='submissions'>Submissions</option>
				</select>
				<button style={{ marginLeft: '1rem', marginBottom: '1rem', padding: '4px', cursor: 'pointer' }} type='submit'>
					Add Sequence Position
				</button>
			</form>

			<ul>
				{sequencePositions.map((position) => (
					<li key={position._id}>
						{editSequencePosition.id === position._id ? (
							<div>
								<input type='text' value={editSequencePosition.name} onChange={(e) => setEditSequencePosition({ ...editSequencePosition, name: e.target.value })} placeholder='Name' />
								<select value={editSequencePosition.category} onChange={(e) => setEditSequencePosition({ ...editSequencePosition, category: e.target.value })}>
									<option value='guards'>Guards</option>
									<option value='legEntanglements'>Leg Entanglements</option>
									<option value='guardPassing'>Guard Passing</option>
									<option value='pinning'>Pinning</option>
									<option value='takedown'>Takedown</option>
									<option value='other'>Other</option>
									<option value='submissions'>Submissions</option>
								</select>
								<button style={{ margin: '4px', padding: '4px', cursor: 'pointer' }} onClick={handleEditSequencePosition}>
									Save
								</button>
								<button style={{ margin: '4px', padding: '4px', cursor: 'pointer' }} onClick={() => setEditSequencePosition({ id: null, name: '', category: '' })}>
									Cancel
								</button>
							</div>
						) : (
							<div>
								{position.name} ({position.category})
								<button
									style={{ margin: '4px', padding: '4px', cursor: 'pointer' }}
									onClick={() => setEditSequencePosition({ id: position._id, name: position.name, category: position.category })}
								>
									Edit
								</button>
								<button style={{ margin: '4px', padding: '4px', cursor: 'pointer' }} onClick={() => handleDeleteSequencePosition(position._id)}>
									Delete
								</button>
							</div>
						)}
					</li>
				))}
			</ul>
		</div>
	);
};

export default SequencePositions;

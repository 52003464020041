import React, { useState, useEffect } from 'react';
import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;

const SubmissionOptions = () => {
	const [submissions, setSubmissions] = useState([]);
	const [newSubmission, setNewSubmission] = useState({
		name: '',
		options: [
			{ label: 'Submission Variation', values: [] },
			{ label: 'Submission Grip', values: [] },
			{ label: 'Submission Mechanics', values: [] },
			{ label: 'Submission Result', values: [] },
		],
		side: [],
	});
	const [editSubmission, setEditSubmission] = useState(null);
	const [error, setError] = useState('');

	useEffect(() => {
		fetchSubmissions();
	}, []);

	const fetchSubmissions = async () => {
		try {
			const response = await axios.get(`${API_URL}/submission-options`);
			setSubmissions(response.data);
		} catch (error) {
			setError('Failed to fetch submissions');
		}
	};

	const handleAddSubmission = async (e) => {
		e.preventDefault();
		try {
			const response = await axios.post(`${API_URL}/submission-options`, newSubmission);
			setSubmissions([...submissions, response.data]);
			setNewSubmission({
				name: '',
				options: [
					{ label: 'Submission Variation', values: [] },
					{ label: 'Submission Grip', values: [] },
					{ label: 'Submission Mechanics', values: [] },
					{ label: 'Submission Result', values: [] },
				],
				side: [],
			});
		} catch (error) {
			setError('Failed to add submission');
		}
	};

	const handleEdit = (id) => {
		const submissionToEdit = submissions.find((submission) => submission._id === id);
		if (!submissionToEdit) {
			return;
		}
		setEditSubmission(submissionToEdit);
		window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top of the page
	};

	const handleEditSubmission = async () => {
		try {
			const response = await axios.put(`${API_URL}/submission-options/${editSubmission._id}`, editSubmission);
			const updatedSubmissions = submissions.map((submission) => {
				if (submission._id === editSubmission._id) {
					return response.data;
				}
				return submission;
			});
			setSubmissions(updatedSubmissions);
			setEditSubmission(null);
		} catch (error) {
			setError('Failed to edit submission');
		}
	};

	const handleDeleteSubmission = async (id) => {
		try {
			await axios.delete(`${API_URL}/submission-options/${id}`);
			setSubmissions(submissions.filter((submission) => submission._id !== id));
		} catch (error) {
			setError('Failed to delete submission');
		}
	};

	const handleLabelChange = (index, value) => {
		const updatedOptions = [...newSubmission.options];
		updatedOptions[index].label = value;
		setNewSubmission({ ...newSubmission, options: updatedOptions });
	};

	const handleValuesChange = (index, value) => {
		const updatedOptions = [...newSubmission.options];
		updatedOptions[index].values = value.split(',');
		setNewSubmission({ ...newSubmission, options: updatedOptions });
	};

	const addLabel = () => {
		if (newSubmission.options.length < 4) {
			const newLabel = `Submission Label ${newSubmission.options.length + 1}`;
			setNewSubmission((prevState) => ({
				...prevState,
				options: [...prevState.options, { label: newLabel, values: [] }],
			}));
		}
	};

	return (
		<div>
			<h2>Submissions</h2>
			{error && <div>Error: {error}</div>}
			{!editSubmission && (
				<form onSubmit={handleAddSubmission}>
					<div>
						<label htmlFor='name'>Name:</label>
						<input id='name' type='text' value={newSubmission.name} onChange={(e) => setNewSubmission({ ...newSubmission, name: e.target.value })} placeholder='Name' required />
					</div>
					{newSubmission.options.map((option, index) => (
						<div key={index}>
							<label htmlFor={`label${index}`}>Option Label:</label>
							<input id={`label${index}`} type='text' value={option.label} onChange={(e) => handleLabelChange(index, e.target.value)} placeholder='Option Label' disabled />
							<br />
							<label htmlFor={`values${index}`}>Option Values:</label>
							<input
								id={`values${index}`}
								style={{ width: '100%' }}
								type='text'
								value={option.values.join(',')}
								onChange={(e) => handleValuesChange(index, e.target.value)}
								placeholder='Option Values (comma-separated)'
							/>
						</div>
					))}
					{newSubmission.options.length < 4 && (
						<button style={{ marginLeft: '1rem', marginBottom: '1rem', padding: '4px', cursor: 'pointer' }} type='button' onClick={addLabel}>
							Add Label
						</button>
					)}
					<button style={{ marginLeft: '1rem', marginBottom: '1rem', padding: '4px', cursor: 'pointer' }} type='submit'>
						Add Submission to Database
					</button>
				</form>
			)}

			{editSubmission && (
				<form onSubmit={handleEditSubmission}>
					<div>
						<label htmlFor='editName'>Name:</label>
						<input id='editName' type='text' value={editSubmission.name} onChange={(e) => setEditSubmission({ ...editSubmission, name: e.target.value })} placeholder='Name' />
					</div>
					{editSubmission.options.map((option, index) => (
						<div key={index}>
							<label htmlFor={`editLabel${index}`}>Option Label:</label>
							<input
								id={`editLabel${index}`}
								type='text'
								value={option.label}
								onChange={(e) =>
									setEditSubmission({
										...editSubmission,
										options: editSubmission.options.map((opt, idx) => (idx === index ? { ...opt, label: e.target.value } : opt)),
									})
								}
								placeholder='Option Label'
								disabled
							/>
							<br />
							<label htmlFor={`editValues${index}`}>Option Values:</label>

							<input
								id={`editValues${index}`}
								type='text'
								value={option.values.join(',')}
								onChange={(e) =>
									setEditSubmission({
										...editSubmission,
										options: editSubmission.options.map((opt, idx) => (idx === index ? { ...opt, values: e.target.value.split(',') } : opt)),
									})
								}
								placeholder='Option Values (comma-separated)'
								style={{ width: '100%' }}
							/>
						</div>
					))}
					<button style={{ margin: '4px', padding: '4px', cursor: 'pointer' }} type='submit'>
						Save
					</button>
					<button style={{ margin: '4px', padding: '4px', cursor: 'pointer' }} onClick={() => setEditSubmission(null)}>
						Cancel
					</button>
				</form>
			)}
			<ul>
				{submissions.map((submission) => (
					<li key={submission._id}>
						<div>
							<strong>Name: </strong>
							{submission.name}
						</div>
						<div>
							<ul>
								{submission.options.map((option, index) => (
									<li key={index}>
										<strong>{option.label}:</strong> {option.values.join(', ')}
									</li>
								))}
							</ul>
						</div>
						<button style={{ margin: '4px', padding: '4px', cursor: 'pointer' }} onClick={() => handleEdit(submission._id)}>
							Edit
						</button>
						<button style={{ margin: '4px', padding: '4px', cursor: 'pointer' }} onClick={() => handleDeleteSubmission(submission._id)}>
							Delete
						</button>
					</li>
				))}
			</ul>
		</div>
	);
};

export default SubmissionOptions;

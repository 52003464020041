import React, { useState, useEffect, useRef } from 'react';
import { Card, CardContent, Typography, useMediaQuery, Button, Box, Collapse, Tooltip, IconButton, List, Modal } from '@mui/material';
import { secondsToMMSS } from '../utils/helperFunctions';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import FolderHierarchy from '../components/FolderHierarchy';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import '../App.css';
import { useNavigate } from 'react-router-dom';
import { Triangle } from 'react-loader-spinner';

const API_URL = process.env.REACT_APP_API_URL;

const MatchDetailsCard = ({ matchData, darkMode, sequenceTimestamp, user, id }) => {
	const navigate = useNavigate();
	const [youTubeEmbed, setYouTubeEmbed] = useState('');
	const youTubeRef = useRef(null);
	const [showFolderModal, setShowFolderModal] = useState(false);
	const [folders, setFolders] = useState([]);
	const [likes, setLikes] = useState(0);
	const [dislikes, setDislikes] = useState(0);
	const [notesExpanded, setNotesExpanded] = useState(false);
	const [message, setMessage] = useState(null);
	const [errorMessage, setErrorMessage] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);

	const handleDelete = async (dataId) => {
		if (window.confirm('Are you sure you want to delete this match and associated sequences/resources?')) {
			try {
				setLoading(true);
				await axios.delete(`${API_URL}/data/${dataId}`);
				navigate('/');
			} catch (error) {
				setError('Error occurred while deleting data.');
			}
		}
	};

	// Modal style
	const modalStyle = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,
		bgcolor: darkMode ? 'rgb(30,30,30)' : 'rgb(225, 225, 225)',
		color: darkMode ? '#fff' : '#000',
		boxShadow: 24,
		height: '85%',
		overflowY: 'auto',
		p: 4,
	};

	const handleFolderSelect = (folderId) => {
		saveItemToFolder(matchData._id, 'Data', folderId);
		setShowFolderModal(false);
	};

	const handleLike = async () => {
		try {
			const response = await axios.patch(`${API_URL}/data/like/${id}`, { userId: user?._id });
			setLikes(response.data.totalLikes);
			setDislikes(response.data.totalDislikes);
		} catch (error) {
			console.error('Error liking match:', error);
		}
	};

	function buildFolderTree(folders, parentId = null) {
		return folders
			.filter((folder) => folder.parentFolder === parentId)
			.map((folder) => ({
				...folder,
				subFolders: buildFolderTree(folders, folder._id),
			}));
	}

	// reset message and errormessage to null if message or errormessage is not null after 3 seconds
	useEffect(() => {
		let timeoutId;
		if (error || message) {
			timeoutId = setTimeout(() => {
				setErrorMessage(null);
				setMessage(null);
			}, 3000);
		}
		return () => clearTimeout(timeoutId);
	}, [error, message]);

	useEffect(() => {
		if (showFolderModal) {
			axios
				.get(`${API_URL}/folders/${user?._id}`)
				.then((response) => {
					const folderTree = buildFolderTree(response.data);
					setFolders(folderTree);
				})
				.catch((error) => {
					console.error('Error fetching folders:', error);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showFolderModal, user?._id]);

	function saveItemToFolder(itemId, itemType, folderId) {
		fetch(`${API_URL}/folders/${folderId}/addItem`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ itemId, itemType }),
		})
			.then((response) => response.json())
			.then((data) => {
				setMessage(data.message);
			})
			.catch((error) => {
				setErrorMessage(error.message);
			});
	}

	const addToUserRecentMatches = async (matchId) => {
		try {
			const userId = user?._id;
			await axios.put(`${API_URL}/user/add-recent-match`, { userId, matchId });
		} catch (error) {
			console.error('Error adding match to user recent matches:', error);
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				// Fetch data object to show
				const matchData = await axios.get(`${API_URL}/data/${id}`);
				setLikes(matchData.data.totalLikes);
				setDislikes(matchData.data.totalDislikes);
				addToUserRecentMatches(id);
			} catch (error) {
				console.error('Error retrieving data:', error);
			}
		};
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	const handleDislike = async () => {
		try {
			const response = await axios.patch(`${API_URL}/data/dislike/${id}`, { userId: user?._id });
			setLikes(response.data.totalLikes);
			setDislikes(response.data.totalDislikes);
		} catch (error) {
			console.error('Error disliking match:', error);
		}
	};

	const toggleNotes = () => {
		setNotesExpanded(!notesExpanded); // Toggle the current state
	};

	const handleNavigate = (e, matchData) => {
		e.preventDefault();
		navigate(`/edit-match/${matchData._id}`);
	};

	const handleNoteClick = (note) => {
		// Extract timestamp from the note (format: HH:MM:SS or MM:SS)
		const timestampMatch = note.match(/(\d+):(\d+):(\d+)|(\d+):(\d+)/);
		let totalSeconds = 0;

		if (timestampMatch) {
			let hours = 0,
				minutes = 0,
				seconds = 0;

			if (timestampMatch[3]) {
				// HH:MM:SS format
				hours = parseInt(timestampMatch[1], 10);
				minutes = parseInt(timestampMatch[2], 10);
				seconds = parseInt(timestampMatch[3], 10);
			} else {
				// MM:SS format
				minutes = parseInt(timestampMatch[4], 10);
				seconds = parseInt(timestampMatch[5], 10);
			}

			totalSeconds = hours * 3600 + minutes * 60 + seconds;
		}

		// Check if youtubeEmbed exists and extract video ID
		if (youTubeEmbed) {
			const videoIdMatch = youTubeEmbed.match(/youtube\.com\/embed\/([a-zA-Z0-9_-]+)/);
			if (videoIdMatch && videoIdMatch[1]) {
				const videoId = videoIdMatch[1];
				const newEmbedUrl = `https://www.youtube.com/embed/${videoId}?start=${totalSeconds}&autoplay=1&mute=0`;
				setYouTubeEmbed(newEmbedUrl);
			}
			window.scrollTo({ top: youTubeRef.current.offsetTop - 50, behavior: 'smooth' });
		}
	};
	const isMobile = useMediaQuery('(max-width:1024px)');

	const handleNewFolder = () => {
		navigate(`/user-folders/${user._id}`);
	};

	useEffect(() => {
		if (youTubeEmbed) {
			const videoIdMatch = youTubeEmbed.match(/youtube\.com\/embed\/([a-zA-Z0-9_-]+)/);
			if (videoIdMatch && videoIdMatch[1]) {
				const videoId = videoIdMatch[1];
				const newEmbedUrl = `https://www.youtube.com/embed/${videoId}?start=${sequenceTimestamp}&autoplay=1&mute=0`;
				setYouTubeEmbed(newEmbedUrl);
			}
			window.scrollTo({ top: youTubeRef.current.offsetTop - 50, behavior: 'smooth' });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sequenceTimestamp]);

	useEffect(() => {
		const createEmbedUrlFromLink = (link) => {
			const videoIdMatch = link.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]+)/);
			if (videoIdMatch && videoIdMatch[1]) {
				return `https://www.youtube.com/embed/${videoIdMatch[1]}`;
			}
			return ''; // Return empty if URL is not valid YouTube link
		};
		setYouTubeEmbed(createEmbedUrlFromLink(matchData.youtubeLink));
	}, [matchData.youtubeLink]);

	if (loading) {
		return (
			<Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
				<Triangle height='100' width='100' color='rgb(47, 199, 112)' ariaLabel='triangle-loading' />
				<Typography variant='h6' style={{ color: 'rgb(47,199,112)' }}>
					Loading...
				</Typography>
			</Box>
		);
	}

	return (
		<>
			<Card
				id='match-card'
				ref={youTubeRef}
				sx={{
					maxWidth: '100%',
					minWidth: 250,
					position: 'relative',
					my: isMobile ? 2 : 0,

					justifyContent: 'flex-start',
					background: darkMode ? 'rgb(18, 57, 44)' : '#fff',
				}}
			>
				{showFolderModal && (
					<Modal open={showFolderModal} onClose={() => setShowFolderModal(false)}>
						<Box sx={modalStyle}>
							<Box style={{ display: 'flex', justifyContent: 'space-between' }}>
								<Typography>Select a Folder</Typography>
								<Typography
									variant='button'
									onClick={() => handleNewFolder()}
									sx={{
										fontFamily: 'monospace',
										textDecoration: 'underline',
										pr: isMobile ? 1 : 0,
										fontWeight: 300,
										color: darkMode ? 'rgb(0,130,60)' : 'rgb(0, 109, 56)',
										':hover': { cursor: 'pointer', color: darkMode ? 'rgb(0,150,70)' : 'rgb(0,130,60)' },
									}}
								>
									New Folder
								</Typography>
							</Box>
							<List>
								{folders.map((folder) => {
									return <FolderHierarchy darkMode={darkMode} key={folder._id} folder={folder} onSelect={handleFolderSelect} />;
								})}
							</List>
						</Box>
					</Modal>
				)}
				<Box style={{ display: 'flex', justifyContent: 'space-between', flexDirection: isMobile ? 'column' : 'row' }}>
					{youTubeEmbed && !matchData.floLink && (
						<Box sx={{ width: isMobile ? '100%' : '60%' }}>
							<iframe
								width='100%'
								height='350'
								style={{ borderRadius: '.75rem' }}
								src={youTubeEmbed}
								title='YouTube video player'
								allow='accelerometer;  clipboard-write; encrypted-media; gyroscope;  '
								allowFullScreen
							></iframe>
						</Box>
					)}
					{youTubeEmbed && matchData.floLink && (
						<Box sx={{ width: isMobile ? '100%' : '60%' }}>
							<iframe
								width='100%'
								height='350'
								style={{ borderRadius: '.75rem' }}
								src={youTubeEmbed}
								title='YouTube video player'
								allow='accelerometer;  clipboard-write; encrypted-media; gyroscope;  '
								allowFullScreen
							></iframe>
						</Box>
					)}
					{matchData.floLink && !youTubeEmbed && (
						<Box sx={{ width: isMobile ? '100%' : '60%' }}>
							<Box
								sx={{
									position: 'relative',
									width: '100%',
									paddingTop: '56.25%', // This sets the aspect ratio to 16:9
									background: '#000',
								}}
							>
								<iframe
									style={{
										position: 'absolute',
										top: 0,
										left: 0,
										width: '100%',
										height: '100%',
										border: 0,
									}}
									allow='accelerometer; autoplay; encrypted-media; gyroscope; '
									allowFullScreen
									title='YouTube video player'
								/>
								<Tooltip title='Watch on Flograppling?' placement='top' arrow>
									<IconButton
										sx={{
											position: 'absolute',
											top: '50%',
											left: '50%',
											transform: 'translate(-50%, -50%)',
											color: darkMode ? '#fff' : 'rgb(100,100,100)',
											bgcolor: 'transparent',
											'&:hover': {
												bgcolor: 'background.default',
											},
											size: 'large',
										}}
										onClick={() => window.open(matchData.floLink, '_blank')}
									>
										<PlayCircleOutlineIcon sx={{ fontSize: '3rem' }} />
									</IconButton>
								</Tooltip>
							</Box>
						</Box>
					)}
					{matchData.ufcLink && !matchData.youtubeLink && (
						<Box sx={{ width: isMobile ? '100%' : '60%' }}>
							<Box
								sx={{
									position: 'relative',
									width: '100%',
									paddingTop: '56.25%', // This sets the aspect ratio to 16:9
									backgroundColor: '#000',
								}}
							>
								<iframe
									style={{
										position: 'absolute',
										top: 0,
										left: 0,
										width: '100%',
										height: '100%',
										border: 0,
									}}
									allow='accelerometer; autoplay; encrypted-media; gyroscope; '
									allowFullScreen
									title='YouTube video player'
								/>
								<Tooltip title='Watch on UFC Fight Pass?' placement='top' arrow>
									<IconButton
										sx={{
											position: 'absolute',
											top: '50%',
											left: '50%',
											transform: 'translate(-50%, -50%)',
											color: darkMode ? '#fff' : 'rgb(100,100,100)',
											bgcolor: 'transparent',
											'&:hover': {
												bgcolor: 'background.default',
											},
											size: 'large',
										}}
										onClick={() => window.open(matchData.ufcLink, '_blank')}
									>
										<PlayCircleOutlineIcon sx={{ fontSize: '3rem' }} />
									</IconButton>
								</Tooltip>
							</Box>
						</Box>
					)}
					{matchData.enigmaLink && !matchData.youtubeLink && (
						<Box sx={{ width: isMobile ? '100%' : '60%' }}>
							<Box
								sx={{
									position: 'relative',
									width: '100%',
									paddingTop: '56.25%', // This sets the aspect ratio to 16:9
									backgroundColor: '#000',
								}}
							>
								<iframe
									style={{
										position: 'absolute',
										top: 0,
										left: 0,
										width: '100%',
										height: '100%',
										border: 0,
									}}
									allow='accelerometer; autoplay; encrypted-media; gyroscope; '
									allowFullScreen
									title='YouTube video player'
								/>
								<Tooltip title='Watch on UFC Fight Pass?' placement='top' arrow>
									<IconButton
										sx={{
											position: 'absolute',
											top: '50%',
											left: '50%',
											transform: 'translate(-50%, -50%)',
											color: darkMode ? '#fff' : 'rgb(100,100,100)',
											bgcolor: 'transparent',
											'&:hover': {
												bgcolor: 'background.default',
											},
											size: 'large',
										}}
										onClick={() => window.open(matchData.ufcLink, '_blank')}
									>
										<PlayCircleOutlineIcon sx={{ fontSize: '3rem' }} />
									</IconButton>
								</Tooltip>
							</Box>
						</Box>
					)}

					<Box sx={{ p: 2, width: isMobile ? '100%' : '40%' }}>
						<Typography variant='h6' sx={{ fontWeight: 600, fontSize: isMobile ? '1.25rem' : '1.5rem' }}>
							{matchData.match}
						</Typography>
						<Typography variant='h6' sx={{ fontWeight: 300, fontSize: isMobile ? '1rem' : '1.2rem' }}>
							{matchData.year} {matchData.organization} {matchData.region ? `(${matchData.region})` : ''}
						</Typography>
						<Box sx={{ display: 'flex', width: '100%', pt: 1, mt: 1 }}>
							<Box sx={{ display: 'flex', flexDirection: 'column', width: '45%' }}>
								<Typography variant='body2' sx={{ fontWeight: 600, fontFamily: 'monospace', color: 'rgb(0, 109, 56)', mb: 1, fontSize: isMobile ? '.8rem' : '1rem' }}>
									WINNER
								</Typography>
								<Typography variant='body2' sx={{ fontWeight: 600, fontFamily: 'monospace', mb: 1, fontSize: isMobile ? '.8rem' : '1rem' }}>
									DIVISION
								</Typography>
								<Typography variant='body2' sx={{ fontWeight: 600, fontFamily: 'monospace', mb: 1, fontSize: isMobile ? '.8rem' : '1rem' }}>
									POINT DISCREPANCY
								</Typography>
								<Typography variant='body2' sx={{ fontWeight: 600, fontFamily: 'monospace', mb: 1, fontSize: isMobile ? '.8rem' : '1rem' }}>
									SUBMISSION FINISH
								</Typography>
								<Typography variant='body2' sx={{ fontWeight: 600, fontFamily: 'monospace', mb: 1, fontSize: isMobile ? '.8rem' : '1rem' }}>
									FINISH TIME
								</Typography>
								<Typography variant='body2' sx={{ fontWeight: 600, fontFamily: 'monospace', mb: 1, fontSize: isMobile ? '.8rem' : '1rem' }}>
									SUBMISSION TYPE
								</Typography>
								<Typography variant='body2' sx={{ fontWeight: 600, fontFamily: 'monospace', mb: 1, fontSize: isMobile ? '.8rem' : '1rem' }}>
									FIRST TO SCORE WINS
								</Typography>
								<Typography variant='body2' sx={{ fontWeight: 600, fontFamily: 'monospace', mb: 1, fontSize: isMobile ? '.8rem' : '1rem' }}>
									WINNERS INITATION
								</Typography>
							</Box>
							<Box sx={{ display: 'flex', flexDirection: 'column', pl: isMobile ? 1 : 3, width: '55%' }}>
								<Typography variant='body2' sx={{ fontWeight: 600, fontFamily: 'monospace', color: 'rgb(0, 109, 56)', mb: 1, ml: isMobile ? 3 : 0, fontSize: isMobile ? '.8rem' : '1rem' }}>
									{matchData.matchWinner}
								</Typography>
								<Typography variant='body2' sx={{ fontWeight: 300, fontFamily: 'monospace', mb: 1, ml: isMobile ? 3 : 0, fontSize: isMobile ? '.8rem' : '1rem' }}>
									{matchData.weightDivision}
								</Typography>

								<Typography variant='body2' sx={{ fontWeight: 300, fontFamily: 'monospace', mb: 1, ml: isMobile ? 3 : 0, fontSize: isMobile ? '.8rem' : '1rem' }}>
									{matchData.pointDiscrepancy}
								</Typography>

								<Typography variant='body2' sx={{ fontWeight: 300, fontFamily: 'monospace', mb: 1, ml: isMobile ? 3 : 0, fontSize: isMobile ? '.8rem' : '1rem' }}>
									{matchData.submissionType ? 'Y' : 'N'}
								</Typography>
								<Typography variant='body2' sx={{ fontWeight: 300, fontFamily: 'monospace', mb: 1, ml: isMobile ? 3 : 0, fontSize: isMobile ? '.8rem' : '1rem' }}>
									{matchData.timeOfFinish ? (
										<>
											{secondsToMMSS(matchData.timeOfFinish)} <small style={{ fontFamily: 'monospace' }}>left in match</small>
										</>
									) : (
										'N/A'
									)}
								</Typography>

								<Typography variant='body2' sx={{ fontWeight: 300, fontFamily: 'monospace', mb: 1, ml: isMobile ? 3 : 0, fontSize: isMobile ? '.8rem' : '1rem' }}>
									{matchData.submissionType ? matchData.submissionType : 'N/A'}
								</Typography>

								<Typography variant='body2' sx={{ fontWeight: 300, fontFamily: 'monospace', mb: 1, ml: isMobile ? 3 : 0, fontSize: isMobile ? '.8rem' : '1rem' }}>
									{matchData.firstToScore}
								</Typography>

								<Typography variant='body2' sx={{ fontWeight: 300, fontFamily: 'monospace', mb: 1, ml: isMobile ? 3 : 0, fontSize: isMobile ? '.8rem' : '1rem' }}>
									{matchData.winnerInitiation}
								</Typography>
							</Box>
						</Box>
					</Box>
				</Box>

				<Card key={matchData._id} id={matchData._id} sx={{ p: isMobile ? 1 : 2, boxShadow: 'none', border: 'none', background: 'none' }}>
					<CardContent sx={{ backgroundColor: 'inherit', padding: 0 }}>
						<Box
							style={{
								display: 'flex',
								flexDirection: 'column',
							}}
						>
							<Box>
								{matchData.notes.length === 0 ? (
									<Typography variant='body2' component='div'>
										No user notes for this match. Edit match to enter freehand notes.
									</Typography>
								) : (
									<Box>
										<Button
											variant='contained'
											sx={{
												width: isMobile ? '100%' : '250px',
												borderRadius: '5rem',
												background: notesExpanded ? '#d20404' : 'rgb(47,160,112)',
												color: darkMode ? '#000' : '#fff',
												'&:hover': { background: notesExpanded ? '#db1c1c' : 'rgb(47,180,112)' },
											}}
											onClick={toggleNotes}
										>
											{notesExpanded ? 'Hide Match Notes' : 'Show Match Notes'}
										</Button>

										<Collapse in={notesExpanded} sx={{ overflowY: 'auto', maxHeight: '800px' }}>
											{matchData.notes.length === 0 ? (
												<Typography>No notes for this match.</Typography>
											) : (
												<Box
													sx={{
														mt: 2,
													}}
												>
													{matchData.notes.map((note, idx) => (
														<Typography
															key={idx}
															disabled={!matchData.youtubeLink}
															variant='body2'
															component='div'
															onClick={() => handleNoteClick(note)}
															sx={{
																margin: '.75rem 0',
																fontFamily: 'monospace',
																cursor: matchData.youtubeLink ? 'pointer' : 'inheirt',
																'&:hover': { textDecoration: matchData.youtubeLink ? 'underline' : 'inherit' },
															}}
														>
															{note}
														</Typography>
													))}
												</Box>
											)}
										</Collapse>
									</Box>
								)}
							</Box>

							<Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: isMobile ? 4 : 2 }}>
								{errorMessage && <Typography color='red'>{errorMessage}</Typography>}
								{/* // save icon */}
								<Box onClick={() => setShowFolderModal(true)} sx={{ display: 'flex', alignItems: 'center', mr: isMobile ? 1 : 2, ':hover': { cursor: 'pointer' } }}>
									<Tooltip title='Save Match to Folder' placement='top' arrow>
										<SaveIcon sx={{ color: 'rgb(255, 153, 0)', fontSize: '1.5rem' }} />
									</Tooltip>
								</Box>
								<Box onClick={(e) => handleNavigate(e, matchData)} sx={{ display: 'flex', alignItems: 'center', mr: isMobile ? 1 : 2, ':hover': { cursor: 'pointer' } }}>
									<Tooltip title='Edit Match' placement='top' arrow>
										<EditIcon sx={{ color: darkMode ? '#249C75' : '#008001' }} />
									</Tooltip>
								</Box>
								{/* // thumbs up icon */}
								<Box onClick={() => handleLike()} sx={{ display: 'flex', alignItems: 'center', mr: isMobile ? 1 : 2, ':hover': { cursor: 'pointer' } }}>
									<ThumbUpIcon sx={{ color: 'rgb(47,160,112)', fontSize: '1.5rem' }} />
									<Typography variant='body2' sx={{ ml: 1, fontSize: '1rem' }}>
										{likes}
									</Typography>
								</Box>
								{/* // thumbs down icon */}
								<Box onClick={() => handleDislike()} sx={{ display: 'flex', alignItems: 'center', mr: isMobile ? 1 : 2, ':hover': { cursor: 'pointer' } }}>
									<ThumbDownIcon sx={{ color: 'rgb(47,160,112)', fontSize: '1.5rem' }} />
									<Typography variant='body2' sx={{ ml: 1, fontSize: '1rem' }}>
										{dislikes}
									</Typography>
								</Box>

								{/* // delete icon */}
								{(user?.role === 'admin' || user?._id === matchData.createdByUser) && (
									<Box onClick={() => handleDelete(matchData._id)} sx={{ display: 'flex', alignItems: 'center', ':hover': { cursor: 'pointer' } }}>
										<DeleteIcon sx={{ color: 'red', fontSize: '1.5rem' }} />
									</Box>
								)}
							</Box>
						</Box>
					</CardContent>
				</Card>
			</Card>
		</>
	);
};

export default MatchDetailsCard;

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Grid, Paper, Typography, Checkbox, FormControlLabel, Box, IconButton, Button, Autocomplete, TextField, Chip } from '@mui/material';
import { Triangle } from 'react-loader-spinner';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import FeaturedMatchCard from '../components/FeaturedMatchCard';
import useDebounce from '../customHooks/useDebounce';
import Head from '../components/Head/Head';
import { styled } from '@mui/system';

const API_URL = process.env.REACT_APP_API_URL;

const MatchesPage = ({ user, darkMode, authUser }) => {
	const [matches, setMatches] = useState([]);
	const [displayedMatches, setDisplayedMatches] = useState([]);
	const [recentlyStudied, setRecentlyStudied] = useState([]);
	const [recentlyEntered, setRecentlyEntered] = useState([]);
	const [featuredMatches, setFeaturedMatches] = useState([]);
	const [loading, setLoading] = useState(true);
	const [loadingMore, setLoadingMore] = useState(false);
	const [filterOptions, setFilterOptions] = useState({
		players: [],
		organizations: [],
		submissionTypes: [],
		youtubeCount: 0,
		allMatches: 0,
		alternativeNames: {},
	});
	const [filters, setFilters] = useState({
		player: [],
		youtube: false,
		organization: '',
		submissionType: '',
		submissionTypeVariations: [],
	});
	const [showFilters, setShowFilters] = useState(false);
	const [page, setPage] = useState(1);
	const [fetching, setFetching] = useState(true);
	const [isTyping, setIsTyping] = useState(false);

	const isSmallScreen = useMediaQuery('(max-width:1024px)');

	const autoCompleteStyles = {
		my: 1.5,
		'& .MuiAutocomplete-clearIndicator': {
			display: 'none',
		},
		'& .MuiOutlinedInput-root': {
			borderColor: darkMode ? 'grey' : 'lightgrey',
			'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
				borderColor: 'rgb(47, 199, 112)',
			},
			'&:hover .MuiOutlinedInput-notchedOutline': {
				borderColor: darkMode ? 'white' : 'grey',
			},
		},
		'& .MuiInputLabel-outlined': {
			color: darkMode ? 'lightgrey' : 'grey',
			'&.Mui-focused': {
				color: 'rgb(47, 199, 112)',
			},
		},
	};

	const isInitialMount = useRef(true);

	const debouncedFilters = useDebounce(filters, 200);

	const StyledListbox = styled('ul')({
		fontFamily: 'monospace',
		padding: 0,
		margin: 0,
	});

	const StyledOption = styled('li')({
		fontFamily: 'monospace',
		width: '100%',
		fontSize: '.9rem',
		':hover': {
			backgroundColor: darkMode ? 'rgb(58, 89, 78)' : 'rgb(224, 240, 230)',
			cursor: 'pointer',
		},
	});

	const fetchRecentlyEntered = async () => {
		if (!localStorage.getItem('matchWiki')) {
			try {
				const recentResponse = await fetch(`${API_URL}/data/recentlyEntered`);
				const recentData = await recentResponse.json();
				const featuredResponse = await fetch(`${API_URL}/data/featuredMatches`);
				const featuredData = await featuredResponse.json();
				setRecentlyEntered(recentData);
				setFeaturedMatches(featuredData);
			} catch (error) {
				console.error('Error fetching recently entered:', error);
			}
		}
	};

	const fetchRecentlyStudied = async () => {
		if (user === null) return;
		if (!localStorage.getItem('matchWiki')) {
			try {
				const response = await fetch(`${API_URL}/data/recentlyStudied/${user?._id}`);
				const data = await response.json();
				setRecentlyStudied(data);
			} catch (error) {
				console.error('Error fetching recently studied:', error);
			}
		}
	};

	const fetchData = async () => {
		try {
			setFetching(true);
			const response = await fetch(`${API_URL}/data/matchesData`);
			const data = await response.json();
			setFilterOptions(data);
			const requestedSub = localStorage.getItem('matchWiki');
			if (requestedSub) {
				setFilters((prevFilters) => {
					return {
						...prevFilters,
						submissionType: requestedSub,
					};
				});
				localStorage.removeItem('matchWiki');
			}
		} catch (error) {
			console.error('Error fetching matches data:', error);
		} finally {
			setFetching(false);
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchData();
		fetchRecentlyEntered();
		fetchRecentlyStudied();

		// eslint-disable-next-line
	}, [user]);

	const isOptionEqualToValue = (option, value) => {
		if (!option || !value) {
			return false;
		}
		if (typeof option === 'string' && typeof value === 'string') {
			return option === value;
		}
		if (option.value && value.value) {
			return option.value === value.value;
		}
		return option.label === value.label;
	};

	const getOptionLabel = (option) => {
		if (fetching) return 'Updating Filters...';
		if (!option) return '';
		if (typeof option === 'string') return option;
		return option.label;
	};

	const renderOption = (props, option) => {
		return (
			<StyledOption {...props}>
				<span style={{ fontFamily: 'monospace' }}>{option.primaryLabel}</span>
				{option.alternatives && <span style={{ fontSize: '0.8em', color: 'gray', marginLeft: '5px' }}>*{option.alternatives.join(', ')}</span>}
			</StyledOption>
		);
	};

	const prepareOptions = (options, alternativeNames = {}) => {
		if (!options) return [];

		const seen = new Set();

		return options
			.filter((option) => option !== null && option !== undefined && option !== '')
			.flatMap((option) => {
				const primaryLabel = `${option}${alternativeNames[option] ? ` [${alternativeNames[option].join(', ')}]` : ''}`;
				if (seen.has(option)) return [];
				seen.add(option);

				const primaryOption = { label: primaryLabel, value: option, primaryLabel: option, alternatives: alternativeNames[option] };
				return [primaryOption];
			});
	};

	const renderNestedOptions = (submissionTypes = [], alternativeNames = {}) => {
		const seen = new Set();

		return submissionTypes
			.filter((submissionType) => submissionType !== null && submissionType !== undefined && submissionType.type !== '') // Filter out null, undefined, and empty string values
			.flatMap((submissionType) => {
				const primaryLabel = `${submissionType.type}`;
				const primaryOption = { label: primaryLabel, value: submissionType.type, primaryLabel: submissionType.type };

				if (seen.has(primaryLabel)) return [];
				seen.add(primaryLabel);

				const variationOptions = submissionType.variations
					? submissionType.variations
							.filter((variation) => variation !== null && variation !== undefined && variation !== '') // Filter out null, undefined, and empty string variations
							.flatMap((variation) => {
								const primaryVariationLabel = `${submissionType.type} [${variation}]${alternativeNames[variation] ? ` *${alternativeNames[variation].join(', ')}` : ''}`;

								const primaryVariationOption = {
									label: primaryVariationLabel,
									value: `${submissionType.type} : ${variation}`,
									primaryLabel: `${submissionType.type} [${variation}]`,
									alternatives: alternativeNames[variation],
								};

								if (seen.has(primaryVariationLabel)) return [];
								seen.add(primaryVariationLabel);

								return [primaryVariationOption];
							})
					: [];

				return [primaryOption, ...variationOptions];
			});
	};

	const fetchMatchesAndUpdateFilters = useCallback(
		async (reset = false) => {
			if (isTyping) return;
			if (reset) {
				setLoading(true);
				setFetching(true);
				setPage(1);
			} else {
				setLoadingMore(true);
				setFetching(true);
			}
			const currentPage = reset ? 1 : page;
			if (
				debouncedFilters.player.length === 0 &&
				debouncedFilters.youtube === false &&
				debouncedFilters.organization === '' &&
				debouncedFilters.submissionType === '' &&
				debouncedFilters.submissionTypeVariations.length === 0
			) {
				setMatches([]);
				setDisplayedMatches([]);
				fetchRecentlyStudied();
				fetchRecentlyEntered();
				fetchData();
				setLoading(false);
				setLoadingMore(false);
				setPage(1); // Reset page to 1 when clearing filters
				return;
			}
			try {
				const matchResponse = await fetch(`${API_URL}/data/matches`, {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({ ...debouncedFilters, page: currentPage, limit: 20 }),
				});
				const matchData = await matchResponse.json();
				const newMatches = reset ? matchData.matches : [...matches, ...matchData.matches];
				setMatches(newMatches);
				setDisplayedMatches(newMatches.slice(0, currentPage * 20));

				setFilterOptions((prev) => ({
					players: matchData.filterOptions.players,
					organizations: matchData.filterOptions.organizations,
					submissionTypes: matchData.filterOptions.submissionTypes,
					youtubeCount: matchData.youtubeCount,
					allMatches: matchData.allMatches,
					alternativeNames: prev.alternativeNames,
				}));
			} catch (error) {
				console.error('Error fetching matches or filter data:', error);
			}
			setLoading(false);
			setLoadingMore(false);
			setFetching(false);
		},
		// eslint-disable-next-line
		[page, isTyping, matches, debouncedFilters]
	);

	const loadMore = () => {
		const currentItemCount = displayedMatches.length;
		const totalItemsAvailable = filterOptions.allMatches;

		// No action if all items are already displayed
		if (currentItemCount >= totalItemsAvailable) return;

		const nextPage = Math.ceil(currentItemCount / 20) + 1;
		setPage(nextPage); // This will trigger the useEffect below to load more data
	};

	useEffect(() => {
		const fetchMoreData = async () => {
			if (page === 1) return; // Skip initial page or any reset scenario that sets page to 1
			await fetchMatchesAndUpdateFilters();
		};

		fetchMoreData();
		// eslint-disable-next-line
	}, [page]);

	useEffect(() => {
		if (isInitialMount.current) {
			isInitialMount.current = false;
		} else {
			fetchMatchesAndUpdateFilters(true);
		}
		// eslint-disable-next-line
	}, [debouncedFilters]);

	const handleFilterChange = (event, newValue, reason, name) => {
		if (event.type === 'click') {
			setIsTyping(false);
		}
		setFilters((prev) => {
			let updatedFilters = {
				...prev,
				[name]: Array.isArray(newValue) ? newValue.map((val) => val.value) : newValue?.value || '',
			};

			if (name === 'player') {
				updatedFilters[name] = Array.isArray(newValue) ? newValue.map((val) => val.value) : [];
			}

			return updatedFilters;
		});
	};

	const handleRemoveVariation = (variation, name) => {
		const variationsKey = name;
		setFilters((prev) => {
			const updatedVariations = prev[variationsKey].filter((v) => v !== variation);
			return {
				...prev,
				[variationsKey]: updatedVariations,
			};
		});
	};

	const handleSubmissionTypeChange = (event, newValue, reason) => {
		if (event.type === 'click') {
			setIsTyping(false);
		}
		if (!newValue) {
			setFilters((prev) => {
				const updatedFilters = {
					...prev,
					submissionType: '',
					submissionTypeVariations: [],
				};

				return updatedFilters;
			});
			return;
		}

		const value = newValue.value || newValue.label; // Ensure we get the correct value
		let submissionType, variation;
		if (value.includes(' : ')) {
			[submissionType, variation] = value.split(' : ').map((item) => item.trim());
		} else {
			submissionType = value.trim();
			variation = null;
		}

		setFilters((prev) => {
			let newVariations = prev.submissionTypeVariations;

			if (prev.submissionType === submissionType) {
				if (variation && !newVariations.includes(variation)) {
					newVariations = [...newVariations, variation];
				}
			} else {
				newVariations = variation ? [variation] : [];
			}

			return {
				...prev,
				submissionType,
				submissionTypeVariations: newVariations,
			};
		});
	};

	const handleCheckboxChange = (event) => {
		const { name, checked } = event.target;
		setFilters((prev) => ({
			...prev,
			[name]: checked,
		}));
	};

	const handleRemoveFilter = (filter) => {
		setFilters((prev) => ({
			...prev,
			[filter]: '',
			submissionTypeVariations: filter === 'submissionType' ? '' : [],
		}));
	};

	const toggleFilters = () => {
		setShowFilters((prev) => !prev);
	};

	return (
		<Grid container sx={{ minHeight: '100%' }}>
			<Head title='Matches' />
			{isSmallScreen ? (
				<Grid item xs={12}>
					<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2, background: darkMode ? 'rgb(18, 57, 44)' : 'rgb(231, 240, 230)' }}>
						<Typography variant='h6'>Advanced Match Filters</Typography>
						<IconButton onClick={toggleFilters}>{showFilters ? <CloseIcon /> : <MenuIcon />}</IconButton>
					</Box>
					{showFilters && (
						<Paper style={{ overflow: 'auto', background: darkMode ? 'rgb(18, 57, 44)' : 'rgb(231, 240, 230)', p: 2, textAlign: 'center' }}>
							<Typography variant='p' gutterBottom>
								Select filters to narrow down your search.
							</Typography>
							<Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '100%', p: 3 }}>
								<Autocomplete
									multiple
									onFocus={() => setIsTyping(true)}
									onBlur={() => setIsTyping(false)}
									disabled={fetching === true}
									sx={autoCompleteStyles}
									isOptionEqualToValue={isOptionEqualToValue}
									getOptionLabel={getOptionLabel}
									options={prepareOptions(filterOptions.players, filterOptions.alternativeNames)}
									value={filters.player.map((player) => ({ value: player, label: player }))}
									onChange={(e, newValue, reason) => handleFilterChange(e, newValue, reason, 'player')}
									renderInput={(params) => (
										<TextField
											{...params}
											InputProps={{
												...params.InputProps,
												style: { fontFamily: 'monospace' },
											}}
											InputLabelProps={{
												...params.InputLabelProps,
												style: { fontFamily: 'monospace' },
											}}
											label='Players'
											variant='outlined'
										/>
									)}
									ListboxComponent={(props) => <StyledListbox {...props} />}
									renderOption={(props, option) => (
										<StyledOption {...props}>
											<span style={{ fontFamily: 'monospace' }}>{option.primaryLabel}</span>
											{option.alternatives && <span style={{ fontSize: '0.8em', color: 'gray', marginLeft: '5px' }}>*{option.alternatives.join(', ')}</span>}
										</StyledOption>
									)}
								/>

								<Autocomplete
									onFocus={() => setIsTyping(true)}
									onBlur={() => setIsTyping(false)}
									disabled={fetching === true}
									sx={autoCompleteStyles}
									isOptionEqualToValue={isOptionEqualToValue}
									getOptionLabel={getOptionLabel}
									options={prepareOptions(filterOptions.organizations, filterOptions.alternativeNames)}
									value={filters.organization}
									onChange={(e, newValue, reason) => handleFilterChange(e, newValue, reason, 'organization')}
									renderInput={(params) => (
										<TextField
											{...params}
											InputProps={{
												...params.InputProps,
												style: { fontFamily: 'monospace' },
											}}
											InputLabelProps={{
												...params.InputLabelProps,
												style: { fontFamily: 'monospace' },
											}}
											label='Organization'
											variant='outlined'
										/>
									)}
									ListboxComponent={(props) => <StyledListbox {...props} />}
									renderOption={renderOption}
								/>
								{filters?.organization !== '' && <Chip label={filters.organization} onDelete={() => handleRemoveFilter('organization')} sx={{ mb: 1 }} />}
								<Autocomplete
									onFocus={() => setIsTyping(true)}
									onBlur={() => setIsTyping(false)}
									disabled={fetching === true}
									sx={autoCompleteStyles}
									isOptionEqualToValue={isOptionEqualToValue}
									getOptionLabel={getOptionLabel}
									options={renderNestedOptions(filterOptions.submissionTypes, filterOptions.alternativeNames)}
									value={filters.submissionType}
									onChange={(e, newValue, reason) => handleSubmissionTypeChange(e, newValue, reason)}
									renderInput={(params) => (
										<TextField
											{...params}
											InputProps={{
												...params.InputProps,
												style: { fontFamily: 'monospace' },
											}}
											InputLabelProps={{
												...params.InputLabelProps,
												style: { fontFamily: 'monospace' },
											}}
											label='Submission Type'
											variant='outlined'
										/>
									)}
									ListboxComponent={(props) => <StyledListbox {...props} />}
									renderOption={renderOption}
								/>
								<Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '100%' }}>
									{filters?.submissionType !== '' && (
										<Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '100%' }}>
											<Typography>
												<span style={{ opacity: '.7' }}>Submission: </span>
											</Typography>
											<Chip
												label={<Typography style={{ fontFamily: 'monospace', fontSize: '0.8rem' }}>{filters.submissionType}</Typography>}
												onDelete={() => handleRemoveFilter('submissionType')}
												sx={{ mb: 1 }}
											/>
										</Box>
									)}
									{filters?.submissionTypeVariations.length > 0 && (
										<Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '100%' }}>
											<Typography>
												<span style={{ opacity: '.7' }}>Variations: </span>
											</Typography>
											{filters?.submissionTypeVariations.map((variation, idx) => (
												<Chip
													key={idx}
													label={<Typography style={{ fontFamily: 'monospace', fontSize: '0.8rem' }}>{variation}</Typography>}
													onDelete={() => handleRemoveVariation(variation, 'submissionTypeVariations')}
													sx={{ m: 0.5 }}
												/>
											))}
										</Box>
									)}
								</Box>

								<FormControlLabel
									sx={{ width: ' fit-content' }}
									control={
										<Checkbox
											sx={{
												color: darkMode ? 'white' : 'black', // Default (unchecked) color
												'&.Mui-checked': {
													color: darkMode ? 'white' : 'black', // Checked color
													'&:after': {
														content: '""',
														position: 'absolute',
														backgroundColor: 'black',
														width: '100%',
														height: '100%',
														zIndex: '-1',
													},
												},
											}}
											disabled={filterOptions.youtubeCount === 0}
											checked={filters.youtube}
											onChange={handleCheckboxChange}
											name='youtube'
										/>
									}
									label={'YouTube'}
								/>
								<Button
									sx={{ background: 'rgb(47,160, 112)', color: darkMode ? '#000' : '#fff', padding: '5px', mt: 2, ':hover': { background: 'rgb(47,180,112)' } }}
									variant='contained'
									onClick={() => {
										setFilters({
											player: [],
											youtube: false,
											organization: '',
											submissionType: '',
											submissionTypeVariations: [],
										});
										setPage(1); // Reset page to 1 when clearing filters
									}}
								>
									Clear Filters
								</Button>
							</Box>
						</Paper>
					)}
				</Grid>
			) : (
				<Grid item xs={3} style={{ minHeight: '100%', maxWidth: '300px' }}>
					<Paper style={{ minHeight: '100%', overflow: 'auto', background: darkMode ? 'rgb(18, 57, 44)' : 'rgb(231, 240, 230)' }}>
						<Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '100%', p: 3 }}>
							<Typography variant='p' gutterBottom sx={{ fontWeight: '600' }}>
								Advanced Match Filters
							</Typography>
							<Typography variant='p' gutterBottom>
								Select filters to narrow down your search.
							</Typography>
							<Autocomplete
								multiple
								onFocus={() => setIsTyping(true)}
								onBlur={() => setIsTyping(false)}
								disabled={fetching === true}
								sx={autoCompleteStyles}
								isOptionEqualToValue={isOptionEqualToValue}
								getOptionLabel={getOptionLabel}
								options={prepareOptions(filterOptions.players, filterOptions.alternativeNames)}
								value={filters.player.map((player) => ({ value: player, label: player }))}
								onChange={(e, newValue, reason) => handleFilterChange(e, newValue, reason, 'player')}
								renderInput={(params) => (
									<TextField
										{...params}
										InputProps={{
											...params.InputProps,
											style: { fontFamily: 'monospace' },
										}}
										InputLabelProps={{
											...params.InputLabelProps,
											style: { fontFamily: 'monospace' },
										}}
										label='Players'
										variant='outlined'
									/>
								)}
								ListboxComponent={(props) => <StyledListbox {...props} />}
								renderOption={(props, option) => (
									<StyledOption {...props}>
										<span style={{ fontFamily: 'monospace' }}>{option.primaryLabel}</span>
										{option.alternatives && <span style={{ fontSize: '0.8em', color: 'gray', marginLeft: '5px' }}>*{option.alternatives.join(', ')}</span>}
									</StyledOption>
								)}
							/>
							<Autocomplete
								onFocus={() => setIsTyping(true)}
								onBlur={() => setIsTyping(false)}
								disabled={fetching === true}
								sx={autoCompleteStyles}
								isOptionEqualToValue={isOptionEqualToValue}
								getOptionLabel={getOptionLabel}
								options={prepareOptions(filterOptions.organizations, filterOptions.alternativeNames)}
								value={filters.organization}
								onChange={(e, newValue, reason) => handleFilterChange(e, newValue, reason, 'organization')}
								renderInput={(params) => (
									<TextField
										{...params}
										InputProps={{
											...params.InputProps,
											style: { fontFamily: 'monospace' },
										}}
										InputLabelProps={{
											...params.InputLabelProps,
											style: { fontFamily: 'monospace' },
										}}
										label='Organization'
										variant='outlined'
									/>
								)}
								ListboxComponent={(props) => <StyledListbox {...props} />}
								renderOption={renderOption}
							/>
							{filters?.organization !== '' && <Chip label={filters.organization} onDelete={() => handleRemoveFilter('organization')} sx={{ mb: 1 }} />}
							<Autocomplete
								onFocus={() => setIsTyping(true)}
								onBlur={() => setIsTyping(false)}
								disabled={fetching === true}
								sx={autoCompleteStyles}
								isOptionEqualToValue={isOptionEqualToValue}
								getOptionLabel={getOptionLabel}
								options={renderNestedOptions(filterOptions.submissionTypes, filterOptions.alternativeNames)}
								value={filters.submissionType}
								onChange={(e, newValue, reason) => handleSubmissionTypeChange(e, newValue, reason)}
								renderInput={(params) => (
									<TextField
										{...params}
										InputProps={{
											...params.InputProps,
											style: { fontFamily: 'monospace' },
										}}
										InputLabelProps={{
											...params.InputLabelProps,
											style: { fontFamily: 'monospace' },
										}}
										label='Submission Type'
										variant='outlined'
									/>
								)}
								ListboxComponent={(props) => <StyledListbox {...props} />}
								renderOption={renderOption}
							/>
							<Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '100%' }}>
								{filters?.submissionType !== '' && (
									<Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '100%' }}>
										<Typography>
											<span style={{ opacity: '.7' }}>Submission: </span>
										</Typography>
										<Chip
											label={<Typography style={{ fontFamily: 'monospace', fontSize: '0.8rem' }}>{filters.submissionType}</Typography>}
											onDelete={() => handleRemoveFilter('submissionType')}
											sx={{ mb: 1 }}
										/>
									</Box>
								)}
								{filters?.submissionTypeVariations.length > 0 && (
									<Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '100%' }}>
										<Typography>
											<span style={{ opacity: '.7' }}>Variations: </span>
										</Typography>
										{filters?.submissionTypeVariations.map((variation, idx) => (
											<Chip
												key={idx}
												label={<Typography style={{ fontFamily: 'monospace', fontSize: '0.8rem' }}>{variation}</Typography>}
												onDelete={() => handleRemoveVariation(variation, 'submissionTypeVariations')}
												sx={{ m: 0.5 }}
											/>
										))}
									</Box>
								)}
							</Box>

							<FormControlLabel
								sx={{ width: ' fit-content', mt: 1 }}
								control={
									<Checkbox
										sx={{
											color: darkMode ? 'white' : 'black', // Default (unchecked) color
											'&.Mui-checked': {
												color: darkMode ? 'white' : 'black', // Checked color
												'&:after': {
													content: '""',
													position: 'absolute',
													backgroundColor: 'black',
													width: '100%',
													height: '100%',
													zIndex: '-1',
												},
											},
										}}
										disabled={filterOptions.youtubeCount === 0}
										checked={filters.youtube}
										onChange={handleCheckboxChange}
										name='youtube'
									/>
								}
								label={'YouTube'}
							/>
							<Button
								sx={{ background: 'rgb(47,160, 112)', color: darkMode ? '#000' : '#fff', padding: '5px', mt: 2, ':hover': { background: 'rgb(47,180,112)' } }}
								variant='contained'
								onClick={() => {
									setFilters({
										player: [],
										youtube: false,
										organization: '',
										submissionType: '',
										submissionTypeVariations: [],
									});
									setPage(1); // Reset page to 1 when clearing filters
								}}
							>
								Clear Filters
							</Button>
						</Box>
					</Paper>
				</Grid>
			)}
			<Grid item sx={{ p: 1, width: isSmallScreen ? '100%' : '75%' }}>
				<Box sx={{ width: '100%', p: isSmallScreen ? 0 : 2 }}>
					<Typography variant='h4' gutterBottom sx={{ textAlign: isSmallScreen ? 'center' : 'inherit' }}>
						Matches
					</Typography>

					{loading && (
						<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', pt: 4 }}>
							<Triangle height='100' width='100' color='rgb(47, 199, 112)' ariaLabel='triangle-loading' />
							<Typography variant='h6' style={{ color: 'rgb(47,199,112)' }}>
								Loading...
							</Typography>
						</Box>
					)}
					{displayedMatches.length !== 0 && !loading ? (
						<>
							<Typography variant='h6' gutterBottom sx={{ textAlign: isSmallScreen ? 'center' : 'inherit' }}>
								Results ({displayedMatches.length} of {filterOptions.allMatches})
							</Typography>
							<Box sx={{ width: '100%', mb: 2, display: 'flex', alignItems: 'flex-start', flexDirection: isSmallScreen ? 'column' : 'row', overflowX: 'auto' }}>
								{displayedMatches?.map((match) => (
									<Box sx={{ p: 2 }} key={match._id}>
										<FeaturedMatchCard match={match} user={user} authUser={authUser} darkMode={darkMode} />
									</Box>
								))}
								{displayedMatches.length < filterOptions.allMatches && (
									<Box sx={{ display: 'flex', alignItems: 'center', flexDirection: isSmallScreen ? 'column' : 'row', justifyContent: 'center', minWidth: 'fit-content' }}>
										{loadingMore && (
											<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mx: 2 }}>
												<Triangle height='100' width='100' color='rgb(47, 199, 112)' ariaLabel='triangle-loading' />
											</Box>
										)}
										<Button
											variant='contained'
											onClick={loadMore}
											sx={{
												backgroundColor: 'rgb(47,160, 112)',
												color: darkMode ? '#000' : '#fff',
												marginLeft: 0,
												'&:hover': { backgroundColor: 'rgb(47, 180, 112)', color: 'white' },
											}}
										>
											Load More
										</Button>
									</Box>
								)}
							</Box>
						</>
					) : (
						<>
							{recentlyStudied.length !== 0 && !loading && (
								<>
									<Typography variant='h6' gutterBottom sx={{ textAlign: isSmallScreen ? 'center' : 'inherit' }}>
										Recently Viewed
									</Typography>
									<Box
										sx={{
											width: '100%',
											mb: 4,
											alignItems: 'flex-start',
											display: 'flex',
											flexDirection: isSmallScreen ? 'column' : 'row',
											overflowX: 'auto',
											borderBottom: isSmallScreen ? '4px solid green' : 'none',
										}}
									>
										{recentlyStudied?.map((match) => (
											<Box sx={{ p: 2 }} key={match._id}>
												<FeaturedMatchCard match={match} user={user} authUser={authUser} darkMode={darkMode} />
											</Box>
										))}
									</Box>
								</>
							)}
							{recentlyEntered.length !== 0 && featuredMatches.length !== 0 && !loading && (
								<>
									<Typography variant='h6' gutterBottom sx={{ textAlign: isSmallScreen ? 'center' : 'inherit' }}>
										Recently Entered
									</Typography>
									<Box
										sx={{
											width: '100%',
											mb: 4,
											alignItems: 'flex-start',
											display: 'flex',
											flexDirection: isSmallScreen ? 'column' : 'row',
											overflowX: 'auto',
											borderBottom: isSmallScreen ? '4px solid green' : 'none',
										}}
									>
										{recentlyEntered?.map((match) => (
											<Box sx={{ p: 2 }} key={match._id}>
												<FeaturedMatchCard match={match} user={user} authUser={authUser} darkMode={darkMode} />
											</Box>
										))}
									</Box>

									<Typography variant='h6' gutterBottom sx={{ textAlign: isSmallScreen ? 'center' : 'inherit' }}>
										Featured Matches
									</Typography>
									<Box
										sx={{
											width: '100%',
											mb: 2,
											alignItems: 'flex-start',
											display: 'flex',
											flexDirection: isSmallScreen ? 'column' : 'row',
											overflowX: 'auto',
										}}
									>
										{featuredMatches?.map((match) => (
											<Box sx={{ p: 2 }} key={match._id}>
												<FeaturedMatchCard match={match} user={user} authUser={authUser} darkMode={darkMode} />
											</Box>
										))}
									</Box>
								</>
							)}
						</>
					)}
				</Box>
			</Grid>
		</Grid>
	);
};

export default MatchesPage;

import React, { useState, useEffect } from 'react';
import { Box, Typography, Container, Card, CardContent, CardActions, CardMedia, TextField } from '@mui/material';
import Head from '../components/Head/Head';
import { useNavigate } from 'react-router-dom';
import { Triangle } from 'react-loader-spinner';
import useDebounce from '../customHooks/useDebounce';

const API_URL = process.env.REACT_APP_API_URL;

export default function Statistics({ darkMode }) {
	const [submissions, setSubmissions] = useState([]);
	const [positions, setPositions] = useState([]);
	const [loading, setLoading] = useState(true);
	const [submissionSearch, setSubmissionSearch] = useState('');
	const [positionSearch, setPositionSearch] = useState('');
	const debouncedSubmissionSearch = useDebounce(submissionSearch, 200);
	const debouncedPositionSearch = useDebounce(positionSearch, 200);

	const styles = {
		// Change outline color when hovered but not focused
		'&:hover .MuiOutlinedInput-notchedOutline:not(.Mui-focused)': {
			borderColor: darkMode ? 'white!important' : 'grey',
		},
		// Change label color when hovered but not focused
		'&:hover .MuiInputLabel-root:not(.Mui-focused)': {
			color: darkMode ? 'white' : '#000',
		},
		// For text color
		'&& input': {
			color: darkMode ? 'rgb(225,225,225)' : '#000',
		},
		// Default outline color
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: darkMode ? 'grey!important' : 'grey',
		},
		// Focused outline color
		'& .MuiOutlinedInput-notchedOutline.Mui-focused': {
			borderColor: darkMode ? 'rgb(47,199,112)' : '#000',
		},
		// Background color
		'& .MuiOutlinedInput-root': {
			backgroundColor: darkMode ? '#000' : '#f7f7f7',
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			borderColor: 'rgb(47, 199, 112)!important',
		},
		// For label text color
		'& .MuiInputLabel-root': {
			color: darkMode ? 'grey' : 'grey',
		},
		// Focused label color
		'& .MuiInputLabel-outlined.Mui-focused': {
			color: 'rgb(47, 180, 112)',
		},
	};

	const navigate = useNavigate();

	const handleNavigation = (e, path) => {
		e.preventDefault();
		navigate(path);
	};

	useEffect(() => {
		fetch(`${API_URL}/wiki`)
			.then((res) => res.json())
			.then((data) => {
				const submissionsData = data.filter((item) => item.type === 'submission');
				const positionsData = data.filter((item) => item.type === 'position');
				setSubmissions(submissionsData);
				setPositions(positionsData);
				setLoading(false);
			})
			.catch((err) => console.error(err));
	}, []);

	const filterItems = (items, searchTerm) => {
		return items.filter(
			(item) =>
				item.term.toLowerCase().includes(searchTerm.toLowerCase()) ||
				(item.alternativeNames && item.alternativeNames.some((alt) => alt.toLowerCase().includes(searchTerm.toLowerCase()))) ||
				(item.variations && item.variations.some((variation) => variation.toLowerCase().includes(searchTerm.toLowerCase())))
		);
	};

	const renderCards = (items, type) => {
		if (items.length === 0) {
			return (
				<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '150px' }}>
					<Typography variant='h6' sx={{ color: darkMode ? 'white' : 'black' }}>
						No results found
					</Typography>
				</Box>
			);
		}

		const firstRow = items.filter((_, index) => index % 2 === 0);
		const secondRow = items.filter((_, index) => index % 2 !== 0);

		return (
			<Box sx={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
				<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
					<Box sx={{ display: 'flex', gap: 2 }}>
						{firstRow.map((item, index) => (
							<Card
								key={index}
								sx={{ width: 250, background: darkMode ? 'rgb(18, 57, 44)' : 'rgb(228, 246, 221)', flexShrink: 0, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
							>
								<CardMedia component='img' height='140' image={item.imageUrlThumb} alt={`${item.term} image`} />
								<CardContent sx={{ pl: 1, py: 0 }}>
									<Typography variant='h6'>{item.term}</Typography>
								</CardContent>
								<CardActions sx={{ mt: '0', justifyContent: 'flex-end' }}>
									<Typography
										variant='button'
										onClick={(e) => handleNavigation(e, `/wiki/${encodeURIComponent(type)}/${encodeURIComponent(item.term)}`)}
										sx={{
											fontFamily: 'monospace',
											textDecoration: 'underline',
											fontWeight: 300,
											color: darkMode ? 'rgb(0,130,60)' : 'rgb(0, 109, 56)',
											':hover': { cursor: 'pointer', color: darkMode ? 'rgb(0,150,70)' : 'rgb(0,130,60)' },
										}}
									>
										View
									</Typography>
								</CardActions>
							</Card>
						))}
					</Box>
					<Box sx={{ display: 'flex', gap: 2, pb: 2 }}>
						{secondRow.map((item, index) => (
							<Card
								key={index}
								sx={{ width: 250, background: darkMode ? 'rgb(18, 57, 44)' : 'rgb(228, 246, 221)', flexShrink: 0, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
							>
								<CardMedia component='img' height='140' image={item.imageUrlThumb} alt={`${item.term} image`} />
								<CardContent sx={{ pl: 1, py: 0 }}>
									<Typography variant='h6'>{item.term}</Typography>
								</CardContent>
								<CardActions sx={{ mt: '0', justifyContent: 'flex-end' }}>
									<Typography
										variant='button'
										onClick={(e) => handleNavigation(e, `/wiki/${encodeURIComponent(type)}/${encodeURIComponent(item.term)}`)}
										sx={{
											fontFamily: 'monospace',
											textDecoration: 'underline',
											fontWeight: 300,
											color: darkMode ? 'rgb(0,130,60)' : 'rgb(0, 109, 56)',
											':hover': { cursor: 'pointer', color: darkMode ? 'rgb(0,150,70)' : 'rgb(0,130,60)' },
										}}
									>
										View
									</Typography>
								</CardActions>
							</Card>
						))}
					</Box>
				</Box>
			</Box>
		);
	};

	if (loading) {
		return (
			<Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
				<Triangle height='100' width='100' color='rgb(47, 199, 112)' ariaLabel='triangle-loading' />
				<Typography variant='h6' style={{ color: 'rgb(47,199,112)' }}>
					Loading...
				</Typography>
			</Box>
		);
	}

	return (
		<Container sx={{ pb: 4 }}>
			<Head title='Statistics' />
			<Box sx={{ marginTop: '20px' }}>
				<Box sx={{ display: 'flex', alignItems: 'center', borderBottom: '2px solid', pb: 1, mb: 2 }}>
					<Typography variant='h5' sx={{ mr: 2 }}>
						Submissions
					</Typography>
					<TextField
						autoComplete='off'
						size='small'
						label='Search Submissions'
						variant='outlined'
						sx={{ ...styles }}
						value={submissionSearch}
						onChange={(e) => setSubmissionSearch(e.target.value)}
					/>
				</Box>
				{renderCards(filterItems(submissions, debouncedSubmissionSearch), 'submission')}
			</Box>
			<Box sx={{ marginTop: '20px' }}>
				<Box sx={{ display: 'flex', alignItems: 'center', borderBottom: '2px solid', pb: 1, mb: 2 }}>
					<Typography variant='h5' sx={{ mr: 2 }}>
						Positions
					</Typography>
					<TextField autoComplete='off' size='small' label='Search Positions' variant='outlined' sx={{ ...styles }} value={positionSearch} onChange={(e) => setPositionSearch(e.target.value)} />
				</Box>
				{renderCards(filterItems(positions, debouncedPositionSearch), 'position')}
			</Box>
		</Container>
	);
}

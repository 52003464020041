import React, { useState, useEffect, useCallback } from 'react';
import { TextField, Select, Grid, MenuItem, FormControl, InputLabel, Button, Typography, Switch, FormControlLabel, useMediaQuery } from '@mui/material';
import { Triangle } from 'react-loader-spinner';
import { Container } from '@mui/material';
import Head from '../components/Head/Head';
import axios from 'axios';
import '../App.css';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { secondsToMMSS } from '../utils/helperFunctions';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const API_URL = process.env.REACT_APP_API_URL;

function AddSequences({ user, darkMode }) {
	const isMobile = useMediaQuery('(max-width:600px)');
	const { id } = useParams();
	const [searchParams] = useSearchParams();
	const [loading, setLoading] = useState(true);
	const [matchData, setMatchData] = useState(null);
	const navigate = useNavigate();
	const [startingPositions, setStartingPositions] = useState([]);
	const [lastSequenceId, setLastSequenceId] = useState(null);
	const [startingPositionVariations, setStartingPositionVariations] = useState([]);
	const [endingPositionVariations, setEndingPositionVariations] = useState([]);
	const [endingPositions, setEndingPositions] = useState([]);
	const [timeError, setTimeError] = useState(false);
	const [needToFetch, setNeedToFetch] = useState(false);
	const [currentGripOptions, setCurrentGripOptions] = useState([]);
	const [currentMechanicsOptions, setCurrentMechanicsOptions] = useState([]);
	const [currentResultOptions, setCurrentResultOptions] = useState([]);
	const [currentVariationOptions, setCurrentVariationOptions] = useState([]);
	const [movements, setMovements] = useState([]);
	const [submissionOptions, setSubmissionOptions] = useState([]);
	const [guards, setGuards] = useState([]);
	const [legEntanglements, setLegEntanglements] = useState([]);
	const [guardPassing, setGuardPassing] = useState([]);
	const [pinning, setPinning] = useState([]);
	const [takedown, setTakedown] = useState([]);
	const [other, setOther] = useState([]);
	const [submissions, setSubmissions] = useState([]);
	const [positionMap, setPositionMap] = useState({
		Back: null,
		'Crab Ride': null,
		'Front Headlock': null,
		'Leg Across': null,
		Flank: null,
		Stack: null,
		'Smash Pass': null,
		'Single Leg': null,
		Clamp: null,
		Mount: null,
		'Side Control': null,
		'North South': null,
		Turtle: null,
		'Half Guard': null,
		'Open Guard': null,
		'X-Guard': null,
		'Straight Hamstring': null,
		'Cross Hamstring': null,
		'Closed Guard': null,
		'K-Guard': null,
		'Irimi Ashi': null,
		'Cross Ashi': null,
		'Inside Ashi': null,
		'50-50': null,
		'Outside Ashi': null,
		Dogfight: null,
		Standing: null,
		'Tie Up': null,
		Spiderweb: null,
		Wedge: null,
	});

	const styles = {
		width: '24%',
		// Change outline color when hovered but not focused
		'&:hover .MuiOutlinedInput-notchedOutline:not(.Mui-focused)': {
			borderColor: darkMode ? 'white!important' : 'grey',
		},
		// Change label color when hovered but not focused
		'&:hover .MuiInputLabel-root:not(.Mui-focused)': {
			color: darkMode ? 'white' : '#000',
		},
		// For text color
		'&& input': {
			color: darkMode ? '#fff' : '#000',
		},
		// Default outline color
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: darkMode ? 'grey!important' : 'grey',
		},
		// Focused outline color
		'& .MuiOutlinedInput-notchedOutline.Mui-focused': {
			borderColor: darkMode ? 'rgb(47,199,112)' : '#000',
		},
		// Background color
		'& .MuiOutlinedInput-root': {
			backgroundColor: darkMode ? 'rgba(0,0,0,0.5)' : '#f7f7f7',
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			borderColor: 'rgb(47, 199, 112)!important',
		},
		// For label text color
		'& .MuiInputLabel-root': {
			color: darkMode ? 'grey' : 'grey',
		},
		// Focused label color
		'& .MuiInputLabel-outlined.Mui-focused': {
			color: 'rgb(47, 180, 112)',
		},
	};

	const stylesAlt = {
		width: '24%',
		// Change outline color when hovered but not focused
		'&:hover .MuiOutlinedInput-notchedOutline:not(.Mui-focused)': {
			borderColor: darkMode ? 'white!important' : 'grey',
		},
		// Change label color when hovered but not focused
		'&:hover .MuiInputLabel-root:not(.Mui-focused)': {
			color: darkMode ? 'white' : 'rgba(0,0,0,0.5)',
		},
		// For text color
		'&& input': {
			color: darkMode ? '#fff' : 'rgba(0,0,0,0.5)',
		},
		// Default outline color
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: darkMode ? 'grey!important' : 'grey',
		},
		// Focused outline color
		'& .MuiOutlinedInput-notchedOutline.Mui-focused': {
			borderColor: darkMode ? 'rgb(47,199,112)' : 'rgba(0,0,0,0.5)',
		},
		// Background color
		'& .MuiOutlinedInput-root': {
			backgroundColor: darkMode ? 'rgba(0,0,0,0.5)' : '#f7f7f7',
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			borderColor: 'rgb(47, 199, 112)!important',
		},
		// For label text color
		'& .MuiInputLabel-root': {
			color: darkMode ? 'grey' : 'grey',
		},
		// Focused label color
		'& .MuiInputLabel-outlined.Mui-focused': {
			color: 'rgb(47, 180, 112)',
		},
	};

	// List of sequence types
	const sequenceTypes = ['Guard Pass', 'Guard Retention', 'Position Entry', 'Position Escape', 'Takedown', 'Takedown Defense', 'Sweep', 'Submission'];

	// Initialize state for the form
	const [sequence, setSequence] = useState({
		player1: '',
		player2: '',
		sequenceType: '',
		sequencePlayer: '',
		startingPosition: '',
		startingPositionVariationPlayer1Upper1: '',
		startingPositionVariationPlayer1Upper2: '',
		startingPositionVariationPlayer1Lower1: '',
		startingPositionVariationPlayer1Lower2: '',
		startingPositionVariationPlayer2Upper1: '',
		startingPositionVariationPlayer2Upper2: '',
		startingPositionVariationPlayer2Lower1: '',
		startingPositionVariationPlayer2Lower2: '',
		player1SubGrip: '',
		player1SubMechanics: '',
		player1SubVariation: '',
		player1SubResult: '',
		player1SubSide: '',
		player2SubSide: '',
		player2SubGrip: '',
		player2SubMechanics: '',
		player2SubVariation: '',
		player2SubResult: '',
		startingPositionTimestamp: 0,
		startingPositionVideoTimestamp: 0,
		startingPositionTimeLeftInMatch: 0,
		endingPosition: '',
		endingPositionVariationPlayer1Upper1: '',
		endingPositionVariationPlayer1Upper2: '',
		endingPositionVariationPlayer1Lower1: '',
		endingPositionVariationPlayer1Lower2: '',
		endingPositionVariationPlayer2Upper1: '',
		endingPositionVariationPlayer2Upper2: '',
		endingPositionVariationPlayer2Lower1: '',
		endingPositionVariationPlayer2Lower2: '',
		endingPositionTimestamp: 0,
		endingPositionVideoTimestamp: 0,
		endingPositionTimeLeftInMatch: 0,
		player1Movement1: '',
		player1Movement2: '',
		player2Movement1: '',
		player2Movement2: '',
		points: [],
		createdByUser: '',
		match_id: '',
		overtime: false,
	});

	const [submittedSequences, setSubmittedSequences] = useState([]);

	const [points, setPoints] = useState([]);

	const pointOptions = {
		'Guard Pass': 3,
		'Mount (ADCC)': 2,
		'Mount (IBJJF)': 4,
		'Knee on Belly': 2,
		'Back (ADCC)': 3,
		'Back (IBJJF)': 4,
		'Sweep To Guard': 2,
		'Sweep Passed Guard (ADCC)': 4,
		'Sweep Passed Guard (IBJJF)': 2,
		'Takedown To Guard': 2,
		'Takedown Passed Guard (ADCC)': 4,
		'Takedown Passed Guard (IBJJF)': 2,
		'Penatly (Guard Pull)': -1,
		'Penalty (Stalling)': -1,
		'Penalty (Other)': -1,
	};

	const handlePointChange = (e, index, fieldType) => {
		const newPoints = [...points];
		if (fieldType === 'pointType') {
			newPoints[index].pointType = e.target.value;
			newPoints[index].pointValue = pointOptions[e.target.value];
		}
		setPoints(newPoints);
	};

	const addPointField = () => {
		setPoints([...points, { pointType: '', pointValue: 0 }]);
	};

	const removePointField = (index) => {
		const newPoints = [...points];
		newPoints.splice(index, 1);
		setPoints(newPoints);
	};

	// Function to convert timestamp (mm:ss or hh:mm:ss) to seconds
	const convertTimestampToSeconds = (timestamp) => {
		// Regular expression to match mm:ss or hh:mm:ss format
		const regex = /^((?:[01]\d|2[0-3]):)?([0-5]\d):([0-5]\d)$/;

		if (regex.test(timestamp)) {
			// Split timestamp into parts
			const parts = timestamp.split(':').map(Number);
			let hours = 0,
				minutes = 0,
				seconds = 0;

			// Determine if the timestamp is in hh:mm:ss or mm:ss format
			if (parts.length === 3) {
				// hh:mm:ss format
				[hours, minutes, seconds] = parts;
			} else if (parts.length === 2) {
				// mm:ss format
				[minutes, seconds] = parts;
			}

			// Convert to seconds and return
			return hours * 3600 + minutes * 60 + seconds;
		} else if (timestamp === '0') {
			// If it's '0', then directly use it
			return 0;
		} else {
			// Handle other invalid formats if needed
			return null;
		}
	};

	// Function to get ending positions based on sequence type
	const getEndingPositions = (type, guards, legEntanglements, guardPassing, pinning, takedown, other, submissions) => {
		switch (type) {
			case 'Guard Pass':
				return [...pinning];
			case 'Guard Retention':
				return [...guards, ...legEntanglements];
			case 'Position Entry':
				return [...guards, ...legEntanglements, ...guardPassing, ...pinning, ...takedown, ...other];
			case 'Position Escape':
				return [...guards, ...legEntanglements, ...guardPassing, ...pinning, ...takedown, ...other];
			case 'Takedown':
				return [...guards, ...legEntanglements, ...guardPassing, ...pinning, ...other];
			case 'Takedown Defense':
				return [...guards, ...legEntanglements, ...guardPassing, ...pinning, ...takedown, ...other];
			case 'Sweep':
				return [...guards, ...legEntanglements, ...guardPassing, ...pinning, ...other, ...takedown];
			case 'Submission':
				return [...submissions];
			default:
				return [];
		}
	};

	// Function to get starting positions based on sequence type
	const getStartingPositions = (type, guards, legEntanglements, guardPassing, pinning, takedown, other, submissions) => {
		switch (type) {
			case 'Guard Pass':
				return [...guards, ...legEntanglements, ...guardPassing];
			case 'Guard Retention':
				return [...guards, ...legEntanglements, ...guardPassing];
			case 'Position Entry':
				return [...guards, ...legEntanglements, ...guardPassing, ...pinning, ...takedown, ...other];
			case 'Position Escape':
				return [...guards, ...legEntanglements, ...guardPassing, ...pinning, ...takedown, ...other];
			case 'Takedown':
				return [...takedown];
			case 'Takedown Defense':
				return [...takedown];
			case 'Sweep':
				return [...guards, ...legEntanglements, ...guardPassing, ...pinning, ...other, ...takedown];
			case 'Submission':
				return [...guards, ...legEntanglements, ...guardPassing, ...pinning, ...takedown, ...other, ...submissions];
			default:
				return [];
		}
	};

	const handleTimeChange = (e) => {
		const value = e.target.value;
		// Regex to enforce either mm:ss or hh:mm:ss, with hours between 0-23 and minutes/seconds between 0-59
		const isValidTime = /^((?:[01]\d|2[0-3]):)?([0-5]\d):([0-5]\d)$/.test(value);

		if (isValidTime && e.target.name === 'startingPositionTimestamp') {
			setSequence((prev) => ({
				...prev,
				startingPositionTimestamp: value,
			}));
			setTimeError(false);
		} else if (isValidTime && e.target.name === 'endingPositionTimestamp') {
			setSequence((prev) => ({
				...prev,
				endingPositionTimestamp: value,
			}));
			setTimeError(false);
		} else if (isValidTime && e.target.name === 'startingPositionVideoTimestamp') {
			setSequence((prev) => ({
				...prev,
				startingPositionVideoTimestamp: value,
			}));
			setTimeError(false);
		} else if (isValidTime && e.target.name === 'endingPositionVideoTimestamp') {
			setSequence((prev) => ({
				...prev,
				endingPositionVideoTimestamp: value,
			}));
			setTimeError(false);
		} else if (isValidTime && e.target.name === 'startingPositionTimeLeftInMatch') {
			setSequence((prev) => ({
				...prev,
				startingPositionTimeLeftInMatch: value,
			}));
			setTimeError(false);
		} else if (isValidTime && e.target.name === 'endingPositionTimeLeftInMatch') {
			setSequence((prev) => ({
				...prev,
				endingPositionTimeLeftInMatch: value,
			}));
			setTimeError(false);
		} else {
			setTimeError(true);
		}
	};

	// Handle input changes and update state
	const handleInputChange = (e) => {
		const { name, value } = e.target;

		if (
			name === 'startingPositionTimeLeftInMatch' ||
			name === 'endingPositionTimeLeftInMatch' ||
			name === 'startingPositionTimestamp' ||
			name === 'endingPositionTimestamp' ||
			name === 'startingPositionVideoTimestamp' ||
			name === 'endingPositionVideoTimestamp'
		) {
			handleTimeChange(e);
		}

		setSequence({
			...sequence,
			[name]: value,
		});
	};

	// Function to handle form submission and create sequence
	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		// Convert startingPositionTimestamp to seconds
		const startingPositionSeconds = convertTimestampToSeconds(sequence.startingPositionTimestamp);
		// Convert endingPositionTimestamp to seconds
		const endingPositionSeconds = convertTimestampToSeconds(sequence.endingPositionTimestamp);
		// Convert startingPositionVideoTimestamp to seconds
		const startingPositionVideoSeconds = convertTimestampToSeconds(sequence.startingPositionVideoTimestamp);
		// Convert endingPositionVideoTimestamp to seconds
		const endingPositionVideoSeconds = convertTimestampToSeconds(sequence.endingPositionVideoTimestamp);

		// Prepare new sequence data
		const newSequenceData = {
			...sequence,
			points,
			prev_sequence: lastSequenceId, // Set the previous sequence to the last one
			startingPositionTimestamp: startingPositionSeconds,
			endingPositionTimestamp: endingPositionSeconds,
			startingPositionVideoTimestamp: startingPositionVideoSeconds,
			endingPositionVideoTimestamp: endingPositionVideoSeconds,
		};

		// Create the new sequence and get its ID
		const currentSequenceId = await createSequence(newSequenceData);

		if (currentSequenceId) {
			navigate(`/show-sequence/${currentSequenceId}`);
			// Add new sequence to submittedSequences
			setSubmittedSequences([...submittedSequences, newSequenceData]);
			// reset part of the state but keep the user and match_id, player1,player2
			setSequence({
				...sequence,
				sequenceType: '',
				sequencePlayer: '',
				startingPosition: '',
				startingPositionTimestamp: 0,
				startingPositionVideoTimestamp: 0,
				startingPositionVariationPlayer1Upper1: '',
				startingPositionVariationPlayer1Upper2: '',
				startingPositionVariationPlayer1Lower1: '',
				startingPositionVariationPlayer1Lower2: '',
				startingPositionVariationPlayer2Upper1: '',
				startingPositionVariationPlayer2Upper2: '',
				startingPositionVariationPlayer2Lower1: '',
				startingPositionVariationPlayer2Lower2: '',
				startingPositionTimeLeftInMatch: 0,
				player1SubGrip: '',
				player1SubMechanics: '',
				player1SubVariation: '',
				player1SubResult: '',
				player1SubSide: '',
				player2SubSide: '',
				player2SubGrip: '',
				player2SubMechanics: '',
				player2SubVariation: '',
				player2SubResult: '',
				endingPosition: '',
				endingPositionTimestamp: 0,
				endingPositionVariationPlayer1Upper1: '',
				endingPositionVariationPlayer1Upper2: '',
				endingPositionVariationPlayer1Lower1: '',
				endingPositionVariationPlayer1Lower2: '',
				endingPositionVariationPlayer2Upper1: '',
				endingPositionVariationPlayer2Upper2: '',
				endingPositionVariationPlayer2Lower1: '',
				endingPositionVariationPlayer2Lower2: '',
				endingPositionVideoTimestamp: 0,
				endingPositionTimeLeftInMatch: 0,
				player1Movement1: '',
				player1Movement2: '',
				player2Movement1: '',
				player2Movement2: '',
				points: [],
			});
			// reset points
			setPoints([]);
		}

		// Update the 'next_sequence' for the last sequence to link to the new sequence
		if (lastSequenceId) {
			await updateSequence(lastSequenceId, {
				next_sequence: currentSequenceId,
			});
		}

		// Update the lastSequenceId state to point to the newly created sequence
		setLastSequenceId(currentSequenceId);

		// Refresh the list of sequences
		setNeedToFetch(!needToFetch);

		// Scroll to top of page
		window.scrollTo(0, 0);
	};

	const handleAddPrev = (sequence) => {
		setSequence((prevSequence) => ({
			...prevSequence,
			sequenceType: sequence.sequenceType,
			endingPosition: sequence.startingPosition,
			endingPositionVariationPlayer1Upper1: sequence.startingPositionVariationPlayer1Upper1,
			endingPositionVariationPlayer1Upper2: sequence.startingPositionVariationPlayer1Upper2,
			endingPositionVariationPlayer1Lower1: sequence.startingPositionVariationPlayer1Lower1,
			endingPositionVariationPlayer1Lower2: sequence.startingPositionVariationPlayer1Lower2,
			endingPositionVariationPlayer2Upper1: sequence.startingPositionVariationPlayer2Upper1,
			endingPositionVariationPlayer2Upper2: sequence.startingPositionVariationPlayer2Upper2,
			endingPositionVariationPlayer2Lower1: sequence.startingPositionVariationPlayer2Lower1,
			endingPositionVariationPlayer2Lower2: sequence.startingPositionVariationPlayer2Lower2,
			endingPositionTimestamp: secondsToMMSS(sequence.startingPositionTimestamp),
			endingPositionVideoTimestamp: secondsToMMSS(sequence.startingPositionVideoTimestamp),
		}));
		window.scrollTo(0, 0, 'smooth');
	};

	const handleAddNext = (sequence) => {
		setSequence((prevSequence) => ({
			...prevSequence,
			sequenceType: sequence.sequenceType,
			startingPosition: sequence.endingPosition,
			startingPositionVariationPlayer1Upper1: sequence.endingPositionVariationPlayer1Upper1,
			startingPositionVariationPlayer1Upper2: sequence.endingPositionVariationPlayer1Upper2,
			startingPositionVariationPlayer1Lower1: sequence.endingPositionVariationPlayer1Lower1,
			startingPositionVariationPlayer1Lower2: sequence.endingPositionVariationPlayer1Lower2,
			startingPositionVariationPlayer2Upper1: sequence.endingPositionVariationPlayer2Upper1,
			startingPositionVariationPlayer2Upper2: sequence.endingPositionVariationPlayer2Upper2,
			startingPositionVariationPlayer2Lower1: sequence.endingPositionVariationPlayer2Lower1,
			startingPositionVariationPlayer2Lower2: sequence.endingPositionVariationPlayer2Lower2,
			startingPositionTimestamp: secondsToMMSS(sequence.endingPositionTimestamp),
			startingPositionVideoTimestamp: secondsToMMSS(sequence.endingPositionVideoTimestamp),
		}));
		window.scrollTo(0, 0, 'smooth');
	};

	// Function to create a new sequence
	const createSequence = async (sequenceData) => {
		try {
			setLoading(true);
			// Make a POST request to create a new sequence
			const response = await axios.post(`${API_URL}/sequence`, sequenceData);

			if (response.data && response.data._id) {
				const sequenceId = response.data._id;

				// Create note for the sequence
				const noteResponse = await axios.post(`${API_URL}/sequence-note`, response.data);
				if (noteResponse.data && noteResponse.data._id) {
					return sequenceId;
				} else {
					throw new Error('Invalid server response for note creation');
				}
			} else {
				throw new Error('Invalid server response');
			}
		} catch (error) {
			return null;
		}
	};

	// Function to update a sequence
	const updateSequence = async (sequenceId, updateData) => {
		try {
			setLoading(true);
			const response = await axios.put(`${API_URL}/sequence/${sequenceId}`, updateData);
			if (response.data && response.data._id) {
				return response.data._id;
			} else {
				throw new Error('Invalid server response');
			}
		} catch (error) {
			return null;
		}
	};

	// Function to fetch sequences
	const fetchSequences = useCallback(async () => {
		if (!user) return;
		try {
			// Fetch data object to edit
			const data = await axios.get(`${API_URL}/data/${id}`);
			setMatchData(data.data);
			setSequence((prev) => ({
				...prev,
				match_id: data.data._id,
				player1: data.data.player1,
				player2: data.data.player2,
			}));

			// Fetch sequences based on the match data
			const response = await axios.get(`${API_URL}/sequence/matchId/${data.data._id}`);
			if (response) {
				setSubmittedSequences(response.data);
			} else {
				throw new Error('Invalid server response for sequences');
			}
		} catch (error) {
			console.error('Error retrieving data:', error);
		} finally {
			setLoading(false);
		}
	}, [user, id]); // Dependencies

	useEffect(() => {
		// fetch movements
		const fetchMovements = async () => {
			try {
				const response = await axios.get(`${API_URL}/sequence-movements`);
				if (response) {
					setMovements(response.data);
				} else {
					throw new Error('Invalid server response for movements');
				}
				// Fetch submission options
				const responseOptions = await fetch(`${API_URL}/submission-options`, {
					method: 'GET',
					headers: { 'Content-Type': 'application/json' },
				});
				const submissionOptionsData = await responseOptions.json();
				setSubmissionOptions(submissionOptionsData);
			} catch (error) {
				console.error('Error retrieving movements:', error);
			}
		};
		fetchMovements();
	}, []);

	useEffect(() => {
		const fetchPositionMap = async () => {
			try {
				const response = await axios.get(`${API_URL}/sequence-position-variations`);
				const data = response.data;

				// Create a new position map object
				const newPositionMap = {};

				// Loop through the fetched data and update the position map
				data.forEach((item) => {
					const positionType = item.positionType;

					// Map the position type to the corresponding data from the backend
					newPositionMap[positionType] = item.variations;
				});

				// Set the state with the updated position map
				setPositionMap(newPositionMap);
			} catch (error) {
				console.error('Error retrieving sequence position variations:', error);
			}
		};

		fetchPositionMap();
	}, []);

	// Function to validate sequence data
	const validateSequenceData = () => {
		// Check if required fields are filled
		const requiredFields = ['sequenceType', 'sequencePlayer', 'startingPosition', 'endingPosition'];

		return requiredFields.every((field) => sequence[field]);
	};

	// Function to insert a sequence in between two other sequences
	const insertSequenceHere = async (prevSequenceId, nextSequenceId) => {
		if (!validateSequenceData()) {
			alert('Please fill in all required fields.');
			return;
		}

		// Convert startingPositionTimestamp to seconds
		const startingPositionSeconds = convertTimestampToSeconds(sequence.startingPositionTimestamp);
		// Convert endingPositionTimestamp to seconds
		const endingPositionSeconds = convertTimestampToSeconds(sequence.endingPositionTimestamp);
		// Convert startingPositionVideoTimestamp to seconds
		const startingPositionVideoSeconds = convertTimestampToSeconds(sequence.startingPositionVideoTimestamp);
		// Convert endingPositionVideoTimestamp to seconds
		const endingPositionVideoSeconds = convertTimestampToSeconds(sequence.endingPositionVideoTimestamp);

		// Prepare new sequence data
		const newSequenceData = {
			...sequence,
			points,
			startingPositionTimestamp: startingPositionSeconds,
			endingPositionTimestamp: endingPositionSeconds,
			startingPositionVideoTimestamp: startingPositionVideoSeconds,
			endingPositionVideoTimestamp: endingPositionVideoSeconds,
			prev_sequence: prevSequenceId,
			next_sequence: nextSequenceId,
		};

		// Create the new sequence and get its ID
		const newSequenceId = await createSequence(newSequenceData);
		setSequence({
			...sequence,
			sequenceType: '',
			sequencePlayer: '',
			startingPosition: '',
			startingPositionTimestamp: 0,
			startingPositionVideoTimestamp: 0,
			startingPositionVariationPlayer1Upper1: '',
			startingPositionVariationPlayer1Upper2: '',
			startingPositionVariationPlayer1Lower1: '',
			startingPositionVariationPlayer1Lower2: '',
			startingPositionVariationPlayer2Upper1: '',
			startingPositionVariationPlayer2Upper2: '',
			startingPositionVariationPlayer2Lower1: '',
			startingPositionVariationPlayer2Lower2: '',
			player1SubGrip: '',
			player1SubMechanics: '',
			player1SubVariation: '',
			player1SubResult: '',
			player1SubSide: '',
			player2SubSide: '',
			player2SubGrip: '',
			player2SubMechanics: '',
			player2SubVariation: '',
			player2SubResult: '',
			startingPositionTimeLeftInMatch: 0,
			endingPosition: '',
			endingPositionTimestamp: 0,
			endingPositionVariationPlayer1Upper1: '',
			endingPositionVariationPlayer1Upper2: '',
			endingPositionVariationPlayer1Lower1: '',
			endingPositionVariationPlayer1Lower2: '',
			endingPositionVariationPlayer2Upper1: '',
			endingPositionVariationPlayer2Upper2: '',
			endingPositionVariationPlayer2Lower1: '',
			endingPositionVariationPlayer2Lower2: '',
			endingPositionVideoTimestamp: 0,
			endingPositionTimeLeftInMatch: 0,
			player1Movement1: '',
			player1Movement2: '',
			player2Movement1: '',
			player2Movement2: '',
			points: [],
		});
		// reset points
		setPoints([]);

		if (newSequenceId) {
			navigate(`/match/${matchData._id}?sequence_id=${newSequenceId}`);
			// Update 'prev_sequence' and 'next_sequence' fields for the surrounding sequences
			if (prevSequenceId) {
				await updateSequence(prevSequenceId, {
					next_sequence: newSequenceId,
				});
			}
			if (nextSequenceId) {
				await updateSequence(nextSequenceId, {
					prev_sequence: newSequenceId,
				});
			}
		}
		// Refresh the list of sequences
		setNeedToFetch(!needToFetch);

		// Scroll to top of page
		window.scrollTo(0, 0);
	};

	// Function to view a sequence
	const viewSequence = (e, sequence) => {
		e.preventDefault();

		navigate(`/match/${sequence.match_id}?sequence_id=${sequence._id}`);
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		axios
			.get(`${API_URL}/sequence-positions`)
			.then((response) => {
				// Handle successful response
				const data = response.data;
				// Extract position names for each category
				const guardsData = data.filter((item) => item.category === 'guards').map((item) => item.name);
				const legEntanglementsData = data.filter((item) => item.category === 'legEntanglements').map((item) => item.name);
				const guardPassingData = data.filter((item) => item.category === 'guardPassing').map((item) => item.name);
				const pinningData = data.filter((item) => item.category === 'pinning').map((item) => item.name);
				const takedownData = data.filter((item) => item.category === 'takedown').map((item) => item.name);
				const otherData = data.filter((item) => item.category === 'other').map((item) => item.name);
				const submissionsData = data.filter((item) => item.category === 'submissions').map((item) => item.name);
				// Update state with fetched data
				setGuards(guardsData);
				setLegEntanglements(legEntanglementsData);
				setGuardPassing(guardPassingData);
				setPinning(pinningData);
				setTakedown(takedownData);
				setOther(otherData);
				setSubmissions(submissionsData);
			})
			.catch((error) => {
				// Handle error
				console.error('Error fetching sequence positions:', error);
			})
			.finally(() => {
				// Update loading state
				setLoading(false);
			});
	}, []);

	useEffect(() => {
		// Extracting search parameters
		const startingPositionFromParams = searchParams.get('startingPosition');
		const startingPositionVariationPlayer1Lower1FromParams = searchParams.get('startingPositionVariationPlayer1Lower1');
		const startingPositionVariationPlayer1Lower2FromParams = searchParams.get('startingPositionVariationPlayer1Lower2');
		const startingPositionVariationPlayer1Upper1FromParams = searchParams.get('startingPositionVariationPlayer1Upper1');
		const startingPositionVariationPlayer1Upper2FromParams = searchParams.get('startingPositionVariationPlayer1Upper2');
		const startingPositionVariationPlayer2Lower1FromParams = searchParams.get('startingPositionVariationPlayer2Lower1');
		const startingPositionVariationPlayer2Lower2FromParams = searchParams.get('startingPositionVariationPlayer2Lower2');
		const startingPositionVariationPlayer2Upper1FromParams = searchParams.get('startingPositionVariationPlayer2Upper1');
		const startingPositionVariationPlayer2Upper2FromParams = searchParams.get('startingPositionVariationPlayer2Upper2');
		const startingPositionTimestampFromParams = searchParams.get('startingPositionTimestamp');
		const startingPositionVideoTimestampFromParams = searchParams.get('startingPositionVideoTimestamp');
		const player1FromParams = searchParams.get('player1');
		const player2FromParams = searchParams.get('player2');
		const sequencePlayerFromParams = searchParams.get('sequencePlayer');
		const sequenceTypeFromParams = searchParams.get('sequenceType');
		const overtimeFromParams = searchParams.get('overtime');

		// Check if parameters exist and update the initial state accordingly
		if (
			startingPositionFromParams ||
			startingPositionVariationPlayer1Lower1FromParams ||
			startingPositionVariationPlayer1Lower2FromParams ||
			startingPositionVariationPlayer1Upper1FromParams ||
			startingPositionVariationPlayer1Upper2FromParams ||
			startingPositionVariationPlayer2Lower1FromParams ||
			startingPositionVariationPlayer2Lower2FromParams ||
			startingPositionVariationPlayer2Upper1FromParams ||
			startingPositionVariationPlayer2Upper2FromParams ||
			startingPositionTimestampFromParams ||
			startingPositionVideoTimestampFromParams ||
			player1FromParams ||
			player2FromParams ||
			sequencePlayerFromParams ||
			sequenceTypeFromParams ||
			overtimeFromParams
		) {
			setSequence((prevSequence) => ({
				...prevSequence,
				startingPosition: startingPositionFromParams || '',
				startingPositionVariationPlayer1Lower1: startingPositionVariationPlayer1Lower1FromParams || '',
				startingPositionVariationPlayer1Lower2: startingPositionVariationPlayer1Lower2FromParams || '',
				startingPositionVariationPlayer1Upper1: startingPositionVariationPlayer1Upper1FromParams || '',
				startingPositionVariationPlayer1Upper2: startingPositionVariationPlayer1Upper2FromParams || '',
				startingPositionVariationPlayer2Lower1: startingPositionVariationPlayer2Lower1FromParams || '',
				startingPositionVariationPlayer2Lower2: startingPositionVariationPlayer2Lower2FromParams || '',
				startingPositionVariationPlayer2Upper1: startingPositionVariationPlayer2Upper1FromParams || '',
				startingPositionVariationPlayer2Upper2: startingPositionVariationPlayer2Upper2FromParams || '',
				startingPositionTimestamp: secondsToMMSS(startingPositionTimestampFromParams) || 0,
				startingPositionVideoTimestamp: secondsToMMSS(startingPositionVideoTimestampFromParams) || 0,
				player1: player1FromParams || '',
				player2: player2FromParams || '',
				sequencePlayer: sequencePlayerFromParams || '',
				sequenceType: sequenceTypeFromParams || 'Position Entry',
				overtime: overtimeFromParams === 'true' ? true : false,
			}));
		}

		// Fetch sequences and other initial data
		fetchSequences();
	}, [searchParams, fetchSequences]);

	// Fetch match data and sequences when component mounts
	useEffect(() => {
		fetchSequences();
	}, [needToFetch, fetchSequences]); // The function will be re-run whenever `needToFetch` changes

	// Fetch user data
	useEffect(() => {
		fetch(`${API_URL}/user/${user?.uid}`, {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
		})
			.then((res) => res.json())
			.then((data) => {
				setSequence((prev) => ({ ...prev, createdByUser: data._id }));
			})
			.catch((err) => console.error(err));
	}, [user?.uid]);

	useEffect(() => {
		const matchingOption = submissionOptions.find((option) => option.name === sequence.endingPosition);

		if (matchingOption) {
			const options = matchingOption.options;

			options.forEach((option) => {
				switch (option.label) {
					case 'Submission Grip':
						setCurrentGripOptions(option.values);
						break;
					case 'Submission Mechanics':
						setCurrentMechanicsOptions(option.values);
						break;
					case 'Submission Variation':
						setCurrentVariationOptions(option.values);
						break;
					case 'Submission Result':
						setCurrentResultOptions(option.values);
						break;
					default:
						break;
				}
			});
		}
	}, [sequence.endingPosition, submissionOptions]);

	// Update starting positions when sequence type changes
	useEffect(() => {
		const positions = getStartingPositions(sequence.sequenceType, guards, legEntanglements, guardPassing, pinning, takedown, other, submissions);
		setStartingPositions(positions);
	}, [sequence.sequenceType, guards, legEntanglements, guardPassing, pinning, takedown, other, submissions]);

	useEffect(() => {
		// Update available variations whenever startingPositions changes
		const variations = positionMap[sequence.startingPosition] || [];
		setStartingPositionVariations(variations);
	}, [sequence.startingPosition, positionMap]);

	// Update ending positions when sequence type changes
	useEffect(() => {
		const positions = getEndingPositions(sequence.sequenceType, guards, legEntanglements, guardPassing, pinning, takedown, other, submissions);
		setEndingPositions(positions);
		// eslint-disable-next-line
	}, [sequence.sequenceType, guards, legEntanglements, guardPassing, pinning, takedown, other, submissions]);

	// Update endingPositionVariations based on the selected endingPosition
	useEffect(() => {
		// Update available variations whenever endingPositions changes
		const variations = positionMap[sequence.endingPosition] || [];
		setEndingPositionVariations(variations);
	}, [sequence.endingPosition, positionMap]);

	if (loading) {
		return (
			<>
				<Head title='Add Sequences Page' />
				<Container maxWidth='md' sx={{ display: 'flex', justifyContent: 'center' }}>
					<div className='loader'>
						<Triangle color='rgb(47, 199, 112)' size={100} />
						<Typography variant='h5' sx={{ color: 'rgb(47, 199, 112)', marginTop: '1rem' }}>
							Loading...
						</Typography>
					</div>
				</Container>
			</>
		);
	}

	return (
		<>
			<Head title='Add Sequence Page' />
			<Container sx={{ marginBottom: '50px' }}>
				<Typography
					sx={{
						textAlign: 'center',
						marginTop: '16px',
						marginBottom: '16px',
					}}
					variant='h6'
				>
					Sequences for {matchData?.match}
				</Typography>

				{matchData && (
					<>
						<form onSubmit={handleSubmit}>
							<Grid container spacing={3}>
								<Grid
									item
									xs={12}
									md={12}
									lg={12}
									sx={{
										display: 'flex',
										justifyContent: 'space-between',
									}}
								>
									{/* Player 1 and Player 2 (Pre-populated, demonstration purpose) */}
									<TextField autoComplete='off' sx={{ width: '49%' }} disabled name='player1' label='Player 1' value={sequence.player1} onChange={handleInputChange} />
									<TextField autoComplete='off' sx={{ width: '49%' }} disabled name='player2' label='Player 2' value={sequence.player2} onChange={handleInputChange} />
								</Grid>
								<Grid
									item
									xs={12}
									md={12}
									lg={12}
									sx={{
										display: 'flex',
										justifyContent: 'center',
									}}
								>
									{/* Sequence Player Dropdown */}
									<FormControl
										sx={{
											width: '50%',

											...stylesAlt,
										}}
									>
										<InputLabel
											sx={{
												'&.MuiInputLabel-shrink': {
													transform: 'translate(14px, -16px) scale(0.75)',
												},
											}}
										>
											Assign Sequence To
										</InputLabel>
										<Select name='sequencePlayer' value={sequence.sequencePlayer} onChange={handleInputChange} required>
											<MenuItem value={sequence.player1}>{sequence.player1}</MenuItem>
											<MenuItem value={sequence.player2}>{sequence.player2}</MenuItem>
										</Select>
									</FormControl>
								</Grid>

								<Grid
									item
									xs={12}
									md={12}
									lg={12}
									sx={{
										display: 'flex',
										justifyContent: 'center',
									}}
								>
									{/* Sequence Type Dropdown */}
									<FormControl
										sx={{
											width: '50%',
											...stylesAlt,
										}}
									>
										<InputLabel
											sx={{
												'&.MuiInputLabel-shrink': {
													transform: 'translate(14px, -16px) scale(0.75)',
												},
											}}
										>
											Sequence Type
										</InputLabel>
										<Select name='sequenceType' required value={sequence.sequenceType} onChange={handleInputChange}>
											{sequenceTypes.sort().map((type) => (
												<MenuItem key={type} value={type}>
													{type}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>

								{/* Starting/Ending Position Dropdown */}
								<Grid
									item
									xs={12}
									md={12}
									lg={12}
									sx={{
										display: 'flex',
										justifyContent: 'space-around',
									}}
								>
									{/* Starting Position Dropdown */}
									<FormControl
										sx={{
											...styles,
										}}
									>
										<InputLabel
											sx={{
												'&.MuiInputLabel-shrink': {
													transform: 'translate(14px, -16px) scale(0.75)',
												},
											}}
										>
											Starting Position
										</InputLabel>
										<Select name='startingPosition' required value={sequence.startingPosition} onChange={handleInputChange}>
											{startingPositions.sort().map((position) => (
												<MenuItem key={position} value={position}>
													{position}
												</MenuItem>
											))}
										</Select>
									</FormControl>

									<FormControl
										sx={{
											width: '19%',
										}}
									>
										{/* ARROW POINTING RIGHT */}
										<svg height='50' width='100%' viewBox='0 0 300 50'>
											{/* Line */}
											<line
												x1='0'
												y1='25'
												x2='250'
												y2='25'
												style={{
													stroke: darkMode ? 'rgb(47,199,112)' : 'black',
													strokeWidth: 10,
												}}
											/>
											{/* Arrowhead */}
											<polygon
												points='300,25 250,5 250,45'
												style={{
													fill: darkMode ? 'rgb(47,199,112)' : 'black',
												}}
											/>
										</svg>
									</FormControl>

									{/* Ending Position Dropdown */}
									<FormControl
										sx={{
											...styles,
										}}
									>
										<InputLabel
											sx={{
												'&.MuiInputLabel-shrink': {
													transform: 'translate(14px, -16px) scale(0.75)',
												},
											}}
										>
											Ending Position
										</InputLabel>
										<Select name='endingPosition' required value={sequence.endingPosition} onChange={handleInputChange}>
											{endingPositions.sort().map((variation) => (
												<MenuItem key={variation} value={variation}>
													{variation}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>

								{/* START TIME POSTIION START */}
								<Grid
									item
									xs={12}
									md={12}
									lg={12}
									sx={{
										display: 'flex',
										justifyContent: 'space-around',
									}}
								>
									<TextField
										autoComplete='off'
										sx={{
											...styles,
											marginBottom: '-8px',
										}}
										required
										id='startingPositionTimestamp'
										name='startingPositionTimestamp'
										label='Sequence Begins (mm:ss)'
										value={sequence.startingPositionTimestamp}
										onChange={handleInputChange}
										error={timeError}
										helperText={timeError ? 'Please enter a valid time (mm:ss)' : 'Time left in match'}
									/>
									<TextField
										autoComplete='off'
										sx={{
											...styles,
											marginBottom: '-8px',
										}}
										id='startingPositionVideoTimestamp'
										name='startingPositionVideoTimestamp'
										label='Video Start (mm:ss) or (hh:mm:ss)'
										value={sequence.startingPositionVideoTimestamp}
										onChange={handleInputChange}
										error={timeError}
										helperText={timeError ? 'Please enter a valid time (mm:ss) or (hh:mm:ss)' : 'Time from start of video'}
									/>

									{/* END TIMESTATMP POSITION START */}
									<TextField
										autoComplete='off'
										sx={{
											...styles,
										}}
										required
										id='endingPositionTimestamp'
										name='endingPositionTimestamp'
										label='Sequence Ends (mm:ss)'
										value={sequence.endingPositionTimestamp}
										onChange={handleInputChange}
										error={timeError}
										helperText={timeError ? 'Please enter a valid time (mm:ss)' : 'Time left in match'}
									/>

									<TextField
										autoComplete='off'
										sx={{
											...styles,
										}}
										id='endingPositionVideoTimestamp'
										name='endingPositionVideoTimestamp'
										label='Video End (mm:ss) or (hh:mm:ss)'
										value={sequence.endingPositionVideoTimestamp}
										onChange={handleInputChange}
										error={timeError}
										helperText={timeError ? 'Please enter a valid time (mm:ss) or (hh:mm:ss)' : 'Time from start of video'}
									/>
								</Grid>

								{/* Player 1 movement2 and starting variations */}
								<Grid
									item
									xs={12}
									md={12}
									lg={12}
									sx={{
										display: 'flex',
										justifyContent: 'space-between',
									}}
								>
									{/* Starting Position Variation Player1 Upper1*/}
									<FormControl
										sx={{
											...styles,
										}}
									>
										<InputLabel
											sx={{
												'&.MuiInputLabel-shrink': {
													transform: 'translate(14px, -16px) scale(0.75)',
												},
											}}
										>
											{sequence.player1} Upper #1 Start
										</InputLabel>
										<Select name='startingPositionVariationPlayer1Upper1' value={sequence.startingPositionVariationPlayer1Upper1} onChange={handleInputChange}>
											<MenuItem value=''>
												<em>None</em>
											</MenuItem>
											{startingPositionVariations.upper?.sort().map((variation) => (
												<MenuItem key={variation} value={variation}>
													{variation}
												</MenuItem>
											))}
										</Select>
									</FormControl>
									{/* Starting Position Variation Player1 Upper1*/}
									<FormControl
										sx={{
											...styles,
										}}
									>
										<InputLabel
											sx={{
												'&.MuiInputLabel-shrink': {
													transform: 'translate(14px, -16px) scale(0.75)',
												},
											}}
										>
											{sequence.player1} Upper #2 Start
										</InputLabel>
										<Select name='startingPositionVariationPlayer1Upper2' value={sequence.startingPositionVariationPlayer1Upper2} onChange={handleInputChange}>
											<MenuItem value=''>
												<em>None</em>
											</MenuItem>
											{startingPositionVariations.upper?.sort().map((variation) => (
												<MenuItem key={variation} value={variation}>
													{variation}
												</MenuItem>
											))}
										</Select>
									</FormControl>

									{/* Ending Position Variation Player1 Upper1*/}
									{sequence.sequenceType === 'Submission' && sequence.player1 === sequence.sequencePlayer ? (
										<FormControl
											sx={{
												...styles,
											}}
										>
											<InputLabel
												sx={{
													'&.MuiInputLabel-shrink': {
														transform: 'translate(14px, -16px) scale(0.75)',
													},
												}}
											>
												{sequence.player1} {sequence.endingPosition} Variation
											</InputLabel>
											<Select name='player1SubVariation' value={sequence.player1SubVariation} onChange={handleInputChange}>
												<MenuItem value=''>
													<em>None</em>
												</MenuItem>
												{currentVariationOptions?.sort().map((variation) => (
													<MenuItem key={variation} value={variation}>
														{variation}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									) : (
										<>
											{sequence.sequenceType === 'Submission' && sequence.player2 === sequence.sequencePlayer ? (
												<FormControl
													sx={{
														...styles,
													}}
												></FormControl>
											) : (
												<FormControl
													sx={{
														...styles,
													}}
												>
													<InputLabel
														sx={{
															'&.MuiInputLabel-shrink': {
																transform: 'translate(14px, -16px) scale(0.75)',
															},
														}}
													>
														{sequence.player1} Upper #1 End
													</InputLabel>
													<Select name='endingPositionVariationPlayer1Upper1' value={sequence.endingPositionVariationPlayer1Upper1} onChange={handleInputChange}>
														<MenuItem value=''>
															<em>None</em>
														</MenuItem>
														{endingPositionVariations.upper?.sort().map((variation) => (
															<MenuItem key={variation} value={variation}>
																{variation}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											)}
										</>
									)}

									{/* Ending Position Variation Player1 Upper2 */}
									{sequence.sequenceType === 'Submission' && sequence.player1 === sequence.sequencePlayer ? (
										<FormControl
											sx={{
												...styles,
											}}
										>
											<InputLabel
												sx={{
													'&.MuiInputLabel-shrink': {
														transform: 'translate(14px, -16px) scale(0.75)',
													},
												}}
											>
												{sequence.player1} {sequence.endingPosition} Grip
											</InputLabel>
											<Select name='player1SubGrip' value={sequence.player1SubGrip} onChange={handleInputChange}>
												<MenuItem value=''>
													<em>None</em>
												</MenuItem>
												{currentGripOptions?.sort().map((variation) => (
													<MenuItem key={variation} value={variation}>
														{variation}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									) : (
										<>
											{sequence.sequenceType === 'Submission' && sequence.player2 === sequence.sequencePlayer ? (
												<FormControl
													sx={{
														...styles,
													}}
												></FormControl>
											) : (
												<FormControl
													sx={{
														...styles,
													}}
												>
													<InputLabel
														sx={{
															'&.MuiInputLabel-shrink': {
																transform: 'translate(14px, -16px) scale(0.75)',
															},
														}}
													>
														{sequence.player1} Upper #2 End
													</InputLabel>
													<Select name='endingPositionVariationPlayer1Upper2' value={sequence.endingPositionVariationPlayer1Upper2} onChange={handleInputChange}>
														<MenuItem value=''>
															<em>None</em>
														</MenuItem>
														{endingPositionVariations.upper?.sort().map((variation) => (
															<MenuItem key={variation} value={variation}>
																{variation}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											)}
										</>
									)}
								</Grid>

								{/* Player 1 movement2 and starting variations */}
								<Grid
									item
									xs={12}
									md={12}
									lg={12}
									sx={{
										display: 'flex',
										justifyContent: 'space-between',
									}}
								>
									{/* Starting Position Variation Player1 Lower1*/}
									<FormControl
										sx={{
											...styles,
										}}
									>
										<InputLabel
											sx={{
												'&.MuiInputLabel-shrink': {
													transform: 'translate(14px, -16px) scale(0.75)',
												},
											}}
										>
											{sequence.player1} Lower #1 Start
										</InputLabel>
										<Select name='startingPositionVariationPlayer1Lower1' value={sequence.startingPositionVariationPlayer1Lower1} onChange={handleInputChange}>
											<MenuItem value=''>
												<em>None</em>
											</MenuItem>
											{startingPositionVariations.lower?.sort().map((variation) => (
												<MenuItem key={variation} value={variation}>
													{variation}
												</MenuItem>
											))}
										</Select>
									</FormControl>
									{/* Starting Position Variation Player1 lower2*/}
									<FormControl
										sx={{
											...styles,
										}}
									>
										<InputLabel
											sx={{
												'&.MuiInputLabel-shrink': {
													transform: 'translate(14px, -16px) scale(0.75)',
												},
											}}
										>
											{sequence.player1} Lower #2 Start
										</InputLabel>
										<Select name='startingPositionVariationPlayer1Lower2' value={sequence.startingPositionVariationPlayer1Lower2} onChange={handleInputChange}>
											<MenuItem value=''>
												<em>None</em>
											</MenuItem>
											{startingPositionVariations.lower?.sort().map((variation) => (
												<MenuItem key={variation} value={variation}>
													{variation}
												</MenuItem>
											))}
										</Select>
									</FormControl>

									{/* Ending Position Variation Player1 Lower1*/}
									{sequence.sequenceType === 'Submission' && sequence.player1 === sequence.sequencePlayer ? (
										<FormControl
											sx={{
												...styles,
											}}
										>
											<InputLabel
												sx={{
													'&.MuiInputLabel-shrink': {
														transform: 'translate(14px, -16px) scale(0.75)',
													},
												}}
											>
												{sequence.player1} {sequence.endingPosition} Mechanics
											</InputLabel>
											<Select name='player1SubMechanics' value={sequence.player1SubMechanics} onChange={handleInputChange}>
												<MenuItem value=''>
													<em>None</em>
												</MenuItem>
												{currentMechanicsOptions?.sort().map((variation) => (
													<MenuItem key={variation} value={variation}>
														{variation}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									) : (
										<>
											{sequence.sequenceType === 'Submission' && sequence.player2 === sequence.sequencePlayer ? (
												<FormControl
													sx={{
														...styles,
													}}
												></FormControl>
											) : (
												<FormControl
													sx={{
														...styles,
													}}
												>
													<InputLabel
														sx={{
															'&.MuiInputLabel-shrink': {
																transform: 'translate(14px, -16px) scale(0.75)',
															},
														}}
													>
														{sequence.player1} Lower #1 End
													</InputLabel>
													<Select name='endingPositionVariationPlayer1Lower1' value={sequence.endingPositionVariationPlayer1Lower1} onChange={handleInputChange}>
														<MenuItem value=''>
															<em>None</em>
														</MenuItem>
														{endingPositionVariations.lower?.sort().map((variation) => (
															<MenuItem key={variation} value={variation}>
																{variation}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											)}
										</>
									)}

									{/* Ending Position Variation Player1 Lower 2 */}
									{sequence.sequenceType === 'Submission' && sequence.player1 === sequence.sequencePlayer ? (
										<FormControl
											sx={{
												...styles,
											}}
										>
											<InputLabel
												sx={{
													'&.MuiInputLabel-shrink': {
														transform: 'translate(14px, -16px) scale(0.75)',
													},
												}}
											>
												{sequence.player1} {sequence.endingPosition} Result
											</InputLabel>
											<Select name='player1SubResult' value={sequence.player1SubResult} onChange={handleInputChange}>
												<MenuItem value=''>
													<em>None</em>
												</MenuItem>
												{currentResultOptions?.sort().map((variation) => (
													<MenuItem key={variation} value={variation}>
														{variation}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									) : (
										<>
											{sequence.sequenceType === 'Submission' && sequence.player2 === sequence.sequencePlayer ? (
												<FormControl
													sx={{
														...styles,
													}}
												>
													<InputLabel
														sx={{
															'&.MuiInputLabel-shrink': {
																transform: 'translate(14px, -16px) scale(0.75)',
															},
														}}
													>
														{sequence.player2} {sequence.endingPosition} Variation
													</InputLabel>
													<Select name='player2SubVariation' value={sequence.player2SubVariation} onChange={handleInputChange}>
														<MenuItem value=''>
															<em>None</em>
														</MenuItem>
														{currentVariationOptions?.sort().map((variation) => (
															<MenuItem key={variation} value={variation}>
																{variation}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											) : (
												<FormControl
													sx={{
														...styles,
													}}
												>
													<InputLabel
														sx={{
															'&.MuiInputLabel-shrink': {
																transform: 'translate(14px, -16px) scale(0.75)',
															},
														}}
													>
														{sequence.player1} Lower #2 End
													</InputLabel>
													<Select name='endingPositionVariationPlayer1Lower2' value={sequence.endingPositionVariationPlayer1Lower2} onChange={handleInputChange}>
														<MenuItem value=''>
															<em>None</em>
														</MenuItem>
														{endingPositionVariations.lower?.sort().map((variation) => (
															<MenuItem key={variation} value={variation}>
																{variation}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											)}
										</>
									)}
								</Grid>

								<Grid
									item
									xs={12}
									md={12}
									lg={12}
									sx={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'space-between',
									}}
								>
									{/* Ending Position Variation */}
									<FormControl
										sx={{
											...styles,
										}}
									>
										<InputLabel
											sx={{
												'&.MuiInputLabel-shrink': {
													transform: 'translate(14px, -16px) scale(0.75)',
												},
											}}
										>
											{sequence.player2} Upper #1 Start
										</InputLabel>
										<Select name='startingPositionVariationPlayer2Upper1' value={sequence.startingPositionVariationPlayer2Upper1} onChange={handleInputChange}>
											<MenuItem value=''>
												<em>None</em>
											</MenuItem>
											{startingPositionVariations.upper?.sort().map((variation) => (
												<MenuItem key={variation} value={variation}>
													{variation}
												</MenuItem>
											))}
										</Select>
									</FormControl>

									<FormControl
										sx={{
											...styles,
										}}
									>
										<InputLabel
											sx={{
												'&.MuiInputLabel-shrink': {
													transform: 'translate(14px, -16px) scale(0.75)',
												},
											}}
										>
											{sequence.player2} Upper #2 Start
										</InputLabel>
										<Select name='startingPositionVariationPlayer2Upper2' value={sequence.startingPositionVariationPlayer2Upper2} onChange={handleInputChange}>
											<MenuItem value=''>
												<em>None</em>
											</MenuItem>
											{startingPositionVariations.upper?.sort().map((variation) => (
												<MenuItem key={variation} value={variation}>
													{variation}
												</MenuItem>
											))}
										</Select>
									</FormControl>

									{/* Ending Position Variation */}
									{sequence.sequenceType === 'Submission' && sequence.player2 === sequence.sequencePlayer ? (
										<FormControl
											sx={{
												...styles,
											}}
										>
											<InputLabel
												sx={{
													'&.MuiInputLabel-shrink': {
														transform: 'translate(14px, -16px) scale(0.75)',
													},
												}}
											>
												{sequence.player2} {sequence.endingPosition} Grip
											</InputLabel>
											<Select name='player2SubGrip' value={sequence.player2SubGrip} onChange={handleInputChange}>
												<MenuItem value=''>
													<em>None</em>
												</MenuItem>
												{currentGripOptions?.sort().map((variation) => (
													<MenuItem key={variation} value={variation}>
														{variation}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									) : (
										<>
											{sequence.sequenceType === 'Submission' && sequence.player1 === sequence.sequencePlayer ? (
												<>
													<FormControl
														sx={{
															...styles,
														}}
													></FormControl>
													<FormControl
														sx={{
															...styles,
														}}
													>
														<InputLabel
															sx={{
																'&.MuiInputLabel-shrink': {
																	transform: 'translate(14px, -16px) scale(0.75)',
																},
															}}
														>
															{sequence.player1} {sequence.endingPosition} Side
														</InputLabel>
														<Select name='player1SubSide' value={sequence.player1SubSide} onChange={handleInputChange}>
															<MenuItem value=''>
																<em>None</em>
															</MenuItem>
															<MenuItem value='Left'>Left</MenuItem>
															<MenuItem value='Right'>Right</MenuItem>
														</Select>
													</FormControl>
												</>
											) : (
												<FormControl
													sx={{
														...styles,
													}}
												>
													<InputLabel
														sx={{
															'&.MuiInputLabel-shrink': {
																transform: 'translate(14px, -16px) scale(0.75)',
															},
														}}
													>
														{sequence.player2} Upper #1 End
													</InputLabel>
													<Select name='endingPositionVariationPlayer2Upper1' value={sequence.endingPositionVariationPlayer2Upper1} onChange={handleInputChange}>
														<MenuItem value=''>
															<em>None</em>
														</MenuItem>
														{endingPositionVariations.upper?.sort().map((variation) => (
															<MenuItem key={variation} value={variation}>
																{variation}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											)}
										</>
									)}

									{/* Ending Position Variation */}
									{sequence.sequenceType === 'Submission' && sequence.player2 === sequence.sequencePlayer ? (
										<FormControl
											sx={{
												...styles,
											}}
										>
											<InputLabel
												sx={{
													'&.MuiInputLabel-shrink': {
														transform: 'translate(14px, -16px) scale(0.75)',
													},
												}}
											>
												{sequence.player2} {sequence.endingPosition} Mechanics
											</InputLabel>
											<Select name='player2SubMechanics' value={sequence.player2SubMechanics} onChange={handleInputChange}>
												<MenuItem value=''>
													<em>None</em>
												</MenuItem>
												{currentMechanicsOptions?.sort().map((variation) => (
													<MenuItem key={variation} value={variation}>
														{variation}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									) : (
										<>
											{sequence.sequenceType === 'Submission' && sequence.player1 === sequence.sequencePlayer ? null : (
												<FormControl
													sx={{
														...styles,
													}}
												>
													<InputLabel
														sx={{
															'&.MuiInputLabel-shrink': {
																transform: 'translate(14px, -16px) scale(0.75)',
															},
														}}
													>
														{sequence.player2} Upper #2 End
													</InputLabel>
													<Select name='endingPositionVariationPlayer2Upper2' value={sequence.endingPositionVariationPlayer2Upper2} onChange={handleInputChange}>
														<MenuItem value=''>
															<em>None</em>
														</MenuItem>
														{endingPositionVariations.upper?.sort().map((variation) => (
															<MenuItem key={variation} value={variation}>
																{variation}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											)}
										</>
									)}
								</Grid>

								<Grid
									item
									xs={12}
									md={12}
									lg={12}
									sx={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'space-between',
									}}
								>
									<FormControl
										sx={{
											...styles,
										}}
									>
										<InputLabel
											sx={{
												'&.MuiInputLabel-shrink': {
													transform: 'translate(14px, -16px) scale(0.75)',
												},
											}}
										>
											{sequence.player2} Lower #1 Start
										</InputLabel>
										<Select name='startingPositionVariationPlayer2Lower1' value={sequence.startingPositionVariationPlayer2Lower1} onChange={handleInputChange}>
											<MenuItem value=''>
												<em>None</em>
											</MenuItem>
											{startingPositionVariations.lower?.sort().map((variation) => (
												<MenuItem key={variation} value={variation}>
													{variation}
												</MenuItem>
											))}
										</Select>
									</FormControl>
									<FormControl
										sx={{
											...styles,
										}}
									>
										<InputLabel
											sx={{
												'&.MuiInputLabel-shrink': {
													transform: 'translate(14px, -16px) scale(0.75)',
												},
											}}
										>
											{sequence.player2} Lower #2 Start
										</InputLabel>
										<Select name='startingPositionVariationPlayer2Lower2' value={sequence.startingPositionVariationPlayer2Lower2} onChange={handleInputChange}>
											<MenuItem value=''>
												<em>None</em>
											</MenuItem>
											{startingPositionVariations.lower?.sort().map((variation) => (
												<MenuItem key={variation} value={variation}>
													{variation}
												</MenuItem>
											))}
										</Select>
									</FormControl>
									{sequence.sequenceType === 'Submission' && sequence.player1 === sequence.sequencePlayer ? (
										<FormControl
											sx={{
												...styles,
											}}
										></FormControl>
									) : null}

									{sequence.sequenceType === 'Submission' && sequence.player2 === sequence.sequencePlayer ? (
										<>
											<FormControl
												sx={{
													...styles,
												}}
											>
												<InputLabel
													sx={{
														'&.MuiInputLabel-shrink': {
															transform: 'translate(14px, -16px) scale(0.75)',
														},
													}}
												>
													{sequence.player2} {sequence.endingPosition} Result
												</InputLabel>
												<Select name='player2SubResult' value={sequence.player2SubResult} onChange={handleInputChange}>
													<MenuItem value=''>
														<em>None</em>
													</MenuItem>
													{currentResultOptions?.sort().map((variation) => (
														<MenuItem key={variation} value={variation}>
															{variation}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</>
									) : (
										<>
											{sequence.sequenceType === 'Submission' && sequence.player1 === sequence.sequencePlayer ? (
												<FormControl
													sx={{
														...styles,
													}}
												></FormControl>
											) : (
												<FormControl
													sx={{
														...styles,
													}}
												>
													<InputLabel
														sx={{
															'&.MuiInputLabel-shrink': {
																transform: 'translate(14px, -16px) scale(0.75)',
															},
														}}
													>
														{sequence.player2} Lower #1 End
													</InputLabel>
													<Select name='endingPositionVariationPlayer2Lower1' value={sequence.endingPositionVariationPlayer2Lower1} onChange={handleInputChange}>
														<MenuItem value=''>
															<em>None</em>
														</MenuItem>
														{endingPositionVariations.lower?.sort().map((variation) => (
															<MenuItem key={variation} value={variation}>
																{variation}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											)}
										</>
									)}

									{sequence.sequenceType === 'Submission' && sequence.player2 === sequence.sequencePlayer ? (
										<FormControl
											sx={{
												...styles,
											}}
										>
											<InputLabel
												sx={{
													'&.MuiInputLabel-shrink': {
														transform: 'translate(14px, -16px) scale(0.75)',
													},
												}}
											>
												{sequence.player2} {sequence.endingPosition} Side
											</InputLabel>
											<Select name='player2SubSide' value={sequence.player2SubSide} onChange={handleInputChange}>
												<MenuItem value=''>
													<em>None</em>
												</MenuItem>
												<MenuItem value='Left'>Left</MenuItem>
												<MenuItem value='Right'>Right</MenuItem>
											</Select>
										</FormControl>
									) : (
										<>
											{sequence.sequenceType === 'Submission' && sequence.player1 === sequence.sequencePlayer ? null : (
												<FormControl
													sx={{
														...styles,
													}}
												>
													<InputLabel
														sx={{
															'&.MuiInputLabel-shrink': {
																transform: 'translate(14px, -16px) scale(0.75)',
															},
														}}
													>
														{sequence.player2} Lower #2 End
													</InputLabel>
													<Select name='endingPositionVariationPlayer2Lower2' value={sequence.endingPositionVariationPlayer2Lower2} onChange={handleInputChange}>
														<MenuItem value=''>
															<em>None</em>
														</MenuItem>
														{endingPositionVariations.lower?.sort().map((variation) => (
															<MenuItem key={variation} value={variation}>
																{variation}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											)}
										</>
									)}
								</Grid>

								<Grid
									item
									xs={12}
									md={12}
									lg={12}
									sx={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'space-between',
									}}
								>
									<FormControl
										sx={{
											...stylesAlt,
										}}
									>
										<InputLabel
											sx={{
												'&.MuiInputLabel-shrink': {
													transform: 'translate(14px, -16px) scale(0.75)',
												},
											}}
										>
											{sequence.player1} Movement 1
										</InputLabel>
										<Select name='player1Movement1' value={sequence.player1Movement1} onChange={handleInputChange}>
											<MenuItem value=''>
												<em>None</em>
											</MenuItem>
											{movements.map((move) => (
												<MenuItem key={move._id} value={move.name}>
													{move.name}
												</MenuItem>
											))}
										</Select>
									</FormControl>
									<FormControl
										sx={{
											...stylesAlt,
										}}
									>
										<InputLabel
											sx={{
												'&.MuiInputLabel-shrink': {
													transform: 'translate(14px, -16px) scale(0.75)',
												},
											}}
										>
											{sequence.player1} Movement 2
										</InputLabel>
										<Select name='player1Movement2' value={sequence.player1Movement2} onChange={handleInputChange}>
											<MenuItem value=''>
												<em>None</em>
											</MenuItem>
											{movements.map((move) => (
												<MenuItem key={move._id} value={move.name}>
													{move.name}
												</MenuItem>
											))}
										</Select>
									</FormControl>
									<FormControl
										sx={{
											...stylesAlt,
										}}
									>
										<InputLabel
											sx={{
												'&.MuiInputLabel-shrink': {
													transform: 'translate(14px, -16px) scale(0.75)',
												},
											}}
										>
											{sequence.player2} Movement 1
										</InputLabel>
										<Select name='player2Movement1' value={sequence.player2Movement1} onChange={handleInputChange}>
											<MenuItem value=''>
												<em>None</em>
											</MenuItem>
											{movements.map((move) => (
												<MenuItem key={move._id} value={move.name}>
													{move.name}
												</MenuItem>
											))}
										</Select>
									</FormControl>
									<FormControl
										sx={{
											...stylesAlt,
										}}
									>
										<InputLabel
											sx={{
												'&.MuiInputLabel-shrink': {
													transform: 'translate(14px, -16px) scale(0.75)',
												},
											}}
										>
											{sequence.player2} Movement 2
										</InputLabel>
										<Select name='player2Movement2' value={sequence.player2Movement2} onChange={handleInputChange}>
											<MenuItem value=''>
												<em>None</em>
											</MenuItem>
											{movements.map((move) => (
												<MenuItem key={move._id} value={move.name}>
													{move.name}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12} md={12} lg={12} sx={{ display: 'flex', justifyContent: 'center' }}>
									<FormControlLabel
										control={
											<Switch
												sx={{
													'& .MuiSwitch-switchBase.Mui-checked': {
														color: 'grey', // Thumb color when checked
														'&:hover': {
															backgroundColor: 'rgba(128, 128, 128, 0.1)', // Ripple color when hovered
														},
													},
													'& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
														backgroundColor: 'grey', // Track color when checked
													},
													'& .MuiSwitch-switchBase': {
														color: 'lightgrey', // Thumb color when not checked
													},
													'& .MuiSwitch-track': {
														backgroundColor: 'lightgrey', // Track color when not checked
													},
												}}
												checked={sequence.overtime}
												onChange={(e) => setSequence({ ...sequence, overtime: e.target.checked })}
												name='overtime'
												color='primary'
											/>
										}
										label={sequence.overtime ? 'Occurred in Overtime' : 'Occurred in Regulation'}
									/>
								</Grid>

								{/* Points Section */}
								<div
									style={{
										margin: '1rem',
										width: '100%',
										textAlign: 'center',
									}}
								>
									{points.map((point, index) => (
										<div
											key={index}
											style={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
											}}
										>
											<Select
												sx={{
													'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
														borderColor: 'rgb(47, 199, 112)',
													},
												}}
												value={point.pointType}
												onChange={(e) => handlePointChange(e, index, 'pointType')}
												style={{ marginRight: '8px' }}
												displayEmpty
												renderValue={(selected) => {
													if (selected === '') {
														return <em>Point Type</em>;
													}
													return selected;
												}}
											>
												<MenuItem value='' disabled>
													Point Type
												</MenuItem>
												{Object.keys(pointOptions).map((option, i) => (
													<MenuItem key={i} value={option}>
														{option}
													</MenuItem>
												))}
											</Select>

											<TextField
												autoComplete='off'
												sx={{
													width: '19%',
													// Change outline color when hovered but not focused
													'&:hover .MuiOutlinedInput-notchedOutline:not(.Mui-focused)': {
														borderColor: darkMode ? 'white!important' : 'grey',
													},
													// Change label color when hovered but not focused
													'&:hover .MuiInputLabel-root:not(.Mui-focused)': {
														color: darkMode ? 'white' : 'rgba(0,0,0,0.5)',
													},
													// For text color
													'&& input': {
														color: darkMode ? '#fff' : 'rgba(0,0,0,0.5)',
													},
													// Default outline color
													'& .MuiOutlinedInput-notchedOutline': {
														borderColor: darkMode ? 'grey!important' : 'grey',
													},
													// Focused outline color
													'& .MuiOutlinedInput-notchedOutline.Mui-focused': {
														borderColor: darkMode ? 'rgb(47,199,112)' : 'rgba(0,0,0,0.5)',
													},
													// Background color
													'& .MuiOutlinedInput-root': {
														backgroundColor: darkMode ? 'rgba(0,0,0,0.5)' : '#f7f7f7',
													},
													'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
														borderColor: 'rgb(47, 199, 112)!important',
													},
													// For label text color
													'& .MuiInputLabel-root': {
														color: darkMode ? 'grey' : 'grey',
													},
													// Focused label color
													'& .MuiInputLabel-outlined.Mui-focused': {
														color: 'rgb(47, 180, 112)',
													},
												}}
												name='pointValue'
												type='number'
												placeholder='Point Value'
												value={point.pointValue}
												readOnly
											/>
											<Button
												sx={{
													marginLeft: '1rem',
													color: 'white',
													backgroundColor: '#d20404',
													'&:hover': {
														backgroundColor: '#db1c1c',
													},
												}}
												variant='contained'
												type='button'
												onClick={() => removePointField(index)}
											>
												Remove
											</Button>
										</div>
									))}
									<Button
										sx={{
											color: 'white',
											marginBottom: '16px',
											mt: 2,
											backgroundColor: 'rgb(47, 160, 112)',
											'&:hover': {
												backgroundColor: 'rgb(47, 180, 112)',
												color: 'white',
											},
										}}
										variant='contained'
										type='button'
										onClick={addPointField}
									>
										Add Points
									</Button>
								</div>

								{/* Submit Button */}
								{submittedSequences.length < 1 && (
									<Grid item xs={12}>
										<Button
											sx={{
												backgroundColor: 'rgb(47, 160, 112)',
												color: 'white',
												marginBottom: '16px',
												'&:hover': {
													backgroundColor: 'rgb(47, 180, 112)',
													color: 'white',
												},
											}}
											type='submit'
											variant='contained'
											disabled={loading}
										>
											Create Sequence
										</Button>
									</Grid>
								)}
							</Grid>
						</form>
						{submittedSequences.length >= 1 ? (
							<Typography sx={{ fontFamily: 'monospace', fontSize: isMobile ? '1.1rem' : '1.1rem' }} variant='body1'>
								Sequences:
							</Typography>
						) : null}
						{submittedSequences.length >= 1 && (
							<div style={{ overflowX: 'auto', marginTop: '1rem' }}>
								<table style={{ marginBottom: '16px', width: '100%' }}>
									<thead>
										<tr>
											<th
												style={{
													backgroundColor: darkMode ? '#12392C' : 'white',
													padding: isMobile ? '0.5rem' : '1rem',
													color: darkMode ? 'white' : 'black',
													border: darkMode ? '1px solid white' : '1px solid black',
												}}
											>
												#
											</th>
											<th
												style={{
													backgroundColor: darkMode ? '#12392C' : 'white',
													padding: isMobile ? '0.5rem' : '1rem',
													color: darkMode ? 'white' : 'black',
													border: darkMode ? '1px solid white' : '1px solid black',
												}}
											>
												Type
											</th>
											<th
												style={{
													backgroundColor: darkMode ? '#12392C' : 'white',
													padding: isMobile ? '0.5rem' : '1rem',
													color: darkMode ? 'white' : 'black',
													border: darkMode ? '1px solid white' : '1px solid black',
												}}
											>
												Player
											</th>
											<th
												style={{
													backgroundColor: darkMode ? '#12392C' : 'white',
													padding: isMobile ? '0.5rem' : '1rem',
													color: darkMode ? 'white' : 'black',
													border: darkMode ? '1px solid white' : '1px solid black',
												}}
											>
												Starting Position
											</th>

											<th
												style={{
													backgroundColor: darkMode ? '#12392C' : 'white',
													padding: isMobile ? '0.5rem' : '1rem',
													color: darkMode ? 'white' : 'black',
													border: darkMode ? '1px solid white' : '1px solid black',
												}}
											>
												Ending Position
											</th>

											<th
												style={{
													backgroundColor: darkMode ? '#12392C' : 'white',
													padding: isMobile ? '0.5rem' : '1rem',
													color: darkMode ? 'white' : 'black',
													border: darkMode ? '1px solid white' : '1px solid black',
												}}
											>
												Time left on clock
											</th>

											<th
												style={{
													backgroundColor: darkMode ? '#12392C' : 'white',
													padding: isMobile ? '0.5rem' : '1rem',
													color: darkMode ? 'white' : 'black',
													border: darkMode ? '1px solid white' : '1px solid black',
												}}
											>
												Actions
											</th>
										</tr>
									</thead>
									<tbody>
										{submittedSequences.length >= 1 &&
											submittedSequences.map((seq, index) => {
												const prevSeq = index > 0 ? submittedSequences[index - 1]._id : null;
												const nextSeq = index < submittedSequences.length - 1 ? submittedSequences[index + 1]._id : null;
												return (
													<tr key={index}>
														<td
															style={{
																backgroundColor: darkMode ? '#12392C' : 'white',
																padding: isMobile ? '0.5rem' : '1rem',
																color: darkMode ? 'white' : 'black',
																border: darkMode ? '1px solid white' : '1px solid black',
																fontFamily: 'monospace',
															}}
														>
															{index + 1}
														</td>
														<td
															style={{
																backgroundColor: darkMode ? '#12392C' : 'white',
																padding: isMobile ? '0.5rem' : '1rem',
																color: darkMode ? 'white' : 'black',
																border: darkMode ? '1px solid white' : '1px solid black',
																fontFamily: 'monospace',
															}}
														>
															{seq.sequenceType}
														</td>
														<td
															style={{
																backgroundColor: darkMode ? '#12392C' : 'white',
																padding: isMobile ? '0.5rem' : '1rem',
																color: darkMode ? 'white' : 'black',
																border: darkMode ? '1px solid white' : '1px solid black',
																fontFamily: 'monospace',
															}}
														>
															{seq.sequencePlayer}
														</td>
														<td
															style={{
																backgroundColor: darkMode ? '#12392C' : 'white',
																padding: isMobile ? '0.5rem' : '1rem',
																color: darkMode ? 'white' : 'black',
																border: darkMode ? '1px solid white' : '1px solid black',
																fontFamily: 'monospace',
															}}
														>
															{seq.startingPosition}
														</td>

														<td
															style={{
																backgroundColor: darkMode ? '#12392C' : 'white',
																padding: isMobile ? '0.5rem' : '1rem',
																color: darkMode ? 'white' : 'black',
																border: darkMode ? '1px solid white' : '1px solid black',
																fontFamily: 'monospace',
															}}
														>
															{seq.endingPosition}
														</td>

														<td
															style={{
																backgroundColor: darkMode ? '#12392C' : 'white',
																padding: isMobile ? '0.5rem' : '1rem',
																color: darkMode ? 'white' : 'black',
																border: darkMode ? '1px solid white' : '1px solid black',
																fontFamily: 'monospace',
															}}
														>
															{seq.overtime && <span style={{ color: '#d20404', marginRight: isMobile ? '0' : '2px' }}>OT</span>}
															{secondsToMMSS(seq.startingPositionTimestamp)} - {secondsToMMSS(seq.endingPositionTimestamp)}
														</td>
														<td
															style={{
																backgroundColor: darkMode ? '#12392C' : 'white',
																padding: isMobile ? '0.5rem' : '1rem',
																color: darkMode ? 'white' : 'black',
																border: darkMode ? '1px solid white' : '1px solid black',
																display: 'flex',
																alignItems: 'flex-start',
																justifyContent: 'space-between',
																flexWrap: 'wrap',
															}}
														>
															<Button
																sx={{
																	margin: '.5rem',
																	color: 'white',
																	borderRadius: '5rem',
																	padding: '5px',
																	fontSize: isMobile ? '0.8rem' : 'inherit',
																	width: '100%',
																	backgroundColor: 'rgb(47, 160, 112)',
																	'&:hover': {
																		backgroundColor: 'rgb(47, 180, 112)',
																		color: 'white',
																	},
																}}
																onClick={() => insertSequenceHere(prevSeq, seq._id)}
															>
																Insert <ArrowUpwardIcon />
															</Button>

															<Button
																sx={{
																	margin: '.5rem',
																	color: 'white',
																	borderRadius: '5rem',
																	padding: '5px',
																	fontSize: isMobile ? '0.8rem' : 'inherit',
																	width: '100%',
																	backgroundColor: 'rgb(47, 160, 112)',
																	'&:hover': {
																		backgroundColor: 'rgb(47, 180, 112)',
																		color: 'white',
																	},
																}}
																onClick={() => handleAddPrev(seq)}
															>
																Add Prev
															</Button>

															<Button
																sx={{
																	margin: '.5rem',
																	color: 'white',
																	borderRadius: '5rem',
																	padding: '5px',
																	fontSize: isMobile ? '0.8rem' : 'inherit',
																	width: '100%',
																	background: 'rgb(47, 160, 112)',
																	'&:hover': {
																		backgroundColor: 'rgb(47, 188, 112)',
																	},
																}}
																onClick={(e) => viewSequence(e, seq)}
																style={{ marginLeft: '10px' }}
															>
																View
															</Button>

															<Button
																sx={{
																	margin: '.5rem',
																	color: 'white',
																	borderRadius: '5rem',
																	padding: '5px',
																	fontSize: isMobile ? '0.8rem' : 'inherit',
																	width: '100%',
																	backgroundColor: 'rgb(47, 160, 112)',
																	'&:hover': {
																		backgroundColor: 'rgb(47, 180, 112)',
																		color: 'white',
																	},
																}}
																onClick={() => handleAddNext(seq)}
															>
																Add Next
															</Button>

															<Button
																sx={{
																	margin: '.5rem',
																	color: 'white',
																	borderRadius: '5rem',
																	padding: '5px',
																	fontSize: isMobile ? '0.8rem' : 'inherit',
																	width: '100%',
																	backgroundColor: 'rgb(47, 160, 112)',
																	'&:hover': {
																		backgroundColor: 'rgb(47, 180, 112)',
																		color: 'white',
																	},
																}}
																onClick={() => insertSequenceHere(seq._id, nextSeq)}
															>
																Insert <ArrowDownwardIcon />
															</Button>
														</td>
													</tr>
												);
											})}
									</tbody>
								</table>
							</div>
						)}
					</>
				)}
			</Container>
		</>
	);
}

export default AddSequences;

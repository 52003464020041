import React, { useState, useEffect, useCallback } from 'react';
import { TextField, Select, Grid, MenuItem, FormControl, InputLabel, Button, Typography, Checkbox, FormControlLabel, LinearProgress } from '@mui/material';
import { Triangle } from 'react-loader-spinner';
import { Container } from '@mui/material';
import Head from '../components/Head/Head';
import axios from 'axios';
import '../App.css';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL;

function EditTrainingResource({ user, darkMode }) {
	const { id } = useParams();
	const [progress, setProgress] = useState(0);
	const [loading, setLoading] = useState(true);
	const [success, setSuccess] = useState(false);
	const navigate = useNavigate();
	const [timeError, setTimeError] = useState(false);
	const [userId, setUserId] = useState(null);

	// Initialize state for the form
	const [trainingResourceToEdit, setTrainingResourceToEdit] = useState({
		match_id: '',
		url: '',
		volume: '',
		chapterTitle: '',
		free: false,
		instructor: '',
		createdByUser: '',
		title: '',
		note: '',
		type: '',
		timestamp: '',
		sequence_id: '',
	});

	const trainingResourceTypes = ['Game', 'Drill', 'Technique', 'Concept', 'Strategy', 'Other'];

	const handleTimeChange = (e) => {
		const value = e.target.value;
		// Updated regex to enforce either mm:ss or hh:mm:ss, with hours between 0-23 and minutes/seconds between 0-59
		const isValidTime = /^((?:[01]\d|2[0-3]):)?([0-5]\d):([0-5]\d)$/.test(value);
		// Checks for mm:ss or hh:mm:ss format

		if (isValidTime && e.target.name === 'timestamp') {
			setTrainingResourceToEdit((prev) => ({ ...prev, timestamp: value }));
			setTimeError(false);
		} else {
			setTimeError(true);
		}
	};

	// Handle input changes and update state
	const handleInputChange = (e) => {
		let { name, value } = e.target;

		if (name === 'timestamp') {
			handleTimeChange(e);
		}

		// This is a TextField or Select change
		if (e.target.type === 'checkbox') {
			// This is a Checkbox sx={{ color: 'grey', '&.Mui-checked': { color: darkMode ? '#fff' : 'black' } }} change
			name = e.target.name;
			value = e.target.checked; // Here, value is either true or false based on checkbox status
		}

		setTrainingResourceToEdit({
			...trainingResourceToEdit,
			[name]: value,
		});
	};

	// Function to handle form submission and create trainingResource
	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		setProgress(0); // Initialize progress at 0
		if (success) setSuccess(false);

		// Simulate progress starting
		let progressInterval = setInterval(() => {
			setProgress((oldProgress) => {
				if (oldProgress >= 99) {
					// Stop incrementing at 99% and wait for completion
					return 99;
				} else {
					// Increment by 10% until reaching 90%, then by 1%
					const newProgress = oldProgress >= 90 ? oldProgress + 1 : oldProgress + 10;
					return Math.min(newProgress, 99); // Ensure not to exceed 99%
				}
			});
		}, 250);

		// Prepare new resource data
		const newTrainingResource = {
			...trainingResourceToEdit,
		};

		try {
			// Edit training resource
			const response = await axios.put(`${API_URL}/training-resource/${trainingResourceToEdit._id}`, newTrainingResource);

			// Check if the response is successful
			if (response.status === 200) {
				fetchResources(); // Refresh the list of resources
				setSuccess(true); // Indicate success
			} else {
				throw new Error('Failed to update the resource');
			}
		} catch (error) {
			console.error('Error updating the training resource:', error);
			// Handle error state as needed
		} finally {
			clearInterval(progressInterval); // Stop the interval as the operation is completed
			setProgress(100); // Set progress to 100% on completion or error
			setLoading(false); // Ensure loading is set to false after operation is complete
			window.scrollTo(0, 0); // Scroll to top of the page
		}
	};

	// Function to fetch trainingResources
	const fetchResources = useCallback(async () => {
		if (!user) return;
		try {
			// Fetch trainingResources based on the id
			const response = await axios.get(`${API_URL}/training-resource/${id}`);
			// Set the state with the resource that matches the id
			const resourceToEdit = response.data.find((resource) => resource._id === id);
			setTrainingResourceToEdit(resourceToEdit);
		} catch (error) {
			console.error('Error retrieving data:', error);
		} finally {
			setLoading(false);
		}
	}, [user, id]); // Dependencies

	// Function to delete a trainingResource
	const deleteTrainingResource = async () => {
		try {
			// Make a DELETE request to delete a trainingResource
			const response = await axios.delete(`${API_URL}/training-resource/${trainingResourceToEdit._id}`);
			if (response.data) {
				navigate('/training-resources');
			} else {
				throw new Error('Invalid server response');
			}
		} catch (error) {
			console.error('Error deleting trainingResource:', error);
		}
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	// Fetch match data and trainingResources when component mounts
	useEffect(() => {
		fetchResources();
	}, [fetchResources]); // The function will be re-run whenever `needToFetch` changes

	// Fetch user data
	useEffect(() => {
		fetch(`${API_URL}/user/${user.uid}`, {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
		})
			.then((res) => res.json())
			.then((data) => {
				setTrainingResourceToEdit((prev) => ({ ...prev }));
				setUserId(data);
			})
			.catch((err) => console.error(err));
	}, [user.uid]);

	// if success === true reset after 2 seconds
	useEffect(() => {
		if (success === true) {
			setTimeout(() => {
				setSuccess(false);
			}, 2000);
		}
	}, [success]);

	if (loading) {
		return (
			<>
				<Head title='Edit Training Resources Page' />
				<Container maxWidth='md' sx={{ display: 'flex', justifyContent: 'center' }}>
					<div className='loader'>
						<Triangle color='rgb(47, 199, 112)' size={100} />
						<div style={{ position: 'relative', width: '100%' }}>
							<LinearProgress
								variant='determinate'
								value={progress}
								sx={{
									height: '12px',
									marginTop: '8px',
									'& .MuiLinearProgress-bar': {
										backgroundColor: 'rgb(47, 199, 112)',
									},
									backgroundColor: '#e0e0e0',
								}}
							/>
							<div
								style={{
									position: 'absolute',
									width: '100%',
									top: 0,
									left: 0,
									height: '12px',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									fontSize: '12px',
									color: 'black', // Choose a text color that stands out
								}}
							>
								{`${progress}%`}
							</div>
						</div>
					</div>
				</Container>
			</>
		);
	}

	return (
		<>
			<Head title='Edit Training Resource Page' />
			<Container sx={{ marginBottom: '50px' }}>
				<Typography sx={{ textAlign: 'center', marginTop: '16px', marginBottom: '16px' }} variant='h6'>
					Edit Training Resource
				</Typography>

				{trainingResourceToEdit && (
					<>
						<form onSubmit={handleSubmit}>
							<Grid container spacing={3}>
								<Grid item xs={12} md={12} lg={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
									{/* trainingResource Type Dropdown */}
									<FormControl
										sx={{
											width: '49%',
											// Change outline color when hovered but not focused
											'&:hover .MuiOutlinedInput-notchedOutline:not(.Mui-focused)': {
												borderColor: darkMode ? 'white!important' : 'grey',
											},
											// Change label color when hovered but not focused
											'&:hover .MuiInputLabel-root:not(.Mui-focused)': {
												color: darkMode ? 'white' : '#000',
											},
											// For text color
											'&& input': {
												color: darkMode ? '#fff' : '#000',
											},
											// Default outline color
											'& .MuiOutlinedInput-notchedOutline': {
												borderColor: darkMode ? 'grey!important' : 'grey',
											},
											// Focused outline color
											'& .MuiOutlinedInput-notchedOutline.Mui-focused': {
												borderColor: darkMode ? 'rgb(47,199,112)' : '#000',
											},
											// Background color
											'& .MuiOutlinedInput-root': {
												backgroundColor: darkMode ? '#000' : '#f7f7f7',
											},
											'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
												borderColor: 'rgb(47, 199, 112)!important',
											},
											// For label text color
											'& .MuiInputLabel-root': {
												color: darkMode ? 'grey' : 'grey',
											},
											// Focused label color
											'& .MuiInputLabel-outlined.Mui-focused': {
												color: 'rgb(47, 180, 112)',
											},
										}}
									>
										<InputLabel sx={{ '&.MuiInputLabel-shrink': { transform: 'translate(14px, -16px) scale(0.75)' } }}>Resource Type</InputLabel>
										<Select name='type' value={trainingResourceToEdit?.type} onChange={handleInputChange} required>
											{trainingResourceTypes.map((type) => (
												<MenuItem key={type} value={type}>
													{type}
												</MenuItem>
											))}
										</Select>
									</FormControl>
									<TextField
										autoComplete='off'
										sx={{
											width: '49%',
											// Change outline color when hovered but not focused
											'&:hover .MuiOutlinedInput-notchedOutline:not(.Mui-focused)': {
												borderColor: darkMode ? 'white!important' : 'grey',
											},
											// Change label color when hovered but not focused
											'&:hover .MuiInputLabel-root:not(.Mui-focused)': {
												color: darkMode ? 'white' : '#000',
											},
											// For text color
											'&& input': {
												color: darkMode ? '#fff' : '#000',
											},
											// Default outline color
											'& .MuiOutlinedInput-notchedOutline': {
												borderColor: darkMode ? 'grey!important' : 'grey',
											},
											// Focused outline color
											'& .MuiOutlinedInput-notchedOutline.Mui-focused': {
												borderColor: darkMode ? 'rgb(47,199,112)' : '#000',
											},
											// Background color
											'& .MuiOutlinedInput-root': {
												backgroundColor: darkMode ? '#000' : '#f7f7f7',
											},
											'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
												borderColor: 'rgb(47, 199, 112)!important',
											},
											// For label text color
											'& .MuiInputLabel-root': {
												color: darkMode ? 'grey' : 'grey',
											},
											// Focused label color
											'& .MuiInputLabel-outlined.Mui-focused': {
												color: 'rgb(47, 180, 112)',
											},
										}}
										required
										id='instructor'
										name='instructor'
										label='Resource Instructor'
										variant='outlined'
										value={trainingResourceToEdit.instructor}
										onChange={handleInputChange}
									/>
								</Grid>
								<Grid item xs={12} md={12} lg={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
									{/* trainingResource Title */}
									<TextField
										autoComplete='off'
										sx={{
											width: '49%',
											// Change outline color when hovered but not focused
											'&:hover .MuiOutlinedInput-notchedOutline:not(.Mui-focused)': {
												borderColor: darkMode ? 'white!important' : 'grey',
											},
											// Change label color when hovered but not focused
											'&:hover .MuiInputLabel-root:not(.Mui-focused)': {
												color: darkMode ? 'white' : '#000',
											},
											// For text color
											'&& input': {
												color: darkMode ? '#fff' : '#000',
											},
											// Default outline color
											'& .MuiOutlinedInput-notchedOutline': {
												borderColor: darkMode ? 'grey!important' : 'grey',
											},
											// Focused outline color
											'& .MuiOutlinedInput-notchedOutline.Mui-focused': {
												borderColor: darkMode ? 'rgb(47,199,112)' : '#000',
											},
											// Background color
											'& .MuiOutlinedInput-root': {
												backgroundColor: darkMode ? '#000' : '#f7f7f7',
											},
											'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
												borderColor: 'rgb(47, 199, 112)!important',
											},
											// For label text color
											'& .MuiInputLabel-root': {
												color: darkMode ? 'grey' : 'grey',
											},
											// Focused label color
											'& .MuiInputLabel-outlined.Mui-focused': {
												color: 'rgb(47, 180, 112)',
											},
										}}
										required
										id='title'
										name='title'
										label='Resource Title'
										placeholder='Do not use / or special characters in title'
										variant='outlined'
										value={trainingResourceToEdit.title}
										onChange={handleInputChange}
									/>
									<TextField
										autoComplete='off'
										sx={{
											width: '49%',
											marginLeft: '8px',
											marginRight: '8px',
											// Change outline color when hovered but not focused
											'&:hover .MuiOutlinedInput-notchedOutline:not(.Mui-focused)': {
												borderColor: darkMode ? 'white!important' : 'grey',
											},
											// Change label color when hovered but not focused
											'&:hover .MuiInputLabel-root:not(.Mui-focused)': {
												color: darkMode ? 'white' : '#000',
											},
											// For text color
											'&& input': {
												color: darkMode ? '#fff' : '#000',
											},
											// Default outline color
											'& .MuiOutlinedInput-notchedOutline': {
												borderColor: darkMode ? 'grey!important' : 'grey',
											},
											// Focused outline color
											'& .MuiOutlinedInput-notchedOutline.Mui-focused': {
												borderColor: darkMode ? 'rgb(47,199,112)' : '#000',
											},
											// Background color
											'& .MuiOutlinedInput-root': {
												backgroundColor: darkMode ? '#000' : '#f7f7f7',
											},
											'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
												borderColor: 'rgb(47, 199, 112)!important',
											},
											// For label text color
											'& .MuiInputLabel-root': {
												color: darkMode ? 'grey' : 'grey',
											},
											// Focused label color
											'& .MuiInputLabel-outlined.Mui-focused': {
												color: 'rgb(47, 180, 112)',
											},
										}}
										id='chapterTitle'
										name='chapterTitle'
										label='Chapter Title'
										variant='outlined'
										value={trainingResourceToEdit?.chapterTitle}
										onChange={handleInputChange}
									/>

									{/* Resource URL   */}
									<TextField
										autoComplete='off'
										sx={{
											width: '49%',
											// Change outline color when hovered but not focused
											'&:hover .MuiOutlinedInput-notchedOutline:not(.Mui-focused)': {
												borderColor: darkMode ? 'white!important' : 'grey',
											},
											// Change label color when hovered but not focused
											'&:hover .MuiInputLabel-root:not(.Mui-focused)': {
												color: darkMode ? 'white' : '#000',
											},
											// For text color
											'&& input': {
												color: darkMode ? '#fff' : '#000',
											},
											// Default outline color
											'& .MuiOutlinedInput-notchedOutline': {
												borderColor: darkMode ? 'grey!important' : 'grey',
											},
											// Focused outline color
											'& .MuiOutlinedInput-notchedOutline.Mui-focused': {
												borderColor: darkMode ? 'rgb(47,199,112)' : '#000',
											},
											// Background color
											'& .MuiOutlinedInput-root': {
												backgroundColor: darkMode ? '#000' : '#f7f7f7',
											},
											'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
												borderColor: 'rgb(47, 199, 112)!important',
											},
											// For label text color
											'& .MuiInputLabel-root': {
												color: darkMode ? 'grey' : 'grey',
											},
											// Focused label color
											'& .MuiInputLabel-outlined.Mui-focused': {
												color: 'rgb(47, 180, 112)',
											},
										}}
										required
										id='url'
										name='url'
										label='Resource URL'
										variant='outlined'
										value={trainingResourceToEdit.url}
										onChange={handleInputChange}
									/>
								</Grid>

								<Grid item xs={12} md={12} lg={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
									<TextField
										autoComplete='off'
										sx={{
											width: '33%',
											// Change outline color when hovered but not focused
											'&:hover .MuiOutlinedInput-notchedOutline:not(.Mui-focused)': {
												borderColor: darkMode ? 'white!important' : 'grey',
											},
											// Change label color when hovered but not focused
											'&:hover .MuiInputLabel-root:not(.Mui-focused)': {
												color: darkMode ? 'white' : '#000',
											},
											// For text color
											'&& input': {
												color: darkMode ? '#fff' : '#000',
											},
											// Default outline color
											'& .MuiOutlinedInput-notchedOutline': {
												borderColor: darkMode ? 'grey!important' : 'grey',
											},
											// Focused outline color
											'& .MuiOutlinedInput-notchedOutline.Mui-focused': {
												borderColor: darkMode ? 'rgb(47,199,112)' : '#000',
											},
											// Background color
											'& .MuiOutlinedInput-root': {
												backgroundColor: darkMode ? '#000' : '#f7f7f7',
											},
											'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
												borderColor: 'rgb(47, 199, 112)!important',
											},
											// For label text color
											'& .MuiInputLabel-root': {
												color: darkMode ? 'grey' : 'grey',
											},
											// Focused label color
											'& .MuiInputLabel-outlined.Mui-focused': {
												color: 'rgb(47, 180, 112)',
											},
										}}
										id='volume'
										name='volume'
										label='Volume'
										variant='outlined'
										value={trainingResourceToEdit.volume}
										onChange={handleInputChange}
										type='text'
									/>
									<FormControlLabel
										sx={{
											width: '33%',
											display: 'flex',
											justifyContent: 'center',
											color: darkMode ? '#fff' : 'grey',
											'&.Mui-checked': { color: darkMode ? '#fff' : 'black' },
										}}
										control={
											<Checkbox
												sx={{ color: 'grey', '&.Mui-checked': { color: darkMode ? '#fff' : 'black' } }}
												checked={trainingResourceToEdit.free}
												onChange={handleInputChange}
												name='free'
											/>
										}
										label='Free?'
									/>
									{/* instructional timestamp   */}
									<TextField
										autoComplete='off'
										sx={{
											width: '33%',
											// Change outline color when hovered but not focused
											'&:hover .MuiOutlinedInput-notchedOutline:not(.Mui-focused)': {
												borderColor: darkMode ? 'white!important' : 'grey',
											},
											// Change label color when hovered but not focused
											'&:hover .MuiInputLabel-root:not(.Mui-focused)': {
												color: darkMode ? 'white' : '#000',
											},
											// For text color
											'&& input': {
												color: darkMode ? '#fff' : '#000',
											},
											// Default outline color
											'& .MuiOutlinedInput-notchedOutline': {
												borderColor: darkMode ? 'grey!important' : 'grey',
											},
											// Focused outline color
											'& .MuiOutlinedInput-notchedOutline.Mui-focused': {
												borderColor: darkMode ? 'rgb(47,199,112)' : '#000',
											},
											// Background color
											'& .MuiOutlinedInput-root': {
												backgroundColor: darkMode ? '#000' : '#f7f7f7',
											},
											'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
												borderColor: 'rgb(47, 199, 112)!important',
											},
											// For label text color
											'& .MuiInputLabel-root': {
												color: darkMode ? 'grey' : 'grey',
											},
											// Focused label color
											'& .MuiInputLabel-outlined.Mui-focused': {
												color: 'rgb(47, 180, 112)',
											},
										}}
										required
										id='timestamp'
										name='timestamp'
										label='Resource Timestamp'
										variant='outlined'
										value={trainingResourceToEdit.timestamp}
										onChange={handleInputChange}
										error={timeError}
										helperText={timeError ? 'Please enter a valid time (mm:ss) OR (hh:mm:ss)' : 'Time in video (mm:ss) OR (hh:mm:ss)'}
									/>
								</Grid>

								<Grid item xs={12} md={12} lg={12} sx={{ display: 'flex', justifyContent: 'center' }}>
									<TextField
										autoComplete='off'
										sx={{
											width: '100%',
											// Change outline color when hovered but not focused
											'&:hover .MuiOutlinedInput-notchedOutline:not(.Mui-focused)': {
												borderColor: darkMode ? 'white!important' : 'grey',
											},
											// Change label color when hovered but not focused
											'&:hover .MuiInputLabel-root:not(.Mui-focused)': {
												color: darkMode ? 'white' : '#000',
											},
											// For text color
											'&& input': {
												color: darkMode ? '#fff' : '#000',
											},
											// Default outline color
											'& .MuiOutlinedInput-notchedOutline': {
												borderColor: darkMode ? 'grey!important' : 'grey',
											},
											// Focused outline color
											'& .MuiOutlinedInput-notchedOutline.Mui-focused': {
												borderColor: darkMode ? 'rgb(47,199,112)' : '#000',
											},
											// Background color
											'& .MuiOutlinedInput-root': {
												backgroundColor: darkMode ? '#000' : '#f7f7f7',
											},
											'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
												borderColor: 'rgb(47, 199, 112)!important',
											},
											// For label text color
											'& .MuiInputLabel-root': {
												color: darkMode ? 'grey' : 'grey',
											},
											// Focused label color
											'& .MuiInputLabel-outlined.Mui-focused': {
												color: 'rgb(47, 180, 112)',
											},
										}}
										id='note'
										name='note'
										label='Resource note'
										variant='outlined'
										value={trainingResourceToEdit.note}
										onChange={handleInputChange}
									/>
								</Grid>
								{/* Submit Button */}
								<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
									<Grid item xs={12}>
										{success && <Typography sx={{ textAlign: 'left', color: 'rgb(47, 199, 112)' }}>Resource successfully updated!</Typography>}
										<Button
											sx={{
												backgroundColor: 'rgb(47, 199, 112)',
												color: 'white',
												marginBottom: '16px',
												'&:hover': { backgroundColor: 'rgb(47, 180, 112)', color: 'white' },
											}}
											type='submit'
											variant='contained'
											disabled={loading}
										>
											Save Changes
										</Button>
										{/* Delete Button */}
										{userId === trainingResourceToEdit.createdByUser && (
											<Grid item xs={12}>
												<Button
													onClick={deleteTrainingResource}
													sx={{
														backgroundColor: '#d20404',
														color: 'white',
														marginBottom: '16px',
														'&:hover': { backgroundColor: '#db1c1c', color: 'white' },
													}}
													type='button'
													variant='contained'
													disabled={loading}
												>
													Delete Resource
												</Button>
											</Grid>
										)}
									</Grid>
								</Grid>
							</Grid>
						</form>
					</>
				)}
			</Container>
		</>
	);
}

export default EditTrainingResource;

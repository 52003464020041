import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';

export default function LoginAndSubscribeDialog({ open, handleClose, handleCloseAndLogin, handleCloseAndSubscribe, authUser, user }) {
	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle>{!authUser ? 'Login Required' : 'Subscription Required'}</DialogTitle>
			<DialogContent>
				<DialogContentText>{!authUser ? 'Please login to access this page.' : 'Subscribe to access premium features'}</DialogContentText>
			</DialogContent>
			<DialogActions sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
				{!authUser && (
					<Button
						sx={{
							backgroundColor: 'rgb(47, 160, 112)',
							color: 'white',
							'&:hover': {
								backgroundColor: 'rgb(47, 199, 112)',
							},
						}}
						onClick={handleCloseAndLogin}
					>
						Login
					</Button>
				)}
				{authUser && user?.subscriptionStatus !== 'active' && (
					<Button
						sx={{
							color: 'white',
							backgroundColor: 'rgb(47, 160, 112)',
							'&:hover': {
								backgroundColor: 'rgb(47, 199, 112)',
							},
						}}
						onClick={handleCloseAndSubscribe}
					>
						Subscribe
					</Button>
				)}
				<Button
					sx={{
						color: 'white',
						backgroundColor: '#d20404',
						'&:hover': {
							backgroundColor: '#db1c1c',
						},
					}}
					onClick={handleClose}
				>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
}

import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, TextField, IconButton, Tooltip, useMediaQuery } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import ModeCommentIcon from '@mui/icons-material/ModeComment';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import { useNavigate } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL;

const Chatbot = ({ darkMode, user, messages, setMessages, isOpen, toggleChatbot }) => {
	const navigate = useNavigate();
	const [input, setInput] = useState('');
	const [size, setSize] = useState('small');
	const isSmallScreen = useMediaQuery('(max-width:600px)');
	const messagesEndRef = useRef(null);

	const clearMessages = () => {
		setMessages([]);
	};

	const toggleSmall = () => {
		setSize('small');
	};

	const toggleMedium = () => {
		setSize('medium');
	};

	const toggleLarge = () => {
		setSize('large');
	};

	const loadingPhrases = [
		'Transitioning to mount... almost there!',
		'Passing the guard to find your info.',
		'Securing a data submission, just a sec!',
		'Spinning through data like an inverted purple belt!',
		'NO KNEEBAHHHS???',
		'Pulling guard on your request, just a moment!',
		'Grappling with the data, almost pinned!',
		'10th Planet & Flat Earth, the perfect combo!',
		'Taking the back... of the data...',
		'Sweeping through the data like you get swept in class!',
		'Executing a data hip escape, just a moment!',
		'Ever heel hook a white belt? Try it!',
		'On the mat with the data, stay patient!',
		'Austism and Steroids, the perfect combo!',
		'Locking in a data RNC, hold tight!',
		'The secret? Acai and Jesus...',
		'Applying knee on belly to the data, just a moment!',
		'Flowing through the data like a black belt.',
		'Grappling with data entries, hang on!',
		'Inverted Omoplata Half Guard Heel Hook, try it!',
		'Data takedown in progress, stay tuned!',
		'Locking up the data triangle, hold tight!',
		'Applying a data leg lock, just a moment!',
		'Have you tried just tapping bro?',
	];

	const sendMessage = async () => {
		if (input.trim()) {
			const newMessage = { sender: 'user', text: input };
			const randomPhrase = loadingPhrases[Math.floor(Math.random() * loadingPhrases.length)];
			setMessages((prevMessages) => [...prevMessages, newMessage, { sender: 'bot', text: randomPhrase }]);
			setInput(''); // Clear the input field after sending the message

			try {
				const response = await fetch(`${API_URL}/data/chat`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({ text: input, user: user?._id }),
				});
				const data = await response.json();

				// Extract and clean the response message
				let responseMessage = data.data.split('}]||')[1].trim();

				// Extract the parsed data
				let parsedData = JSON.parse(data.data.split('}]||')[0] + '}]');

				// Filter out only the resources and deduplicate them
				const resources = parsedData.filter((item) => item.tag_set === 'resource');
				const uniqueResources = resources.filter((item, index, self) => index === self.findIndex((t) => t.metadata.timestamp === item.metadata.timestamp));

				// Filter out sequences and matches
				const sequencesAndMatches = parsedData.filter((item) => item.tag_set === 'sequence' || item.tag_set === 'match');

				// Combine the unique resources with sequences and matches
				const combinedItems = [...uniqueResources, ...sequencesAndMatches];

				// Replace citation markers with links using the combined items
				const citationRegex = /\[\^([0-9]+)\]/g;
				responseMessage = responseMessage.replace(citationRegex, (match, p1) => {
					const index = parseInt(p1) - 1;
					const item = combinedItems[index];
					if (item && item.tracking_id) {
						if (item.tag_set === 'sequence') {
							return `<a href="/match/${item.metadata.match_id}?sequence_id=${item.metadata._id}"  class="chat-link" data-path="/match/${item.metadata.match_id}?sequence_id=${item.metadata._id}"   style="font-family: monospace; display: inline; cursor: pointer; text-decoration: underline; font-weight: 600; color: rgb(0, 109, 56); margin-left: 4px; margin-right: 4px;">[View Sequence]</a>`;
						} else if (item.tag_set === 'resource') {
							const queryParams = new URLSearchParams({ timestamp: item.metadata.timestamp }).toString();
							return `<a href="/resource/${item.tracking_id}?${queryParams}" class="chat-link" data-path="/resource/${item.tracking_id}?${queryParams}" style="font-family: monospace; text-decoration: underline; display: inline; cursor: pointer; font-weight: 600; color: rgb(0, 109, 56); margin-left: 4px; margin-right: 4px;">[View Resource]</a>`;
						} else if (item.tag_set === 'match') {
							return `<a href="/match/${item.tracking_id}" class="chat-link" data-path="/match/${item.tracking_id}" style="font-family: monospace; text-decoration: underline; font-weight: 600; display: inline; cursor: pointer; color: rgb(0, 109, 56); margin-left: 4px; margin-right: 4px;">[View Match]</a>`;
						}
					}
					return match;
				});

				// Remove citation markers and document links
				responseMessage = responseMessage.replace(/\[\^[0-9]+\]|\^[0-9]+|\[[0-9]+\]|\[\^[0-9]+\]:\s*Doc\s*\d+|Doc\s*[1-9]|\^[1-9]|\^\^[1-9]/g, '');

				setMessages((prevMessages) => prevMessages.slice(0, -1).concat({ sender: 'bot', text: responseMessage }));
			} catch (error) {
				console.error(error);
				setMessages((prevMessages) => prevMessages.slice(0, -1).concat({ sender: 'bot', text: 'Error processing your request.' }));
			}
		}
	};

	const handleLinkClick = (e) => {
		if (e.target.classList.contains('chat-link')) {
			e.preventDefault();
			const path = e.target.getAttribute('data-path');
			navigate(path);
		}
	};

	const handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			sendMessage();
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleLinkClick);
		return () => {
			document.removeEventListener('click', handleLinkClick);
		};
		// eslint-disable-next-line
	}, []);

	const scrollToBottom = () => {
		if (messagesEndRef.current) {
			messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	};

	useEffect(() => {
		if (isOpen) {
			scrollToBottom();
		}
	}, [isOpen, messages]);

	return (
		<>
			{isOpen && (
				<Box
					sx={{
						position: 'fixed',
						top: 0,
						left: 0,
						width: '100vw',
						height: '100vh',
						backgroundColor: darkMode ? 'rgba(0,0,0, .4)' : 'rgba(0, 0, 0, .5)',
						zIndex: 2147483646,
						pointerEvents: 'auto',
					}}
					onClick={toggleChatbot}
				/>
			)}
			<Box
				sx={{
					position: 'fixed',
					bottom: 16,
					right: 16,
					width: !isOpen ? 115 : size === 'small' ? 350 : size === 'medium' ? 450 : 600,
					boxShadow: 3,
					borderRadius: 2,
					overflow: 'hidden',
					backgroundColor: 'background.paper',
					zIndex: 2147483647,
					pointerEvents: 'auto',
				}}
			>
				{isOpen ? (
					<Box sx={{ display: 'flex', flexDirection: 'column', height: size === 'small' ? 500 : size === 'medium' ? 550 : 700 }}>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
								p: 1,
								bgcolor: 'rgb(47,160, 112)',
								color: 'primary.contrastText',
							}}
						>
							<Box>OutlierDB Chat</Box>
							<Box sx={{ display: 'flex', alignItems: 'center' }}>
								<IconButton size='small' sx={{ color: darkMode ? '#000' : '#fff', fontSize: '0.9rem' }} onClick={toggleSmall}>
									<AspectRatioIcon sx={{ fontSize: '0.9rem' }} />
								</IconButton>
								{isSmallScreen ? null : (
									<>
										<IconButton size='small' sx={{ color: darkMode ? '#000' : '#fff', fontSize: '1rem' }} onClick={toggleMedium}>
											<AspectRatioIcon sx={{ fontSize: '1.2rem' }} />
										</IconButton>
										<IconButton size='small' sx={{ color: darkMode ? '#000' : '#fff', fontSize: '1.1rem' }} onClick={toggleLarge}>
											<AspectRatioIcon sx={{ fontSize: '1.4rem' }} />
										</IconButton>
									</>
								)}
							</Box>
							<Button
								variant='contained'
								sx={{ background: 'rgb(190, 49, 49)', height: '25px', color: darkMode ? '#000' : '#fff', ':hover': { background: '#da3c3c' } }}
								onClick={clearMessages}
								style={{ pointerEvents: 'auto' }}
							>
								Clear
							</Button>
							<IconButton size='small' onClick={toggleChatbot}>
								<CloseIcon sx={{ color: 'primary.contrastText' }} />
							</IconButton>
						</Box>
						<Box sx={{ flex: 1, p: 1, overflowY: 'auto' }}>
							{messages.map((message, index) => (
								<Box
									key={index}
									sx={{
										display: 'flex',
										justifyContent: message.sender === 'user' ? 'flex-end' : 'flex-start',
										mb: 1,
									}}
									ref={index === messages.length - 1 ? messagesEndRef : null}
								>
									<Box
										sx={{
											p: 1,
											bgcolor: message.sender === 'user' ? (darkMode ? 'rgb(47,140,112)' : 'rgb(47,199,112)') : darkMode ? 'rgb(30,30,30)' : 'grey.300',
											borderRadius: 2,
										}}
										dangerouslySetInnerHTML={{ __html: message.text }} // Render HTML content
									/>
								</Box>
							))}
						</Box>
						<Box sx={{ display: 'flex', p: 1, borderTop: 1, borderColor: 'divider' }}>
							<TextField
								value={input}
								onChange={(e) => setInput(e.target.value)}
								onKeyDown={handleKeyPress}
								fullWidth
								variant='outlined'
								size='small'
								autoComplete='off'
								sx={{
									mr: 1,
									// Change outline color when hovered but not focused
									'&:hover .MuiOutlinedInput-notchedOutline:not(.Mui-focused)': {
										borderColor: darkMode ? 'white!important' : 'grey',
									},

									// Default outline color
									'& .MuiOutlinedInput-notchedOutline': {
										borderColor: darkMode ? 'grey!important' : 'grey',
									},
									// Focused outline color
									'& .MuiOutlinedInput-notchedOutline.Mui-focused': {
										borderColor: darkMode ? 'rgb(47,199,112)' : '#000',
									},
									// Background color
									'& .MuiOutlinedInput-root': {
										backgroundColor: darkMode ? '#000' : '#f7f7f7',
									},
									'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
										borderColor: 'rgb(47, 199, 112)!important',
									},
									// For label text color
									'& .MuiInputLabel-root': {
										color: darkMode ? 'grey' : 'grey',
									},
									// Focused label color
									'& .MuiInputLabel-outlined.Mui-focused': {
										color: 'rgb(47, 180, 112)',
									},
								}}
								placeholder='Ask a training question'
							/>
							<IconButton onClick={sendMessage} sx={{ background: 'rgb(47,160, 112)', mr: 1, color: darkMode ? '#000' : '#fff', ':hover': { background: 'rgb(47,180,112)' } }}>
								<SendIcon />
							</IconButton>
						</Box>
					</Box>
				) : (
					<>
						{user?.subscriptionStatus === 'active' ? (
							<Button
								variant='contained'
								sx={{ background: 'rgb(47,160, 112)', width: '115px', color: darkMode ? '#000' : '#fff', ':hover': { background: 'rgb(47,180,112)' } }}
								onClick={toggleChatbot}
								style={{ pointerEvents: 'auto' }}
							>
								<ModeCommentIcon />
								&nbsp; Chat
							</Button>
						) : (
							<Tooltip title='Please subscribe to use the chatbot'>
								<Button variant='contained' sx={{ background: 'grey', width: '115px', color: darkMode ? '#000' : '#fff', ':hover': { background: 'grey' } }} style={{ pointerEvents: 'auto' }}>
									<ModeCommentIcon />
									&nbsp; Chat
								</Button>
							</Tooltip>
						)}
					</>
				)}
			</Box>
		</>
	);
};

export default Chatbot;

import { ListItem, ListItemText } from '@mui/material';
function FolderListItem({ folder, depth = 0, onSelect, darkMode }) {
	const hasSubfolders = folder.subFolders && folder.subFolders.length > 0;

	return (
		<>
			<ListItem
				sx={{
					paddingLeft: depth * 20,
					cursor: 'pointer',
					background: darkMode ? '#042E21' : '#A4B2A4',
					padding: '2px 8px',
					margin: '8px 0',
					':hover': { background: darkMode ? '#12392C' : '#9eba9c' },
				}}
				onClick={() => onSelect(folder._id)}
			>
				<ListItemText primary={folder.name} />
			</ListItem>
			{hasSubfolders && folder.subFolders.map((subFolder) => <FolderListItem key={subFolder._id} folder={subFolder} depth={depth + 1} onSelect={onSelect} />)}
		</>
	);
}

export default FolderListItem;

import React, { useState, useEffect } from 'react';
import { Box, Typography, Container, Grid, useMediaQuery, Card, CardContent, Tooltip, IconButton } from '@mui/material';
import Head from '../components/Head/Head';
import { useNavigate } from 'react-router-dom';
import { Triangle } from 'react-loader-spinner';
import { useParams } from 'react-router-dom';
import GroupedSequences from '../components//GroupedSequences';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import FeaturedSequenceCard from '../components/FeaturedSequenceCard';
import FeaturedResourceCard from '../components/FeaturedResourceCard';
import WikiPieChart from '../components/WikiPieChart';
const API_URL = process.env.REACT_APP_API_URL;

export default function Wiki({ user, authUser, darkMode }) {
	const navigate = useNavigate();
	const isMobile = useMediaQuery('(max-width:600px)');
	const [termData, setTermData] = useState(null);
	const [topGroups, setTopGroups] = useState([]);
	const [statData, setStatData] = useState([]);
	const [additionalData, setAdditionalData] = useState(null);
	const [additionalSequences, setAdditionalSequences] = useState([]);
	const [additionalResources, setAdditionalResources] = useState([]);
	const [stats, setStats] = useState([]);
	const [loading, setLoading] = useState(true);
	const [youTubeEmbed, setYouTubeEmbed] = useState('');
	const params = useParams();

	const onGroupClick = (group) => {
		localStorage.setItem('wikiGroup', JSON.stringify(group._id));
		navigate(`/sequences`);
	};

	const highlightTerm = (note, term) => {
		const regexPattern = `(${term.split(/[-\s\\/]/).join('[-\\s\\/]?')})`;
		const regex = new RegExp(regexPattern, 'gi');
		const parts = note.split(regex);
		return parts.map((part, index) =>
			part.match(regex) ? (
				<span key={index} style={{ backgroundColor: darkMode ? 'green' : 'yellow' }}>
					{part}
				</span>
			) : (
				part
			)
		);
	};

	const handleVariationClick = (variation, term) => {
		const parts = variation.split('$');
		const textToLog = parts.length > 1 ? parts[1] : parts[0];
		localStorage.setItem('wikiVariation', JSON.stringify({ term: term, variation: textToLog }));
		navigate(`/sequences`);
	};

	const formatVariation = (variation) => {
		const parts = variation.split('$');
		return parts[0]; // Return the part before the $
	};

	const convertToEmbedUrl = (url) => {
		const youtubeRegex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:embed\/|watch\?v=)|youtu\.be\/)([a-zA-Z0-9_-]+)/;
		const match = url.match(youtubeRegex);
		if (match && match[1]) {
			return `https://www.youtube.com/embed/${match[1]}`;
		}
		return null;
	};

	useEffect(() => {
		fetch(`${API_URL}/wiki/${encodeURIComponent(params.type)}/${encodeURIComponent(params.item)}`)
			.then((res) => res.json())
			.then((data) => {
				setTermData(data);
				setTopGroups(data.groups);
				setStatData(data.statData);
				setAdditionalSequences(data.additionalSequences);
				setAdditionalResources(data.additionalResources);
				setStats(data.additionalStats);

				if (data.additionalData) {
					setAdditionalData(data.additionalData);
					if (data.additionalData.youtubeLink) {
						const embedUrl = convertToEmbedUrl(data.additionalData.youtubeLink);
						setYouTubeEmbed(embedUrl);
					}
				}
				setLoading(false);
			})
			.catch((err) => console.error(err));
	}, [params.item, params.type]);

	const handleNoteClick = (note) => {
		// Extract timestamp from the note (format: HH:MM:SS or MM:SS)
		const timestampMatch = note.match(/(\d+):(\d+):(\d+)|(\d+):(\d+)/);
		let totalSeconds = 0;

		if (timestampMatch) {
			let hours = 0,
				minutes = 0,
				seconds = 0;

			if (timestampMatch[3]) {
				// HH:MM:SS format
				hours = parseInt(timestampMatch[1], 10);
				minutes = parseInt(timestampMatch[2], 10);
				seconds = parseInt(timestampMatch[3], 10);
			} else {
				// MM:SS format
				minutes = parseInt(timestampMatch[4], 10);
				seconds = parseInt(timestampMatch[5], 10);
			}

			totalSeconds = hours * 3600 + minutes * 60 + seconds;
		}

		// Update the YouTube embed URL with the new start time
		if (youTubeEmbed) {
			const videoIdMatch = youTubeEmbed.match(/youtube\.com\/embed\/([a-zA-Z0-9_-]+)/);
			if (videoIdMatch && videoIdMatch[1]) {
				const videoId = videoIdMatch[1];
				const newEmbedUrl = `https://www.youtube.com/embed/${videoId}?start=${totalSeconds}&autoplay=1&mute=0`;
				setYouTubeEmbed(newEmbedUrl);
			}
		}
		// Scroll to YouTube embed
		window.scrollTo(0, 0, { behavior: 'smooth' });
	};

	if (loading) {
		return (
			<Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
				<Triangle height='100' width='100' color='rgb(47, 199, 112)' ariaLabel='triangle-loading' />
				<Typography variant='h6' style={{ color: 'rgb(47,199,112)' }}>
					Loading...
				</Typography>
			</Box>
		);
	}

	return (
		<Container>
			<Head title='Wiki' />
			{termData && (
				<>
					{additionalData && (additionalData.youtubeLink || additionalData.floLink) && (
						<Grid item sx={{ display: 'flex', width: '100%', maxHeight: '500px', flexDirection: isMobile ? 'column' : 'row', mt: 2 }}>
							<Box sx={{ display: 'flex', flexDirection: 'column', width: isMobile ? '100%' : '65%' }}>
								<Typography variant={isMobile ? 'h5' : 'h4'}>{termData.term} in Action</Typography>
								<Box sx={{ position: 'relative', paddingBottom: '56.25%' }}>
									{additionalData.youtubeLink !== '' && (
										<iframe
											src={youTubeEmbed}
											allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
											allowFullScreen
											style={{
												position: 'absolute',
												top: 0,
												left: 0,
												width: '100%',
												height: '100%',
											}}
											title={termData.term}
										></iframe>
									)}
									{additionalData.floLink !== '' && additionalData.youtubeLink === '' && (
										<>
											<iframe
												style={{
													position: 'absolute',
													top: 0,
													left: 0,
													width: '100%',
													height: '100%',
													border: 0,
													backgroundColor: 'black',
												}}
												allow='accelerometer; autoplay; encrypted-media; gyroscope; '
												allowFullScreen
												title='YouTube video player'
											/>
											<Tooltip title='Watch on Flograppling?' placement='top' arrow>
												<IconButton
													sx={{
														position: 'absolute',
														top: '50%',
														left: '50%',
														transform: 'translate(-50%, -50%)',
														color: darkMode ? '#fff' : 'rgb(100,100,100)',
														bgcolor: 'transparent',
														'&:hover': {
															bgcolor: 'background.default',
														},
														size: 'large',
													}}
													onClick={() => window.open(additionalData.floLink, '_blank')}
												>
													<PlayCircleOutlineIcon sx={{ fontSize: '3rem' }} />
												</IconButton>
											</Tooltip>
										</>
									)}
								</Box>
							</Box>
							<Box sx={{ display: 'flex', flexDirection: 'column', width: isMobile ? '100%' : '35%', mt: isMobile ? 1 : 5, pl: 2, overflowY: 'auto' }}>
								<Typography variant='h6'>Match Notes</Typography>
								{additionalData.notes ? (
									additionalData.notes.map((note, idx) => (
										<Typography
											key={idx}
											variant='body2'
											component='div'
											sx={{ margin: '.5rem 0', fontFamily: 'monospace', cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
											onClick={() => handleNoteClick(note)}
										>
											{highlightTerm(note, termData.term, darkMode)}
										</Typography>
									))
								) : (
									<Typography variant='body2' sx={{ margin: '1rem 0' }}>
										No notes available
									</Typography>
								)}
							</Box>
						</Grid>
					)}
					{termData.type === 'submission' && (
						<Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', mt: 2 }}>
							<a
								href='/matches'
								onMouseDown={(e) => {
									localStorage.setItem('matchWiki', termData.term);
								}}
								style={{ textDecoration: 'none' }}
							>
								<Typography
									variant='button'
									sx={{
										fontFamily: 'monospace',
										textDecoration: 'underline',
										pr: isMobile ? 1 : 0,
										fontWeight: 300,

										color: darkMode ? 'rgb(0,130,60)' : 'rgb(0, 109, 56)',
										':hover': { cursor: 'pointer', color: darkMode ? 'rgb(0,150,70)' : 'rgb(0,130,60)' },
									}}
								>
									SEE MORE MATCHES
								</Typography>
							</a>
						</Box>
					)}
					<Grid item xs={12} sm={6} md={4} lg={3} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', mt: 3 }}>
						<Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
							<Typography variant='h5'>{termData.term}</Typography>
							<Typography sx={{ letterSpacing: '.25px' }} variant='body1'>
								{termData.description}
							</Typography>
						</Box>
						{termData.type === 'submission' && (
							<Box sx={{ display: 'flex', mt: 5, flexDirection: 'row', justifyContent: 'center', width: '100%', gap: 2, flexWrap: 'wrap' }}>
								<Card
									sx={{
										width: isMobile ? '225px' : '250px',
										height: isMobile ? '250px' : '250px',
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										alignItems: 'center',
										textAlign: 'center',
										p: 1,
										boxSizing: 'border-box',
										borderRadius: '10px',
										background: darkMode ? 'rgb(18, 57, 44)' : 'rgb(228, 246, 221)',
									}}
								>
									<CardContent sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
										<Typography sx={{ fontSize: isMobile ? '2rem' : '3rem', fontFamily: 'verdana', flexShrink: 1 }} variant='h6'>
											{statData.count}
										</Typography>
										<Typography sx={{ fontSize: isMobile ? '.7rem' : '.9rem', fontWeight: '600', fontFamily: 'verdana', flexShrink: 1 }} variant='h6'>
											{termData.term.toUpperCase()} SEQUENCES
										</Typography>
										<Typography sx={{ pt: 1, fontSize: isMobile ? '.8rem' : '.9rem', fontWeight: '400', fontFamily: 'verdana', flexShrink: 1 }} variant='h6'>
											in OutlierDB
										</Typography>
									</CardContent>
								</Card>
								<Card
									sx={{
										width: isMobile ? '225px' : '250px',
										height: isMobile ? '250px' : '250px',
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										alignItems: 'center',
										textAlign: 'center',
										p: 1,
										boxSizing: 'border-box',
										borderRadius: '10px',
										background: darkMode ? 'rgb(18, 57, 44)' : 'rgb(228, 246, 221)',
									}}
								>
									<CardContent sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
										<Typography sx={{ fontFamily: 'verdana', flexShrink: 1 }} variant='h6'>
											SUBMISSIONS
										</Typography>
										<WikiPieChart percentage={stats?.percentage} term={termData.term} darkMode={darkMode} position={false} />
									</CardContent>
								</Card>
								<Card
									sx={{
										width: isMobile ? '225px' : '250px',
										height: isMobile ? '250px' : '250px',
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										alignItems: 'center',
										textAlign: 'center',
										p: 1,
										boxSizing: 'border-box',
										borderRadius: '10px',
										background: darkMode ? 'rgb(18, 57, 44)' : 'rgb(228, 246, 221)',
									}}
								>
									<CardContent sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
										<Typography sx={{ fontSize: isMobile ? '1.5rem' : '1.5rem', fontFamily: 'verdana', flexShrink: 1 }} variant='h6'>
											TOP ATHLETE
										</Typography>
										<Typography sx={{ pt: 1, fontSize: isMobile ? '.7rem' : '.9rem', fontWeight: '600', fontFamily: 'verdana', flexShrink: 1 }} variant='h6'>
											{stats?.topAthlete?._id.toUpperCase()}
										</Typography>
										<Typography sx={{ pt: 1, fontSize: isMobile ? '.8rem' : '.9rem', fontWeight: '400', fontFamily: 'verdana', flexShrink: 1 }} variant='h6'>
											{stats?.topAthlete?.count} {termData.term}'s in OutlierDB
										</Typography>
									</CardContent>
								</Card>
								<Card
									sx={{
										width: isMobile ? '225px' : '250px',
										height: isMobile ? '250px' : '250px',
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										alignItems: 'center',
										textAlign: 'center',
										p: 1,
										boxSizing: 'border-box',
										borderRadius: '10px',
										background: darkMode ? 'rgb(18, 57, 44)' : 'rgb(228, 246, 221)',
									}}
								>
									<CardContent sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
										<Typography sx={{ fontSize: isMobile ? '1.5rem' : '1.5rem', fontFamily: 'verdana', flexShrink: 1 }} variant='h6'>
											TOP POSITION
										</Typography>
										<Typography sx={{ pt: 1, fontSize: isMobile ? '.7rem' : '.9rem', fontWeight: '600', fontFamily: 'verdana', flexShrink: 1 }} variant='h6'>
											{stats?.topPositionPercentage}% <span style={{ fontWeight: '500' }}>of {termData.term}'s come from</span> {stats?.topPosition?._id.toUpperCase()}
										</Typography>
									</CardContent>
								</Card>
							</Box>
						)}

						{termData.type === 'position' && (
							<Box sx={{ display: 'flex', mt: 5, flexDirection: 'row', justifyContent: 'center', width: '100%', gap: 2, flexWrap: 'wrap' }}>
								<Card
									sx={{
										width: isMobile ? '225px' : '250px',
										height: isMobile ? '250px' : '250px',
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										alignItems: 'center',
										textAlign: 'center',
										p: 1,
										boxSizing: 'border-box',
										borderRadius: '10px',
										background: darkMode ? 'rgb(18, 57, 44)' : 'rgb(228, 246, 221)',
									}}
								>
									<CardContent sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
										<Typography sx={{ fontSize: isMobile ? '2rem' : '3rem', fontFamily: 'verdana', flexShrink: 1 }} variant='h6'>
											{statData.count}
										</Typography>
										<Typography sx={{ fontSize: isMobile ? '.7rem' : '.9rem', fontWeight: '600', fontFamily: 'verdana', flexShrink: 1 }} variant='h6'>
											{termData.term.toUpperCase()} SEQUENCES
										</Typography>
										<Typography sx={{ pt: 1, fontSize: isMobile ? '.8rem' : '.9rem', fontWeight: '400', fontFamily: 'verdana', flexShrink: 1 }} variant='h6'>
											in OutlierDB
										</Typography>
									</CardContent>
								</Card>
								<Card
									sx={{
										width: isMobile ? '250px' : '250px',
										height: isMobile ? '250px' : '250px',
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										alignItems: 'center',
										textAlign: 'center',
										p: 1,
										boxSizing: 'border-box',
										borderRadius: '10px',
										background: darkMode ? 'rgb(18, 57, 44)' : 'rgb(228, 246, 221)',
									}}
								>
									<CardContent sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
										<Typography sx={{ fontFamily: 'verdana', flexShrink: 1 }} variant='h6'>
											SUBMISSIONS
										</Typography>
										<WikiPieChart percentage={stats?.percentage} term={stats?.topPosition?._id} darkMode={darkMode} position={true} />
									</CardContent>
								</Card>
								<Card
									sx={{
										width: isMobile ? '225px' : '250px',
										height: isMobile ? '250px' : '250px',
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										alignItems: 'center',
										textAlign: 'center',
										p: 1,
										boxSizing: 'border-box',
										borderRadius: '10px',
										background: darkMode ? 'rgb(18, 57, 44)' : 'rgb(228, 246, 221)',
									}}
								>
									<CardContent sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
										<Typography sx={{ fontSize: isMobile ? '1.5rem' : '1.5rem', fontFamily: 'verdana', flexShrink: 1 }} variant='h6'>
											TOP ATHLETE
										</Typography>
										<Typography sx={{ pt: 1, fontSize: isMobile ? '.7rem' : '.9rem', fontWeight: '600', fontFamily: 'verdana', flexShrink: 1 }} variant='h6'>
											{stats?.topAthlete?._id.toUpperCase()}
										</Typography>
										<Typography sx={{ pt: 1, fontSize: isMobile ? '.8rem' : '.9rem', fontWeight: '400', fontFamily: 'verdana', flexShrink: 1 }} variant='h6'>
											{stats?.topAthlete?.count} submissions {stats?.topPosition?._id === 'N/A' ? 'found' : 'from'} {stats?.topPosition?._id === 'N/A' ? '' : stats?.topPosition?._id}
										</Typography>
									</CardContent>
								</Card>
								<Card
									sx={{
										width: isMobile ? '225px' : '250px',
										height: isMobile ? '250px' : '250px',
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										alignItems: 'center',
										textAlign: 'center',
										p: 1,
										boxSizing: 'border-box',
										borderRadius: '10px',
										background: darkMode ? 'rgb(18, 57, 44)' : 'rgb(228, 246, 221)',
									}}
								>
									<CardContent sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
										<Typography sx={{ fontSize: isMobile ? '1.5rem' : '1.5rem', fontFamily: 'verdana', flexShrink: 1 }} variant='h6'>
											TOP SUB
										</Typography>
										{stats?.topPosition?._id.toUpperCase() === 'N/A' ? (
											<>
												<Typography sx={{ pt: 1, fontWeight: '600', fontSize: isMobile ? '.7rem' : '.9rem', fontFamily: 'verdana', flexShrink: 1 }} variant='h6'>
													N/A
												</Typography>
												<Typography sx={{ pt: 1, fontSize: isMobile ? '.7rem' : '.9rem', fontFamily: 'verdana', flexShrink: 1 }} variant='h6'>
													0 submissions found
												</Typography>
											</>
										) : (
											<Typography sx={{ pt: 1, fontSize: isMobile ? '.7rem' : '.9rem', fontWeight: '600', fontFamily: 'verdana', flexShrink: 1 }} variant='h6'>
												{stats?.topPositionPercentage}%{' '}
												<span style={{ fontWeight: '500' }}>
													of {stats?.topPosition?._id.toUpperCase()} submissions are <span style={{ fontWeight: '600' }}>{stats?.termSubmissionsCount[0]?._id}</span>'s
												</span>
											</Typography>
										)}
									</CardContent>
								</Card>
							</Box>
						)}
						<Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: isMobile ? 2 : 8, mt: 5 }}>
							<Box sx={{ display: 'flex', flexDirection: ' column', width: isMobile ? '100%' : '25%' }}>
								<Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', minHeight: isMobile ? '100px' : '150px' }}>
									<Typography variant='h6' sx={{ my: 1, textAlign: isMobile ? 'center' : 'left' }}>
										Variations
									</Typography>
									{termData?.variations?.length > 0 ? (
										termData.variations.map((variation, index) => (
											<Typography
												key={index}
												sx={{
													ml: 2,
													fontFamily: 'monospace',
													my: 0.5,
													fontSize: '.9rem',
													cursor: 'pointer',
													textAlign: isMobile ? 'center' : 'left',
													textDecoration: 'underline',
													color: darkMode ? 'rgb(0,130,60)' : 'rgb(0, 109, 56)',
													':hover': { cursor: 'pointer', color: darkMode ? 'rgb(0,150,70)' : 'rgb(0,130,60)' },
												}}
												variant='body1'
												onClick={() => handleVariationClick(variation, termData.term)}
											>
												{formatVariation(variation)}
											</Typography>
										))
									) : (
										<Typography sx={{ ml: 2 }} variant='body1'>
											None found
										</Typography>
									)}
								</Box>
								<Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', minHeight: isMobile ? '100px' : '150px' }}>
									<Typography variant='h6' sx={{ my: 1, textAlign: isMobile ? 'center' : 'left' }}>
										Alternative Names
									</Typography>
									{termData?.alternativeNames?.length > 0 ? (
										<Typography sx={{ ml: 2, my: 0.5, textAlign: isMobile ? 'center' : 'left' }} variant='body1'>
											{termData.alternativeNames.join(', ')}
										</Typography>
									) : (
										<Typography variant='body1'>None found</Typography>
									)}
								</Box>
							</Box>
							<Box sx={{ display: 'flex', flexDirection: 'column', width: isMobile ? '100%' : '75%' }}>
								<Typography variant='h5'>Top {termData.term} Sequences</Typography>
								{topGroups.length > 0 && (
									<Box sx={{ mt: 2 }}>
										<GroupedSequences groups={topGroups} onGroupClick={onGroupClick} wiki={true} />
									</Box>
								)}
							</Box>
						</Box>
						<Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', mt: 4 }}>
							<Typography variant='h5'>Suggested Sequences</Typography>
							<Box sx={{ my: 4, display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
								{additionalSequences.length > 0 &&
									additionalSequences.map((sequence, index) => (
										<Box key={index} sx={{ display: 'flex', flexDirection: 'row', justifyContent: additionalSequences.length < 2 ? 'space-between' : 'space-evenly', width: '100%' }}>
											<FeaturedSequenceCard key={index} sequence={sequence} darkMode={darkMode} user={user} authUser={authUser} />
										</Box>
									))}
							</Box>
						</Box>

						<Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', mb: additionalResources.length < 1 ? 4 : 0 }}>
							{termData.type === 'position' && (
								<Box sx={{ display: 'flex', flexDirection: 'column', width: 'fit-content', mb: additionalResources.length < 1 ? 4 : 0 }}>
									<a
										href='/sequences'
										onMouseDown={(e) => {
											localStorage.setItem('seqTerm', JSON.stringify({ term: termData.term, starting: true }));
										}}
										style={{ textDecoration: 'none', marginBottom: '1rem' }}
									>
										<Typography
											variant='button'
											sx={{
												fontFamily: 'monospace',
												textDecoration: 'underline',
												pr: isMobile ? 1 : 0,
												fontWeight: 300,

												color: darkMode ? 'rgb(0,130,60)' : 'rgb(0, 109, 56)',
												':hover': { cursor: 'pointer', color: darkMode ? 'rgb(0,150,70)' : 'rgb(0,130,60)' },
											}}
										>
											SEE MORE SEQUENCES STARTING IN {termData.term}
										</Typography>
									</a>
									<a
										href='/sequences'
										onMouseDown={(e) => {
											localStorage.setItem('seqTerm', JSON.stringify({ term: termData.term, starting: false }));
										}}
										style={{ textDecoration: 'none' }}
									>
										<Typography
											variant='button'
											sx={{
												fontFamily: 'monospace',
												textDecoration: 'underline',
												pr: isMobile ? 1 : 0,
												fontWeight: 300,

												color: darkMode ? 'rgb(0,130,60)' : 'rgb(0, 109, 56)',
												':hover': { cursor: 'pointer', color: darkMode ? 'rgb(0,150,70)' : 'rgb(0,130,60)' },
											}}
										>
											SEE MORE SEQUENCES ENDING IN {termData.term}
										</Typography>
									</a>
								</Box>
							)}
							{termData.type === 'submission' && (
								<a
									href='/sequences'
									onMouseDown={(e) => {
										localStorage.setItem('seqTerm', JSON.stringify({ term: termData.term, starting: false }));
									}}
									style={{ textDecoration: 'none' }}
								>
									<Typography
										variant='button'
										sx={{
											fontFamily: 'monospace',
											textDecoration: 'underline',
											pr: isMobile ? 1 : 0,
											fontWeight: 300,

											color: darkMode ? 'rgb(0,130,60)' : 'rgb(0, 109, 56)',
											':hover': { cursor: 'pointer', color: darkMode ? 'rgb(0,150,70)' : 'rgb(0,130,60)' },
										}}
									>
										SEE MORE {termData.term.toUpperCase()} SEQUENCES
									</Typography>
								</a>
							)}
						</Box>

						{additionalResources.length > 0 && (
							<Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', mt: 4 }}>
								<Typography variant='h5'>Suggested Resources</Typography>
								<Box sx={{ my: 4, display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
									{additionalResources.map((resource, index) => (
										<Box key={index} sx={{ display: 'flex', flexDirection: 'row', justifyContent: additionalResources.length < 2 ? 'space-between' : 'space-evenly', width: '100%' }}>
											<FeaturedResourceCard key={index} resource={resource} darkMode={darkMode} user={user} authUser={authUser} />
										</Box>
									))}
								</Box>

								<Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', mb: 4 }}>
									<a
										href='/training-resources'
										onMouseDown={(e) => {
											localStorage.setItem('resourceWiki', termData.term);
										}}
										style={{ textDecoration: 'none' }}
									>
										<Typography
											variant='button'
											sx={{
												fontFamily: 'monospace',
												textDecoration: 'underline',
												pr: isMobile ? 1 : 0,
												fontWeight: 300,

												color: darkMode ? 'rgb(0,130,60)' : 'rgb(0, 109, 56)',
												':hover': { cursor: 'pointer', color: darkMode ? 'rgb(0,150,70)' : 'rgb(0,130,60)' },
											}}
										>
											SEE MORE RESOURCES
										</Typography>
									</a>
								</Box>
							</Box>
						)}
					</Grid>
				</>
			)}
		</Container>
	);
}

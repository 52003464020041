import React, { useState, useEffect } from 'react';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const SequencePositionVariations = () => {
	const [variations, setVariations] = useState([]);
	const [newVariation, setNewVariation] = useState('');
	const [newUpperVariation, setNewUpperVariation] = useState('');
	const [newLowerVariation, setNewLowerVariation] = useState('');
	const [editVariation, setEditVariation] = useState(null);
	const [error, setError] = useState('');

	useEffect(() => {
		fetchVariations();
	}, []);

	const fetchVariations = async () => {
		try {
			const response = await axios.get(`${API_URL}/sequence-position-variations`);
			setVariations(response.data);
		} catch (error) {
			setError('Failed to fetch sequence position variations');
		}
	};

	const handleAddVariation = async () => {
		try {
			const response = await axios.post(`${API_URL}/sequence-position-variations`, {
				positionType: newVariation,
				variations: { upper: [newUpperVariation], lower: [newLowerVariation] },
			});
			setVariations([...variations, response.data]);
			setNewVariation('');
			setNewUpperVariation('');
			setNewLowerVariation('');
		} catch (error) {
			setError('Failed to add sequence position variation');
		}
	};

	const handleEditVariation = async () => {
		try {
			const response = await axios.put(`${API_URL}/sequence-position-variations/${editVariation.id}`, editVariation);
			const updatedVariations = variations.map((variation) => {
				if (variation._id === editVariation.id) {
					return response.data;
				}
				return variation;
			});
			setVariations(updatedVariations);
			setEditVariation(null);
		} catch (error) {
			setError('Failed to edit sequence position variation');
		}
	};

	const handleDeleteVariation = async (id) => {
		try {
			await axios.delete(`${API_URL}/sequence-position-variations/${id}`);
			setVariations(variations.filter((variation) => variation._id !== id));
		} catch (error) {
			setError('Failed to delete sequence position variation');
		}
	};

	const handleEdit = (id) => {
		const variationToEdit = variations.find((variation) => variation._id === id);
		if (!variationToEdit) {
			return;
		}
		setEditVariation({
			id: variationToEdit._id,
			positionType: variationToEdit.positionType,
			variations: {
				upper: [...variationToEdit.variations.upper],
				lower: [...variationToEdit.variations.lower],
			},
		});
		window.scrollTo({
			top: 0,
			behavior: 'smooth', // Optional: Adds smooth scrolling animation
		});
	};

	const handleCancelEdit = () => {
		setEditVariation(null);
	};

	const formTitle = editVariation ? 'Edit Position and Variations' : 'Add New Position and Variations';

	const handleSubmit = editVariation ? handleEditVariation : handleAddVariation;

	return (
		<div>
			<h2>{formTitle}</h2>
			{error && <div>Error: {error}</div>}
			<form onSubmit={handleSubmit}>
				<label htmlFor='positionType'>Position Type</label>
				<input
					type='text'
					value={editVariation ? editVariation.positionType : newVariation}
					onChange={(e) => (editVariation ? setEditVariation({ ...editVariation, positionType: e.target.value }) : setNewVariation(e.target.value))}
					placeholder='Position Type'
					required
					style={{ width: '100%', display: 'block' }}
				/>
				<label htmlFor='upperVariations'>Upper Variations</label>
				<input
					type='text'
					value={editVariation ? editVariation.variations.upper.join(', ') : newUpperVariation}
					onChange={(e) =>
						editVariation ? setEditVariation({ ...editVariation, variations: { ...editVariation.variations, upper: e.target.value.split(', ') } }) : setNewUpperVariation(e.target.value)
					}
					placeholder='Upper Variations...comma separated'
					required
					style={{ width: '100%', display: 'block' }}
				/>
				<label htmlFor='lowerVariations'>Lower Variations</label>
				<input
					type='text'
					value={editVariation ? editVariation.variations.lower.join(', ') : newLowerVariation}
					onChange={(e) =>
						editVariation ? setEditVariation({ ...editVariation, variations: { ...editVariation.variations, lower: e.target.value.split(', ') } }) : setNewLowerVariation(e.target.value)
					}
					placeholder='Lower Variation...comma separated'
					required
					style={{ width: '100%', display: 'block' }}
				/>
				<button style={{ margin: '4px', padding: '4px', cursor: 'pointer' }} type='submit'>
					{editVariation ? 'Save' : 'Add Variation'}
				</button>
				{editVariation && (
					<button style={{ margin: '4px', padding: '4px', cursor: 'pointer' }} onClick={handleCancelEdit}>
						Cancel
					</button>
				)}
			</form>

			<ul>
				{variations.map((variation) => (
					<li key={variation._id}>
						<div>
							<strong>Position Type: </strong>
							{variation.positionType}
						</div>
						<div>
							<strong>Upper Variations:</strong>
							<ul>
								{variation.variations.upper.map((upperVar, index) => (
									<li key={index}>{upperVar}</li>
								))}
							</ul>
						</div>
						<div>
							<strong>Lower Variations:</strong>
							<ul>
								{variation.variations.lower.map((lowerVar, index) => (
									<li key={index}>{lowerVar}</li>
								))}
							</ul>
						</div>
						<button style={{ margin: '4px', padding: '4px', cursor: 'pointer' }} onClick={() => handleEdit(variation._id)}>
							Edit
						</button>
						<button style={{ margin: '4px', padding: '4px', cursor: 'pointer' }} onClick={() => handleDeleteVariation(variation._id)}>
							Delete
						</button>
					</li>
				))}
			</ul>
		</div>
	);
};

export default SequencePositionVariations;

// SequenceMovements.js
import { useState, useEffect } from 'react';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const SequenceMovements = () => {
	const [movements, setMovements] = useState([]);
	const [newMovement, setNewMovement] = useState('');
	const [editMovement, setEditMovement] = useState({ id: null, name: '' });
	const [error, setError] = useState('');

	useEffect(() => {
		fetchMovements();
	}, []);

	const fetchMovements = async () => {
		try {
			const response = await axios.get(`${API_URL}/sequence-movements`);
			setMovements(response.data);
		} catch (error) {
			setError('Failed to fetch movements');
		}
	};

	const handleAddMovement = async () => {
		try {
			const response = await axios.post(`${API_URL}/sequence-movements`, { name: newMovement });
			setMovements([...movements, response.data]);
			setNewMovement('');
		} catch (error) {
			setError('Failed to add movement');
		}
	};

	const handleEditMovement = async () => {
		try {
			const response = await axios.put(`${API_URL}/sequence-movements/${editMovement.id}`, { name: editMovement.name });
			const updatedMovements = movements.map((movement) => {
				if (movement._id === editMovement.id) {
					return response.data;
				}
				return movement;
			});
			setMovements(updatedMovements);
			setEditMovement({ id: null, name: '' });
		} catch (error) {
			setError('Failed to edit movement');
		}
	};

	const handleDeleteMovement = async (id) => {
		try {
			await axios.delete(`${API_URL}/sequence-movements/${id}`);
			setMovements(movements.filter((movement) => movement._id !== id));
		} catch (error) {
			setError('Failed to delete movement');
		}
	};

	return (
		<div>
			<h2>Sequence Movements</h2>
			{error && <div>Error: {error}</div>}
			<form onSubmit={handleAddMovement} style={{ margin: '1rem' }}>
				<input type='text' value={newMovement} onChange={(e) => setNewMovement(e.target.value)} placeholder='New Movement' required />
				<button type='submit'>Add Movement</button>
			</form>
			<ul>
				{movements.map((movement) => (
					<li key={movement._id}>
						{editMovement.id === movement._id ? (
							<div>
								<input type='text' value={editMovement.name} onChange={(e) => setEditMovement({ ...editMovement, name: e.target.value })} />
								<button style={{ padding: '4px', margin: '4px', cursor: 'pointer' }} onClick={handleEditMovement}>
									Save
								</button>
								<button style={{ padding: '4px', margin: '4px', cursor: 'pointer' }} onClick={() => setEditMovement({ id: null, name: '' })}>
									Cancel
								</button>
							</div>
						) : (
							<div>
								{movement.name}
								<button style={{ padding: '4px', margin: '4px', cursor: 'pointer' }} onClick={() => setEditMovement({ id: movement._id, name: movement.name })}>
									Edit
								</button>
								<button style={{ padding: '4px', margin: '4px', cursor: 'pointer' }} onClick={() => handleDeleteMovement(movement._id)}>
									Delete
								</button>
							</div>
						)}
					</li>
				))}
			</ul>
		</div>
	);
};

export default SequenceMovements;

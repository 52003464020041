import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const usePageViews = () => {
	const location = useLocation();

	useEffect(() => {
		if (window.gtag) {
			window.gtag('config', 'G-FFNL9S74VP', {
				page_path: location.pathname,
			});
		}
	}, [location]);
};

export default usePageViews;

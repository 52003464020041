// DatabasePage.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AlternativeNames from '../components/AlternativeNames';
import SequenceMovements from '../components/SequenceMovements';
import SequencePositions from '../components/SequencePositions';
import SequencePositionVariations from '../components/SequencePositionVariations';
import SubmissionOptions from '../components/SubmissionOptions';
import SubmissionPositionVariations from '../components/SubmissionPositionVariations';
import Head from '../components/Head/Head';

const DatabasePage = ({ user }) => {
	const navigate = useNavigate();
	const [selectedModel, setSelectedModel] = useState('alternativeNames');
	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth', // Optional: Adds smooth scrolling animation
		});
	};

	useEffect(() => {
		if (user?.role !== 'admin') {
			navigate('/account');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div style={{ padding: '2rem' }}>
			<Head title='DB Terms' />
			<div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
				<button style={{ color: 'rgb(47,180,112)', padding: '4px', cursor: 'pointer' }} onClick={() => setSelectedModel('alternative-names')}>
					Alternative Names
				</button>
				<button style={{ color: 'rgb(47,180,112)', padding: '4px', cursor: 'pointer' }} onClick={() => setSelectedModel('sequence-movements')}>
					Sequence Movements
				</button>
				<button style={{ color: 'rgb(47,180,112)', padding: '4px', cursor: 'pointer' }} onClick={() => setSelectedModel('sequence-positions')}>
					Sequence Positions
				</button>
				<button style={{ color: 'rgb(47,180,112)', padding: '4px', cursor: 'pointer' }} onClick={() => setSelectedModel('sequence-position-variations')}>
					Sequence Position Variations
				</button>
				<button style={{ color: 'rgb(47,180,112)', padding: '4px', cursor: 'pointer' }} onClick={() => setSelectedModel('submission-options')}>
					Submission Options
				</button>
				<button style={{ color: 'rgb(47,180,112)', padding: '4px', cursor: 'pointer' }} onClick={() => setSelectedModel('submission-position-variations')}>
					Submission Position Variations
				</button>
			</div>
			{selectedModel === 'alternative-names' && <AlternativeNames />}
			{selectedModel === 'sequence-movements' && <SequenceMovements />}
			{selectedModel === 'sequence-positions' && <SequencePositions />}
			{selectedModel === 'sequence-position-variations' && <SequencePositionVariations />}
			{selectedModel === 'submission-options' && <SubmissionOptions />}
			{selectedModel === 'submission-position-variations' && <SubmissionPositionVariations />}

			<button style={{ margin: '1rem', cursor: 'pointer' }} onClick={() => scrollToTop()}>
				Return to top of page
			</button>
		</div>
	);
};

export default DatabasePage;

const secondsToMMSS = (seconds) => {
	const hours = Math.floor(seconds / 3600);
	const remainingSecondsAfterHours = seconds % 3600;
	const minutes = Math.floor(remainingSecondsAfterHours / 60);
	const remainingSeconds = remainingSecondsAfterHours % 60;

	if (hours > 0) {
		return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
	} else {
		return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
	}
};

const formatMovementText = (sequence) => {
	const player1Movements = sequence.player1Movement1 || sequence.player1Movement2 ? `${sequence.player1Movement1 || ''} ${sequence.player1Movement2 ? `/ ${sequence.player1Movement2}` : ''}` : '';

	const player2Movements = sequence.player2Movement1 || sequence.player2Movement2 ? `${sequence.player2Movement1 || ''} ${sequence.player2Movement2 ? `/ ${sequence.player2Movement2}` : ''}` : '';

	if (player1Movements && player2Movements) {
		return `${player1Movements} : ${player2Movements}`;
	} else {
		return player1Movements || player2Movements || 'N/A';
	}
};

const formatVariations = (variation1, variation2, upperVariation1, upperVariation2) => {
	// Create an array to hold the variations
	const variations = [];

	// Add lower variations if they exist
	if (variation1) variations.push(variation1);
	if (variation2) variations.push(variation2);

	// Add upper variations if they exist
	if (upperVariation1) variations.push(upperVariation1);
	if (upperVariation2) variations.push(upperVariation2);

	// Join the variations with a slash if more than one variation is present
	return variations.join(' / ') || 'N/A'; // Returns 'N/A' if no variations are present
};

module.exports = {
	secondsToMMSS,
	formatMovementText,
	formatVariations,
};

import React from 'react';
import { DialogContentText } from '@mui/material';

function TermsAndConditions() {
    return (
        <DialogContentText id="alert-dialog-description">
            <h4>Terms of Use</h4>
            <p>These Terms of Use govern your access and use of the Outlier Database website and any
                related sites (collectively, the “Outlier Database Site”). Please read these Terms of Use carefully
                before using the Outlier Database Site or opening a Outlier Database account (“Account”) as
                they govern your use of the information, tools, features, and functionality provided through the
                Outlier Database Site and constitute an agreement concerning your legal rights and obligations
                with respect to Outlier Database LLC, its affiliates, subsidiaries, divisions, contractors, including
                all data sources, marketing partners and third party</p>
            <br />
            <p>By using the Outlier Database Site, you agree to these Terms of Use. If you do not agree to
                these Terms of Use, you may not use the Outlier Database Site.</p>
            <br />
            <p><strong>Eligibility</strong></p>
            <p>THIS WEBSITE, SUBSCRIPTION SERVICES, AND THE PRODUCT ARE OFFERED AND
                AVAILABLE TO USERS WHO ARE 18 YEARS OF AGE OR OLDER. IF YOU ARE UNDER
                AGE 18 YOU MUST HAVE YOUR PARENT OR LEGAL GUARDIAN'S PERMISSION TO USE
                THE SERVICES, AS WELL AS HAVE HIM OR HER READ AND AGREE TO THESE TERMS.
                BY USING THE WEBSITE, AND/OR THE PRODUCT, YOU REPRESENT, ACKNOWLEDGE,
                AND AGREE THAT YOU ARE AT LEAST 18 YEARS OF AGE AND AGREE TO THESE
                TERMS OF USE. IF YOU DO NOT MEET THESE REQUIREMENTS, YOU MUST NOT
                ACCESS OR USE THE WEBSITE, AND/OR THE PRODUCT. IF YOU ARE ENTERING INTO
                THESE LICENSE TERMS ON BEHALF OF A COMPANY, BUSINESS, OR OTHER LEGAL
                ENTITY, YOU REPRESENT THAT YOU HAVE THE AUTHORITY TO BIND SUCH ENTITY TO
                THESE LICENSE TERMS. IF YOU DO NOT HAVE SUCH AUTHORITY, OR IF YOU OR YOUR
                ENTITY DO NOT AGREE WITH THESE TERMS OF USE, YOU MUST NOT ACCEPT THESE
                TERMS OF USE AND MAY NOT USE ANY OF THE SERVICES, THE PRODUCT, AND/OR
                THE WEBSITE. AS A COMPANY, BUSINESS, OR OTHER LEGAL ENTITY, YOU AGREE TO
                THESE TERMS OF USE ON BEHALF OF YOUR ENTITY AND ALL AUTHORIZED USERS
                UNDER YOUR ENTITY'S ACCOUNT, WHETHER INTERNAL OR EXTERNAL, AND YOU AND
                YOUR ENTITY ARE RESPONSIBLE FOR SUCH AUTHORIZED USERS AND THEIR
                COMPLIANCE WITH THESE TERMS OF USE. YOU AND YOUR ENTITY ARE
                RESPONSIBLE FOR ENSURING THAT ALL OF YOUR AUTHORIZED USERS, WHETHER
                INTERNAL OR EXTERNAL, UNDERSTAND THESE TERMS OF USE AND AGREE TO ABIDE
                BY THESE TERMS OF USE. ANY BREACH OF THESE LICENSE TERMS BY ANY OF YOUR
                AUTHORIZED USERS WILL BE DEEMED A BREACH BY YOUR ENTITY. IF YOU ARE AN
                ACCOUNT ADMINISTRATOR FOR A COMPANY, BUSINESS, OR OTHER LEGAL ENTITY,
                YOU AGREE THAT YOU HAVE ALL NECESSARY RIGHTS TO PROVIDE US WITH ANY
                PERSONAL DATA, IF ANY, OF YOU OR YOUR ENTITY'S AUTHORIZED USERS UNDER
                SUCH ENTITY'S ACCOUNT.</p>
            <br />
            <p>
                <strong>Ownership; Intellectual Property; Proprietary Rights</strong>
            </p>
            <p>
                The Website and all of its content, features, and functionality (including, but not limited to, all
                information, software text, displays, images, video, and audio, and the design and arrangement
                thereof) are owned by Outlier Database, its licensors, or other providers of such material and
                are all protected by copyright, trademark, patent, trade secret, and other intellectual property or
                proprietary rights laws. Except as otherwise required or limited by applicable law, any
                reproduction, distribution, modification, retransmission, or publication of any copyrighted
                material is strictly prohibited without the express written consent of the copyright owner. Outlier
                Database, its logo, and all related names, logos, product and service names, designs, and
                slogans are trademarks of Outlier Database or its affiliates or licensors. You must not use such
                marks without the prior written permission of the Outlier Database. All other names, logos,
                product and service names, designs, and slogans on this Website are the trademarks of their
                respective owners.
            </p>
            <br />
            <p>
                You acknowledge and agree that by providing Outlier Database with any personal or proprietary
                information through the Outlier Database Site, you consent to the lawful transmission of such
                personal or proprietary information over international borders as necessary for processing in
                accordance with Outlier Database's standard business practices and these Terms of Use.
            </p>
            <br />
            <p>By posting your content you are granting Outlier Database, to the extent lawfully permissible, a
                non-exclusive, royalty-free, perpetual, and worldwide license to use your content and your
                association to such content in connection with the operation of the Services, including, without
                limitation, the license rights to copy, distribute, transmit, publicly display, publicly perform,
                reproduce, edit, modify, delete, translate and reformat your content, and/or to incorporate such
                content and your association with such content into a collective work, which may include name
                and likeness.</p>
            <br />
            <p>
                To the extent lawfully permissible, you acknowledge, consent and agree that Outlier Database
                shall also have the right to access, preserve and disclose your account information and content
                if required to do so by law or in a good faith belief that such access preservation or disclosure is
                reasonably necessary to: (a) comply with legal process; (b) enforce these Terms; (c) respond to
                claims that any content violates the rights of third parties; (d) respond to your requests for
                customer service; or (e) protect the rights, property or personal safety of Outlier Database, its
                users and the public.
            </p>
            <br />
            <p>To the extent lawfully permissible, you grant Outlier Database permission to use your name for
                attribution purposes. You, likewise, agree to represent yourself accurately. You acknowledge
                that misrepresentation may lead Outlier Database, at its sole discretion, to cancel your use of
                the Services and delete any of your content.</p>
            <br />
            <p>You will be responsible for the content of any material you enter on the Outlier Database Site.
                Outlier Database retains the right, which it may or may not exercise in its sole discretion, to
                review, edit or delete any material that Outlier Database deems to be illegal, offensive or
                otherwise inappropriate.</p>
            <br />
            <p>You understand that the technical processing and transmission of the Outlier Database Site,
                including your Content, may involve (a) transmissions over various networks; and (b) changes
                to conform and adapt to technical requirements of connecting networks or devices.</p>
            <br />
            <p><strong>Jurisdiction</strong></p>
            <p>Outlier Database is based in the United States. We make no claims that the Services, Product,
                or the Website or any of their content is accessible or appropriate outside of the United States.
                Access to the Website, Product, or the Services may not be legal by certain persons or in
                certain countries. If you access the Website, Product, or the Services from outside the United
                States, you do so on your own initiative and are responsible for compliance with local laws.</p>
            <br />
            <p><strong>Use Restrictions; Suspension; Termination</strong></p>
            <p>You agree not to reproduce, copy, retransmit, distribute, disseminate, sell, sub-license, publish,
                broadcast, or circulate the information received through the Service to anyone without the
                express prior written consent of Outlier Database. You may not: (a) use or permit the use of the
                Service to prepare an original database or a comparison to other databases that are sold,
                rented, published, or furnished in any manner to a third party; (b) use or permit the use of the
                Service for the purpose of compiling, enhancing, verifying, supplementing, adding to, or deleting
                from any mailing list, business directory, or other compilation of information that is sold, rented,
                published, or furnished in any manner to a third party; (c) use or permit the use of the
                information in connection with any individual credit, employment, or insurance applications; or
                (d) voluntarily produce the Service in legal proceedings without Outlier Database's prior written
                approval. Systematic access or extraction of content from this Outlier Database Site, including
                the use of “bots” or “spiders,” is prohibited. Outlier Database prohibits caching, unauthorized
                hypertext links to this Outlier Database Site and the framing of any content available through the
                Outlier Database Site. Outlier Database reserves the right to disable any unauthorized links or
                frames.</p>
            <br />
            <p>Access to and use of password protected and/or secure areas of this Outlier Database Site is
                restricted to authorized users only. Unauthorized individuals attempting to access these areas of
                the Outlier Database Site may be subject to prosecution.</p>
            <br />
            <p>No part of the Outlier Database Site may be reproduced, modified, or distributed in any form or
                manner without the prior written permission of Outlier Database; provided no such permission is
                necessary in connection with the fair use of excerpts of freely available (i.e., available to any
                visitor to the Outlier Database Site without any requirement of registration or payment of fees)
                factual information on the Outlier Database Site regarding descriptions of Outlier Database and
                its products and services.</p>
            <br />
            <p><strong>Accounts and Security</strong></p>
            <p>To access and use many of the Outlier Database Services, Information and/or Software, you
                must access our Website and register with us to open an Account. As part of the registration
                process, each user will submit his or her email address and select a password. You shall
                provide us with accurate, complete, and updated Account information. Failure to do so shall
                constitute a breach of this Agreement, which may result in immediate termination of your
                Account. You agree that you will not (i) select or use the email address of another person with
                the intent to impersonate that person; (ii) use a name subject to the rights of any other person
                without authorization; (iii) use an email address that Outlier Database, in its sole discretion,
                deems inappropriate or offensive; or (iv) breach any representation, warranty or promise made
                by you in this Agreement regarding your Account.</p>
            <br />
            <p>You agree to immediately notify us of any known or suspected unauthorized use(s) of your
                Account, or any known or suspected breach of security, including loss, theft, or unauthorized
                disclosure of your password. You shall be responsible for maintaining the confidentiality of your
                password. It is your sole responsibility to protect your password and not share your password
                with any other people. Accordingly, you understand and agree that you shall be liable for any
                activity performed by others using the Site, your email address and password. We are not
                responsible for any loss or damage arising from your failure to maintain the confidentiality of
                your password.</p>
            <br />
            <p>We may immediately terminate your Account, or suspend your access to your Account, in our
                sole discretion and, without notice, for conduct that we believe is: (i) illegal, fraudulent,

                harassing or abusive; (ii) a violation of this Agreement or any other policies or guidelines posted
                by Outlier; or (iii) harmful to other users, third parties, or the business interests of Outlier. Use of
                an Account for illegal, fraudulent or abusive purposes may be referred to law enforcement
                authorities without notice to you. If you file a claim against Outlier, or a claim which in any way
                involves Outlier, then we may terminate your Account. Upon termination of your Account by us
                for any of the above-mentioned reasons, (a) you may not establish a new Account for a period
                of one year (or such other duration as we may determine in our sole discretion) from the date of
                termination, (b) we will have no obligation to notify any third parties regarding such termination,
                and (c) you will be responsible for any damages that may result or arise out of termination of
                your Account.</p>
            <br />
            <p>You may only use the Website and/or open an Account if your applicable jurisdiction allows you
                to accept the terms set forth in this Agreement in accordance with the acceptance procedures
                adopted by us.</p>
            <br />
            <p><strong>Content Restrictions</strong></p>
            <p>You will not upload, post, transmit or otherwise make available any Content that: (a) is unlawful,
                harmful, threatening, abusive, harassing, tortuous, defamatory, vulgar, obscene, pornographic,
                libelous, invasive of another&#39;s privacy, hateful, or racially, ethnically or otherwise objectionable;
                (b) harm minors in any way; (c) impersonate any person or entity, including, but not limited to, a
                Outlier Database official, forum leader, guide, moderator or host, or falsely state or otherwise
                misrepresent your affiliation with a person or entity; (d) forge headers or otherwise manipulate
                identifiers in order to disguise the origin of any Content transmitted through the Outlier Database
                Site; (e) remove any proprietary notice from the Outlier Database Site; (f) cause, permit or
                authorize the modification, create of derivative works, or translation of the Outlier Database Site
                without our prior written permission; (g) use the Outlier Database Site for any commercial
                purpose or the benefit of any third party or any manner not permitted by the licenses granted
                hereunder; (h) use the Outlier Database Site for fraudulent purposes; (i) attempt to decompile,
                reverse engineer, disassemble or hack the Outlier Database Site or any of the software
                embedded or included therein, or to defeat or overcome any encryption technology or security
                measures implemented by Outlier Database with respect to the Outlier Database Site, or any of
                the Outlier products and/or data transmitted, processed or stored by Outlier Database; (j)
                harvest or collect any information about or regarding other Account holders, including but not
                limited to any personal data or information; (k) upload, post, email, transmit, or otherwise make
                available any content that you do not have a right to transmit under any law or under contractual
                or fiduciary relationships (such as inside information, proprietary and confidential information
                learned or disclosed as part of employment relationships or under nondisclosure agreements);
                (l) upload, post, email, transmit or otherwise make available any content that infringes any
                patent, trademark, trade secret, copyright or other proprietary rights of any party; (m) upload,
                post, email, or otherwise transmit any material that contains software viruses or any other
                computer code, files or programs designed to interrupt, destroy or limit the functionality of any
                computer software or hardware or telecommunications equipment; (o) disrupt the normal flow of
                dialogue, cause a screen to “scroll” faster than other users of the Outlier Database Site are able
                to type, or otherwise act in a manner that negatively affects other users' ability to engage in real-
                time exchanges; (p) interfere with or disrupt the Outlier Database Site or servers or networks
                connected to or operated with the Outlier Database Site, or disobey any requirements,
                procedures, policies, or regulations of networks connected to or operated with the Outlier
                Database Site; (q) intentionally or unintentionally violate any applicable local, state, national or
                international law, including, but not limited to, regulations promulgated by the U.S. Securities
                and Exchange Commission, any rules of any national or other securities exchange, including,
                without limitation, the New York Stock Exchange, the American Stock Exchange or the
                NASDAQ, and any regulations having the force of law; (r) &quot;stalk&quot; or otherwise harass another;
                (s) promote or provide instructional information about illegal activities, promote physical harm or
                injury against any group or individual, or promote any act of cruelty to animals; (t) use the
                Outlier Database Site to provide material support or resources (or to conceal or disguise the
                nature, location, source, or ownership of material support or resources) to any organization(s)
                designated by the United States government as a foreign terrorist organization pursuant to
                section 219 of the Immigration and Nationality Act.</p>
            <br />
            <p>You may not use the Outlier Database name to endorse or promote any product, opinion, cause
                or political candidate. Representation of your personal opinions as endorsed by Outlier
                Database is strictly prohibited.</p>
            <br />
            <p>We reserve the right to refuse to provide you access to the Outlier Database Site or to allow you
                to open an Account for any reason.</p>
            <br />
            <p><strong>User Warranties and Indemnification</strong></p>
            <p>By posting content to the Outlier Database Site, you warrant that you either own or otherwise
                control all of the rights to that content, including, without limitation, all the rights necessary for
                you to provide, post, upload, input or submit the content, or that your use of the content is a
                protected fair use. You agree that you will not knowingly and with intent to defraud provide
                material and misleadingly false information. You warrant also that the content you supply does
                not violate these Terms of Use, and that you will indemnify and hold Outlier Database harmless
                for any and all claims resulting from content you supply.</p>
            <br />
            <p><strong>General Disclaimers; Disclaimer of Warranties and Liability</strong></p>
            <p>THE OUTLIER DATABASE SITE MAY PROVIDE A LINK TO OTHER WEBSITES BY
                ALLOWING THE USER TO LEAVE THE OUTLIER DATABASE SITE TO ACCESS THIRD-
                PARTY MATERIAL OR BY BRINGING THE THIRD-PARTY MATERIAL INTO THIS OUTLIER
                DATABASE SITE VIA &quot;INVERSE&quot; HYPERLINKS AND FRAMING TECHNOLOGY (A &quot;LINKED
                SITE&quot;). OUTLIER DATABASE HAS NO DISCRETION OR OBLIGATION TO ALTER, UPDATE,
                OR CONTROL THE CONTENT ON A LINKED SITE. USER SHOULD BE AWARE THAT
                LINKED SITES MAY CONTAIN TRANSMISSION OF PERSONAL DATA PROVISIONS THAT
                DIFFER FROM THE PROVISIONS PROVIDED HEREIN. OUTLIER DATABASE IS NOT
                RESPONSIBLE FOR SUCH PROVISIONS, AND EXPRESSLY DISCLAIMS ANY AND ALL
                LIABILITY RELATED TO SUCH PROVISIONS.</p>
            <br />
            <p><strong>Outlier Database does not warrant the accuracy, completeness or timeliness of any of the Services.</strong> ALL SERVICES ON THIS OUTLIER DATABASE SITE, OR A LINKED SITE, ARE
                PROVIDED ON AN &quot;AS IS,&quot; &quot;AS AVAILABLE&quot; BASIS. OUTLIER DATABASE DISCLAIMS ALL
                WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING WITHOUT
                LIMITATION, ANY WARRANTIES OF ACCURACY, COMPLETENESS, CURRENTNESS,
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUALITY, NON-
                INFRINGEMENT, AND THOSE ARISING FROM COURSE OF DEALING OR USAGE OF
                GRADE OR FROM OUTLIER DATABASE'S CONDUCT IN COLLECTING, COMPILING, OR
                INTERPRETING ANY DATA OR INFORMATION. OUTLIER DATABASE DOES NOT MAKE
                ANY WARRANTY AS TO THE ACCURACY, COMPLETENESS, TIMELINESS OR
                RELIABILITY OF THE OUTLIER DATABASE SITE OR ITS ONLINE BUSINESS
                DIRECTORIES. OUTLIER DATABASE DOES NOT WARRANT THAT YOU WILL BE ABLE TO

                ACCESS OR USE THE OUTLIER DATABASE SITE OR USE THE SERVICE AT THE TIMES
                OR LOCATIONS OF YOUR CHOOSING. OUTLIER DATABASE DOES NOT WARRANT THAT
                THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE; THAT DEFECTS WILL BE
                CORRECTED; OR THAT THE OUTLIER DATABASE SITE OR THE SERVICE ARE FREE OF
                VIRUSES OR OTHER HARMFUL COMPONENTS.</p>
            <br />
            <p>YOU ACKNOWLEDGE THAT THE ENTIRE RISK ARISING OUT OF THE USE OR
                PERFORMANCE OF THE SITE REMAINS WITH YOU TO THE MAXIMUM EXTENT
                PERMITTED BY LAW.</p>
            <br />
            <p>TO THE MAXIMUM EXTENT PERMITTED BY LAW, OUTLIER DATABASE, ITS LICENSORS
                AND BUSINESS PARTNERS AND THEIR RESPECTIVE DIRECTORS, OFFICERS,
                EMPLOYEES AND AGENTS (COLLECTIVELY, THE “RELATED PARTIES”) WILL NOT BE
                LIABLE FOR ANY LOSS OR INJURY ARISING OUT OF, IN WHOLE OR IN PART, OUTLIER
                DATABASE&#39;S CONDUCT IN PROCURING, COMPILING, COLLECTING, INTERPRETING,
                REPORTING OR DELIVERING SERVICES. OUTLIER DATABASE AND THE RELATED
                PARTIES WILL NOT BE LIABLE, WHETHER IN CONTRACT, TORT, STRICT LIABILITY OR
                OTHERWISE, FOR ANY DIRECT, PUNITIVE, SPECIAL, CONSEQUENTIAL, INCIDENTAL OR
                INDIRECT DAMAGES (INCLUDING WITHOUT LIMITATION LOST PROFITS, LOST DATA,
                LOST REVENUES, LOSS OF BUSINESS OPPORTUNITY, AND THE COST OF PROCURING
                SUBSTITUTE SERVICE OR LOST OPPORTUNITY) ARISING OUT OF OR IN CONNECTION
                WITH THE USE OF THE OUTLIER DATABASE SITE OR A LINKED SITE, OR WITH THE
                DELAY OR INABILITY TO USE THE OUTLIER DATABASE SITE OR A LINKED SITE, EVEN IF
                OUTLIER DATABASE AND THE RELATED PARTIES IS MADE AWARE OF THE POSSIBILITY
                OF SUCH DAMAGES. THIS LIMITATION ON LIABILITY INCLUDES, BUT IS NOT LIMITED
                TO, THE TRANSMISSION OF ANY VIRUSES WHICH MAY INFECT A USER&#39;S EQUIPMENT,
                FAILURE OF MECHANICAL OR ELECTRONIC EQUIPMENT OR COMMUNICATION LINES,
                TELEPHONE OR OTHER INTERCONNECT PROBLEMS (e.g., YOU CANNOT ACCESS
                YOUR INTERNET SERVICE PROVIDER), UNAUTHORIZED ACCESS, THEFT, OPERATOR
                ERRORS, STRIKES OR OTHER LABOR PROBLEMS OR ANY FORCE MAJEURE. YOUR
                SOLE REMEDY WITH RESPECT TO ANY PROBLEMS OR DISSATISFACTION WITH THE
                Outlier DATABASE SITE IS TO DISCONTINUE ANY USE OF THE OUTLIER DATABASE SITE.</p>
            <br />
            <p>The opinions and information provided by Outlier Database in articles and blog posts
                (collectively the “Marketing Information”) are provided “as-is”. Nothing stated or implied in the
                Marketing Information should be construed to be legal, tax, or professional advice. Outlier
                Database makes no representations or warranties, express or implied, with respect to such
                Marketing Information or the results of the use or reliance on such Marketing Information.
                Please contact an attorney or tax professional if you are in need of legal or tax advice.</p>
            <br />
            <p>You acknowledge that Outlier Database may or may not pre-screen or regularly review posted
                content, but that we and our designees shall have the right (but not the obligation) to remove in
                our sole discretion any content that we consider to violate these Terms of Use. You agree that
                you must evaluate, and bear all risks associated with, the use of any Content, including any
                reliance on the accuracy, completeness, or usefulness of such Content. In this regard, you
                acknowledge that you may not rely on any Content created by us or submitted to us, including
                without limitation information in Outlier Database message boards, directories, and in all other
                parts of the Outlier Database Site.</p>
            <br />
            <p>Some jurisdictions do not allow the disclaimer of implied warranties, so said implied disclaimers
                may not apply to you. Some jurisdictions do not allow the limitation of liability, so the foregoing
                limitation may not apply to you. In such states or jurisdictions, the liability of Outlier Database
                and its Related Parties shall be limited to the fullest extent permitted by law.</p>
            <br />
            <p><strong>Consent; Marketing; and Privacy Rights</strong></p>
            <p>BY ACCEPTING THE TERMS OF THIS AGREEMENT, YOU EXPRESSLY AUTHORIZE
                OUTLIER TO CONTACT YOU IN ANY LAWFUL MANNER, INCLUDING WHERE LEGALLY
                PERMITTED THROUGH THE USE OF AUTOMATIC AND/OR COMPUTERIZED DIALING
                SYSTEMS AND PRE-RECORDED MESSAGE AND CALL TECHNOLOGIES, AND FOR ANY
                LAWFUL PURPOSE, INCLUDING, BUT NOT LIMITED TO, ACCOUNT SUPPORT OR TO
                INFORM YOU OF OR PROMOTE OR MARKET OUTLIER PRODUCTS AND THIRD PARTY
                SERVICES WHICH OUTLIER BELIEVES MAY BE OF INTEREST TO YOU, AND YOU
                HEREBY UNAMBIGUOUSLY AGREE AND EXPRESSLY CONSENT TO RECEIVE SUCH
                MAILERS, EMAILS, TEXTS AND TELEPHONE CALLS.</p>
            <br />
            <p>YOU EXPRESSLY CONSENT TO BE CONTACTED AT THE ADDRESSES, TELEPHONE
                NUMBERS (INCLUDING MOBILE OR WIRELESS NUMBERS), AND EMAIL ADDRESSES
                YOU PROVIDE TO Outlier (EITHER VERBALLY OR WRITTEN) AS CONTAINED IN YOUR
                OUTLIER SERVICES, INFORMATION AND/OR SOFTWARE. YOU REPRESENT AND
                WARRANT THAT ANY MOBILE OR WIRELESS TELEPHONE NUMBER YOU PROVIDE
                BELONGS TO YOU AND IS ASSOCIATED WITH A MOBILE DEVICE IN YOUR
                POSSESSION. YOU HEREBY AGREE TO NOTIFY OUTLIER AT YOUR EARLIEST
                CONVENIENCE IF YOUR MOBILE OR WIRELESS TELEPHONE NUMBER CHANGES.</p>
            <br />
            <p>YOUR CONSENT TO THE TERMS OF THIS SECTION IS NOT REQUIRED, DIRECTLY OR
                INDIRECTLY, AS A CONDITION OF PURCHASING ANY GOODS OR SERVICES,
                INCLUDING INFORMATION OR SOFTWARE, FROM OUTLIER. SHOULD YOU NOT WISH
                TO CONSENT TO BEING CONTACTED FOR EITHER OR BOTH ACCOUNT SUPPORT OR
                PROMOTIONAL PURPOSES, OR TO REVOKE A CONSENT PREVIOUSLY GIVEN, YOU
                AGREE TO CONTACT US USING ONE OF THE FOLLOWING METHODS, OR ANOTHER
                METHOD THAT OUTLIER HAS SPECIFIED, TO CLEARLY INFORM OUTLIER OF YOUR
                DESIRE TO REVOKE CONSENT.</p>
            <br />
            <p>Under California Civil Code Section 1789.3, certain residents of California who hold an Account
                may be entitled to certain specific information. If you are unable to receive the information you
                are seeking, or resolve a complaint you may have regarding the services available through the
                Site, you may contact the Complaint Assistance Unit of the Division of Consumer Services of
                the Department of Consumer Affairs in writing at 1625 North Market Blvd., Suite North 112,
                Sacramento, California 95834, or by telephone at 800-952-5210.</p>
            <br />
            <p><strong>Amendments and Modifications</strong></p>
            <p>Outlier Database has the right to amend these Terms of Use at any time and without notice to
                you. Such amendments shall be effective immediately upon posting. You agree to review the
                Outlier Database Site periodically to be aware of any such amendments. Your continued use of
                an Outlier Database Site after such amendments have been made shall constitute acceptance
                of the amendments.</p>
        </DialogContentText>
    )
}

export default TermsAndConditions;
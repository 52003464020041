import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Triangle } from 'react-loader-spinner';
import NewWeeklyFeature from '../components/NewWeeklyFeature';
import Head from '../components/Head/Head';

const API_URL = process.env.REACT_APP_API_URL;

export default function WeeklyFeatures({ darkMode }) {
	const [loading, setLoading] = useState(true);
	const [features, setFeatures] = useState([]);
	const [newFeature, setNewFeature] = useState(false);

	useEffect(() => {
		const fetchFeatures = async () => {
			try {
				const response = await axios.get(`${API_URL}/featured/all`);

				if (response.data.length > 0) {
					setFeatures(response.data);
				}
			} catch (error) {
				console.error('Error fetching features:', error);
			} finally {
				setLoading(false);
			}
		};

		fetchFeatures();
	}, [newFeature]);

	if (loading) {
		return (
			<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
				<Head title='Weekly Features' />
				<Triangle color='rgb(47, 199, 112)' height={100} width={100} />
			</div>
		);
	}

	return (
		<div style={{ padding: '2rem' }}>
			<Head title='Weekly Features' />
			<h1 style={{ textAlign: 'center', fontSize: '1.5rem', marginTop: '1rem' }}>Weekly Features</h1>
			<NewWeeklyFeature darkMode={darkMode} setNewFeature={setNewFeature} />

			{features.map((feature, index) => (
				<div key={index}>
					<h2>Match Details</h2>
					<p>
						<strong>Match:</strong> {feature.match.match}
					</p>
					<p>
						<strong>Winner:</strong> {feature.match.matchWinner}
					</p>
					<p>
						<strong>YouTube Link:</strong>{' '}
						<a href={feature.match.youtubeLink} target='_blank' rel='noopener noreferrer'>
							{feature.match.youtubeLink}
						</a>
					</p>

					{feature.sequences.length > 0 && (
						<div>
							<h3>Sequences</h3>
							{feature.sequences.map((seq, idx) => (
								<div key={idx}>
									<p>
										<strong>Type:</strong> {seq.sequenceType}
									</p>
									<p>
										<strong>Player:</strong> {seq.sequencePlayer}
									</p>
								</div>
							))}
						</div>
					)}

					{feature.resources.length > 0 ? (
						<div>
							<h3>Resources</h3>
							{feature.resources.map((res, idx) => (
								<div key={idx}>
									<p>
										<strong>Title:</strong> {res.title}
									</p>
									<p>
										<strong>URL:</strong>{' '}
										<a href={res.url} target='_blank' rel='noopener noreferrer'>
											{res.url}
										</a>
									</p>
								</div>
							))}
						</div>
					) : (
						<h3>No resources</h3>
					)}
				</div>
			))}
		</div>
	);
}

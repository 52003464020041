// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { GoogleAuthProvider, signInWithPopup, signOut, getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSING_SENDER_ID,
	appId: process.env.REACT_APP_APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Config the provider - Google
const provider = new GoogleAuthProvider();
// Create a reference to our firebase authentication instance
const auth = getAuth(app);

// Config login and logout workflows
function login() {
	return signInWithPopup(auth, provider);
}

async function logout(navigate) {
	await signOut(auth);
	localStorage.clear();
	navigate('/');
}

// Function to sign up with email and password
function signUpWithEmail(email, password) {
	return createUserWithEmailAndPassword(auth, email, password);
}

// Function to sign in with email and password
function signInWithEmail(email, password) {
	return signInWithEmailAndPassword(auth, email, password);
}

const handleForgotPassword = async (email) => {
	try {
		await sendPasswordResetEmail(auth, email); // Use the auth instance defined earlier
	} catch (error) {
		console.error('Error sending password reset email:', error.message);
	}
};

// Export functionality so we can access it inside of React
export { login, logout, auth, signUpWithEmail, signInWithEmail, handleForgotPassword };

import React, { useState, useEffect } from 'react';
import {
	Container,
	Button,
	Box,
	List,
	ListItemIcon,
	ListItemText,
	ListItemButton,
	FormControlLabel,
	Switch,
	FormControl,
	MenuItem,
	InputLabel,
	Select,
	TextField,
	Typography,
	useMediaQuery,
} from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import Head from '../components/Head/Head';
import FolderCreationForm from '../components/FolderCreation';
import '../App.css';
import { useNavigate } from 'react-router-dom';
import { Triangle } from 'react-loader-spinner';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const API_URL = process.env.REACT_APP_API_URL;

function UserFolders({ user, darkMode }) {
	const isMobile = useMediaQuery('(max-width:600px)');
	const navigate = useNavigate();
	const [folders, setFolders] = useState([]);
	const [newFolder, setNewFolder] = useState(false);
	const [loading, setLoading] = useState(true);
	const [showPublicOnly, setShowPublicOnly] = useState(false);
	const [sortMethod, setSortMethod] = useState('mostRecent'); // Default sorting method
	const [searchQuery, setSearchQuery] = useState('');
	const [displayFolders, setDisplayFolders] = useState([]);

	const styles = {
		// Change outline color when hovered but not focused
		'&:hover .MuiOutlinedInput-notchedOutline:not(.Mui-focused)': {
			borderColor: darkMode ? 'white!important' : 'grey',
		},
		// Change label color when hovered but not focused
		'&:hover .MuiInputLabel-root:not(.Mui-focused)': {
			color: darkMode ? 'white' : '#000',
		},
		// For text color
		'&& input': {
			color: darkMode ? 'rgb(225,225,225)' : '#000',
		},
		// Default outline color
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: darkMode ? 'grey!important' : 'grey',
		},
		// Focused outline color
		'& .MuiOutlinedInput-notchedOutline.Mui-focused': {
			borderColor: darkMode ? 'rgb(47,199,112)' : '#000',
		},
		// Background color
		'& .MuiOutlinedInput-root': {
			backgroundColor: darkMode ? '#000' : '#f7f7f7',
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			borderColor: 'rgb(47, 199, 112)!important',
		},

		// Focused label color
		'& .MuiInputLabel-outlined.Mui-focused': {
			color: 'rgb(47, 180, 112)',
		},
		'& .css-v3zyv7-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-v3zyv7-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-v3zyv7-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input':
			{
				color: 'rgb(225,225,225)',
			},
	};

	const handleToggleChange = () => {
		setShowPublicOnly(!showPublicOnly);
	};

	const handleNewFolder = (newFolder) => {
		setNewFolder(false);
		// Add new folder to the state
		setFolders((prevFolders) => [...prevFolders, newFolder]);
		// Re-fetch folders to get the updated list including the new folder
		fetchFolders();
	};

	const fetchFolders = async () => {
		try {
			setLoading(true);
			const endpoint = showPublicOnly ? '/folders/public' : `/folders/${user?._id}`;
			const response = await fetch(`${API_URL}${endpoint}`, {
				method: 'GET',
				headers: { 'Content-Type': 'application/json' },
			});
			const data = await response.json();
			const parentFolders = data.filter((folder) => !folder.parentFolder);
			setFolders(parentFolders);
			setDisplayFolders(parentFolders);
		} catch (err) {
			console.error(err);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchFolders();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showPublicOnly]);

	useEffect(() => {
		const sortedFolders = [...folders];
		switch (sortMethod) {
			case 'mostLikes':
				sortedFolders.sort((a, b) => b.totalLikes - a.totalLikes);
				break;
			case 'alphabetical':
				sortedFolders.sort((a, b) => a.name.localeCompare(b.name));
				break;
			case 'mostRecent':
			default:
				sortedFolders.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
				break;
		}
		setDisplayFolders(sortedFolders);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sortMethod]);

	// Filter folders by search query
	useEffect(() => {
		if (searchQuery.trim() !== '') {
			const filteredFolders = folders.filter((folder) => folder.name.toLowerCase().includes(searchQuery.toLowerCase()));
			setDisplayFolders(filteredFolders); // Update the displayed folders
		} else {
			setDisplayFolders(folders); // Reset to original folders if search query is empty
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchQuery]);

	if (loading) {
		return (
			<>
				<Head title='User Folders Page' />

				<Container maxWidth='md' sx={{ display: 'flex', justifyContent: 'center' }}>
					<div className='loader'>
						<Triangle color='rgb(47, 199, 112)' size={100} />
						<Typography variant='h5' sx={{ color: 'rgb(47, 199, 112)', marginTop: '1rem' }}>
							Loading...
						</Typography>
					</div>
				</Container>
			</>
		);
	}

	return (
		<>
			<Head title='User Folders Page' />
			<Container sx={{ display: 'flex', flexDirection: 'column', marginTop: '1rem', marginBottom: '4rem' }}>
				<Box style={{ marginBottom: '1rem', display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'center', justifyContent: 'center' }}>
					<FormControlLabel control={<Switch checked={showPublicOnly} onChange={handleToggleChange} color='default' />} label={showPublicOnly ? 'Public Folders' : 'My Folders'} />
					<FormControl sx={{ ...styles, margin: isMobile ? '1rem 0' : '0' }}>
						<InputLabel id='sort-label'>Sort By</InputLabel>
						<Select labelId='sort-label' value={sortMethod} label='Sort By' onChange={(e) => setSortMethod(e.target.value)}>
							<MenuItem value='alphabetical'>Alphabetically</MenuItem>
							<MenuItem value='mostLikes'>Most Likes</MenuItem>
							<MenuItem value='mostRecent'>Most Recent</MenuItem>
						</Select>
					</FormControl>

					<TextField autoComplete='off' label='Search Folders' variant='outlined' value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} sx={{ marginLeft: '1rem', ...styles }} />
				</Box>
				{newFolder ? (
					<FolderCreationForm darkMode={darkMode} setNewFolder={setNewFolder} parentFolderId={null} handleNewFolder={handleNewFolder} user={user} />
				) : (
					<>
						{!user?.username ? (
							<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
								<em sx={{ marginBottom: '2rem' }}>*You must have a username to create a folder.</em>
								<Button
									variant='contained'
									sx={{
										backgroundColor: 'rgb(47, 160, 112)',
										color: darkMode ? '#000' : '#fff',
										marginTop: '16px',
										width: 'fit-content',
										'&:hover': { backgroundColor: 'rgb(47,180,112)' },
									}}
									onClick={() => navigate(`/user-profile/${user?.uid}`)}
								>
									<AccountCircleIcon fontSize='small' sx={{ mr: 1 }} />
									Profile
								</Button>
							</Box>
						) : (
							<Button
								variant='contained'
								sx={{
									backgroundColor: 'rgb(47, 160, 112)',
									color: 'white',
									marginBottom: '16px',
									alignSelf: 'center',
									'&:hover': { backgroundColor: 'rgb(47, 180, 112)', color: 'white' },
								}}
								type='button'
								onClick={() => setNewFolder(true)}
							>
								Create Folder
							</Button>
						)}
					</>
				)}
				<List
					sx={{
						width: '100%',
						display: 'flex',
						flexWrap: 'wrap',
						alignItems: isMobile ? 'center' : 'flex-start',
						justifyContent: isMobile ? 'center' : 'flex-start',
						flexDirection: isMobile ? 'column' : 'row',
					}}
				>
					{displayFolders.length > 0 ? (
						displayFolders.map((folder) => (
							<ListItemButton
								key={folder._id}
								sx={{ justifyContent: isMobile ? 'center' : 'flex-start', minWidth: isMobile ? '100%' : '25%', maxWidth: isMobile ? '100%' : '25%', marginBottom: '1rem' }}
								onClick={() => navigate(`/show-folder/${folder._id}`)}
							>
								{folder.isPublic ? <p style={{ color: 'green', marginRight: '8px' }}>(Public)</p> : <p style={{ color: '#d20404', marginRight: '8px' }}>(Private)</p>}
								<ListItemIcon sx={{ minWidth: '2rem' }}>
									<FolderIcon sx={{ color: darkMode ? 'rgb(225,225,225)' : 'inherit' }} />
								</ListItemIcon>
								<ListItemText primary={folder.name} />
							</ListItemButton>
						))
					) : (
						<p style={{ margin: '0 auto' }}>You have no folders</p>
					)}
				</List>
			</Container>
		</>
	);
}

export default UserFolders;

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Button, ListItemButton, ListItemIcon, ListItemText, useMediaQuery, IconButton, Typography } from '@mui/material';
import { Triangle } from 'react-loader-spinner';
import Head from '../components/Head/Head';
import FolderCreationForm from '../components/FolderCreation';
import axios from 'axios';
import '../App.css';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';
import SportsKabaddi from '@mui/icons-material/SportsKabaddi';
import ResourceIcon from '@mui/icons-material/School';
import SequenceIcon from '@mui/icons-material/Timeline';
import FaClose from '@mui/icons-material/Close';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';

const API_URL = process.env.REACT_APP_API_URL;

function ShowFolder({ darkMode, user, authUser }) {
	const isMobile = useMediaQuery('(max-width:600px)');
	const [loading, setLoading] = useState(true);
	const [folder, setFolder] = useState(null);
	const [showCreateForm, setShowCreateForm] = useState(false); // State to toggle folder creation form
	const navigate = useNavigate();
	const [items, setItems] = useState([]); // State for storing item details
	const [isEditing, setIsEditing] = useState(false);
	const [newFolderName, setNewFolderName] = useState(folder ? folder.name : '');
	const [error, setError] = useState(null);

	const { id } = useParams();

	const [searchInput, setSearchInput] = useState('');
	const [searchResults, setSearchResults] = useState([]);

	const [liked, setLiked] = useState(false);
	const [disliked, setDisliked] = useState(false);
	const [likes, setLikes] = useState(folder?.totalLikes ? folder?.totalLikes : 0);
	const [dislikes, setDislikes] = useState(folder?.totalDislikes ? folder?.totalDislikes : 0);

	const viewResource = (resource) => {
		const queryParams = new URLSearchParams({ timestamp: resource.timestamp }).toString();
		return `/resource/${resource._id}?${queryParams}`;
	};

	const viewSequence = (sequence) => {
		return `/match/${sequence.match_id}?sequence_id=${sequence._id}`;
	};

	const handleNavigate = (item) => {
		if (item.title) {
			return viewResource(item);
		} else if (item.sequenceType) {
			return viewSequence(item);
		} else {
			return `/match/${item._id}`;
		}
	};

	const handleLike = async () => {
		if (!liked) {
			try {
				const response = await axios.post(`${API_URL}/folders/${folder._id}/like`, { userId: user?._id });
				setLikes(response.data.totalLikes);
				setDislikes(response.data.totalDislikes);
				setLiked(true);
				setDisliked(false);
			} catch (error) {
				console.error('Error liking folder:', error);
			}
		}
	};

	const handleDislike = async () => {
		if (!disliked) {
			try {
				const response = await axios.post(`${API_URL}/folders/${folder._id}/dislike`, { userId: user?._id });
				setLikes(response.data.totalLikes);
				setDislikes(response.data.totalDislikes);
				setLiked(false);
				setDisliked(true);
			} catch (error) {
				console.error('Error disliking folder:', error);
			}
		}
	};

	// Handle search input change
	const handleSearchInputChange = async (e) => {
		setSearchInput(e.target.value);
		if (e.target.value.length > 1) {
			try {
				const response = await axios.get(`${API_URL}/folders/search-users/${e.target.value}`);
				setSearchResults(response.data);
			} catch (error) {
				console.error('Error searching users:', error);
			}
		} else {
			setSearchResults([]);
		}
	};

	// Add user to allowedUsers
	const addUserToAllowed = async (userToAdd) => {
		try {
			await axios.patch(`${API_URL}/folders/${folder._id}/addAllowedUser`, { userToAdd });
			setFolder({ ...folder, allowedUsers: [...folder.allowedUsers, userToAdd] });
			setShowUsers(true);
		} catch (error) {
			setError('User already allowed.');
		}
	};

	// Remove user from allowedUsers
	const removeFromFolder = async (userId) => {
		try {
			await axios.patch(`${API_URL}/folders/${folder._id}/removeAllowedUser`, { userId });
			// Update the state to reflect the change
			setAllowedUserDetails(allowedUserDetails.filter((user) => user._id !== userId));
			setFolder({ ...folder, allowedUsers: folder.allowedUsers.filter((user) => user !== userId) });
		} catch (error) {
			console.error('Error removing user from allowed users:', error);
		}
	};

	const handleEditClick = () => {
		setIsEditing(true);
		setNewFolderName(folder.name);
	};

	const updateFolderName = async () => {
		if (!newFolderName.trim()) {
			alert('Folder name cannot be empty.');
			return;
		}
		try {
			const response = await axios.patch(`${API_URL}/folders/${folder._id}/updateName`, {
				newName: newFolderName,
			});
			if (response.data) {
				setFolder({ ...folder, name: newFolderName });
				setIsEditing(false);
			}
		} catch (error) {
			console.error('Error updating folder name:', error);
		}
	};

	useEffect(() => {
		const fetchFolder = async () => {
			try {
				const response = await axios.get(`${API_URL}/folders/hierarchy/${id}`);
				setFolder(response.data);
				setLikes(response.data.totalLikes || 0);
				setDislikes(response.data.totalDislikes || 0);
				setLoading(false);
			} catch (error) {
				setLoading(false);
			}
		};

		fetchFolder();
	}, [id]); // Depend only on `id`

	// if error reset to null after 3 seconds
	useEffect(() => {
		if (error) {
			setTimeout(() => {
				setError(null);
			}, 2000);
		}
	}, [error]);

	useEffect(() => {
		if (folder && folder.items) {
			const fetchItemDetails = async () => {
				const fetchedItems = await Promise.all(
					folder.items.map(async (item) => {
						try {
							const response = await axios.get(`${API_URL}/folders/item/${item.reference}/${item.type}`);
							return response.data;
						} catch {
							return null; // Return null for non-existent items
						}
					})
				);
				setItems(fetchedItems.filter((item) => item !== null)); // Filter out null values
			};
			fetchItemDetails();
		}
	}, [folder]);

	const handleRemoveItem = async (itemId) => {
		try {
			await axios.delete(`${API_URL}/folders/${id}/removeItem/${itemId}`);

			// Update the items state to reflect the removal
			const updatedItems = items.filter((item) => item._id !== itemId);
			setItems(updatedItems);
		} catch (error) {
			console.error('Error removing item:', error);
		}
	};

	const handleDelete = async (event, folderId) => {
		event.stopPropagation(); // Prevent event propagation

		if (window.confirm('Are you sure you want to delete this folder and all its subfolders?')) {
			try {
				setLoading(true);
				const token = await authUser?.getIdToken();
				const config = {
					headers: { Authorization: `Bearer ${token}` },
				};
				await axios.delete(`${API_URL}/folders/${folderId}`, config);
				if (folderId === id) {
					navigate(`/user-folders/${user?._id}`);
				} else {
					setFolder({ ...folder, subFolders: folder.subFolders.filter((subFolder) => subFolder._id !== folderId) });
				}
			} catch (error) {
				console.error('Error deleting folder:', error);
			} finally {
				setLoading(false);
			}
		}
	};

	const handleNewSubFolder = (newFolder) => {
		// Update the folder state to include the new subfolder
		setFolder({ ...folder, subFolders: [...folder.subFolders, newFolder] });
		setShowCreateForm(false);
	};

	const displaySubFolders = () => {
		return (
			<div style={{ marginLeft: '20px' }}>
				{folder.subFolders.map((subFolder) => {
					return (
						<ListItemButton key={subFolder._id} onClick={() => navigate(`/show-folder/${subFolder._id}`)}>
							<ListItemIcon>
								<FolderIcon />
							</ListItemIcon>
							<ListItemText primary={subFolder.name} secondary={`${subFolder.items.length} items`} />
							<DeleteIcon onClick={(e) => handleDelete(e, subFolder._id)} sx={{ cursor: 'pointer' }} />
						</ListItemButton>
					);
				})}
			</div>
		);
	};

	const onDragEnd = async (result) => {
		if (!result.destination) return;

		const reorderedItems = Array.from(items);
		const [removed] = reorderedItems.splice(result.source.index, 1);
		reorderedItems.splice(result.destination.index, 0, removed);

		setItems(reorderedItems);

		// Prepare data for the API call
		const updatedOrder = reorderedItems.map((item) => item._id);

		try {
			await axios.post(`${API_URL}/folders/update-items-order`, {
				folderId: id,
				newOrder: updatedOrder,
			});
		} catch (error) {
			console.error('Error updating order:', error);
		}
	};

	const [allowedUserDetails, setAllowedUserDetails] = useState([]);
	const [showUsers, setShowUsers] = useState(false);

	useEffect(() => {
		const fetchUsernames = async () => {
			try {
				const userDetails = await Promise.all(folder?.allowedUsers.map((userId) => axios.get(`${API_URL}/folders/user/${userId}`)));
				// Extract usernames from the response
				const usernames = userDetails.map((response) => ({
					_id: response.data._id,
					username: response.data.username,
				}));
				setAllowedUserDetails(usernames);
			} catch (error) {
				console.error('Error fetching user details:', error);
			}
		};

		if (folder?.allowedUsers && folder?.allowedUsers.length > 0) {
			fetchUsernames();
		}
	}, [folder?.allowedUsers]);

	const displayItems = () => {
		return (
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId='droppable-items'>
					{(provided) => (
						<div {...provided.droppableProps} ref={provided.innerRef}>
							{items.map((item, index) => {
								return (
									<Draggable key={item._id.toString()} draggableId={item._id.toString()} index={index}>
										{(provided) => (
											<ListItemButton ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
												<ListItemIcon>
													{item.match && <SportsKabaddi />}
													{item.sequenceType && !item.title && <SequenceIcon />}
													{item.type && item.title && <ResourceIcon />}
												</ListItemIcon>
												<ListItemText
													primary={
														item.match
															? `Match: ${item.match}`
															: item.sequenceType && !item.title
															? `Sequence Type: ${item.sequenceType}`
															: item.title
															? `Resource: ${item.title}`
															: 'Unknown Item'
													}
													secondary={
														item.match
															? `${item.year} - ${item.organization} - Winner: ${item.matchWinner}`
															: item.sequenceType
															? `${item.player1} vs ${item.player2}`
															: item.title
															? `Type: ${item.type}, Instructor: ${item.instructor ? item.instructor : 'N/A'}`
															: ''
													}
												/>
												<FaClose onClick={() => handleRemoveItem(item._id)} sx={{ cursor: 'pointer', marginLeft: 'auto', marginRight: '1rem' }} />
												<a href={handleNavigate(item)} style={{ color: darkMode ? 'green' : 'green', fontFamily: 'monospace' }} className='url-anchor'>
													VIEW
												</a>
											</ListItemButton>
										)}
									</Draggable>
								);
							})}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
		);
	};

	if (loading) {
		return (
			<>
				<Head title='Show Folder Page' />
				<Container maxWidth='md' sx={{ display: 'flex', justifyContent: 'center' }}>
					<div className='loader'>
						<Triangle color='rgb(47, 199, 112)' size={100} />
						<Typography variant='h5' sx={{ color: 'rgb(47, 199, 112)', marginTop: '1rem' }}>
							Loading...
						</Typography>
					</div>
				</Container>
			</>
		);
	}

	return (
		<>
			<Head title='Show Folder Page' />
			<Container>
				{folder && !folder.isPublic && (
					<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1rem' }}>
						<div style={{ width: '48%' }}>
							<h3>Allowed Users:</h3>
							<p style={{ borderBottom: '1px solid black', borderRadius: '0' }} className='showUsersForFolders' onClick={() => setShowUsers(!showUsers)}>
								{showUsers ? 'Hide' : 'Show'} Users
							</p>
							{allowedUserDetails && allowedUserDetails.length > 0 ? (
								<ul style={{ listStyle: 'none', display: showUsers ? 'block' : 'none' }}>
									{allowedUserDetails.map((user) => (
										<li key={user._id} className='search-result-item list-item-flex' onClick={() => removeFromFolder(user._id)}>
											<p>{user.username}</p> <p>X</p>
										</li>
									))}
								</ul>
							) : (
								<p style={{ color: 'grey' }}>No mas</p>
							)}
						</div>
						<div style={{ width: '48%' }}>
							<input
								type='text'
								style={{
									fontSize: '1rem',
									outline: 'none',
									border: '1px solid lightgrey',
								}}
								value={searchInput}
								onChange={handleSearchInputChange}
								placeholder='Username...'
							/>
							<Button
								sx={{ color: 'grey', marginLeft: '8px' }}
								onClick={() => {
									setSearchInput('');
									setSearchResults([]);
								}}
							>
								Clear
							</Button>
							{error && <p style={{ color: '#d20404' }}>{error}</p>}
							{searchResults.length > 0 && (
								<ul style={{ listStyle: 'none' }}>
									{searchResults.map((user) => (
										<li key={user._id} onClick={() => addUserToAllowed(user._id)} className='search-result-item list-item-flex'>
											<p>{user.username}</p>
											<p style={{ fontSize: '1.25rem' }}>+</p>
										</li>
									))}
								</ul>
							)}
						</div>
					</div>
				)}
				{folder && (
					<div style={{ border: darkMode ? '1px solid rgb(225,225,225)' : '1px solid black', borderRadius: '8px', margin: '2rem 0' }}>
						<h1
							style={{
								paddingLeft: '8px',
								paddingRight: '8px',
								fontSize: isMobile ? '1rem' : '1.75rem',
								background: darkMode ? 'rgba(90, 90, 90, 0.5)' : 'rgba(0,0,0,0.1)',
								display: 'flex',
								alignItems: 'center',
								flexWrap: isEditing ? 'wrap' : 'nowrap',
								justifyContent: 'space-between',
							}}
						>
							{folder.isPublic ? (
								<span style={{ color: 'green', fontSize: isMobile ? '.6rem' : '1rem' }}>Public {isMobile ? '' : ` - ${folder.createdByName}`}</span>
							) : (
								<span style={{ color: '#d20404', fontSize: isMobile ? '.6rem' : '1rem' }}>Private {isMobile ? '' : ` - ${folder.createdByName}`}</span>
							)}
							{isEditing ? (
								<div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
									<input
										style={{
											fontSize: isMobile ? '1rem' : '1.5rem',
											outline: 'none',
											border: '1px solid lightgrey',
										}}
										type='text'
										value={newFolderName}
										onChange={(e) => setNewFolderName(e.target.value)}
									/>
									<Button
										variant='contained'
										sx={{
											backgroundColor: 'rgb(47, 199, 112)',
											color: 'white',
											marginLeft: '1rem',
											padding: '0',
											'&:hover': { backgroundColor: 'rgb(47, 180, 112)', color: 'white' },
										}}
										onClick={updateFolderName}
									>
										Save
									</Button>
									<Button
										variant='contained'
										sx={{
											backgroundColor: '#d20404',
											color: 'white',
											marginLeft: '1rem',
											padding: '0',
											'&:hover': { backgroundColor: '#db1c1c', color: 'white' },
										}}
										onClick={() => setIsEditing(false)}
									>
										X
									</Button>
								</div>
							) : (
								<p style={{ fontSize: isMobile ? '.9rem' : '1.75rem' }} onClick={handleEditClick}>
									{folder.name}{' '}
									{isMobile ? (
										<p style={{ fontSize: isMobile ? '0.8rem' : '1rem' }}>
											({folder.items.length} items {folder.subFolders.length} subfolders)
										</p>
									) : (
										<span style={{ fontSize: isMobile ? '0.8rem' : '1rem' }}>
											({folder.items.length} items {folder.subFolders.length} subfolders)
										</span>
									)}
								</p>
							)}
							<div style={{ display: 'flex', alignItems: 'center' }}>
								{folder && !folder.parentFolder && (
									<>
										<IconButton sx={{ color: darkMode ? '#fff' : '#000', '&:hover': { backgroundColor: darkMode ? 'grey' : 'darkgrey' } }} onClick={handleLike}>
											<ThumbUpIcon fontSize={isMobile ? 'small' : 'medium'} />
										</IconButton>
										<Typography variant='body1' style={{ marginRight: isMobile ? '8px' : '16px' }}>
											{likes}
										</Typography>
										<IconButton sx={{ color: darkMode ? '#fff' : '#000', '&:hover': { backgroundColor: darkMode ? 'grey' : 'darkgrey' } }} onClick={handleDislike}>
											<ThumbDownIcon fontSize={isMobile ? 'small' : 'medium'} />
										</IconButton>
										<Typography variant='body1'>{dislikes}</Typography>
										<DeleteIcon onClick={(e) => handleDelete(e, folder._id)} sx={{ cursor: 'pointer', marginLeft: isMobile ? '.5rem' : '1rem' }} />
									</>
								)}
								{folder && folder.parentFolder && <DeleteIcon onClick={(e) => handleDelete(e, folder._id)} sx={{ cursor: 'pointer', marginLeft: '1rem' }} />}
							</div>
						</h1>
						{folder.subFolders && folder.subFolders.length > 0 && (
							<div style={{ padding: '1rem' }}>
								<h3>Subfolders:</h3>
								{displaySubFolders()}
							</div>
						)}
						{items && items.length > 0 && displayItems()}
					</div>
				)}
				{showCreateForm ? (
					<FolderCreationForm setNewFolder={setShowCreateForm} parentFolderId={id} handleNewFolder={handleNewSubFolder} isParentPublic={folder.isPublic} user={user} />
				) : (
					<Button
						variant='contained'
						sx={{
							backgroundColor: 'rgb(47, 199, 112)',
							color: darkMode ? '#000' : '#fff',
							marginBottom: '16px',
							'&:hover': { backgroundColor: 'rgb(47, 180, 112)', color: 'white' },
						}}
						onClick={() => setShowCreateForm(true)}
					>
						Add folder
					</Button>
				)}
			</Container>
		</>
	);
}

export default ShowFolder;

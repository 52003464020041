import UserAuth from '../components/UserAuth';
import Head from '../components/Head/Head';

function Auth({ darkMode, setUser, authUser, setAuthUser }) {
	return (
		<>
			<Head title='Auth' />
			<UserAuth darkMode={darkMode} setUser={setUser} authUser={authUser} setAuthUser={setAuthUser} />
		</>
	);
}

export default Auth;

import React, { useState, useEffect } from 'react';
import { Triangle } from 'react-loader-spinner';
import { Container, Typography, Button, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import Head from '../components/Head/Head';
import axios from 'axios';
import '../App.css';

const API_URL = process.env.REACT_APP_API_URL;

function UserProfile({ hasSubscription, user, setUser, darkMode }) {
	const styles = {
		// Change outline color when hovered but not focused
		'&:hover .MuiOutlinedInput-notchedOutline:not(.Mui-focused)': {
			borderColor: darkMode ? 'white!important' : 'grey',
		},
		// Change label color when hovered but not focused
		'&:hover .MuiInputLabel-root:not(.Mui-focused)': {
			color: darkMode ? 'white' : '#000',
		},
		// For text color
		'&& input': {
			color: darkMode ? 'rgb(225,225,225)' : '#000',
		},
		// Default outline color
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: darkMode ? 'grey!important' : 'grey',
		},
		// Focused outline color
		'& .MuiOutlinedInput-notchedOutline.Mui-focused': {
			borderColor: darkMode ? 'rgb(47,199,112)' : '#000',
		},
		// Background color
		'& .MuiOutlinedInput-root': {
			backgroundColor: darkMode ? '#000' : '#fff',
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			borderColor: 'rgb(47, 180, 112)!important',
		},
		// Label color
		'& .MuiInputLabel-outlined': {
			color: darkMode ? 'rgb(225,225,225)' : '#000',
		},
		'#mui-component-select-level': {
			color: darkMode ? 'rgb(225,225,225)' : '#000',
		},

		// Focused label color
		'& .MuiInputLabel-outlined.Mui-focused': {
			color: 'rgb(47, 180, 112)',
		},
	};
	const [loading, setLoading] = useState(true);

	const [userData, setUserData] = useState(null);
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);

	useEffect(() => {
		const fetchUserData = async () => {
			try {
				fetch(`${API_URL}/user/${user?.uid}/user-data`, {
					method: 'GET',
					headers: { 'Content-Type': 'application/json' },
				})
					.then((res) => res.json())
					.then((data) => {
						setUserData(data);
					})
					.catch((err) => console.error(err));
				setLoading(false);
			} catch (error) {
				console.error('Error fetching user data:', error);
			}
		};

		fetchUserData();
	}, [user?.uid]);

	useEffect(() => {
		// Check if user data is available
		if (userData) {
			setFormValues({
				username: userData.username || '',
				affiliation: userData.affiliation || '',
				level: userData.level || 'White',
				email: userData.email || '',
				role: userData.role || '',
			});
		}
	}, [userData]);

	const [formValues, setFormValues] = useState({
		username: user?.username || '',
		affiliation: user?.affiliation || '',
		level: user?.level || 'White',
		role: user?.role || '',
	});

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormValues({
			...formValues,
			[name]: value,
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const response = await axios.put(`${API_URL}/user/${user?.uid}/update`, formValues);
			if (response.status === 200) {
				setUser(response.data.user);
				const { username, affiliation, level, role } = response.data;
				setFormValues({
					username: username,
					affiliation: affiliation,
					level: level,
					role: role,
				});
				setSuccess('Successfully updated user information!');
			}
		} catch (error) {
			if (error.response && error.response.status === 400) {
				setError(error.response.data.message);
			} else {
				console.error('Error updating user information:', error);
			}
		}
	};

	useEffect(() => {
		// reset error to null after 2 seconds
		if (!error) return;
		const timeout = setTimeout(() => {
			setError(null);
		}, 2000);

		return () => clearTimeout(timeout);
	}, [error]);

	useEffect(() => {
		// reset success to null after 2 seconds
		if (!success) return;
		const timeout = setTimeout(() => {
			setSuccess(null);
		}, 2000);

		return () => clearTimeout(timeout);
	}, [success]);

	if (loading) {
		return (
			<>
				<Head title='User Profile Page' />
				<Container maxWidth='md' style={{ display: 'flex', justifyContent: 'center' }}>
					<div className='loader'>
						<Triangle color='rgb(47, 199, 112)' size={100} />
						<Typography variant='h5' sx={{ color: 'rgb(47, 199, 112)', marginTop: '1rem' }}>
							Loading...
						</Typography>
					</div>
				</Container>
			</>
		);
	}

	return (
		<>
			<Head title='User Profile Page' />
			<Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '1rem', marginBottom: '4rem' }}>
				{userData?.email && (
					<Typography variant='h6' gutterBottom sx={{ textAlign: 'center', marginTop: '1rem' }}>
						Update profile for {userData?.email}
					</Typography>
				)}
				<form onSubmit={handleSubmit}>
					{error && (
						<Typography variant='body1' sx={{ color: '#d20404' }}>
							{error}
						</Typography>
					)}
					{success && (
						<Typography variant='body1' sx={{ color: 'green' }}>
							{success}
						</Typography>
					)}
					<TextField
						autoComplete='off'
						sx={{ ...styles }}
						label='Username'
						name='username'
						value={formValues.username}
						onChange={handleInputChange}
						fullWidth
						margin='normal'
						variant='outlined'
					/>
					<TextField
						autoComplete='off'
						sx={{ ...styles }}
						label='Affiliation'
						name='affiliation'
						value={formValues.affiliation}
						onChange={handleInputChange}
						fullWidth
						margin='normal'
						variant='outlined'
					/>
					<FormControl sx={{ ...styles }} fullWidth variant='outlined' margin='normal'>
						<InputLabel>Rank</InputLabel>
						<Select label='Rank' name='level' value={formValues.level} onChange={handleInputChange}>
							{['White', 'Blue', 'Purple', 'Brown', 'Black'].map((level) => (
								<MenuItem key={level} value={level}>
									{level}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl sx={{ ...styles }} fullWidth variant='outlined' margin='normal'>
						<InputLabel>Role</InputLabel>
						<Select label='Role' name='role' value={formValues.role} onChange={handleInputChange}>
							{['Coach - Professional', 'Coach - Hobbyist', 'Student - Professional', 'Student - Hobbyist'].map((role) => (
								<MenuItem key={role} value={role}>
									{role}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<Button
							sx={{
								backgroundColor: 'rgb(47,160, 112)',
								color: darkMode ? '#000' : '#fff',
								marginLeft: 0,
								'&:hover': { backgroundColor: 'rgb(47, 180, 112)', color: 'white' },
							}}
							type='submit'
							variant='contained'
						>
							Save
						</Button>
					</div>
				</form>
			</Container>
		</>
	);
}

export default UserProfile;

import { useState, useEffect } from 'react';

const API_URL = process.env.REACT_APP_API_URL;

const AlternativeNames = () => {
	const [alternativeNames, setAlternativeNames] = useState([]);
	const [newName, setNewName] = useState('');
	const [newAlternatives, setNewAlternatives] = useState('');
	const [editingName, setEditingName] = useState(null);
	const [error, setError] = useState('');

	useEffect(() => {
		fetchAlternativeNames();
	}, []);

	const fetchAlternativeNames = () => {
		fetch(`${API_URL}/alternative-names`)
			.then((response) => {
				if (!response.ok) {
					throw new Error('Failed to fetch alternative names');
				}
				return response.json();
			})
			.then((data) => setAlternativeNames(data))
			.catch((error) => setError(error.message));
	};

	const handleAddName = (e) => {
		e.preventDefault();
		fetch(`${API_URL}/alternative-names`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ term: newName, alternatives: newAlternatives.split(',') }),
		})
			.then((response) => {
				if (!response.ok) {
					throw new Error('Failed to add alternative name');
				}
				return response.json();
			})
			.then((data) => {
				setAlternativeNames([...alternativeNames, data]);
				setNewName('');
				setNewAlternatives('');
			})
			.catch((error) => setError(error.message));
	};

	const handleEditName = (id, term, alternatives) => {
		fetch(`${API_URL}/alternative-names/${id}`, {
			method: 'PUT',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ term, alternatives }),
		})
			.then((response) => {
				if (!response.ok) {
					throw new Error('Failed to edit alternative name');
				}
				setEditingName(null);
				fetchAlternativeNames();
			})
			.catch((error) => setError(error.message));
	};

	const handleDeleteName = (id) => {
		fetch(`${API_URL}/alternative-names/${id}`, {
			method: 'DELETE',
		})
			.then((response) => {
				if (!response.ok) {
					throw new Error('Failed to delete alternative name');
				}
				setAlternativeNames(alternativeNames.filter((name) => name._id !== id));
			})
			.catch((error) => setError(error.message));
	};

	const handleEditButtonClick = (id, term, alternatives) => {
		setEditingName({ id, term, alternatives: alternatives.join(', ') });
	};

	const handleCancelEdit = () => {
		setEditingName(null);
	};

	const handleEditFormSubmit = (e) => {
		e.preventDefault();
		const { id, term, alternatives } = editingName;
		handleEditName(id, term, alternatives.split(','));
	};

	return (
		<div>
			<h2>Alternative Names</h2>
			{error && <div>Error: {error}</div>}
			<form onSubmit={handleAddName}>
				<input type='text' value={newName} onChange={(e) => setNewName(e.target.value)} placeholder='New Term' required />
				<input type='text' value={newAlternatives} onChange={(e) => setNewAlternatives(e.target.value)} placeholder='New Alternatives (comma separated)' required style={{ width: '100%' }} />
				<button style={{ marginTop: '1rem', marginBottom: '1rem', cursor: 'pointer', padding: '5px' }} type='submit'>
					Add Name
				</button>
			</form>
			<ul>
				{alternativeNames.map((name) => (
					<li key={name._id}>
						{name._id === editingName?.id ? (
							<form onSubmit={handleEditFormSubmit}>
								<input type='text' value={editingName.term} onChange={(e) => setEditingName({ ...editingName, term: e.target.value })} />
								<input type='text' style={{ width: '100%' }} value={editingName.alternatives} onChange={(e) => setEditingName({ ...editingName, alternatives: e.target.value })} />
								<button style={{ margin: '8px', cursor: 'pointer', padding: '5px' }} type='submit'>
									Save
								</button>
								<button style={{ margin: '8px', cursor: 'pointer', padding: '5px' }} type='button' onClick={handleCancelEdit}>
									Cancel
								</button>
							</form>
						) : (
							<>
								<strong>{name.term}: </strong> {name.alternatives.join(', ')}
								<button style={{ margin: '8px', cursor: 'pointer', padding: '5px' }} onClick={() => handleEditButtonClick(name._id, name.term, name.alternatives)}>
									Edit
								</button>
								<button style={{ margin: '8px', cursor: 'pointer', padding: '5px' }} onClick={() => handleDeleteName(name._id)}>
									Delete
								</button>
							</>
						)}
					</li>
				))}
			</ul>
		</div>
	);
};

export default AlternativeNames;

import React, { useState, useEffect, useCallback, memo } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { auth } from './firebase';
import './App.css';
import GoogleAnalytics from './utils/GoogleAnalytics';
import Header from './components/Header';
import Auth from './pages/Auth';
import FeaturedPage from './pages/FeaturedPage';
import Account from './pages/Account';
import Contact from './pages/Contact';
import SearchResults from './pages/SearchResults';
import DataEntryForm from './pages/DataEntryForm';
import EditData from './pages/EditData';
import MatchAndSequencesDetails from './pages/MatchAndSequencesDetails';
import MatchPage from './pages/MatchPage';
import AddSequences from './pages/AddSequences';
import EditSequence from './pages/EditSequence';
import TrainingResources from './pages/TrainingResources';
import AddTrainingResource from './pages/AddTrainingResource';
import EditTrainingResource from './pages/EditTrainingResource';
import ResourcePage from './pages/ResourcePage';
import RewardsDashboard from './pages/RewardsDashboard';
import UserProfile from './pages/UserProfile';
import UserFolders from './pages/UserFolders';
import ShowFolder from './pages/ShowFolder';
import SuggestionsForm from './pages/SuggestionsForm';
import Sequences from './pages/Sequences';
import Statistics from './pages/Statistics';
import Wiki from './pages/Wiki';
import DatabaseTerms from './pages/DatabaseTerms';
import WeeklyFeatures from './pages/WeeklyFeatures';
import { Box, Grid, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { Triangle } from 'react-loader-spinner';
import Footer from './components/Footer';
import ChatBot from './components/ChatBot';
import './App.css';
import usePageViews from './customHooks/usePageViews';

const API_URL = process.env.REACT_APP_API_URL;

const ProtectedRoute = memo(({ authUser, user, children }) => {
	const navigate = useNavigate();
	usePageViews();

	useEffect(() => {
		if (!authUser) {
			navigate('/auth');
		} else if (authUser && user && user.subscriptionStatus !== 'active') {
			navigate('/account');
		}
	}, [authUser, user, navigate]);

	if (!authUser || (authUser && user && user.subscriptionStatus !== 'active')) {
		return null;
	}

	return children;
});

function App() {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const [user, setUser] = useState(null);
	const [authUser, setAuthUser] = useState(null);
	const [darkMode, setDarkMode] = useState(localStorage.getItem('theme') === 'dark' ? true : false);
	const [data, setData] = useState(null);
	const [searchType, setSearchType] = useState(localStorage.getItem('searchType') || 'hybrid_search');
	const [search, setSearch] = useState(localStorage.getItem('search') || '');
	const [matches, setMatches] = useState([]);
	const [sequences, setSequences] = useState([]);
	const [resources, setresources] = useState([]);
	const [suggestedQueries, setSuggestedQueries] = useState([]);
	const [messages, setMessages] = useState([]);
	const [isChatbotOpen, setIsChatbotOpen] = useState(false);
	const [popularSearches, setPopularSearches] = useState([]);

	const theme = createTheme({
		palette: {
			mode: darkMode ? 'dark' : 'light',
			background: {
				default: darkMode ? '#032e21' : '#f1ffeb',
				paper: darkMode ? '#032e21' : '#f1ffeb', // Ensuring that components using paper also receive the color
			},
		},
	});

	useEffect(() => {
		localStorage.setItem('theme', darkMode ? 'dark' : 'light');
		document.body.style.backgroundColor = darkMode ? '#032e21' : '#f1ffeb';
	}, [darkMode]);

	const toggleDarkMode = useCallback(() => {
		setDarkMode((prevDarkMode) => !prevDarkMode);
	}, []);

	useEffect(() => {
		const fetchAppData = async () => {
			try {
				const token = await authUser.getIdToken();
				const responseData = await fetch(`${API_URL}/data`, {
					method: 'GET',
					headers: {
						Authorization: 'Bearer ' + token,
					},
				});

				const fetchedData = await responseData.json();

				setData(fetchedData);
			} catch (error) {
				console.error('Error fetching app data:', error);
			}
		};
		if (authUser) {
			fetchAppData();
		}
	}, [authUser]);

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged((userFromFirebase) => {
			if (userFromFirebase) {
				setAuthUser(userFromFirebase);
				axios
					.get(`${API_URL}/user/${userFromFirebase.uid}`)
					.then((response) => {
						setUser(response.data);
						localStorage.setItem('user', JSON.stringify(response.data));
					})
					.catch((error) => {
						console.error('Error fetching user:', error);
					});
				setLoading(false);
			} else {
				setLoading(false);
			}
		});
		return () => {
			unsubscribe();
		};
		// eslint-disable-next-line
	}, []);

	const executeSearch = useCallback(
		async (e, searchValue, newPage) => {
			try {
				e.preventDefault();
				setLoading(true);
				localStorage.setItem('search', searchValue);
				const response = await fetch(`${API_URL}/data/search/${encodeURIComponent(searchValue)}/${searchType}/${newPage}`, {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
					},
				});
				const data = await response.json();
				setMatches(data.data.matches);
				setSequences(data.data.sequences);
				setresources(data.data.resources);
				setSuggestedQueries(data.data.suggested);

				const popularSearchesThisWeek = await axios.get(`${API_URL}/data/searches`);
				setPopularSearches(popularSearchesThisWeek.data);

				await axios.post(`${API_URL}/data/searches`, {
					searchedFor: searchValue,
					requestedData: '',
					uid: user.uid,
					email: user.email,
				});
			} catch (error) {
				console.error('Error executing search', error);
			} finally {
				setLoading(false);
				navigate('/search-results');
			}
		},
		[searchType, user, navigate]
	);

	const handleSearch = useCallback(
		async (e, search) => {
			e.preventDefault();
			await executeSearch(e, search.trim(), 1);
		},
		[executeSearch]
	);

	const handleMessagesUpdate = useCallback((newMessages) => {
		setMessages(newMessages);
	}, []);

	const handleChatbotToggle = useCallback(() => {
		setIsChatbotOpen(!isChatbotOpen);
	}, [isChatbotOpen]);

	usePageViews();

	if (loading) {
		return (
			<Box
				sx={{
					background: darkMode ? '#052E21' : '#F1FFEB',
					minHeight: '100vh',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Header
					user={user}
					authUser={authUser}
					handleSearch={handleSearch}
					toggleDarkMode={toggleDarkMode}
					darkMode={darkMode}
					setUser={setUser}
					setAuthUser={setAuthUser}
					search={search}
					setSearch={setSearch}
					searchType={searchType}
					setSearchType={setSearchType}
				/>
				<Grid item xs={12}>
					<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', pt: 4 }}>
						<Triangle height='100' width='100' color='rgb(47, 199, 112)' ariaLabel='triangle-loading' />
						<Typography variant='h6' style={{ color: 'rgb(47,199,112)' }}>
							Loading...
						</Typography>
					</Box>
				</Grid>
			</Box>
		);
	}

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<GoogleAnalytics />
			<Box
				sx={{
					color: darkMode ? 'rgb(225,225,225)' : '#000',
					minHeight: '100vh',
					display: 'flex',
					flexDirection: 'column',

					background: darkMode ? '#032e21' : '#f1ffeb',
				}}
			>
				<Header
					user={user}
					authUser={authUser}
					handleSearch={handleSearch}
					toggleDarkMode={toggleDarkMode}
					darkMode={darkMode}
					setUser={setUser}
					setAuthUser={setAuthUser}
					setSearch={setSearch}
					search={search}
					searchType={searchType}
					setSearchType={setSearchType}
				/>
				<Box
					component='main'
					sx={{
						flexGrow: 1,
					}}
				>
					<Routes>
						<Route path='/' element={<FeaturedPage user={user} darkMode={darkMode} authUser={authUser} />} />
						<Route path='/auth' element={<Auth darkMode={darkMode} setUser={setUser} authUser={authUser} setAuthUser={setAuthUser} />} />
						<Route path='/account' element={<Account user={user} authUser={authUser} darkMode={darkMode} />} />
						<Route path='/contact' element={<Contact darkMode={darkMode} user={user} />} />
						<Route
							path='/weekly-features'
							element={
								<ProtectedRoute authUser={authUser} user={user}>
									<WeeklyFeatures user={user} authUser={authUser} darkMode={darkMode} />
								</ProtectedRoute>
							}
						/>
						<Route
							path='/search-results'
							element={
								<ProtectedRoute authUser={authUser} user={user}>
									<SearchResults
										handleSearch={handleSearch}
										setSearch={setSearch}
										loading={loading}
										user={user}
										authUser={authUser}
										darkMode={darkMode}
										matches={matches}
										sequences={sequences}
										resources={resources}
										suggestedQueries={suggestedQueries}
										popularSearches={popularSearches}
									/>
								</ProtectedRoute>
							}
						/>
						<Route
							path='/data-entry'
							element={
								<ProtectedRoute authUser={authUser} user={user}>
									<DataEntryForm user={user} authUser={authUser} darkMode={darkMode} data={data} />
								</ProtectedRoute>
							}
						/>
						<Route
							path='/edit-match/:id'
							element={
								<ProtectedRoute authUser={authUser} user={user}>
									<EditData user={user} authUser={authUser} darkMode={darkMode} data={data} />
								</ProtectedRoute>
							}
						/>
						<Route
							path='/match/:id'
							element={
								<ProtectedRoute authUser={authUser} user={user}>
									<MatchAndSequencesDetails authUser={authUser} user={user} darkMode={darkMode} isChatbotOpen={isChatbotOpen} />
								</ProtectedRoute>
							}
						/>
						<Route
							path='/matches'
							element={
								<ProtectedRoute authUser={authUser} user={user}>
									<MatchPage authUser={authUser} user={user} darkMode={darkMode} />
								</ProtectedRoute>
							}
						/>
						<Route
							path='/statistics'
							element={
								<ProtectedRoute authUser={authUser} user={user}>
									<Statistics darkMode={darkMode} />
								</ProtectedRoute>
							}
						/>
						<Route
							path='/wiki/:type/:item'
							element={
								<ProtectedRoute authUser={authUser} user={user}>
									<Wiki user={user} authUser={authUser} darkMode={darkMode} />
								</ProtectedRoute>
							}
						/>
						<Route
							path='/sequences'
							element={
								<ProtectedRoute authUser={authUser} user={user}>
									<Sequences authUser={authUser} user={user} darkMode={darkMode} />
								</ProtectedRoute>
							}
						/>
						<Route
							path='/add-sequences/:id'
							element={
								<ProtectedRoute authUser={authUser} user={user}>
									<AddSequences authUser={authUser} user={user} darkMode={darkMode} />
								</ProtectedRoute>
							}
						/>
						<Route
							path='/edit-sequence/:id'
							element={
								<ProtectedRoute authUser={authUser} user={user}>
									<EditSequence user={user} authUser={authUser} darkMode={darkMode} />
								</ProtectedRoute>
							}
						/>
						<Route
							path='/training-resources'
							element={
								<ProtectedRoute authUser={authUser} user={user}>
									<TrainingResources user={user} authUser={authUser} darkMode={darkMode} />
								</ProtectedRoute>
							}
						/>
						<Route
							path='/add-training-resource'
							element={
								<ProtectedRoute authUser={authUser} user={user}>
									<AddTrainingResource user={user} darkMode={darkMode} authUser={authUser} />
								</ProtectedRoute>
							}
						/>
						<Route
							path='/edit-training-resource/:id'
							element={
								<ProtectedRoute authUser={authUser} user={user}>
									<EditTrainingResource user={user} darkMode={darkMode} authUser={authUser} />
								</ProtectedRoute>
							}
						/>
						<Route
							path='/resource/:id'
							element={
								<ProtectedRoute authUser={authUser} user={user}>
									<ResourcePage user={user} darkMode={darkMode} authUser={authUser} />
								</ProtectedRoute>
							}
						/>
						<Route
							path='/rewards-dashboard'
							element={
								<ProtectedRoute authUser={authUser} user={user}>
									<RewardsDashboard user={user} darkMode={darkMode} authUser={authUser} />
								</ProtectedRoute>
							}
						/>
						<Route path='/user-profile/:id' element={<UserProfile user={user} setUser={setUser} darkMode={darkMode} />} />
						<Route
							path='/user-folders/:id'
							element={
								<ProtectedRoute authUser={authUser} user={user}>
									<UserFolders user={user} darkMode={darkMode} authUser={authUser} />
								</ProtectedRoute>
							}
						/>
						<Route
							path='/show-folder/:id'
							element={
								<ProtectedRoute authUser={authUser} user={user}>
									<ShowFolder user={user} authUser={authUser} darkMode={darkMode} />
								</ProtectedRoute>
							}
						/>
						<Route
							path='/suggestions-form'
							element={
								<ProtectedRoute authUser={authUser} user={user}>
									<SuggestionsForm user={user} darkMode={darkMode} authUser={authUser} />
								</ProtectedRoute>
							}
						/>
						<Route
							path='/database-terms'
							element={
								<ProtectedRoute authUser={authUser} user={user}>
									<DatabaseTerms user={user} darkMode={darkMode} authUser={authUser} />
								</ProtectedRoute>
							}
						/>
					</Routes>
				</Box>
				<ChatBot darkMode={darkMode} user={user} messages={messages} setMessages={handleMessagesUpdate} isOpen={isChatbotOpen} toggleChatbot={handleChatbotToggle} />
				<Footer darkMode={darkMode} user={user} authUser={authUser} />
			</Box>
		</ThemeProvider>
	);
}

export default App;

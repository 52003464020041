import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Grid, Paper, Typography, Checkbox, FormControlLabel, Box, IconButton, Button, Autocomplete, TextField, Chip, ButtonGroup } from '@mui/material';
import { Triangle } from 'react-loader-spinner';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import FeaturedSequenceCard from '../components/FeaturedSequenceCard';
import GroupedSequences from '../components/GroupedSequences';
import { styled } from '@mui/system';
import useDebounce from '../customHooks/useDebounce';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import Head from '../components/Head/Head';

const API_URL = process.env.REACT_APP_API_URL;

const SequencesPage = ({ user, darkMode, authUser }) => {
	window.scrollTo(0, 0);
	const [sequences, setSequences] = useState([]);
	const [groupedSequences, setGroupedSequnces] = useState([]);
	const [displayedSequences, setDisplayedSequences] = useState([]);
	const [recentlyStudied, setRecentlyStudied] = useState([]);
	const [featuredSequences, setFeaturedSequences] = useState([]);
	const [loadingSequences, setLoadingSequences] = useState(true);
	const [totalMatchingSequences, setTotalMatchingSequences] = useState(0);
	const [loadingMore, setLoadingMore] = useState(false);
	const [fetching, setFetching] = useState(true);
	const [showFilters, setShowFilters] = useState(false);
	const [loadCount, setLoadCount] = useState(20);
	const isSmallScreen = useMediaQuery('(max-width:1024px)');
	const [cardView, setCardView] = useState(true);

	const [filterOptions, setFilterOptions] = useState({
		players: [],
		sequenceTypes: [],
		totalSequences: 0,
		youtubeCount: 0,
		resourceCount: 0,
		pointsCount: 0,
		startingPositions: [],
		endingPositions: [],
		movements: [],
		alternativeNames: {},
	});

	const [isTyping, setIsTyping] = useState(false);

	const [filters, setFilters] = useState({
		player: [],
		sequenceType: '',
		youtube: false,
		resource: false,
		points: false,
		startingPosition: '',
		endingPosition: '',
		startingPositionVariations: [],
		endingPositionVariations: [],
		movements: [],
	});
	const debouncedFilters = useDebounce(filters, 200);

	const isInitialMount = useRef(true);

	const autoCompleteStyles = {
		my: 1.5, // Adds margin below each Autocomplete
		'& .MuiAutocomplete-clearIndicator': {
			display: 'none', // Hide the clear "X" icon
		},
		'& .MuiOutlinedInput-root': {
			borderColor: darkMode ? 'grey' : 'lightgrey',
			'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
				borderColor: 'rgb(47, 199, 112)',
			},
			'&:hover .MuiOutlinedInput-notchedOutline': {
				borderColor: darkMode ? 'white' : 'grey',
			},
		},
		'& .MuiInputLabel-outlined': {
			color: darkMode ? 'lightgrey' : 'grey', // Change the color of the label text
			'&.Mui-focused': {
				color: 'rgb(47, 199, 112)', // Change the color of the label text when focused
			},
		},
	};

	const StyledListbox = styled('ul')({
		fontFamily: 'monospace',
		padding: 0,
		margin: 0,
		textOverflow: 'ellipsis',
	});

	const StyledOption = styled('li')({
		fontFamily: 'monospace',
		width: '100%',
		fontSize: '.9rem',
		':hover': {
			backgroundColor: darkMode ? '#3A594E' : 'rgb(224, 240, 230)',
			cursor: 'pointer',
		},
	});

	const fetchRecentlyStudied = async () => {
		if (!user?._id) return;
		try {
			const response = await fetch(`${API_URL}/sequence/recentlyStudiedSequences/${user?._id}`);
			const data = await response.json();
			setRecentlyStudied(data);
			const featResponse = await fetch(`${API_URL}/sequence/featuredSequences`);
			const featData = await featResponse.json();
			setFeaturedSequences(featData);
		} catch (error) {}
	};

	const fetchData = async () => {
		try {
			setFetching(true);
			const response = await fetch(`${API_URL}/sequence/sequenceData`);
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			const data = await response.json();
			setFilterOptions(data);
		} catch (error) {
			console.error('Error fetching sequence data:', error);
		} finally {
			setFetching(false);
		}
	};

	useEffect(() => {
		fetchRecentlyStudied();
		fetchData();
		setLoadingSequences(false);
		// eslint-disable-next-line
	}, [user]);

	const onGroupClick = (group) => {
		setCardView(true);
		setFilters({
			...filters,
			sequenceType: group._id.sequenceType,
			startingPosition: group._id.startingPosition,
			endingPosition: group._id.endingPosition,
		});
	};

	const renderOption = (props, option) => {
		return (
			<StyledOption {...props}>
				<span style={{ fontFamily: 'monospace' }}>{option.primaryLabel}</span>
				{option.alternatives && <span style={{ fontSize: '0.8em', color: 'gray', marginLeft: '5px' }}>*{option.alternatives.join(', ')}</span>}
			</StyledOption>
		);
	};

	const prepareOptions = (options = [], alternativeNames = {}) => {
		const seen = new Set();

		return options
			.filter((option) => option !== null && option !== undefined && option !== '') // Filter out null, undefined, and empty string values
			.flatMap((option) => {
				const primaryLabel = `${option}${alternativeNames[option] ? ` [${alternativeNames[option].join(', ')}]` : ''}`;
				if (seen.has(option)) return [];
				seen.add(option);

				const primaryOption = { label: primaryLabel, value: option, primaryLabel: option, alternatives: alternativeNames[option] };
				return [primaryOption];
			});
	};

	const renderNestedOptions = (positions = [], alternativeNames = {}) => {
		const seen = new Set();

		return positions
			.filter((position) => position !== null && position !== undefined && position._id !== '') // Filter out null, undefined, and empty string values
			.flatMap((position) => {
				const primaryLabel = `${position._id}${alternativeNames[position._id] ? ` [${alternativeNames[position._id].join(', ')}]` : ''}`;
				const primaryOption = { label: primaryLabel, value: position._id, primaryLabel: position._id, alternatives: alternativeNames[position._id] };

				if (seen.has(primaryLabel)) return [];
				seen.add(primaryLabel);

				const variationOptions = position.variations
					? position.variations
							.filter((variation) => variation !== null && variation !== undefined && variation !== '') // Filter out null, undefined, and empty string variations
							.flatMap((variation) => {
								const primaryVariationLabel = `${position._id} [${variation}]${alternativeNames[variation] ? ` (${alternativeNames[variation].join(', ')})` : ''}`;
								const primaryVariationOption = {
									label: primaryVariationLabel,
									value: `${position._id} : ${variation}`,
									primaryLabel: `${position._id} [${variation}]`,
									alternatives: alternativeNames[variation],
								};

								if (seen.has(primaryVariationLabel)) return [];
								seen.add(primaryVariationLabel);

								return [primaryVariationOption];
							})
					: [];

				return [primaryOption, ...variationOptions];
			});
	};

	const isOptionEqualToValue = (option, value) => {
		if (!option || !value) {
			return false;
		}
		if (typeof option === 'string' && typeof value === 'string') {
			return option === value;
		}
		if (option.value && value.value) {
			return option.value === value.value;
		}
		return option.label === value.label;
	};

	const getOptionLabel = (option) => {
		if (fetching) return 'Updating Filters...';
		if (!option) return '';
		if (typeof option === 'string') return option;
		return option.label;
	};

	const fetchSequencesAndUpdateFilters = useCallback(
		async (page = 1) => {
			if (isTyping) return;
			if (page === 1) {
				setLoadingSequences(true);
			} else {
				setLoadingMore(true);
			}

			// If all filters are empty, fetch initial data
			if (
				debouncedFilters.player.length === 0 &&
				debouncedFilters.sequenceType === '' &&
				debouncedFilters.startingPosition === '' &&
				debouncedFilters.endingPosition === '' &&
				debouncedFilters.movements.length === 0 &&
				debouncedFilters.startingPositionVariations.length === 0 &&
				debouncedFilters.endingPositionVariations.length === 0 &&
				!debouncedFilters.youtube &&
				!debouncedFilters.resource &&
				!debouncedFilters.points
			) {
				setSequences([]);
				setFetching(true);
				fetchData();
				setLoadingSequences(false);
				return;
			}
			try {
				setFetching(true);
				const sequenceResponse = await fetch(`${API_URL}/sequence/sequences`, {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({ ...debouncedFilters, page, limit: 20 }),
				});
				const sequenceData = await sequenceResponse.json();

				let filteredSequences = sequenceData.sequences;
				if (debouncedFilters.youtube && !debouncedFilters.resource && !debouncedFilters.points) {
					filteredSequences = filteredSequences.filter((seq) => seq.youtubeLink);
				} else if (debouncedFilters.resource && !debouncedFilters.youtube && !debouncedFilters.points) {
					filteredSequences = filteredSequences.filter((seq) => seq.resources.length > 0);
				} else if (debouncedFilters.points && !debouncedFilters.youtube && !debouncedFilters.resource) {
					filteredSequences = filteredSequences.filter((seq) => seq.points.length > 0);
				} else if (debouncedFilters.youtube && debouncedFilters.resource && !debouncedFilters.points) {
					filteredSequences = filteredSequences.filter((seq) => seq.youtubeLink && seq.resources.length > 0);
				} else if (debouncedFilters.youtube && debouncedFilters.points && !debouncedFilters.resource) {
					filteredSequences = filteredSequences.filter((seq) => seq.youtubeLink && seq.points.length > 0);
				} else if (debouncedFilters.resource && debouncedFilters.points && !debouncedFilters.youtube) {
					filteredSequences = filteredSequences.filter((seq) => seq.resources.length > 0 && seq.points.length > 0);
				} else if (debouncedFilters.youtube && debouncedFilters.resource && debouncedFilters.points) {
					filteredSequences = filteredSequences.filter((seq) => seq.youtubeLink && seq.resources.length > 0 && seq.points.length > 0);
				}

				setSequences((prevSequences) => (page === 1 ? filteredSequences : [...prevSequences, ...filteredSequences]));
				setTotalMatchingSequences(sequenceData.totalMatchingSequences);
				setGroupedSequnces(sequenceData.groupedSequences);
				setFilterOptions((prev) => {
					return {
						...prev,
						players: sequenceData.players,
						sequenceTypes: sequenceData.sequenceTypes,
						totalSequences: sequenceData.totalSequences,
						youtubeCount: sequenceData.youtubeCount,
						resourceCount: sequenceData.resourceCount,
						pointsCount: sequenceData.pointsCount,
						startingPositions: sequenceData.startingPositions,
						endingPositions: sequenceData.endingPositions,
						movements: sequenceData.movements,
						alternativeNames: sequenceData.alternativeNames,
					};
				});

				setDisplayedSequences((prevDisplayedSequences) => (page === 1 ? filteredSequences.slice(0, loadCount) : [...prevDisplayedSequences, ...filteredSequences.slice(0, loadCount)]));
				setFetching(false);
			} catch (error) {
				console.error('Error fetching sequences or filter data:', error);
			}

			if (page === 1) {
				setLoadingSequences(false);
			} else {
				setLoadingMore(false);
			}
		},
		[debouncedFilters, isTyping] // eslint-disable-line
	);

	// UseEffect to check for wikiGroup in localStorage
	useEffect(() => {
		const storedGroup = localStorage.getItem('wikiGroup');
		const storedSeqTerm = localStorage.getItem('seqTerm');
		const seqReq = JSON.parse(storedSeqTerm);
		const storedVariation = localStorage.getItem('wikiVariation');
		const reqVariation = JSON.parse(storedVariation);
		if (reqVariation) {
			setFilters({
				...filters,

				endingPosition: reqVariation.term ? reqVariation.term : '',
				endingPositionVariations: reqVariation.variation ? [reqVariation.variation] : [],
			});
			localStorage.removeItem('wikiVariation');
			localStorage.removeItem('wikiGroup');
			localStorage.removeItem('seqTerm');
		}
		if (seqReq) {
			setFilters({
				...filters,
				startingPosition: seqReq.starting ? seqReq.term : '',
				endingPosition: seqReq.starting ? '' : seqReq.term,
			});
			localStorage.removeItem('seqTerm');
			localStorage.removeItem('wikiGroup');
			localStorage.removeItem('wikiVariation');
		}
		if (storedGroup) {
			const group = JSON.parse(storedGroup);
			setFilters({
				...filters,
				sequenceType: group.sequenceType || '',
				startingPosition: group.startingPosition || '',
				endingPosition: group.endingPosition || '',
			});
			localStorage.removeItem('wikiGroup');
			localStorage.removeItem('seqTerm');
			localStorage.removeItem('wikiVariation');
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (isInitialMount.current) {
			isInitialMount.current = false;
		} else {
			fetchSequencesAndUpdateFilters();
		}
		// eslint-disable-next-line
	}, [debouncedFilters]);

	useEffect(() => {
		setDisplayedSequences(sequences.slice(0, loadCount));
		// eslint-disable-next-line
	}, [sequences, loadCount]);

	const handleFilterChange = (event, newValue, reason, name) => {
		if (event.type === 'click') {
			setIsTyping(false);
		}

		setFilters((prev) => {
			let updatedFilters = {
				...prev,
				[name]: Array.isArray(newValue) ? newValue.map((val) => val.value) : newValue?.value || '',
			};

			if (name === 'player') {
				updatedFilters[name] = Array.isArray(newValue) ? newValue.map((val) => val.value) : [];
			}

			// Preserve variations when updating other filters
			if (name !== 'startingPosition' && name !== 'endingPosition') {
				updatedFilters.startingPositionVariations = prev.startingPositionVariations;
				updatedFilters.endingPositionVariations = prev.endingPositionVariations;
			}

			if (name === 'startingPosition' || name === 'endingPosition') {
				updatedFilters[name === 'startingPosition' ? 'startingPositionVariations' : 'endingPositionVariations'] = [];
			}

			if (name === 'movements') {
				updatedFilters = {
					...prev,
					movements: Array.isArray(newValue) ? newValue.map((val) => val.value) : [],
				};
			}
			return updatedFilters;
		});
		setFetching(true);
		setLoadCount(20);
	};

	const handlePositionChange = (event, newValue, reason, name) => {
		if (event.type === 'click') {
			setIsTyping(false);
		}
		if (!newValue) {
			setFilters((prev) => {
				const updatedFilters = {
					...prev,
					[name]: '',
					[name === 'startingPosition' ? 'startingPositionVariations' : 'endingPositionVariations']: [],
				};

				return updatedFilters;
			});
			setLoadCount(20);
			return;
		}

		const value = newValue.value || newValue.label; // Ensure we get the correct value
		let position, variation;
		if (value.includes(' : ')) {
			[position, variation] = value.split(' : ').map((item) => item.trim());
		} else {
			position = value.trim();
			variation = null;
		}

		setFilters((prev) => {
			const variationsKey = name === 'startingPosition' ? 'startingPositionVariations' : 'endingPositionVariations';
			const currentPosition = prev[name];
			let newVariations = prev[variationsKey];

			if (currentPosition === position) {
				if (variation && !newVariations.includes(variation)) {
					newVariations = [...newVariations, variation];
				}
			} else {
				newVariations = variation ? [variation] : [];
			}

			const updatedFilters = {
				...prev,
				[name]: position,
				[variationsKey]: newVariations,
			};

			// Preserve other variations when updating positions
			if (name !== 'startingPosition') {
				updatedFilters.startingPositionVariations = prev.startingPositionVariations;
			}
			if (name !== 'endingPosition') {
				updatedFilters.endingPositionVariations = prev.endingPositionVariations;
			}

			return updatedFilters;
		});
		setLoadCount(20);
	};

	const handleCheckboxChange = (event) => {
		const { name, checked } = event.target;
		setFilters((prev) => {
			const updatedFilters = {
				...prev,
				[name]: checked,
			};
			setLoadCount(20);
			return updatedFilters;
		});
	};

	const handleRemoveFilter = (filter) => {
		setFilters((prev) => {
			const updatedFilters = {
				...prev,
				[filter]: '',
				// Reset variations when position is removed
				[filter === 'startingPosition' ? 'startingPositionVariations' : 'endingPositionVariations']: [],
			};
			if (
				updatedFilters.player.length === 0 &&
				updatedFilters.sequenceType === '' &&
				updatedFilters.youtube === false &&
				updatedFilters.resource === false &&
				updatedFilters.points === false &&
				updatedFilters.startingPosition === '' &&
				updatedFilters.endingPosition === '' &&
				updatedFilters.movements.length === 0 &&
				updatedFilters.startingPositionVariations.length === 0 &&
				updatedFilters.endingPositionVariations.length === 0
			) {
				// If all filters are cleared, reset sequences
				setSequences([]);
				setFetching(true);
				fetchData(); // Fetch initial data without any filters
				setLoadingSequences(false);
				return updatedFilters;
			} else {
				setLoadCount(20);
				return updatedFilters;
			}
		});
	};

	const handleRemoveVariation = (variation, name) => {
		const variationsKey = name === 'startingPosition' ? 'startingPositionVariations' : name === 'endingPosition' ? 'endingPositionVariations' : 'movements';
		setFilters((prev) => {
			const updatedVariations = prev[variationsKey].filter((v) => v !== variation);
			const updatedFilters = {
				...prev,
				[variationsKey]: updatedVariations,
			};

			if (
				updatedFilters.player.length === 0 &&
				updatedFilters.sequenceType === '' &&
				updatedFilters.youtube === false &&
				updatedFilters.resource === false &&
				updatedFilters.startingPosition === '' &&
				updatedFilters.endingPosition === '' &&
				updatedFilters.movements.length === 0 &&
				updatedFilters.startingPositionVariations.length === 0 &&
				updatedFilters.endingPositionVariations.length === 0
			) {
				// If all filters are cleared, reset sequences
				setSequences([]);
				setFetching(true);
				fetchData(); // Fetch initial data without any filters
				setLoadingSequences(false);
				return updatedFilters;
			} else {
				setLoadCount(20);
				return updatedFilters;
			}
		});
	};

	const toggleFilters = () => {
		setShowFilters((prev) => !prev);
	};

	const loadMore = () => {
		setLoadCount((prevLoadCount) => {
			const newLoadCount = prevLoadCount + 20;
			const newPage = Math.ceil(newLoadCount / 20);
			fetchSequencesAndUpdateFilters(newPage);
			return newLoadCount;
		});
	};

	return (
		<Grid container sx={{ height: '100%' }}>
			<Head title='Sequences' />
			{isSmallScreen ? (
				<>
					<Grid item xs={12}>
						<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2, background: darkMode ? 'rgb(18, 57, 44)' : 'rgb(231, 240, 230)' }}>
							<Typography variant='h6'>Advanced Sequence Filters</Typography>
							<IconButton onClick={toggleFilters}>{showFilters ? <CloseIcon /> : <MenuIcon />}</IconButton>
						</Box>
						{showFilters && (
							<Paper style={{ overflow: 'auto', background: darkMode ? 'rgb(18, 57, 44)' : 'rgb(231, 240, 230)', p: 2, textAlign: 'center' }}>
								<Typography variant='p' gutterBottom>
									Select filters to narrow down your search.
								</Typography>
								<Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '100%', p: 3 }}>
									<Autocomplete
										multiple
										onFocus={() => setIsTyping(true)}
										onBlur={() => setIsTyping(false)}
										disabled={fetching === true}
										sx={autoCompleteStyles}
										isOptionEqualToValue={isOptionEqualToValue}
										getOptionLabel={getOptionLabel}
										options={prepareOptions(filterOptions.players, filterOptions.alternativeNames)}
										value={filters.player.map((player) => ({ value: player, label: player }))}
										onChange={(e, newValue, reason) => handleFilterChange(e, newValue, reason, 'player')}
										renderInput={(params) => (
											<TextField
												{...params}
												InputProps={{
													...params.InputProps,
													style: { fontFamily: 'monospace' },
												}}
												InputLabelProps={{
													...params.InputLabelProps,
													style: { fontFamily: 'monospace' },
												}}
												label='Players'
												variant='outlined'
											/>
										)}
										ListboxComponent={(props) => <StyledListbox {...props} />}
										renderOption={(props, option) => (
											<StyledOption {...props}>
												<span style={{ fontFamily: 'monospace' }}>{option.primaryLabel}</span>
												{option.alternatives && <span style={{ fontSize: '0.8em', color: 'gray', marginLeft: '5px' }}>*{option.alternatives.join(', ')}</span>}
											</StyledOption>
										)}
									/>

									<Autocomplete
										onFocus={() => setIsTyping(true)}
										onBlur={() => setIsTyping(false)}
										disabled={fetching === true}
										sx={autoCompleteStyles}
										isOptionEqualToValue={isOptionEqualToValue}
										getOptionLabel={getOptionLabel}
										options={prepareOptions(filterOptions.sequenceTypes, filterOptions.alternativeNames)}
										value={filters.sequenceType ? { value: filters.sequenceType, label: filters.sequenceType } : null}
										onChange={(e, newValue, reason) => handleFilterChange(e, newValue, reason, 'sequenceType')}
										renderInput={(params) => (
											<TextField
												{...params}
												InputProps={{
													...params.InputProps,
													style: { fontFamily: 'monospace' },
												}}
												InputLabelProps={{
													...params.InputLabelProps,
													style: { fontFamily: 'monospace' },
												}}
												label='Sequence Type'
												variant='outlined'
											/>
										)}
										ListboxComponent={(props) => <StyledListbox {...props} />}
										renderOption={(props, option) => (
											<StyledOption {...props}>
												<span style={{ fontFamily: 'monospace' }}>{option.primaryLabel}</span>
												{option.alternatives && <span style={{ fontSize: '0.8em', color: 'gray', marginLeft: '5px' }}>*{option.alternatives.join(', ')}</span>}
											</StyledOption>
										)}
									/>
									{filters?.sequenceType !== '' && <Chip label={filters.sequenceType} onDelete={() => handleRemoveFilter('sequenceType')} sx={{ mb: 1 }} />}

									<Autocomplete
										onFocus={() => setIsTyping(true)}
										onBlur={() => setIsTyping(false)}
										disabled={fetching === true}
										sx={autoCompleteStyles}
										isOptionEqualToValue={isOptionEqualToValue}
										getOptionLabel={getOptionLabel}
										options={renderNestedOptions(filterOptions.startingPositions, filterOptions.alternativeNames)}
										value={filters.startingPosition}
										onChange={(e, newValue, reason) => handlePositionChange(e, newValue, reason, 'startingPosition')}
										renderInput={(params) => (
											<TextField
												{...params}
												InputProps={{
													...params.InputProps,
													style: { fontFamily: 'monospace' },
												}}
												InputLabelProps={{
													...params.InputLabelProps,
													style: { fontFamily: 'monospace' },
												}}
												label='Starting Position'
												variant='outlined'
											/>
										)}
										ListboxComponent={(props) => <StyledListbox {...props} />}
										renderOption={renderOption}
									/>
									<Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '100%' }}>
										{filters?.startingPosition !== '' && (
											<Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '100%' }}>
												<Typography>
													<span style={{ opacity: '.7' }}>Position: </span>
												</Typography>
												<Chip
													label={<Typography style={{ fontFamily: 'monospace', fontSize: '0.8rem' }}>{filters.startingPosition}</Typography>}
													onDelete={() => handleRemoveFilter('startingPosition')}
													sx={{ mb: 1 }}
												/>
											</Box>
										)}
										{filters?.startingPositionVariations.length > 0 && (
											<Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '100%' }}>
												<Typography>
													<span style={{ opacity: '.7' }}>Variations: </span>
												</Typography>

												{filters?.startingPositionVariations.map((variation, idx) => (
													<Chip
														key={idx}
														label={<Typography style={{ fontFamily: 'monospace', fontSize: '0.8rem' }}>{variation}</Typography>}
														onDelete={() => handleRemoveVariation(variation, 'startingPosition')}
														sx={{ m: 0.5 }}
													/>
												))}
											</Box>
										)}
									</Box>

									<Autocomplete
										onFocus={() => setIsTyping(true)}
										onBlur={() => setIsTyping(false)}
										disabled={fetching === true}
										sx={autoCompleteStyles}
										isOptionEqualToValue={isOptionEqualToValue}
										getOptionLabel={getOptionLabel}
										options={renderNestedOptions(filterOptions.endingPositions, filterOptions.alternativeNames)}
										value={filters.endingPosition}
										onChange={(e, newValue, reason) => handlePositionChange(e, newValue, reason, 'endingPosition')}
										renderInput={(params) => (
											<TextField
												{...params}
												InputProps={{
													...params.InputProps,
													style: { fontFamily: 'monospace' },
												}}
												InputLabelProps={{
													...params.InputLabelProps,
													style: { fontFamily: 'monospace' },
												}}
												label='Ending Position'
												variant='outlined'
											/>
										)}
										ListboxComponent={(props) => <StyledListbox {...props} />}
										renderOption={renderOption}
									/>

									<Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '100%' }}>
										{filters?.endingPosition !== '' && (
											<Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '100%' }}>
												<Typography>
													<span style={{ opacity: '.7' }}>Position: </span>
												</Typography>
												<Chip
													label={<Typography style={{ fontFamily: 'monospace', fontSize: '0.8rem' }}>{filters.endingPosition}</Typography>}
													onDelete={() => handleRemoveFilter('endingPosition')}
													sx={{ mb: 1 }}
												/>
											</Box>
										)}
										{filters?.endingPositionVariations.length > 0 && (
											<Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '100%' }}>
												<Typography>
													<span style={{ opacity: '.7' }}>Variations: </span>
												</Typography>
												{filters?.endingPositionVariations.map((variation, idx) => (
													<Chip
														key={idx}
														label={<Typography style={{ fontFamily: 'monospace', fontSize: '0.8rem' }}>{variation}</Typography>}
														onDelete={() => handleRemoveVariation(variation, 'endingPosition')}
														sx={{ m: 0.5 }}
													/>
												))}
											</Box>
										)}
									</Box>
									<Autocomplete
										multiple
										onFocus={() => setIsTyping(true)}
										onBlur={() => setIsTyping(false)}
										disabled={fetching === true}
										sx={autoCompleteStyles}
										isOptionEqualToValue={isOptionEqualToValue}
										getOptionLabel={getOptionLabel}
										options={prepareOptions(filterOptions.movements, filterOptions.alternativeNames)}
										value={filters.movements.map((movement) => ({ value: movement, label: movement }))}
										onChange={(e, newValue, reason) => handleFilterChange(e, newValue, reason, 'movements')}
										renderInput={(params) => (
											<TextField
												{...params}
												InputProps={{
													...params.InputProps,
													style: { fontFamily: 'monospace' },
												}}
												InputLabelProps={{
													...params.InputLabelProps,
													style: { fontFamily: 'monospace' },
												}}
												label='Movements'
												variant='outlined'
											/>
										)}
										ListboxComponent={(props) => <StyledListbox {...props} />}
										renderOption={(props, option) => (
											<StyledOption {...props}>
												<span style={{ fontFamily: 'monospace' }}>{option.primaryLabel}</span>
												{option.alternatives && <span style={{ fontSize: '0.8em', color: 'gray', marginLeft: '5px' }}>*{option.alternatives.join(', ')}</span>}
											</StyledOption>
										)}
									/>

									<Box sx={{ display: 'flex', flexWrap: 'wrap', minWidth: '100%', mt: 2 }}>
										<FormControlLabel
											sx={{ width: 'fit-content' }}
											control={
												<Checkbox
													sx={{
														color: darkMode ? 'white' : 'black', // Default (unchecked) color
														'&.Mui-checked': {
															color: darkMode ? 'white' : 'black', // Checked color
															'&:after': {
																content: '""',
																position: 'absolute',
																backgroundColor: 'black',
																width: '100%',
																height: '100%',
																zIndex: '-1',
															},
														},
													}}
													disabled={filterOptions.youtubeCount === 0}
													checked={filters.youtube}
													onChange={handleCheckboxChange}
													name='youtube'
												/>
											}
											label={'Youtube'}
										/>

										<FormControlLabel
											sx={{ width: 'fit-content' }}
											control={
												<Checkbox
													sx={{
														color: darkMode ? 'white' : 'black', // Default (unchecked) color
														'&.Mui-checked': {
															color: darkMode ? 'white' : 'black', // Checked color
															'&:after': {
																content: '""',
																position: 'absolute',
																backgroundColor: 'black',
																width: '100%',
																height: '100%',
																zIndex: '-1',
															},
														},
													}}
													disabled={filterOptions.resourceCount === 0}
													checked={filters.resource}
													onChange={handleCheckboxChange}
													name='resource'
												/>
											}
											label={'Resources'}
										/>

										<FormControlLabel
											sx={{ width: 'fit-content' }}
											control={
												<Checkbox
													sx={{
														color: darkMode ? 'white' : 'black', // Default (unchecked) color
														'&.Mui-checked': {
															color: darkMode ? 'white' : 'black', // Checked color
															'&:after': {
																content: '""',
																position: 'absolute',
																backgroundColor: 'black',
																width: '100%',
																height: '100%',
																zIndex: '-1',
															},
														},
													}}
													disabled={filterOptions.pointsCount === 0 || fetching === true}
													checked={filters.points}
													onChange={handleCheckboxChange}
													name='points'
												/>
											}
											label={'Points'}
										/>
									</Box>
									{/* Clear Button*/}
									<Button
										sx={{ background: 'rgb(47,160, 112)', color: darkMode ? '#000' : '#fff', padding: '5px', mt: 2, ':hover': { background: 'rgb(47,180,112)' } }}
										variant='contained'
										onClick={() =>
											setFilters({
												player: [],
												sequenceType: '',
												youtube: false,
												resource: false,
												points: false,
												startingPosition: '',
												endingPosition: '',
												startingPositionVariations: [],
												endingPositionVariations: [],
												movements: [],
											})
										}
									>
										Clear Filters
									</Button>
								</Box>
							</Paper>
						)}
					</Grid>
				</>
			) : (
				<Grid item xs={3} style={{ minHeight: '100%', maxWidth: '300px', flexShrink: 0 }}>
					<Paper
						style={{
							minHeight: loadingSequences ? '100vh' : '100%',
							overflow: 'auto',
							background: darkMode ? 'rgb(18, 57, 44)' : 'rgb(231, 240, 230)',
							display: 'flex',
							maxWidth: '300px',
						}}
					>
						<Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '100%', p: 3 }}>
							<Typography variant='p' gutterBottom sx={{ fontWeight: '600' }}>
								Advanced Sequence Filters
							</Typography>
							<Typography variant='p' gutterBottom>
								Select filters to narrow down your search.
							</Typography>
							<Autocomplete
								multiple
								onFocus={() => setIsTyping(true)}
								onBlur={() => setIsTyping(false)}
								disabled={fetching === true}
								sx={autoCompleteStyles}
								isOptionEqualToValue={isOptionEqualToValue}
								getOptionLabel={getOptionLabel}
								options={prepareOptions(filterOptions.players, filterOptions.alternativeNames)}
								value={filters.player.map((player) => ({ value: player, label: player }))}
								onChange={(e, newValue, reason) => handleFilterChange(e, newValue, reason, 'player')}
								renderInput={(params) => (
									<TextField
										{...params}
										InputProps={{
											...params.InputProps,
											style: { fontFamily: 'monospace' },
										}}
										InputLabelProps={{
											...params.InputLabelProps,
											style: { fontFamily: 'monospace' },
										}}
										label='Players'
										variant='outlined'
									/>
								)}
								ListboxComponent={(props) => <StyledListbox {...props} />}
								renderOption={(props, option) => (
									<StyledOption {...props}>
										<span style={{ fontFamily: 'monospace' }}>{option.primaryLabel}</span>
										{option.alternatives && <span style={{ fontSize: '0.8em', color: 'gray', marginLeft: '5px' }}>*{option.alternatives.join(', ')}</span>}
									</StyledOption>
								)}
							/>

							<Autocomplete
								onFocus={() => setIsTyping(true)}
								onBlur={() => setIsTyping(false)}
								disabled={fetching === true}
								sx={autoCompleteStyles}
								isOptionEqualToValue={isOptionEqualToValue}
								getOptionLabel={getOptionLabel}
								options={prepareOptions(filterOptions.sequenceTypes, filterOptions.alternativeNames)}
								value={filters.sequenceType ? { value: filters.sequenceType, label: filters.sequenceType } : null}
								onChange={(e, newValue, reason) => handleFilterChange(e, newValue, reason, 'sequenceType')}
								renderInput={(params) => (
									<TextField
										{...params}
										InputProps={{
											...params.InputProps,
											style: { fontFamily: 'monospace' },
										}}
										InputLabelProps={{
											...params.InputLabelProps,
											style: { fontFamily: 'monospace' },
										}}
										label='Sequence Type'
										variant='outlined'
									/>
								)}
								ListboxComponent={(props) => <StyledListbox {...props} />}
								renderOption={(props, option) => (
									<StyledOption {...props}>
										<span style={{ fontFamily: 'monospace' }}>{option.primaryLabel}</span>
										{option.alternatives && <span style={{ fontSize: '0.8em', color: 'gray', marginLeft: '5px' }}>*{option.alternatives.join(', ')}</span>}
									</StyledOption>
								)}
							/>
							{filters?.sequenceType !== '' && <Chip label={filters.sequenceType} onDelete={() => handleRemoveFilter('sequenceType')} sx={{ mb: 1 }} />}

							<Autocomplete
								onFocus={() => setIsTyping(true)}
								onBlur={() => setIsTyping(false)}
								disabled={fetching === true}
								sx={autoCompleteStyles}
								isOptionEqualToValue={isOptionEqualToValue}
								getOptionLabel={getOptionLabel}
								options={renderNestedOptions(filterOptions.startingPositions, filterOptions.alternativeNames)}
								value={filters.startingPosition}
								onChange={(e, newValue, reason) => handlePositionChange(e, newValue, reason, 'startingPosition')}
								renderInput={(params) => (
									<TextField
										{...params}
										InputProps={{
											...params.InputProps,
											style: { fontFamily: 'monospace' },
										}}
										InputLabelProps={{
											...params.InputLabelProps,
											style: { fontFamily: 'monospace' },
										}}
										label='Starting Position'
										variant='outlined'
									/>
								)}
								ListboxComponent={(props) => <StyledListbox {...props} />}
								renderOption={renderOption}
							/>
							<Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '100%' }}>
								{filters?.startingPosition !== '' && (
									<Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '100%' }}>
										<Typography>
											<span style={{ opacity: '.7' }}>Position: </span>
										</Typography>
										<Chip
											label={<Typography style={{ fontFamily: 'monospace', fontSize: '0.8rem' }}>{filters.startingPosition}</Typography>}
											onDelete={() => handleRemoveFilter('startingPosition')}
											sx={{ mb: 1 }}
										/>
									</Box>
								)}
								{filters?.startingPositionVariations.length > 0 && (
									<Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '100%' }}>
										<Typography>
											<span style={{ opacity: '.7' }}>Variations: </span>
										</Typography>

										{filters?.startingPositionVariations.map((variation, idx) => (
											<Chip
												key={idx}
												label={<Typography style={{ fontFamily: 'monospace', fontSize: '0.8rem' }}>{variation}</Typography>}
												onDelete={() => handleRemoveVariation(variation, 'startingPosition')}
												sx={{ m: 0.5 }}
											/>
										))}
									</Box>
								)}
							</Box>

							<Autocomplete
								onFocus={() => setIsTyping(true)}
								onBlur={() => setIsTyping(false)}
								disabled={fetching === true}
								sx={autoCompleteStyles}
								isOptionEqualToValue={isOptionEqualToValue}
								getOptionLabel={getOptionLabel}
								options={renderNestedOptions(filterOptions.endingPositions, filterOptions.alternativeNames)}
								value={filters.endingPosition}
								onChange={(e, newValue, reason) => handlePositionChange(e, newValue, reason, 'endingPosition')}
								renderInput={(params) => (
									<TextField
										{...params}
										InputProps={{
											...params.InputProps,
											style: { fontFamily: 'monospace' },
										}}
										InputLabelProps={{
											...params.InputLabelProps,
											style: { fontFamily: 'monospace' },
										}}
										label='Ending Position'
										variant='outlined'
									/>
								)}
								ListboxComponent={(props) => <StyledListbox {...props} />}
								renderOption={renderOption}
							/>

							<Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '100%' }}>
								{filters?.endingPosition !== '' && (
									<Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '100%' }}>
										<Typography>
											<span style={{ opacity: '.7' }}>Position: </span>
										</Typography>
										<Chip
											label={<Typography style={{ fontFamily: 'monospace', fontSize: '0.8rem' }}>{filters.endingPosition}</Typography>}
											onDelete={() => handleRemoveFilter('endingPosition')}
											sx={{ mb: 1 }}
										/>
									</Box>
								)}
								{filters?.endingPositionVariations.length > 0 && (
									<Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '100%' }}>
										<Typography>
											<span style={{ opacity: '.7' }}>Variations: </span>
										</Typography>
										{filters?.endingPositionVariations.map((variation, idx) => (
											<Chip
												key={idx}
												label={<Typography style={{ fontFamily: 'monospace', fontSize: '0.8rem' }}>{variation}</Typography>}
												onDelete={() => handleRemoveVariation(variation, 'endingPosition')}
												sx={{ m: 0.5 }}
											/>
										))}
									</Box>
								)}
							</Box>
							<Autocomplete
								multiple
								onFocus={() => setIsTyping(true)}
								onBlur={() => setIsTyping(false)}
								disabled={fetching === true}
								sx={autoCompleteStyles}
								isOptionEqualToValue={isOptionEqualToValue}
								getOptionLabel={getOptionLabel}
								options={prepareOptions(filterOptions.movements, filterOptions.alternativeNames)}
								value={filters.movements.map((movement) => ({ value: movement, label: movement }))}
								onChange={(e, newValue, reason) => handleFilterChange(e, newValue, reason, 'movements')}
								renderInput={(params) => (
									<TextField
										{...params}
										InputProps={{
											...params.InputProps,
											style: { fontFamily: 'monospace' },
										}}
										InputLabelProps={{
											...params.InputLabelProps,
											style: { fontFamily: 'monospace' },
										}}
										label='Movements'
										variant='outlined'
									/>
								)}
								ListboxComponent={(props) => <StyledListbox {...props} />}
								renderOption={(props, option) => (
									<StyledOption {...props}>
										<span style={{ fontFamily: 'monospace' }}>{option.primaryLabel}</span>
										{option.alternatives && <span style={{ fontSize: '0.8em', color: 'gray', marginLeft: '5px' }}>*{option.alternatives.join(', ')}</span>}
									</StyledOption>
								)}
							/>

							<Box sx={{ display: 'flex', flexWrap: 'wrap', minWidth: '100%', mt: 2 }}>
								<FormControlLabel
									sx={{ width: 'fit-content' }}
									control={
										<Checkbox
											sx={{
												color: darkMode ? 'white' : 'black', // Default (unchecked) color
												'&.Mui-checked': {
													color: darkMode ? 'white' : 'black', // Checked color
													'&:after': {
														content: '""',
														position: 'absolute',
														backgroundColor: 'black',
														width: '100%',
														height: '100%',
														zIndex: '-1',
													},
												},
											}}
											disabled={filterOptions.youtubeCount === 0 || fetching === true}
											checked={filters.youtube}
											onChange={handleCheckboxChange}
											name='youtube'
										/>
									}
									label={'Youtube'}
								/>
								<FormControlLabel
									sx={{ width: 'fit-content' }}
									control={
										<Checkbox
											sx={{
												color: darkMode ? 'white' : 'black', // Default (unchecked) color
												'&.Mui-checked': {
													color: darkMode ? 'white' : 'black', // Checked color
													'&:after': {
														content: '""',
														position: 'absolute',
														backgroundColor: 'black',
														width: '100%',
														height: '100%',
														zIndex: '-1',
													},
												},
											}}
											disabled={filterOptions.resourceCount === 0 || fetching === true}
											checked={filters.resource}
											onChange={handleCheckboxChange}
											name='resource'
										/>
									}
									label={'Resources'}
								/>

								<FormControlLabel
									sx={{ width: 'fit-content' }}
									control={
										<Checkbox
											sx={{
												color: darkMode ? 'white' : 'black', // Default (unchecked) color
												'&.Mui-checked': {
													color: darkMode ? 'white' : 'black', // Checked color
													'&:after': {
														content: '""',
														position: 'absolute',
														backgroundColor: 'black',
														width: '100%',
														height: '100%',
														zIndex: '-1',
													},
												},
											}}
											disabled={filterOptions.pointsCount === 0 || fetching === true}
											checked={filters.points}
											onChange={handleCheckboxChange}
											name='points'
										/>
									}
									label={'Points'}
								/>
							</Box>

							{/* Clear Button*/}
							<Button
								sx={{ background: 'rgb(47,160, 112)', color: darkMode ? '#000' : '#fff', padding: '5px', mt: 2, ':hover': { background: 'rgb(47,180,112)' } }}
								variant='contained'
								onClick={() =>
									setFilters({
										player: [],
										sequenceType: '',
										youtube: false,
										resource: false,
										points: false,
										startingPosition: '',
										endingPosition: '',
										startingPositionVariations: [],
										endingPositionVariations: [],
										movements: [],
									})
								}
							>
								Clear Filters
							</Button>
						</Box>
					</Paper>
				</Grid>
			)}
			<Grid item sx={{ p: 1, width: isSmallScreen ? '100%' : '75%' }}>
				<Box sx={{ width: '100%', p: isSmallScreen ? 0 : 2 }}>
					<Typography variant='h4' gutterBottom sx={{ textAlign: isSmallScreen ? 'center' : 'inherit' }}>
						Sequences
					</Typography>
					{displayedSequences.length !== 0 && !loadingSequences && (
						<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
							<ButtonGroup
								sx={{
									mb: 2,
									boxShadow: 'none',
									background: 'none',
									border: 'none',
									p: 0,
								}}
								variant='contained'
								aria-label='view toggle'
							>
								<Button
									onClick={() => setCardView(true)}
									sx={{
										borderRadius: '5rem',
										backgroundColor: cardView ? 'rgb(47,160, 112)' : 'grey.400',
										color: cardView ? '#303030' : 'rgb(100, 100, 100)', // Adjust text color for better contrast
										boxShadow: 'none',
										border: 'none',
										outline: 'none',
										marginRight: '-2px',
										'&:hover': {
											backgroundColor: cardView ? 'rgb(47,160, 112)' : 'grey.400',
											color: '#303030',
										},
									}}
								>
									<ViewModuleIcon />
								</Button>
								<Button
									onClick={() => setCardView(false)}
									sx={{
										borderRadius: '5rem',
										backgroundColor: !cardView ? 'rgb(47,160, 112)' : 'grey.300',
										color: !cardView ? '#303030' : 'rgb(100,100,100)', // Adjust text color for better contrast
										boxShadow: 'none',
										border: 'none',
										marginLeft: '-2px',
										outline: 'none',
										'&:hover': {
											backgroundColor: !cardView ? 'rgb(47,160, 112)' : 'grey.400',
											color: '#303030',
										},
									}}
								>
									<ViewListIcon />
								</Button>
							</ButtonGroup>
						</Box>
					)}
					{loadingSequences && (
						<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', pt: 4 }}>
							<Triangle height='100' width='100' color='rgb(47, 199, 112)' ariaLabel='triangle-loading' />
							<Typography variant='h6' style={{ color: 'rgb(47,199,112)' }}>
								Loading...
							</Typography>
						</Box>
					)}

					<>
						{displayedSequences.length !== 0 && !loadingSequences ? (
							<>
								{cardView ? (
									<>
										<Typography variant='h6' gutterBottom sx={{ textAlign: isSmallScreen ? 'center' : 'inherit' }}>
											Results ({displayedSequences.length} of {totalMatchingSequences})
										</Typography>

										<Box sx={{ width: '100%', mb: 2, display: 'flex', alignItems: 'center', flexDirection: isSmallScreen ? 'column' : 'row', overflowX: 'auto' }}>
											{displayedSequences?.map((sequence, idx) => (
												<Box sx={{ p: 2 }} key={idx}>
													<FeaturedSequenceCard sequence={sequence} user={user} authUser={authUser} darkMode={darkMode} />
												</Box>
											))}
											{displayedSequences.length < totalMatchingSequences && (
												<Box sx={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', alignItems: 'center', justifyContent: 'center', minWidth: 'fit-content' }}>
													{loadingMore && (
														<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', mx: 2 }}>
															<Triangle height='100' width='100' color='rgb(47, 199, 112)' ariaLabel='triangle-loading' />
														</Box>
													)}
													<Button
														onClick={loadMore}
														sx={{
															backgroundColor: 'rgb(47,160, 112)',
															color: darkMode ? '#000' : '#fff',
															marginLeft: 0,
															'&:hover': { backgroundColor: 'rgb(47, 180, 112)', color: 'white' },
															padding: '5px',
														}}
													>
														Load More
													</Button>
												</Box>
											)}
										</Box>
									</>
								) : (
									<GroupedSequences groups={groupedSequences} onGroupClick={onGroupClick} wiki={false} />
								)}
							</>
						) : (
							<>
								{recentlyStudied?.length !== 0 && !loadingSequences && (
									<>
										<Typography variant='h6' gutterBottom sx={{ textAlign: isSmallScreen ? 'center' : 'inherit' }}>
											Recently Viewed
										</Typography>
										<Box
											sx={{
												width: '100%',
												mb: 4,
												display: 'flex',
												flexDirection: isSmallScreen ? 'column' : 'row',
												overflowX: 'auto',
												borderBottom: isSmallScreen ? '4px solid green' : 'none',
												alignItems: 'center',
											}}
										>
											{recentlyStudied?.map((sequence, idx) => (
												<Box sx={{ p: 2 }} key={idx}>
													<FeaturedSequenceCard sequence={sequence} user={user} authUser={authUser} darkMode={darkMode} />
												</Box>
											))}
										</Box>
									</>
								)}

								{featuredSequences.length !== 0 && !loadingSequences && (
									<>
										<Typography variant='h6' gutterBottom sx={{ textAlign: isSmallScreen ? 'center' : 'inherit' }}>
											Featured Sequences
										</Typography>
										<Box
											sx={{
												width: '100%',
												mb: 2,
												display: 'flex',
												flexDirection: isSmallScreen ? 'column' : 'row',
												overflowX: 'auto',
												alignItems: 'center',
											}}
										>
											{featuredSequences?.map((sequence, idx) => (
												<Box sx={{ p: isSmallScreen ? 0 : 2 }} key={idx}>
													<FeaturedSequenceCard sequence={sequence} user={user} authUser={authUser} darkMode={darkMode} />
												</Box>
											))}
										</Box>
									</>
								)}
							</>
						)}
					</>
				</Box>
			</Grid>
		</Grid>
	);
};

export default SequencesPage;

import React, { useState, useEffect } from 'react';
import { Card, CardContent, Tooltip, Typography, Grid, IconButton, Box, useMediaQuery } from '@mui/material';
import { formatVariations, formatMovementText } from '../utils/helperFunctions';
import InfoIcon from '@mui/icons-material/Info';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { useNavigate } from 'react-router-dom';
import LoginAndSubscribeDialog from './LoginAndSubscribeDialog';
import YouTubeLiteEmbed from './YouTubeLiteEmbed';

const SequenceCard = React.memo(({ sequence, darkMode, user, authUser }) => {
	const navigate = useNavigate();
	const isMobile = useMediaQuery('(max-width:1275px)');
	const [featuredSeqEmbed, setFeaturedSeqEmbed] = useState(null);
	const [showOverlay, setShowOverlay] = useState(false);
	const [open, setOpen] = useState(false);
	const handleNavigation = (event, path, sequence) => {
		if (user?.subscriptionStatus !== 'active' && path !== '/account' && path !== '/auth' && path !== '/' && path !== '/logout') {
			event.preventDefault();
			setOpen(true); // Open dialog
		} else {
			event.preventDefault();
			navigate(`/match/${sequence.match_id}?sequence_id=${sequence._id}`);
		}
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleCloseAndLogin = () => {
		setOpen(false);
		navigate('/auth');
	};

	const handleCloseAndSubscribe = () => {
		setOpen(false);
		navigate('/account');
	};

	const overlayContentStyles = {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		bgcolor: darkMode ? 'rgba(19, 56, 44,.95)' : 'rgba(241, 255, 235, 0.9)',
		color: darkMode ? 'rgb(200,200,200)' : 'rgb(30,30,30)',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		p: 2,
		boxSizing: 'border-box',
		border: '3px solid rgb(0, 109, 56)',
	};

	const greenLineStyle = {
		height: '2px',
		width: '100%',
		backgroundColor: 'rgb(0, 109, 56)',
		my: 1, // Margin top and bottom for spacing
	};

	useEffect(() => {
		const createEmbedUrlFromLink = (link) => {
			const videoIdMatch = link.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]+)/);
			if (!videoIdMatch) return null;
			const videoId = videoIdMatch[1];
			return { videoId, startTime: sequence.startingPositionVideoTimestamp ? sequence.startingPositionVideoTimestamp - 2 : 0 };
		};

		if (sequence.youtubeLink) {
			setFeaturedSeqEmbed(createEmbedUrlFromLink(sequence.youtubeLink));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sequence.youtubeLink, sequence.startingPositionVideoTimestamp]);

	return (
		<Card sx={{ maxWidth: 350, minWidth: 350, position: 'relative', my: isMobile ? 2 : 0, background: darkMode ? 'rgb(18, 57, 44)' : 'rgb(228, 246, 221)' }}>
			{featuredSeqEmbed ? (
				<Box style={{ position: 'relative', width: '100%' /* 16:9 Aspect Ratio */ }}>
					<YouTubeLiteEmbed videoId={featuredSeqEmbed.videoId} start={featuredSeqEmbed.startTime} />
				</Box>
			) : (
				<Box style={{ position: 'relative', width: '100%', paddingTop: '56.25%' /* 16:9 Aspect Ratio */ }}>
					<iframe
						style={{
							position: 'absolute',
							top: 0,
							left: 0,
							width: '100%',
							height: '100%',
							border: 0,
							background: '#000',
						}}
						allow='accelerometer; autoplay; encrypted-media; gyroscope; '
						allowFullScreen
						title='YouTube video player'
					/>
					<Tooltip title='Watch on external site?' placement='top' arrow>
						<IconButton
							sx={{
								position: 'absolute',
								top: '50%',
								left: '50%',
								transform: 'translate(-50%, -50%)',
								color: darkMode ? '#fff' : 'rgb(100,100,100)',
								bgcolor: 'transparent',
								'&:hover': {
									bgcolor: 'background.default',
								},
								size: 'large',
							}}
							onClick={() => window.open(sequence.link, '_blank')}
						>
							<PlayCircleOutlineIcon sx={{ fontSize: '3rem' }} />
						</IconButton>
					</Tooltip>
				</Box>
			)}
			{showOverlay && (
				<Box sx={overlayContentStyles}>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							width: '100%',
							mb: 1,
							'& .MuiTypography-root': {
								wordWrap: 'break-word',
							},
						}}
					>
						<Typography variant='body1' sx={{ fontFamily: 'monospace', fontWeight: 300, width: '50%', fontSize: '.8rem' }}>
							Starting Position
						</Typography>
						<Typography variant='body1' sx={{ fontFamily: 'monospace', fontWeight: 600, width: '50%', fontSize: '.8rem' }}>
							{sequence.startingPosition}
						</Typography>
					</Box>
					<Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
						<Typography variant='body1' sx={{ fontFamily: 'monospace', fontWeight: 300, width: '50%', fontSize: '.8rem' }}>
							Variation
						</Typography>
						<Typography variant='body1' sx={{ fontFamily: 'monospace', fontWeight: 600, width: '50%', fontSize: '.8rem' }}>
							{formatVariations(
								sequence.startingPositionVariationPlayer1Lower1,
								sequence.startingPositionVariationPlayer1Lower2,
								sequence.startingPositionVariationPlayer1Upper1,
								sequence.startingPositionVariationPlayer1Upper2
							)}
						</Typography>
					</Box>
					<Box sx={greenLineStyle} />
					<Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
						<Typography variant='body1' sx={{ fontFamily: 'monospace', fontWeight: 300, width: '50%', fontSize: '.8rem' }}>
							Movements
						</Typography>
						<Typography variant='body1' sx={{ fontFamily: 'monospace', fontWeight: 600, width: '50%', fontSize: '.8rem' }}>
							{formatMovementText(sequence)}
						</Typography>
					</Box>
					<Box sx={greenLineStyle} />
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							width: '100%',
							mb: 1,
							'& .MuiTypography-root': {
								wordWrap: 'break-word',
							},
						}}
					>
						<Typography variant='body1' sx={{ fontFamily: 'monospace', fontWeight: 300, width: '50%', fontSize: '.8rem' }}>
							Ending Position
						</Typography>
						<Typography variant='body1' sx={{ fontFamily: 'monospace', fontWeight: 600, width: '50%', fontSize: '.8rem' }}>
							{sequence.endingPosition}
						</Typography>
					</Box>
					<Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
						<Typography variant='body1' sx={{ fontFamily: 'monospace', fontWeight: 300, width: '50%', fontSize: '.8rem' }}>
							Variation
						</Typography>
						<Typography variant='body1' sx={{ fontFamily: 'monospace', fontWeight: 600, width: '50%', fontSize: '.8rem' }}>
							{sequence.sequenceType === 'Submission'
								? sequence.player1SubVariation
									? sequence.player1SubVariation
									: 'N/A'
								: formatVariations(
										sequence.endingPositionVariationPlayer1Lower1,
										sequence.endingPositionVariationPlayer1Lower2,
										sequence.endingPositionVariationPlayer1Upper1,
										sequence.endingPositionVariationPlayer1Upper2
								  )}
						</Typography>
					</Box>
				</Box>
			)}

			<CardContent>
				<Grid container justifyContent='space-between' alignItems='center'>
					<Grid item mb={1}>
						<Typography variant='subtitle1' style={{ fontFamily: 'monospace' }}>
							{sequence.sequenceType.toUpperCase()}
						</Typography>
					</Grid>
				</Grid>
				<Typography variant='subtitle2' component='p' mb={1}>
					{sequence.startingPosition} &rarr; {sequence.endingPosition}
				</Typography>
				<Typography variant='body2' color='text.secondary' sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					{sequence.player1} vs. {sequence.player2}
					<span>
						{user?.subscriptionStatus === 'active' ? (
							<a
								href={`/match/${sequence.match_id}?sequence_id=${sequence._id}`}
								onClick={(e) => handleNavigation(e, `/match/${sequence.match_id}?sequence_id=${sequence._id}`, sequence)}
								style={{ textDecoration: 'none', cursor: 'pointer' }}
							>
								<Typography
									variant='button'
									sx={{
										fontFamily: 'monospace',
										position: 'absolute',
										bottom: '5px',
										right: '50px',
										textDecoration: 'underline',
										fontWeight: 300,
										color: darkMode ? 'rgb(0,130,60)' : 'rgb(0, 109, 56)',
										':hover': { cursor: 'pointer', color: darkMode ? 'rgb(0,150,70)' : 'rgb(0,130,60)' },
									}}
								>
									View
								</Typography>
							</a>
						) : (
							<Typography
								variant='button'
								onClick={(e) => handleNavigation(e, `/match/${sequence.match_id}`, sequence)}
								sx={{
									fontFamily: 'monospace',
									position: 'absolute',
									bottom: '5px',
									right: '50px',
									textDecoration: 'underline',
									fontWeight: 300,
									color: darkMode ? 'rgb(0,130,60)' : 'rgb(0, 109, 56)',
									':hover': { cursor: 'pointer', color: darkMode ? 'rgb(0,150,70)' : 'rgb(0,130,60)' },
								}}
							>
								View
							</Typography>
						)}
						<IconButton
							style={{
								position: 'absolute',
								bottom: '-2px',
								right: '0',
							}}
							onMouseEnter={() => setShowOverlay(!showOverlay)}
							onMouseLeave={() => setShowOverlay(!showOverlay)}
						>
							<InfoIcon style={{ color: darkMode ? 'rgb(200,200,200)' : 'rgb(30,30,30)' }} />
						</IconButton>
					</span>
				</Typography>
			</CardContent>
			<LoginAndSubscribeDialog open={open} handleClose={handleClose} authUser={authUser} user={user} handleCloseAndLogin={handleCloseAndLogin} handleCloseAndSubscribe={handleCloseAndSubscribe} />
		</Card>
	);
});

export default SequenceCard;

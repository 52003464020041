import React, { useState, useEffect, useRef } from 'react';
import { TextField, Select, Grid, MenuItem, FormControl, InputLabel, Button, Typography, Checkbox, FormControlLabel } from '@mui/material';
import { Triangle } from 'react-loader-spinner';
import { Container } from '@mui/material';
import Head from '../components/Head/Head';
import axios from 'axios';
import '../App.css';

const API_URL = process.env.REACT_APP_API_URL;

function AddTrainingResource({ user, darkMode, authUser }) {
	const formRef = useRef(null);
	const [loading, setLoading] = useState(false);
	const [timeError, setTimeError] = useState(false);
	const [needToFetch, setNeedToFetch] = useState(false);

	// Initialize state for the form
	const [trainingResource, setTrainingResource] = useState({
		url: '',
		instructor: '',
		free: false,
		volume: '',
		chapterTitle: '',
		createdByUser: '',
		title: '',
		note: '',
		type: '',
		timestamp: '',
	});

	const trainingResourceTypes = ['Concept', 'Drill', 'Game', 'Strategy', 'Technique', 'Other'];

	// Function to handle form submission and create resource
	const createTrainingResource = async (newTrainingResource) => {
		try {
			// Make a POST request to create a new resource
			const response = await axios.post(`${API_URL}/training-resource`, newTrainingResource);
			if (response.data) {
				return response.data;
			} else {
				throw new Error('Invalid server response');
			}
		} catch (error) {
			console.error('Error creating resource:', error);
		}
	};

	const handleTimeChange = (e) => {
		const value = e.target.value;
		// regex to enforce either mm:ss or hh:mm:ss, with hours between 0-23 and minutes/seconds between 0-59
		const isValidTime = /^((?:[01]\d|2[0-3]):)?([0-5]\d):([0-5]\d)$/.test(value);

		if (isValidTime && e.target.name === 'timestamp') {
			setTrainingResource((prev) => ({ ...prev, timestamp: value }));
			setTimeError(false);
		} else {
			setTimeError(true);
		}
	};

	// Handle input changes and update state
	const handleInputChange = (e) => {
		let { name, value } = e.target;

		if (name === 'timestamp') {
			handleTimeChange(e);
		}

		// This is a TextField or Select change
		if (e.target.type === 'checkbox') {
			// This is a Checkbox change
			name = e.target.name;
			value = e.target.checked;
		}

		setTrainingResource({
			...trainingResource,
			[name]: value,
		});
	};

	// Function to handle form submission and create trainingResource
	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		// Prepare new resource data
		const newTrainingResource = {
			...trainingResource,
			createdByUser: user._id,
		};

		// Create the new training resource
		const newResource = await createTrainingResource(newTrainingResource);

		// Refresh the list of resources
		if (newResource) setNeedToFetch(!needToFetch);

		// Reset form state
		setTrainingResource({
			...trainingResource,
			url: '',
			volume: '',
			title: '',
			chapterTitle: '',
			note: '',
			type: '',
			instructor: '',
			timestamp: '',
			free: false,
		});

		// Scroll to top of page
		window.scrollTo(0, 0);
		setLoading(false);
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	if (loading) {
		return (
			<>
				<Head title='Add Training Resources Page' />
				<Container maxWidth='md' sx={{ display: 'flex', justifyContent: 'center' }}>
					<div className='loader'>
						<Triangle color='rgb(47, 199, 112)' size={100} />
						<Typography variant='h5' sx={{ color: 'rgb(47, 199, 112)', marginTop: '1rem' }}>
							Loading...
						</Typography>
					</div>
				</Container>
			</>
		);
	}

	return (
		<>
			<Head title='Add Training Resources Page' />
			<Container sx={{ marginBottom: '50px' }}>
				<Typography sx={{ textAlign: 'center', marginTop: '16px', marginBottom: '16px' }} variant='h6'>
					Add New Training Resource
				</Typography>

				<form onSubmit={handleSubmit} ref={formRef} style={{ marginTop: '2rem' }}>
					<Grid container spacing={3}>
						<Grid item xs={12} md={12} lg={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
							{/* trainingResource Type Dropdown */}
							<FormControl
								sx={{
									width: '49%',
									'&:hover .MuiOutlinedInput-notchedOutline:not(.Mui-focused)': {
										borderColor: darkMode ? 'white!important' : 'grey',
									},
									'&:hover .MuiInputLabel-root:not(.Mui-focused)': {
										color: darkMode ? 'white' : '#000',
									},
									'&& input': {
										color: darkMode ? '#fff' : '#000',
									},
									'& .MuiOutlinedInput-notchedOutline': {
										borderColor: darkMode ? 'grey!important' : 'grey',
									},
									'& .MuiOutlinedInput-notchedOutline.Mui-focused': {
										borderColor: darkMode ? 'rgb(47,199,112)' : '#000',
									},
									'& .MuiOutlinedInput-root': {
										backgroundColor: darkMode ? '#000' : '#f7f7f7',
									},
									'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
										borderColor: 'rgb(47, 199, 112)!important',
									},
									'& .MuiInputLabel-root': {
										color: darkMode ? 'grey' : 'grey',
									},
									'& .MuiInputLabel-outlined.Mui-focused': {
										color: 'rgb(47, 180, 112)',
									},
								}}
							>
								<InputLabel sx={{ '&.MuiInputLabel-shrink': { transform: 'translate(14px, -16px) scale(0.75)' } }}>Resource Type</InputLabel>
								<Select name='type' value={trainingResource.type} onChange={handleInputChange} required>
									{trainingResourceTypes.map((type) => (
										<MenuItem key={type} value={type}>
											{type}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							<TextField
								autoComplete='off'
								sx={{
									width: '49%',
									'&:hover .MuiOutlinedInput-notchedOutline:not(.Mui-focused)': {
										borderColor: darkMode ? 'white!important' : 'grey',
									},
									'&:hover .MuiInputLabel-root:not(.Mui-focused)': {
										color: darkMode ? 'white' : '#000',
									},
									'&& input': {
										color: darkMode ? '#fff' : '#000',
									},
									'& .MuiOutlinedInput-notchedOutline': {
										borderColor: darkMode ? 'grey!important' : 'grey',
									},
									'& .MuiOutlinedInput-notchedOutline.Mui-focused': {
										borderColor: darkMode ? 'rgb(47,199,112)' : '#000',
									},
									'& .MuiOutlinedInput-root': {
										backgroundColor: darkMode ? '#000' : '#f7f7f7',
									},
									'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
										borderColor: 'rgb(47, 199, 112)!important',
									},
									'& .MuiInputLabel-root': {
										color: darkMode ? 'grey' : 'grey',
									},
									'& .MuiInputLabel-outlined.Mui-focused': {
										color: 'rgb(47, 180, 112)',
									},
								}}
								required
								id='instructor'
								name='instructor'
								label='Resource Instructor'
								variant='outlined'
								value={trainingResource.instructor}
								onChange={handleInputChange}
							/>
						</Grid>

						<Grid item xs={12} md={12} lg={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
							{/* trainingResource Title */}
							<TextField
								autoComplete='off'
								sx={{
									width: '49%',
									'&:hover .MuiOutlinedInput-notchedOutline:not(.Mui-focused)': {
										borderColor: darkMode ? 'white!important' : 'grey',
									},
									'&:hover .MuiInputLabel-root:not(.Mui-focused)': {
										color: darkMode ? 'white' : '#000',
									},
									'&& input': {
										color: darkMode ? '#fff' : '#000',
									},
									'& .MuiOutlinedInput-notchedOutline': {
										borderColor: darkMode ? 'grey!important' : 'grey',
									},
									'& .MuiOutlinedInput-notchedOutline.Mui-focused': {
										borderColor: darkMode ? 'rgb(47,199,112)' : '#000',
									},
									'& .MuiOutlinedInput-root': {
										backgroundColor: darkMode ? '#000' : '#f7f7f7',
									},
									'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
										borderColor: 'rgb(47, 199, 112)!important',
									},
									'& .MuiInputLabel-root': {
										color: darkMode ? 'grey' : 'grey',
									},
									'& .MuiInputLabel-outlined.Mui-focused': {
										color: 'rgb(47, 180, 112)',
									},
								}}
								required
								id='title'
								name='title'
								label='Resource Title'
								variant='outlined'
								placeholder='Do not use / or special characters in title'
								value={trainingResource.title}
								onChange={handleInputChange}
							/>

							<TextField
								autoComplete='off'
								sx={{
									width: '49%',
									marginLeft: '8px',
									marginRight: '8px',
									'&:hover .MuiOutlinedInput-notchedOutline:not(.Mui-focused)': {
										borderColor: darkMode ? 'white!important' : 'grey',
									},
									'&:hover .MuiInputLabel-root:not(.Mui-focused)': {
										color: darkMode ? 'white' : '#000',
									},
									'&& input': {
										color: darkMode ? '#fff' : '#000',
									},
									'& .MuiOutlinedInput-notchedOutline': {
										borderColor: darkMode ? 'grey!important' : 'grey',
									},
									'& .MuiOutlinedInput-notchedOutline.Mui-focused': {
										borderColor: darkMode ? 'rgb(47,199,112)' : '#000',
									},
									'& .MuiOutlinedInput-root': {
										backgroundColor: darkMode ? '#000' : '#f7f7f7',
									},
									'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
										borderColor: 'rgb(47, 199, 112)!important',
									},
									'& .MuiInputLabel-root': {
										color: darkMode ? 'grey' : 'grey',
									},
									'& .MuiInputLabel-outlined.Mui-focused': {
										color: 'rgb(47, 180, 112)',
									},
								}}
								id='chapterTitle'
								name='chapterTitle'
								label='Chapter Title'
								variant='outlined'
								value={trainingResource.chapterTitle}
								onChange={handleInputChange}
							/>

							{/* Resource URL   */}
							<TextField
								autoComplete='off'
								sx={{
									width: '49%',
									'&:hover .MuiOutlinedInput-notchedOutline:not(.Mui-focused)': {
										borderColor: darkMode ? 'white!important' : 'grey',
									},
									'&:hover .MuiInputLabel-root:not(.Mui-focused)': {
										color: darkMode ? 'white' : '#000',
									},
									'&& input': {
										color: darkMode ? '#fff' : '#000',
									},
									'& .MuiOutlinedInput-notchedOutline': {
										borderColor: darkMode ? 'grey!important' : 'grey',
									},
									'& .MuiOutlinedInput-notchedOutline.Mui-focused': {
										borderColor: darkMode ? 'rgb(47,199,112)' : '#000',
									},
									'& .MuiOutlinedInput-root': {
										backgroundColor: darkMode ? '#000' : '#f7f7f7',
									},
									'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
										borderColor: 'rgb(47, 199, 112)!important',
									},
									'& .MuiInputLabel-root': {
										color: darkMode ? 'grey' : 'grey',
									},
									'& .MuiInputLabel-outlined.Mui-focused': {
										color: 'rgb(47, 180, 112)',
									},
								}}
								required
								id='url'
								name='url'
								label='Resource URL'
								variant='outlined'
								value={trainingResource.url}
								onChange={handleInputChange}
							/>
						</Grid>

						<Grid item xs={12} md={12} lg={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
							<TextField
								autoComplete='off'
								sx={{
									width: '33%',
									'&:hover .MuiOutlinedInput-notchedOutline:not(.Mui-focused)': {
										borderColor: darkMode ? 'white!important' : 'grey',
									},
									'&:hover .MuiInputLabel-root:not(.Mui-focused)': {
										color: darkMode ? 'white' : '#000',
									},
									'&& input': {
										color: darkMode ? '#fff' : '#000',
									},
									'& .MuiOutlinedInput-notchedOutline': {
										borderColor: darkMode ? 'grey!important' : 'grey',
									},
									'& .MuiOutlinedInput-notchedOutline.Mui-focused': {
										borderColor: darkMode ? 'rgb(47,199,112)' : '#000',
									},
									'& .MuiOutlinedInput-root': {
										backgroundColor: darkMode ? '#000' : '#f7f7f7',
									},
									'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
										borderColor: 'rgb(47, 199, 112)!important',
									},
									'& .MuiInputLabel-root': {
										color: darkMode ? 'grey' : 'grey',
									},
									'& .MuiInputLabel-outlined.Mui-focused': {
										color: 'rgb(47, 180, 112)',
									},
								}}
								id='volume'
								name='volume'
								label='Volume'
								variant='outlined'
								value={trainingResource.volume}
								onChange={handleInputChange}
								type='text'
							/>
							<FormControlLabel
								sx={{
									width: '33%',
									display: 'flex',
									justifyContent: 'center',
									color: darkMode ? '#fff' : 'grey',
									'&.Mui-checked': { color: darkMode ? '#fff' : 'black' },
								}}
								control={<Checkbox sx={{ color: 'grey', '&.Mui-checked': { color: darkMode ? '#fff' : 'black' } }} checked={trainingResource.free} onChange={handleInputChange} name='free' />}
								label='Free?'
							/>
							{/* instructional timestamp   */}
							<TextField
								autoComplete='off'
								sx={{
									width: '33%',
									'&:hover .MuiOutlinedInput-notchedOutline:not(.Mui-focused)': {
										borderColor: darkMode ? 'white!important' : 'grey',
									},
									'&:hover .MuiInputLabel-root:not(.Mui-focused)': {
										color: darkMode ? 'white' : '#000',
									},
									'&& input': {
										color: darkMode ? '#fff' : '#000',
									},
									'& .MuiOutlinedInput-notchedOutline': {
										borderColor: darkMode ? 'grey!important' : 'grey',
									},
									'& .MuiOutlinedInput-notchedOutline.Mui-focused': {
										borderColor: darkMode ? 'rgb(47,199,112)' : '#000',
									},
									'& .MuiOutlinedInput-root': {
										backgroundColor: darkMode ? '#000' : '#f7f7f7',
									},
									'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
										borderColor: 'rgb(47, 199, 112)!important',
									},
									'& .MuiInputLabel-root': {
										color: darkMode ? 'grey' : 'grey',
									},
									'& .MuiInputLabel-outlined.Mui-focused': {
										color: 'rgb(47, 180, 112)',
									},
								}}
								required
								id='timestamp'
								name='timestamp'
								label='Resource Timestamp'
								variant='outlined'
								value={trainingResource.timestamp}
								onChange={handleInputChange}
								error={timeError}
								helperText={timeError ? 'Please enter a valid time (mm:ss) OR (hh:mm:ss)' : 'Time in video (mm:ss) OR (hh:mm:ss)'}
							/>
						</Grid>

						<Grid item xs={12} md={12} lg={12} sx={{ display: 'flex', justifyContent: 'center' }}>
							<TextField
								autoComplete='off'
								sx={{
									width: '100%',
									'&:hover .MuiOutlinedInput-notchedOutline:not(.Mui-focused)': {
										borderColor: darkMode ? 'white!important' : 'grey',
									},
									'&:hover .MuiInputLabel-root:not(.Mui-focused)': {
										color: darkMode ? 'white' : '#000',
									},
									'&& input': {
										color: darkMode ? '#fff' : '#000',
									},
									'& .MuiOutlinedInput-notchedOutline': {
										borderColor: darkMode ? 'grey!important' : 'grey',
									},
									'& .MuiOutlinedInput-notchedOutline.Mui-focused': {
										borderColor: darkMode ? 'rgb(47,199,112)' : '#000',
									},
									'& .MuiOutlinedInput-root': {
										backgroundColor: darkMode ? '#000' : '#f7f7f7',
									},
									'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
										borderColor: 'rgb(47, 199, 112)!important',
									},
									'& .MuiInputLabel-root': {
										color: darkMode ? 'grey' : 'grey',
									},
									'& .MuiInputLabel-outlined.Mui-focused': {
										color: 'rgb(47, 180, 112)',
									},
								}}
								id='note'
								name='note'
								label='Resource note'
								required
								variant='outlined'
								value={trainingResource.note}
								onChange={handleInputChange}
							/>
						</Grid>
						{/* Submit Button */}
						<Grid item xs={12}>
							<Button
								sx={{
									backgroundColor: 'rgb(47, 160, 112)',
									color: 'white',
									marginBottom: '16px',
									'&:hover': { backgroundColor: 'rgb(47, 180, 112)', color: 'white' },
								}}
								type='submit'
								variant='contained'
								disabled={loading}
							>
								Add Resource
							</Button>
						</Grid>
					</Grid>
				</form>
			</Container>
		</>
	);
}

export default AddTrainingResource;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;

const SubmissionPositionVariationComponent = () => {
	const [variations, setVariations] = useState([]);
	const [newVariation, setNewVariation] = useState({ position: '', positionVariationUpper: [], positionVariationLower: [] });
	const [editVariation, setEditVariation] = useState(null);
	const [error, setError] = useState('');

	useEffect(() => {
		fetchVariations();
	}, []);

	const fetchVariations = async () => {
		try {
			const response = await axios.get(`${API_URL}/submission-position-variations`);
			setVariations(response.data);
		} catch (error) {
			setError('Failed to fetch variations');
		}
	};

	const handleAddVariation = async () => {
		try {
			const response = await axios.post(`${API_URL}/submission-position-variations`, newVariation);
			setVariations([...variations, response.data]);
			setNewVariation({ position: '', positionVariationUpper: [], positionVariationLower: [] });
		} catch (error) {
			setError('Failed to add variation');
		}
	};

	const handleEdit = (id) => {
		const variationToEdit = variations.find((variation) => variation._id === id);
		if (!variationToEdit) {
			return;
		}
		setEditVariation({ ...variationToEdit });
		window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top of the page
	};

	const handleEditVariation = async () => {
		try {
			const response = await axios.put(`${API_URL}/submission-position-variations/${editVariation._id}`, editVariation);
			const updatedVariations = variations.map((variation) => {
				if (variation._id === editVariation._id) {
					return response.data;
				}
				return variation;
			});
			setVariations(updatedVariations);
			setEditVariation(null);
		} catch (error) {
			setError('Failed to edit variation');
		}
	};

	const handleDeleteVariation = async (id) => {
		try {
			await axios.delete(`${API_URL}/submission-position-variations/${id}`);
			setVariations(variations.filter((variation) => variation._id !== id));
		} catch (error) {
			setError('Failed to delete variation');
		}
	};

	return (
		<div>
			<h2>Submission Position Variations</h2>
			{error && <div>Error: {error}</div>}
			{!editVariation && (
				<form onSubmit={handleAddVariation}>
					<div>
						<label htmlFor='position'>Position:</label>
						<input
							id='position'
							type='text'
							value={newVariation.position}
							onChange={(e) => setNewVariation({ ...newVariation, position: e.target.value })}
							placeholder='Position'
							style={{ width: '100%' }}
							required
						/>
					</div>
					<div>
						<label htmlFor='positionVariationUpper'>Upper Variations:</label>
						<input
							id='positionVariationUpper'
							type='text'
							value={newVariation.positionVariationUpper.join(',')}
							onChange={(e) => setNewVariation({ ...newVariation, positionVariationUpper: e.target.value.split(',') })}
							placeholder='Upper Variations (comma-separated)'
							style={{ width: '100%' }}
						/>
					</div>
					<div>
						<label htmlFor='positionVariationLower'>Lower Variations:</label>
						<input
							id='positionVariationLower'
							type='text'
							value={newVariation.positionVariationLower.join(',')}
							onChange={(e) => setNewVariation({ ...newVariation, positionVariationLower: e.target.value.split(',') })}
							placeholder='Lower Variations (comma-separated)'
							style={{ width: '100%' }}
						/>
					</div>
					<button style={{ marginLeft: '1rem', marginBottom: '1rem', padding: '4px', cursor: 'pointer' }} type='submit'>
						Add Variation
					</button>
				</form>
			)}
			{editVariation && (
				<form onSubmit={handleEditVariation}>
					<div>
						<label htmlFor='editPosition'>Position:</label>
						<input
							id='editPosition'
							type='text'
							value={editVariation.position}
							onChange={(e) => setEditVariation({ ...editVariation, position: e.target.value })}
							placeholder='Position'
							style={{ width: '100%' }}
							required
						/>
					</div>
					<div>
						<label htmlFor='editPositionVariationUpper'>Upper Variations:</label>
						<input
							id='editPositionVariationUpper'
							type='text'
							value={editVariation.positionVariationUpper.join(',')}
							onChange={(e) => setEditVariation({ ...editVariation, positionVariationUpper: e.target.value.split(',') })}
							placeholder='Upper Variations (comma-separated)'
							style={{ width: '100%' }}
						/>
					</div>
					<div>
						<label htmlFor='editPositionVariationLower'>Lower Variations:</label>
						<input
							id='editPositionVariationLower'
							type='text'
							value={editVariation.positionVariationLower.join(',')}
							onChange={(e) => setEditVariation({ ...editVariation, positionVariationLower: e.target.value.split(',') })}
							placeholder='Lower Variations (comma-separated)'
							style={{ width: '100%' }}
						/>
					</div>
					<button style={{ padding: '4px', margin: '4px', cursor: 'pointer' }} type='submit'>
						Save
					</button>
					<button style={{ padding: '4px', margin: '4px', cursor: 'pointer' }} onClick={() => setEditVariation(null)}>
						Cancel
					</button>
				</form>
			)}
			<ul>
				{variations.map((variation) => (
					<li key={variation._id}>
						<div>
							<strong>Position: </strong>
							{variation.position}
						</div>
						<div>
							<strong>Upper Variations:</strong> {variation.positionVariationUpper.join(', ')}
						</div>
						<div>
							<strong>Lower Variations:</strong> {variation.positionVariationLower.join(', ')}
						</div>
						<button style={{ padding: '4px', margin: '4px', cursor: 'pointer' }} onClick={() => handleEdit(variation._id)}>
							Edit
						</button>
						<button style={{ padding: '4px', margin: '4px', cursor: 'pointer' }} onClick={() => handleDeleteVariation(variation._id)}>
							Delete
						</button>
					</li>
				))}
			</ul>
		</div>
	);
};

export default SubmissionPositionVariationComponent;

import { useEffect, useState, useRef } from 'react';
import { Container, Grid, Box, Card, CardContent, Typography, useMediaQuery, Tooltip, Button, Collapse, Divider } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Head from '../components/Head/Head';
import SequenceCard from '../components/FeaturedSequenceCard';
import ResourceCard from '../components/FeaturedResourceCard';
import MatchCard from '../components/FeaturedMatchCard';
import LoginAndSubscribeDialog from '../components/LoginAndSubscribeDialog';
import LIMIBJJ from '../assets/limibjj.webp';
import { Triangle } from 'react-loader-spinner';
import '../App.css';

const API_URL = process.env.REACT_APP_API_URL;

function FeaturedPage({ user, darkMode, authUser }) {
	const navigate = useNavigate();
	const isMobile = useMediaQuery('(max-width:900px)');
	const [feature, setFeature] = useState(null);
	const [loading, setLoading] = useState(true);
	const [videoSrc, setVideoSrc] = useState('');
	const [youTubeEmbed, setYouTubeEmbed] = useState('');
	const [youTubeSeqEmbed, setYouTubeSeqEmbed] = useState('');
	const [youTubeResourceEmbed, setYouTubeResourceEmbed] = useState('');
	const youtubeEmbedRef = useRef(null);
	const [notesExpanded, setNotesExpanded] = useState(true);
	const [activeTab, setActiveTab] = useState('sequence');
	const [featuredSequences, setFeaturedSequences] = useState([]);
	const [featuredResources, setFeaturedResources] = useState([]);
	const [featuredMatches, setFeaturedMatches] = useState([]);
	const [open, setOpen] = useState(false);
	const handleNavigation = (event, path) => {
		if (user?.subscriptionStatus !== 'active' && path !== '/account' && path !== '/auth' && path !== '/' && path !== '/logout') {
			event.preventDefault();
			setOpen(true); // Open dialog
		} else {
			navigate(path);
		}
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleCloseAndLogin = () => {
		setOpen(false);
		navigate('/auth');
	};

	const handleCloseAndSubscribe = () => {
		setOpen(false);
		navigate('/account');
	};

	const handleNavigate = (path) => {
		navigate(path);
	};

	const viewSequence = (e, sequence) => {
		e.preventDefault();
		navigate(`/match/${sequence.match_id}?sequence_id=${sequence._id}`);
	};

	const viewResource = (e, resource) => {
		e.preventDefault();
		const queryParams = new URLSearchParams({ timestamp: resource.timestamp }).toString();
		navigate(`/resource/${resource._id}?${queryParams}`);
	};

	// Function to handle tab click
	const handleTabClick = (tab) => {
		setActiveTab(tab);
	};

	// Helper function to get button style
	const getButtonStyle = (tab) => ({
		width: '32%',
		borderRadius: '5rem',
		background: activeTab === tab ? 'rgb(47,120,112)' : 'rgb(47,160,112)', // Active tab gets a different background
		color: darkMode ? '#000' : '#fff',
		'&:hover': { background: activeTab === tab ? 'rgb(47,110,112)' : 'rgb(47,180,112)' },
	});

	const toggleNotes = () => {
		setNotesExpanded(!notesExpanded);
	};

	const handleNoteClick = (note) => {
		// Extract timestamp from the note (format: HH:MM:SS or MM:SS)
		const timestampMatch = note.match(/(\d+):(\d+):(\d+)|(\d+):(\d+)/);
		let totalSeconds = 0;

		if (timestampMatch) {
			let hours = 0,
				minutes = 0,
				seconds = 0;

			if (timestampMatch[3]) {
				// HH:MM:SS format
				hours = parseInt(timestampMatch[1], 10);
				minutes = parseInt(timestampMatch[2], 10);
				seconds = parseInt(timestampMatch[3], 10);
			} else {
				// MM:SS format
				minutes = parseInt(timestampMatch[4], 10);
				seconds = parseInt(timestampMatch[5], 10);
			}

			totalSeconds = hours * 3600 + minutes * 60 + seconds;
		}

		// Check if youtubeEmbed exists and extract video ID
		if (youTubeEmbed) {
			const videoIdMatch = youTubeEmbed.match(/youtube\.com\/embed\/([a-zA-Z0-9_-]+)/);
			if (videoIdMatch && videoIdMatch[1]) {
				const videoId = videoIdMatch[1];
				const newEmbedUrl = `https://www.youtube.com/embed/${videoId}?start=${totalSeconds}&autoplay=1&mute=0`;
				setYouTubeEmbed(newEmbedUrl);
			}
		}
		// scroll to youtube embed
		youtubeEmbedRef.current.scrollIntoView({ behavior: 'smooth' });
	};

	const createEmbedUrlFromLink = (link, data) => {
		// Extract the video ID from the link
		const videoIdMatch = link.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]+)/);
		if (!videoIdMatch) return null;
		const videoId = videoIdMatch[1];

		const timestampToSeconds = (timestamp) => {
			const parts = timestamp.split(':').map(Number); // Convert all parts to numbers
			let seconds = 0;

			if (parts.length === 3) {
				// Format is hh:mm:ss
				seconds = parts[0] * 3600 + parts[1] * 60 + parts[2];
			} else if (parts.length === 2) {
				// Format is mm:ss
				seconds = parts[0] * 60 + parts[1];
			}
			return seconds;
		};

		// Determine the start time based on provided data
		let startTime = 0;
		if (data.startingTime) {
			return `https://www.youtube.com/embed/${videoId}?start=0&autoplay0&mute=0`;
		} else if (data.startingPositionVideoTimestamp) {
			startTime = data.startingPositionVideoTimestamp - 2;
			return `https://www.youtube.com/embed/${videoId}?start=${startTime}&autoplay=0&mute=0`;
		} else if (data.timestamp) {
			startTime = timestampToSeconds(data.timestamp);
			return `https://www.youtube.com/embed/${videoId}?start=${startTime}&autoplay=0&mute=0`;
		}
	};

	useEffect(() => {
		const fetchData = async (endpoint, setState) => {
			try {
				const response = await axios.get(`${API_URL}/data/${endpoint}`);
				if (response.data) setState(response.data);
			} catch (error) {
				console.error(`Error fetching ${endpoint}:`, error);
			}
		};

		// Using Promise.all to execute both fetch operations in parallel
		Promise.all([fetchData('random-sequences', setFeaturedSequences), fetchData('random-resources', setFeaturedResources), fetchData('recent-matches', setFeaturedMatches)]).catch((error) => {
			console.error('Error fetching data:', error);
		});
	}, []);
	useEffect(() => {
		const fetchFeatured = async () => {
			try {
				const response = await axios.get(`${API_URL}/featured`);
				setFeature(response.data);
				let shortsUrl = response.data.feature.youtubeLink;
				let embedUrl = shortsUrl.replace('/shorts/', '/embed/');
				embedUrl = embedUrl + '?mute=0';
				setVideoSrc(embedUrl);

				const { match, sequence, resource } = response.data;
				if (match && match.youtubeLink) {
					const embedUrl = createEmbedUrlFromLink(match.youtubeLink, { startingTime: '0:00' });
					setYouTubeEmbed(embedUrl);
				}

				if (sequence && match.youtubeLink) {
					const sequenceEmbedUrl = createEmbedUrlFromLink(match.youtubeLink, { startingPositionVideoTimestamp: sequence.startingPositionVideoTimestamp });
					setYouTubeSeqEmbed(sequenceEmbedUrl);
				}

				if (resource && resource.url) {
					const resourceEmbedUrl = createEmbedUrlFromLink(resource.url, { timestamp: resource.timestamp });
					setYouTubeResourceEmbed(resourceEmbedUrl);
				}
			} catch (error) {
				console.error('Error fetching featured :', error);
			} finally {
				setLoading(false);
				window.scrollTo(0, 0, 'smooth');
			}
		};

		fetchFeatured();
	}, []);

	if (loading) {
		return (
			<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
				<Triangle color='rgb(47, 199, 112)' height={100} width={100} />
			</Box>
		);
	}
	return (
		<>
			<Head title='Home Page' />

			<Container
				style={{
					height: '100%',
					minWidth: '100%',
					display: 'flex',
					flexDirection: isMobile ? 'column' : 'row',
					justifyContent: 'center',
					padding: isMobile ? '0' : '1rem',
				}}
			>
				<Grid
					item
					xs={12} // Full width on mobile
					md={8} // 8 out of 12 columns = 66.66%, approximately 70%
					sx={{
						height: '100%',
						width: isMobile ? '100%' : '60%',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						borderRadius: '.5rem',
						padding: isMobile ? '1rem' : '0',
					}}
				>
					<Box
						ref={youtubeEmbedRef}
						sx={{
							width: '100%',
							display: 'inline-flex',
							alignItems: 'center',
							justifyContent: 'space-between',
							padding: '0',
						}}
					>
						<Typography variant='h6' component='h3' sx={{ fontSize: isMobile ? '1.5rem' : '2rem', fontWeight: 600 }}>
							Match of the Week
						</Typography>
					</Box>

					<Divider
						sx={{
							borderColor: darkMode ? 'rgb(200,200,200)' : 'rgb(30,30,30)',
							borderBottomWidth: 2,
							mb: 1,
						}}
					/>
					<Box style={{ display: 'flex', justifyContent: 'space-between', margin: '.5rem 0' }}>
						<Button variant='contained' onClick={() => handleTabClick('match')} sx={getButtonStyle('match')}>
							Match
						</Button>
						<Button variant='contained' onClick={() => handleTabClick('sequence')} sx={getButtonStyle('sequence')}>
							Sequence
						</Button>
						<Button variant='contained' onClick={() => handleTabClick('resource')} sx={getButtonStyle('resource')}>
							Resource
						</Button>
					</Box>

					{activeTab === 'match' && (
						<>
							<Box className='responsive-iframe'>
								<iframe
									style={{ borderRadius: '.75rem' }}
									src={youTubeEmbed ? youTubeEmbed : null}
									title='YouTube video player'
									allow='accelerometer;  clipboard-write; encrypted-media; gyroscope;  '
									allowFullScreen
								></iframe>
							</Box>
							<Box style={{ display: 'flex', justifyContent: 'space-between', margin: '.5rem 0' }}>
								<Typography variant='h5' sx={{ fontWeight: 600, fontSize: isMobile ? '1.25rem' : '1.5rem' }}>
									{feature.match.match}
								</Typography>
								{user?.subscriptionStatus === 'active' && (
									<Button
										variant='contained'
										sx={{
											marginLeft: isMobile ? '0' : '1rem',
											marginTop: isMobile ? '0.5rem' : '0.25rem',
											height: isMobile ? '2.4rem' : 'inherit',

											width: isMobile ? '100%' : '250px',
											borderRadius: '5rem',
											background: 'rgb(47,160,112)',
											color: darkMode ? '#000' : '#fff',
											'&:hover': { background: 'rgb(47,180,112)' },
											display: 'block', // Ensure it takes full width on mobile
										}}
										onClick={() => handleNavigate(`/match/${feature.match._id}`)}
									>
										View Match
									</Button>
								)}
								{user?.subscriptionStatus !== 'active' && (
									<Tooltip title='Subscription Required' placement='bottom' arrow>
										<Button
											variant='contained'
											sx={{
												marginLeft: isMobile ? '0' : '1rem',
												marginTop: isMobile ? '0.5rem' : '0.25rem',
												height: isMobile ? '2.4rem' : 'inherit',

												width: isMobile ? '100%' : '250px',
												borderRadius: '5rem',
												display: 'block', // Ensure it takes full width on mobile
												background: 'darkGrey',
												color: darkMode ? '#000' : '#fff',
												'&:hover': { background: 'grey' },
											}}
										>
											View Match
										</Button>
									</Tooltip>
								)}
							</Box>
							<Typography variant='h6' sx={{ fontWeight: 300, marginBottom: isMobile ? 0 : '2rem', marginTop: isMobile ? '0' : '-.75rem', fontSize: isMobile ? '1.1rem' : '1.25rem' }}>
								{feature.match.year} {feature.match.organization}
							</Typography>
							<Box
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: isMobile ? 'center' : 'inherit',
									flexDirection: isMobile ? 'column' : 'row',
								}}
							>
								{!isMobile && (
									<Typography variant='p' sx={{ color: darkMode ? '#00964e' : '#006d38', fontFamily: 'monospace' }}>
										<span style={{ color: darkMode ? '#00964e' : '#006d38', fontWeight: '600', fontFamily: 'monospace' }}>Winner&nbsp;</span>
										{feature.match.matchWinner}
									</Typography>
								)}
								{isMobile && (
									<Typography variant='p' sx={{ color: darkMode ? '#00964e' : '#006d38', fontFamily: 'monospace', marginTop: '.5rem' }}>
										<span style={{ color: darkMode ? '#00964e' : '#006d38', fontWeight: '600', fontFamily: 'monospace' }}>Winner&nbsp;</span>
										{feature.match.matchWinner}
									</Typography>
								)}
								<Typography variant='p' sx={{ fontFamily: 'monospace', marginTop: isMobile ? '.5rem' : '0' }}>
									<span style={{ fontWeight: '600', fontFamily: 'monospace' }}>Division&nbsp;</span>
									{feature.match.weightDivision}
								</Typography>
								<Typography variant='p' sx={{ fontFamily: 'monospace', marginTop: isMobile ? '.5rem' : '0' }}>
									<span style={{ fontWeight: '600', fontFamily: 'monospace' }}>Submission&nbsp;</span>
									{feature.match.submissionType ? feature.match.submissionType : 'N/A'}
								</Typography>
							</Box>
							<Card key={feature.match._id} id={feature.match._id} sx={{ mt: 2, boxShadow: 'none', border: 'none', background: 'none' }}>
								<CardContent sx={{ backgroundColor: 'inherit', padding: 0 }}>
									<Box
										style={{
											display: 'flex',
											flexDirection: 'column',
										}}
									>
										<Box>
											{feature.match.notes.length === 0 ? (
												<Typography variant='body2' component='div'>
													No user notes for this match. Edit match to enter freehand notes.
												</Typography>
											) : (
												<>
													<Button
														variant='contained'
														sx={{
															width: isMobile ? '100%' : '250px',
															borderRadius: '5rem',

															background: notesExpanded ? '#d20404' : 'rgb(47,160,112)',
															color: darkMode ? '#000' : '#fff',
															'&:hover': { background: notesExpanded ? '#db1c1c' : 'rgb(47,180,112)' },
														}}
														onClick={toggleNotes}
													>
														{notesExpanded ? 'Hide Match Notes' : 'Show Match Notes'}
													</Button>

													<Collapse in={notesExpanded}>
														{feature.match.notes.length === 0 ? (
															<Typography>No notes for this match.</Typography>
														) : (
															<>
																<Box>
																	{feature.match.notes.map((note, idx) => (
																		<Typography
																			key={idx}
																			variant='body2'
																			component='div'
																			dangerouslySetInnerHTML={{ __html: note }}
																			onClick={() => handleNoteClick(note)}
																			sx={{ margin: '1rem 0', fontFamily: 'monospace', cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
																		></Typography>
																	))}
																</Box>
															</>
														)}
													</Collapse>
												</>
											)}
										</Box>
									</Box>
								</CardContent>
							</Card>
						</>
					)}
					{activeTab === 'sequence' && (
						<>
							<Box className='responsive-iframe'>
								<iframe
									style={{ borderRadius: '.75rem' }}
									src={youTubeSeqEmbed ? youTubeSeqEmbed : null}
									title='YouTube video player'
									allow='accelerometer;  clipboard-write; encrypted-media; gyroscope;  '
									allowFullScreen
								></iframe>
							</Box>
							<Box style={{ display: 'flex', justifyContent: 'space-between', margin: '.5rem 0' }}>
								<Typography variant='h5' sx={{ fontWeight: 600, fontSize: isMobile ? '1.25rem' : '1.5rem' }}>
									{feature.match.match}
								</Typography>
								{user?.subscriptionStatus === 'active' && (
									<Button
										variant='contained'
										sx={{
											marginLeft: isMobile ? '0' : '1rem',
											marginTop: isMobile ? '0.5rem' : '0.25rem',
											height: isMobile ? '2.4rem' : 'inherit',

											width: isMobile ? '100%' : '250px',
											borderRadius: '5rem',
											background: 'rgb(47,160,112)',
											color: darkMode ? '#000' : '#fff',
											'&:hover': { background: 'rgb(47,180,112)' },
											display: 'block', // Ensure it takes full width on mobile
										}}
										onClick={(e) => viewSequence(e, feature.sequence)}
									>
										View Sequence
									</Button>
								)}
								{user?.subscriptionStatus !== 'active' && (
									<Tooltip title='Subscription Required' placement='bottom' arrow>
										<Button
											variant='contained'
											sx={{
												marginLeft: isMobile ? '0' : '1rem',
												marginTop: isMobile ? '0.5rem' : '0.25rem',
												height: isMobile ? '2.4rem' : 'inherit',

												width: isMobile ? '100%' : '250px',
												borderRadius: '5rem',
												display: 'block', // Ensure it takes full width on mobile
												background: 'darkGrey',
												color: darkMode ? '#000' : '#fff',
												'&:hover': { background: 'grey' },
											}}
										>
											View Sequence
										</Button>
									</Tooltip>
								)}
							</Box>
							<Typography variant='h6' sx={{ fontWeight: 300, marginBottom: isMobile ? 0 : '2rem', marginTop: isMobile ? '0' : '-.75rem', fontSize: isMobile ? '1.1rem' : '1.25rem' }}>
								{feature.match.year} {feature.match.organization}
							</Typography>
							<Box
								style={{
									marginTop: '1rem',
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: isMobile ? 'center' : 'inherit',
									flexDirection: isMobile ? 'column' : 'row',
								}}
							>
								<Typography variant='p' sx={{ fontFamily: 'monospace', marginTop: isMobile ? '.5rem' : '0' }}>
									<span style={{ fontWeight: '600', fontFamily: 'monospace' }}>Type&nbsp;</span>
									{feature.sequence.sequenceType}
								</Typography>
								{!isMobile && (
									<Typography variant='p' sx={{ fontFamily: 'monospace', marginTop: isMobile ? '.5rem' : '0' }}>
										<span style={{ fontWeight: '600', fontFamily: 'monospace' }}>Starting Position&nbsp;</span>
										{feature.sequence.startingPosition}
									</Typography>
								)}
								{isMobile && (
									<Typography variant='p' sx={{ fontFamily: 'monospace', marginTop: isMobile ? '.5rem' : '0' }}>
										<span style={{ fontWeight: '600', fontFamily: 'monospace' }}>Starting Position&nbsp;</span>
										{feature.sequence.startingPosition}
									</Typography>
								)}

								<Typography variant='p' sx={{ fontFamily: 'monospace', marginTop: isMobile ? '.5rem' : '0' }}>
									<span style={{ fontWeight: '600', fontFamily: 'monospace' }}>EndingPosition&nbsp;</span>
									{feature.sequence.endingPosition}
								</Typography>
							</Box>
						</>
					)}
					{activeTab === 'resource' && (
						<>
							<Box className='responsive-iframe' style={{ marginBottom: '1rem' }}>
								<iframe
									style={{ borderRadius: '.75rem' }}
									src={youTubeResourceEmbed ? youTubeResourceEmbed : null}
									title='YouTube video player'
									allow='accelerometer;  clipboard-write; encrypted-media; gyroscope;  '
									allowFullScreen
								></iframe>
							</Box>
							<Box style={{ display: 'flex', justifyContent: 'space-between', margin: '.5rem 0' }}>
								<Typography variant='h5' sx={{ fontWeight: 600, fontSize: isMobile ? '1.25rem' : '1.5rem' }}>
									{feature.match.match}
								</Typography>
								{user?.subscriptionStatus === 'active' && (
									<Button
										variant='contained'
										sx={{
											marginLeft: isMobile ? '0' : '1rem',
											marginTop: isMobile ? '0.5rem' : '0.25rem',
											height: isMobile ? '2.4rem' : 'inherit',

											width: isMobile ? '100%' : '250px',
											borderRadius: '5rem',
											background: 'rgb(47,160,112)',
											color: darkMode ? '#000' : '#fff',
											'&:hover': { background: 'rgb(47,180,112)' },
											display: 'block', // Ensure it takes full width on mobile
										}}
										onClick={(e) => viewResource(e, feature.resource)}
									>
										View Resource
									</Button>
								)}
								{user?.subscriptionStatus !== 'active' && (
									<Tooltip title='Subscription Required' placement='bottom' arrow>
										<Button
											variant='contained'
											sx={{
												marginLeft: isMobile ? '0' : '1rem',
												marginTop: isMobile ? '0.5rem' : '0.25rem',
												height: isMobile ? '2.4rem' : 'inherit',

												width: isMobile ? '100%' : '250px',
												borderRadius: '5rem',
												display: 'block', // Ensure it takes full width on mobile
												background: 'darkGrey',
												color: darkMode ? '#000' : '#fff',
												'&:hover': { background: 'grey' },
											}}
										>
											View Resource
										</Button>
									</Tooltip>
								)}
							</Box>
							<Typography variant='h6' sx={{ fontWeight: 300, marginBottom: '1rem', marginTop: isMobile ? '0' : '-.75rem', fontSize: isMobile ? '1.1rem' : '1.25rem' }}>
								{feature.match.year} {feature.match.organization}
							</Typography>
							<Box
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: isMobile ? 'center' : 'inherit',
									flexDirection: isMobile ? 'column' : 'row',
								}}
							>
								{!isMobile && (
									<Typography variant='p' sx={{ fontFamily: 'monospace', marginTop: isMobile ? '.5rem' : '0' }}>
										<span style={{ fontWeight: '600', fontFamily: 'monospace' }}>Instructor&nbsp;</span>
										{feature.resource.instructor}
									</Typography>
								)}
								{isMobile && (
									<Typography variant='p' sx={{ fontFamily: 'monospace', marginTop: isMobile ? '.5rem' : '0' }}>
										<span style={{ fontWeight: '600', fontFamily: 'monospace' }}>Instructor&nbsp;</span>
										{feature.resource.instructor}
									</Typography>
								)}
								<Typography variant='p' sx={{ fontFamily: 'monospace', marginTop: isMobile ? '.5rem' : '0' }}>
									<span style={{ fontWeight: '600', fontFamily: 'monospace' }}>Type&nbsp;</span>
									{feature.resource.type}
								</Typography>
								<Typography variant='p' sx={{ fontFamily: 'monospace', marginTop: isMobile ? '.5rem' : '0' }}>
									<span style={{ fontWeight: '600', fontFamily: 'monospace' }}>Timestamp&nbsp;</span>
									{feature.resource.timestamp}
								</Typography>
							</Box>
							<Card sx={{ mt: 1, boxShadow: 'none', border: 'none', background: 'none' }}>
								<CardContent sx={{ backgroundColor: 'inherit', padding: 0 }}>
									<Box
										style={{
											display: 'flex',
											flexDirection: 'column',
										}}
									>
										<>
											<Typography variant='p' sx={{ fontFamily: 'monospace' }}>
												<span style={{ fontWeight: '600', fontFamily: 'monospace' }}>Title&nbsp;</span>"{feature.resource.title}"
											</Typography>
											{feature.resource.note && (
												<Typography variant='body2' component='div' sx={{ margin: '.5rem 0', fontFamily: 'monospace' }}>
													<span style={{ fontWeight: '600', fontFamily: 'monospace' }}>Note:&nbsp;</span>
													{feature.resource.note}
												</Typography>
											)}
										</>
									</Box>
								</CardContent>
							</Card>
						</>
					)}

					{isMobile && (
						<Grid
							item
							xs={12} // Full width on mobile
							md={4} // 4 out of 12 columns = 33.33%, approximately 30%
							sx={{
								height: '100%',
								width: isMobile ? '100%' : '25%',
								flexDirection: 'column',
								marginLeft: isMobile ? '0' : '2rem',
								alignItems: 'center',
								padding: isMobile ? '1rem' : '0',
								borderRadius: '.5rem',
								border: 'none',
							}}
						>
							<Box
								sx={{
									width: '100%',
									borderRadius: '5px',
									textAlign: 'center',
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginTop: 0 }}>
									<Box style={{ display: 'flex', marginBottom: '2px' }}>
										<img src={LIMIBJJ} alt='logo' style={{ height: '50px', width: '50px', borderRadius: '50%', background: 'rgb(0,0,0)' }} />
										<Box sx={{ display: 'flex', alignItems: 'center' }}>
											<Typography
												component='a'
												href='https://www.youtube.com/@LIMIBJJ'
												target='_blank'
												rel='noreferrer'
												sx={{
													fontSize: '.9rem',
													fontWeight: 600,
													textDecoration: 'none',
													color: darkMode ? 'rgb(225,225,225)' : 'rgb(30,30,30)',
													padding: '0',
													':hover': { textDecoration: 'underline' },
												}}
											>
												@LIMIBJJ
											</Typography>
										</Box>
									</Box>
									<Button
										component='a'
										href='https://www.youtube.com/@LIMIBJJ'
										target='_blank'
										rel='noreferrer'
										sx={{
											borderRadius: '5rem',
											height: '50%',
											width: '35%',
											background: 'rgb(47,160,112)',
											color: darkMode ? '#000' : '#fff',
											'&:hover': { background: 'rgb(47,180,112)' },
										}}
									>
										Subscribe
									</Button>
								</Box>
								<Box className='responsive-iframe-shorts' style={{ width: '100%' }}>
									<iframe
										style={{ borderRadius: '.75rem' }}
										src={videoSrc}
										title='YouTube video player'
										allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; '
										allowFullScreen
									></iframe>
								</Box>
							</Box>
						</Grid>
					)}

					<Grid container spacing={2} sx={{ mt: 2, width: isMobile ? '100vw' : '85vw' }}>
						<Typography variant='h6' component='h3' sx={{ fontSize: isMobile ? '1.5rem' : '2rem', fontWeight: 600, pl: isMobile ? 1 : 0 }}>
							Featured Sequences
						</Typography>
						<Box
							sx={{
								mt: 2,
								width: '100%',
								display: 'flex',
								alignItems: 'center',
								flexWrap: 'wrap',
								flexDirection: isMobile ? 'column' : 'row',
								justifyContent: 'space-between',
								p: isMobile ? 1 : 2,
							}}
						>
							{featuredSequences.length === 0 ? (
								// Loading Triangle
								<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
									<Triangle color='rgb(47, 199, 112)' height={100} width={100} />
									<Typography variant='h4' sx={{ color: 'rgb(47, 199, 112)' }}>
										Loading Featured Sequences...
									</Typography>
								</Box>
							) : (
								<>
									{featuredSequences.map((sequence, index) => (
										<SequenceCard key={index} sequence={sequence} user={user} authUser={authUser} darkMode={darkMode} />
									))}
								</>
							)}
						</Box>
						{user?.subscriptionStatus === 'active' ? (
							<Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
								<a href='/sequences' style={{ textDecoration: 'none' }}>
									<Typography
										variant='button'
										onClick={(e) => handleNavigation(e, '/sequences')}
										sx={{
											fontFamily: 'monospace',
											textDecoration: 'underline',
											pr: isMobile ? 1 : 0,
											fontWeight: 300,
											color: darkMode ? 'rgb(0,130,60)' : 'rgb(0, 109, 56)',
											':hover': { cursor: 'pointer', color: darkMode ? 'rgb(0,150,70)' : 'rgb(0,130,60)' },
										}}
									>
										See More Sequences
									</Typography>
								</a>
							</Box>
						) : (
							<Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
								<Typography
									variant='button'
									onClick={(e) => handleNavigation(e, '/sequences')}
									sx={{
										fontFamily: 'monospace',
										textDecoration: 'underline',
										pr: isMobile ? 1 : 0,
										fontWeight: 300,
										color: darkMode ? 'rgb(0,130,60)' : 'rgb(0, 109, 56)',
										':hover': { cursor: 'pointer', color: darkMode ? 'rgb(0,150,70)' : 'rgb(0,130,60)' },
									}}
								>
									See More Sequences
								</Typography>
							</Box>
						)}
					</Grid>
					<Grid container spacing={2} sx={{ mt: 2, width: isMobile ? '100vw' : '85vw' }}>
						<Typography variant='h6' component='h3' sx={{ fontSize: isMobile ? '1.5rem' : '2rem', fontWeight: 600, pl: isMobile ? 1 : 0 }}>
							Featured Resources
						</Typography>
						<Box
							sx={{
								mt: 2,
								width: '100%',
								display: 'flex',
								alignItems: 'center',
								flexWrap: 'wrap',
								flexDirection: isMobile ? 'column' : 'row',
								justifyContent: 'space-between',
								p: isMobile ? 1 : 2,
							}}
						>
							{featuredResources.length === 0 ? (
								// Loading Triangle
								<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
									<Triangle color='rgb(47, 199, 112)' height={100} width={100} />
									<Typography variant='h4' sx={{ color: 'rgb(47, 199, 112)' }}>
										Loading Featured Resources...
									</Typography>
								</Box>
							) : (
								<>
									{featuredResources.map((resource, index) => (
										<ResourceCard key={index} user={user} authUser={authUser} resource={resource} darkMode={darkMode} />
									))}
								</>
							)}
						</Box>
						{user?.subscriptionStatus === 'active' ? (
							<Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
								<a href='/training-resources' style={{ textDecoration: 'none' }}>
									<Typography
										variant='button'
										sx={{
											fontFamily: 'monospace',
											textDecoration: 'underline',
											pr: isMobile ? 1 : 0,
											fontWeight: 300,
											color: darkMode ? 'rgb(0,130,60)' : 'rgb(0, 109, 56)',
											':hover': { cursor: 'pointer', color: darkMode ? 'rgb(0,150,70)' : 'rgb(0,130,60)' },
										}}
									>
										See More Resources
									</Typography>
								</a>
							</Box>
						) : (
							<Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
								<Typography
									onClick={(e) => handleNavigation(e, '/training-resources')}
									variant='button'
									sx={{
										fontFamily: 'monospace',
										textDecoration: 'underline',
										pr: isMobile ? 1 : 0,
										fontWeight: 300,
										color: darkMode ? 'rgb(0,130,60)' : 'rgb(0, 109, 56)',
										':hover': { cursor: 'pointer', color: darkMode ? 'rgb(0,150,70)' : 'rgb(0,130,60)' },
									}}
								>
									See More Resources
								</Typography>
							</Box>
						)}
					</Grid>
					<Grid container spacing={2} sx={{ mt: 2, width: isMobile ? '100vw' : '85vw' }}>
						<Typography variant='h6' component='h3' sx={{ fontSize: isMobile ? '1.5rem' : '2rem', fontWeight: 600, pl: isMobile ? 1 : 0 }}>
							Recent Matches
						</Typography>
						<Box
							sx={{
								mt: 2,
								width: '100%',
								display: 'flex',
								alignItems: 'center',
								flexWrap: 'wrap',
								flexDirection: isMobile ? 'column' : 'row',
								justifyContent: 'space-between',
								p: isMobile ? 1 : 2,
							}}
						>
							{featuredMatches.length === 0 ? (
								// Loading Triangle
								<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
									<Triangle color='rgb(47, 199, 112)' height={100} width={100} />
									<Typography variant='h4' sx={{ color: 'rgb(47, 199, 112)' }}>
										Loading Featured Matches...
									</Typography>
								</Box>
							) : (
								<Box
									sx={{
										width: '100%',
										display: 'flex',
										flexWrap: 'wrap',
										alignItems: isMobile ? 'center' : 'flex-start',
										flexDirection: isMobile ? 'column' : 'row',
										justifyContent: 'space-between',
									}}
								>
									{featuredMatches.map((match, index) => (
										<Box key={index}>
											<MatchCard match={match} darkMode={darkMode} user={user} authUser={authUser} />
										</Box>
									))}
								</Box>
							)}
						</Box>

						{user?.subscriptionStatus === 'active' ? (
							<Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
								<a href='/matches' style={{ textDecoration: 'none' }}>
									<Typography
										variant='button'
										sx={{
											fontFamily: 'monospace',
											textDecoration: 'underline',
											pr: isMobile ? 1 : 0,
											fontWeight: 300,
											color: darkMode ? 'rgb(0,130,60)' : 'rgb(0, 109, 56)',
											':hover': { cursor: 'pointer', color: darkMode ? 'rgb(0,150,70)' : 'rgb(0,130,60)' },
										}}
									>
										SEE MORE MATCHES
									</Typography>
								</a>
							</Box>
						) : (
							<Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
								<Typography
									variant='button'
									onClick={(e) => handleNavigation(e, '/matches')}
									sx={{
										fontFamily: 'monospace',
										textDecoration: 'underline',
										pr: isMobile ? 1 : 0,
										fontWeight: 300,
										color: darkMode ? 'rgb(0,130,60)' : 'rgb(0, 109, 56)',
										':hover': { cursor: 'pointer', color: darkMode ? 'rgb(0,150,70)' : 'rgb(0,130,60)' },
									}}
								>
									SEE MORE MATCHES
								</Typography>
							</Box>
						)}
					</Grid>
				</Grid>
				{!isMobile && (
					<Grid
						item
						xs={12} // Full width on mobile
						md={4} // 4 out of 12 columns = 33.33%, approximately 30%
						sx={{
							height: '100%',
							width: isMobile ? '100%' : '25%',
							flexDirection: 'column',
							marginLeft: isMobile ? '0' : '2rem',
							alignItems: 'center',
							padding: isMobile ? '1rem' : '0',
							borderRadius: '.5rem',
							border: 'none',
						}}
					>
						<Box
							sx={{
								width: '100%',
								borderRadius: '5px',
								textAlign: 'center',
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginTop: 0 }}>
								<Box style={{ display: 'flex', marginBottom: '2px' }}>
									<img src={LIMIBJJ} alt='logo' style={{ height: '50px', width: '50px', borderRadius: '50%', background: 'rgb(0,0,0)' }} />
									<Box sx={{ display: 'flex', alignItems: 'center' }}>
										<Typography
											component='a'
											href='https://www.youtube.com/@LIMIBJJ'
											target='_blank'
											rel='noreferrer'
											sx={{
												fontSize: '.9rem',
												fontWeight: 600,
												textDecoration: 'none',
												color: darkMode ? 'rgb(225,225,225)' : 'rgb(30,30,30)',
												padding: '0',
												':hover': { textDecoration: 'underline' },
											}}
										>
											@LIMIBJJ
										</Typography>
									</Box>
								</Box>
								<Button
									component='a'
									href='https://www.youtube.com/@LIMIBJJ'
									target='_blank'
									rel='noreferrer'
									sx={{
										borderRadius: '5rem',
										height: '50%',
										width: '35%',
										background: 'rgb(47,160,112)',
										color: darkMode ? '#000' : '#fff',
										'&:hover': { background: 'rgb(47,180,112)' },
									}}
								>
									Subscribe
								</Button>
							</Box>
							<Box className='responsive-iframe-shorts' style={{ width: '100%' }}>
								<iframe
									style={{ borderRadius: '.75rem' }}
									src={videoSrc}
									title='YouTube video player'
									allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; '
									allowFullScreen
								></iframe>
							</Box>
						</Box>
					</Grid>
				)}
				<LoginAndSubscribeDialog
					open={open}
					handleClose={handleClose}
					handleCloseAndLogin={handleCloseAndLogin}
					handleCloseAndSubscribe={handleCloseAndSubscribe}
					user={user}
					authUser={authUser}
				/>
			</Container>
		</>
	);
}

export default FeaturedPage;

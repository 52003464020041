import React, { useState, useEffect, useRef } from 'react';
import LazyLoad from 'react-lazyload';
import './YouTubeLiteEmbed.css';
import YouTubeIcon from '@mui/icons-material/YouTube';
import useMediaQuery from '@mui/material/useMediaQuery';

const YouTubeLiteEmbed = ({ videoId, start }) => {
	const isMobile = useMediaQuery('(max-width:600px)');
	const [isIframeLoaded, setIframeLoaded] = useState(false);
	const iframeRef = useRef(null);

	const handleThumbnailClick = () => {
		setIframeLoaded(true);
	};

	useEffect(() => {
		const iframe = iframeRef.current;
		if (iframe && isIframeLoaded) {
			const handleIframeLoad = () => {
				iframe.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
			};
			iframe.addEventListener('load', handleIframeLoad);
			return () => {
				iframe.removeEventListener('load', handleIframeLoad);
			};
		}
	}, [isIframeLoaded]);

	return (
		<LazyLoad height={200} offset={100} once>
			<div className='youtube-lite-embed'>
				{isIframeLoaded ? (
					<iframe
						ref={iframeRef}
						width='350'
						height='200'
						src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=${isMobile ? 1 : 0}${start ? `&start=${start}` : ''}&enablejsapi=1`}
						title='YouTube video player'
						allowFullScreen={true}
						allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
					></iframe>
				) : (
					<div className='thumbnail' onClick={handleThumbnailClick}>
						<img src={`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`} alt='YouTube Thumbnail' className='thumbnail-image' />
						<div className='play-button' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
							<YouTubeIcon className='youtube-icon-with-background' sx={{ color: 'rgb(255, 0, 0)', fontSize: '5rem' }} />
						</div>
					</div>
				)}
			</div>
		</LazyLoad>
	);
};

export default YouTubeLiteEmbed;

import React, { useState, useEffect } from 'react';
import { Triangle } from 'react-loader-spinner';
import { Container, Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery } from '@mui/material';
import Head from '../components/Head/Head';
import axios from 'axios';
import '../App.css';
import { useNavigate } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL;

function RewardsDashboard({ user, darkMode }) {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const [points, setPoints] = useState(0);
	const [success, setSuccess] = useState('');
	const [showNextRedemptionDate, setShowNextRedemptionDate] = useState('');
	const isMobile = useMediaQuery('(max-width:600px)');

	const [openDialog, setOpenDialog] = useState(false);

	const handleOpenDialog = () => {
		setOpenDialog(true);
	};

	const handleRedeemFreeMonth = async () => {
		// Close the confirmation dialog
		setOpenDialog(false);

		// Check if user has enough points
		if (points < 250) {
			alert('Not enough points to redeem a free month');
			return;
		}

		// Call API to redeem free month
		try {
			const response = await axios.post(`${API_URL}/user/redeem-free-month`, { uid: user?.uid });
			if (response.data.message === 'success') {
				// Update points state or refetch user data
				setPoints(points - 250);
				setSuccess('Free month redeemed successfully and will be applied to your next billing cycle');
			}
		} catch (error) {
			alert('Error redeeming free month. Please try again later');
		}
	};

	useEffect(() => {
		if (success) {
			setTimeout(() => {
				setSuccess('');
			}, 5000);
		}
	}, [success]);

	useEffect(() => {
		const now = new Date();
		let nextRedemptionDate = new Date(user?.lastRedeemedAt);
		nextRedemptionDate.setDate(nextRedemptionDate.getDate() + 29);

		if (now < nextRedemptionDate) {
			// show a message with `nextRedemptionDate.toDateString()`
			setShowNextRedemptionDate(nextRedemptionDate.toLocaleDateString());
		}
	}, [user?.lastRedeemedAt, user]);

	useEffect(() => {
		const fetchUserData = async () => {
			try {
				const response = await axios.get(`${API_URL}/user/user-points/${user?.uid}`);
				setPoints(response.data);
				setLoading(false);
			} catch (error) {
				console.error('Error fetching user data:', error);
			}
		};

		fetchUserData();
	}, [user?.uid, user]);

	if (loading) {
		return (
			<>
				<Head title='Rewards Dashboard Page' />
				<Container maxWidth='md' style={{ display: 'flex', justifyContent: 'center' }}>
					<div className='loader'>
						<Triangle color='rgb(47, 199, 112)' size={100} />
						<Typography variant='h5' sx={{ color: 'rgb(47, 199, 112)', marginTop: '1rem' }}>
							Loading...
						</Typography>
					</div>
				</Container>
			</>
		);
	}

	return (
		<>
			<Head title='Rewards Dashboard Page' />
			<Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '1rem', marginBottom: '4rem' }}>
				<Typography variant='h5'>Rewards Dashboard</Typography>
				<Typography variant='h6'>Available Points: {points}</Typography>
				{showNextRedemptionDate && (
					<Typography variant='body1' sx={{ marginTop: '1rem', color: '#d20404' }}>
						You can redeem another free month on {showNextRedemptionDate}
					</Typography>
				)}

				<Button
					sx={{
						color: darkMode ? '#000' : '#fff',
						marginTop: '1rem',
						backgroundColor: 'rgb(47, 199, 112)',
						'&:hover': {
							backgroundColor: 'rgb(47, 180, 112)',
						},
					}}
					variant='contained'
					onClick={handleOpenDialog}
					disabled={points < 250 || showNextRedemptionDate}
				>
					Redeem Free Month (250 points)
				</Button>
				<Typography variant='body1' sx={{ marginTop: '1rem' }}>
					Earn points by entering data into OutlierDB
				</Typography>
				<Button
					variant='contained'
					onClick={() => navigate('/data-entry')}
					sx={{
						marginTop: '1rem',
						backgroundColor: 'rgb(47, 199, 112)',
						'&:hover': { backgroundColor: 'rgb(47, 180, 112)' },
					}}
					fullWidth={isMobile ? true : false}
				>
					Data Entry
				</Button>
				{success && (
					<Typography variant='body1' sx={{ marginTop: '1rem', color: 'rgb(47, 199, 112)' }}>
						{success}
					</Typography>
				)}

				<Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
					<DialogTitle>Redeem Free Month</DialogTitle>
					<DialogContent>
						<DialogContentText>Are you sure you want to redeem a free month for 250 points?</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							sx={{
								color: '#fff',
								backgroundColor: '#d20404',
								'&:hover': {
									backgroundColor: '#db1c1c',
								},
							}}
							onClick={() => setOpenDialog(false)}
						>
							Cancel
						</Button>
						<Button
							sx={{
								color: '#fff',
								backgroundColor: 'rgb(47, 199, 112)',
								'&:hover': {
									backgroundColor: 'rgb(47, 180, 112)',
								},
							}}
							onClick={handleRedeemFreeMonth}
							autoFocus
						>
							Confirm
						</Button>
					</DialogActions>
				</Dialog>
			</Container>
		</>
	);
}

export default RewardsDashboard;

import { useState } from 'react';
import { logout } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { AppBar, Box, Toolbar, IconButton, Tooltip, TextField, useMediaQuery, Menu, MenuItem, Select, InputBase } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import LightModeIcon from '@mui/icons-material/LightMode';
import NightlightIcon from '@mui/icons-material/Nightlight';
import HeaderLinks from './HeaderLinks';
import MobileHeaderLinks from './MobileHeaderLinks';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import StarIcon from '@mui/icons-material/Star';
import StorageIcon from '@mui/icons-material/Storage';
import CreateIcon from '@mui/icons-material/Create';
import RedeemIcon from '@mui/icons-material/Redeem';
import LoginIcon from '@mui/icons-material/Login';
import SettingsIcon from '@mui/icons-material/Settings';
import styled from '@mui/system/styled';

function Header({ authUser, user, setUser, setAuthUser, toggleDarkMode, darkMode, handleSearch, setSearch, search, setSearchType, searchType }) {
	const isMobileSmall = useMediaQuery('(max-width:600px)');
	const selectedStyle = {
		backgroundColor: '#a8f3c8 !important',
		color: darkMode ? '#000' : '#000 !important',

		fontSize: isMobileSmall ? '0.7rem' : '0.8rem',

		'&:hover': {
			backgroundColor: '#8dd6ad !important',
		},
	};

	const unselectedStyle = {
		backgroundColor: 'rgb(45,45,45)!important',
		color: 'rgb(225,225,225)!important',

		fontSize: isMobileSmall ? '0.7rem' : '0.8rem',

		'&:hover': {
			backgroundColor: 'rgb(30,30,30)!important',
			color: '#fff !important',
		},
	};

	// Adjusted CustomInput for better visibility control
	const CustomInput = styled(InputBase)({
		'& .MuiInputBase-input': {
			visibility: 'hidden',
			height: '100%',
			cursor: 'pointer',
		},
		'& .MuiInputBase-root': {
			color: 'transparent',
			'&:hover': {
				backgroundColor: 'transparent',
			},
		},
		svg: {
			color: '#a8f3c8',
		},
	});

	const [selectOpen, setSelectOpen] = useState(false);

	const handleSelectOpen = () => {
		setSelectOpen(true);
	};

	const handleSelectClose = () => {
		setSelectOpen(false);
	};
	const navigate = useNavigate();
	const isMobile = useMediaQuery('(max-width:1100px)');
	const [open, setOpen] = useState(false);
	const [drawerOpen, setDrawerOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);

	const handleMenu = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const handleDrawerToggle = () => {
		setDrawerOpen(!drawerOpen);
	};

	const handleLogout = () => {
		navigate('/');
		logout(navigate);
		setUser(null);
		setAuthUser(null);
	};

	const handleNavigation = (event, path) => {
		if (user?.subscriptionStatus !== 'active' && path !== '/account' && path !== '/auth' && path !== '/' && path !== '/logout') {
			event.preventDefault();
			setOpen(true); // Open dialog
		} else if (path === '/logout') {
			handleLogout();
		} else {
			navigate(path);
		}
		if (path !== '/user-folders' && drawerOpen) {
			handleDrawerToggle();
		}
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleCloseAndLogin = () => {
		setOpen(false);
		navigate('/auth');
	};

	const handleCloseAndSubscribe = () => {
		setOpen(false);
		navigate('/account');
	};

	return (
		<AppBar position='static' sx={{ background: 'rgb(30,30,30)', maxWidth: '100%' }}>
			<Toolbar style={{ paddingLeft: isMobile ? '4px' : '1rem', paddingRight: isMobile ? '4px' : '1rem', width: '100%' }}>
				{isMobile ? (
					<Box sx={{ display: 'flex', marginRight: 'auto', justifyContent: 'flex-start' }}>
						<MobileHeaderLinks
							setOpen={setOpen}
							darkMode={darkMode}
							handleDrawerToggle={handleDrawerToggle}
							drawerOpen={drawerOpen}
							handleLogout={handleLogout}
							handleClose={handleClose}
							handleNavigation={handleNavigation}
							handleCloseAndLogin={handleCloseAndLogin}
							handleCloseAndSubscribe={handleCloseAndSubscribe}
							user={user}
							authUser={authUser}
							open={open}
						/>
					</Box>
				) : (
					<Box sx={{ display: 'flex', marginRight: 'auto', width: '50%', justifyContent: 'flex-end', alignItems: 'center' }}>
						<HeaderLinks
							setOpen={setOpen}
							handleLogout={handleLogout}
							handleClose={handleClose}
							handleNavigation={handleNavigation}
							handleCloseAndLogin={handleCloseAndLogin}
							handleCloseAndSubscribe={handleCloseAndSubscribe}
							user={user}
							authUser={authUser}
							open={open}
						/>
					</Box>
				)}
				<Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginRight: 'auto' }}>
					<IconButton
						onClick={toggleDarkMode}
						sx={{ color: darkMode ? 'rgba(244, 240, 20, 0.8)' : 'rgba(255, 0, 255, 0.75)', margin: '0 auto', marginLeft: isMobile ? '1.5rem' : 'auto' }}
						aria-label='toggle dark mode'
					>
						{darkMode ? (
							<Box
								sx={{
									'&:hover': {
										backgroundColor: 'rgba(255,255,255,0.25)', // apply grey background on hover
									},
									display: 'inline-flex', // This makes the Box behave like an inline element
									borderRadius: '50%', // Optional: to make it round if you need it
									padding: '5px',
								}}
							>
								<LightModeIcon fontSize='small' />
							</Box>
						) : (
							<Box
								sx={{
									'&:hover': {
										backgroundColor: 'rgba(255,255,255,0.25)', // apply grey background on hover
									},
									display: 'inline-flex', // This makes the Box behave like an inline element
									borderRadius: '50%', // Optional: to make it round if you need it
									padding: '5px',
								}}
							>
								<NightlightIcon fontSize='small' />
							</Box>
						)}
					</IconButton>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<div style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'row-reverse' }}>
							<IconButton onClick={handleSelectOpen} sx={{ color: '#a8f3c8', zIndex: 1, ml: '-1.5rem' }}>
								<SettingsIcon fontSize='small' />
							</IconButton>
							<Select
								labelId='search-type-label'
								id='search-type-select'
								value={searchType}
								onClick={(e) => {
									if (e.target.innerText === 'Hybrid') {
										localStorage.setItem('searchType', 'hybrid_search');
										setSearchType('hybrid_search');
									}
									if (e.target.innerText === 'Semantic') {
										localStorage.setItem('searchType', 'semantic');
										setSearchType('semantic');
									}
									if (e.target.innerText === 'Fulltext') {
										localStorage.setItem('searchType', 'fulltext');
										setSearchType('fulltext');
									}

									handleSelectClose();
								}}
								open={selectOpen}
								onClose={handleSelectClose}
								input={<CustomInput />}
								renderValue={() => ''}
								IconComponent={() => null} // Removes the dropdown arrow icon
								MenuProps={{
									PaperProps: {
										sx: {
											backgroundColor: darkMode ? 'rgb(0,0,0)' : 'rgb(45,45,45)',
										},
									},
								}}
							>
								<Tooltip title='Hybrid search combines fulltext and semantic search.' placement='left' arrow>
									<MenuItem value='hybrid_search' sx={searchType === 'hybrid_search' ? selectedStyle : unselectedStyle}>
										Hybrid
									</MenuItem>
								</Tooltip>
								<Tooltip title="Semantic search aims to improve accuracy by trying to understand the searcher's intent." placement='left' arrow>
									<MenuItem value='semantic' sx={searchType === 'semantic' ? selectedStyle : unselectedStyle}>
										Semantic
									</MenuItem>
								</Tooltip>
								<Tooltip title='Fulltext search performs a plain text match against all the words.' placement='left' arrow>
									<MenuItem value='fulltext' sx={searchType === 'fulltext' ? selectedStyle : unselectedStyle}>
										Fulltext
									</MenuItem>
								</Tooltip>
							</Select>
						</div>
						<form style={{ display: 'flex' }} onSubmit={(e) => handleSearch(e, search)}>
							<TextField
								type='search'
								placeholder={!authUser ? 'Login Required...' : user?.subscriptionStatus !== 'active' ? 'Subscription Required...' : 'Gordon Ryan...'}
								disabled={user?.subscriptionStatus !== 'active'}
								autoFocus
								variant='outlined'
								autoComplete='off'
								value={search ? search : ''}
								onChange={(e) => setSearch(e.target.value)}
								sx={{
									minWidth: isMobile ? '100px' : '400px',
									marginRight: '.25rem',
									marginLeft: 'auto',
									'.MuiOutlinedInput-input': {
										height: '35px',
										padding: '2px 10px 2px 8px',
									},
									'& .MuiInputBase-input': { minWidth: '0' },

									input: {
										color: darkMode ? '#fff' : '#000',
									},
									'& .MuiOutlinedInput-root': {
										backgroundColor: darkMode ? '#000' : '#f7f7f7', // Set default background color
									},
									'& .MuiOutlinedInput-root.Mui-focused': {
										backgroundColor: darkMode ? '#000' : '#f7f7f7', // Set background color when focused
									},
									'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
										borderColor: 'rgb(47, 225, 112)',
									},
									'& .MuiInputLabel-outlined.Mui-focused': {
										color: 'rgb(47, 225, 112)',
									},
									borderRadius: '5px',
								}}
							/>

							<span
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									borderRadius: '50%',
									padding: '5px',
									marginRight: '0.5rem',
									cursor: 'pointer',
								}}
								onClick={(e) => handleSearch(e, search)}
							>
								<SearchIcon fontSize={'medium'} />
							</span>
						</form>
					</Box>

					<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
						<div>
							{!authUser && !anchorEl && (
								<div>
									<div className='arrow-up'></div>
								</div>
							)}
							<IconButton size='small' edge='end' aria-label='account of current user' aria-controls='menu-appbar' aria-haspopup='true' onClick={handleMenu}>
								<PersonIcon sx={{ color: !authUser ? 'rgb(225,225,225)' : '#56df89', fontSize: isMobile ? '1.5rem' : '1.75rem' }} />
							</IconButton>
							<Menu
								id='menu-appbar'
								anchorEl={anchorEl}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								open={Boolean(anchorEl)}
								onClose={handleMenuClose}
								sx={{
									marginTop: '2rem',
									marginLeft: '1rem',
									'& .MuiPaper-root': {
										// Targeting the Paper component inside the Menu
										backgroundColor: darkMode ? 'rgb(0, 0, 0)' : 'rgb(30,30,30)', // Set to black
										color: '#fff', // Optional: Change text color to white for better visibility
									},
								}}
							>
								<MenuItem
									sx={{ '&:hover': { backgroundColor: 'rgb(50, 50, 50)' } }}
									disabled={authUser === null}
									onClick={() => {
										navigate(`/user-profile/${user?._id}`);
										handleMenuClose();
									}}
								>
									<AccountCircleIcon fontSize='small' sx={{ mr: 1, color: '#a8f3c8' }} /> <span style={{ fontSize: '0.9rem', color: '#a8f3c8' }}>Profile</span>
								</MenuItem>
								<MenuItem
									sx={{ '&:hover': { backgroundColor: 'rgb(50, 50, 50)' } }}
									disabled={user?.subscriptionStatus !== 'active'}
									onClick={(e) => {
										handleNavigation(e, `/user-folders/${user?._id}`);
										handleMenuClose();
									}}
								>
									<FolderSpecialIcon fontSize='medium' sx={{ mr: 1, color: '#a8f3c8' }} /> <span style={{ fontSize: '0.9rem', color: '#a8f3c8' }}>Folders</span>
								</MenuItem>
								<MenuItem
									sx={{ '&:hover': { backgroundColor: 'rgb(50, 50, 50)' } }}
									disabled={user?.subscriptionStatus !== 'active'}
									onClick={(e) => {
										handleNavigation(e, `/data-entry`);
										handleMenuClose();
									}}
								>
									<CreateIcon fontSize='medium' sx={{ mr: 1, color: '#a8f3c8' }} /> <span style={{ fontSize: '0.9rem', color: '#a8f3c8' }}>Data Entry</span>
								</MenuItem>
								<MenuItem
									sx={{ '&:hover': { backgroundColor: 'rgb(50, 50, 50)' } }}
									disabled={user?.subscriptionStatus !== 'active'}
									onClick={(e) => {
										handleNavigation(e, `/rewards-dashboard`);
										handleMenuClose();
									}}
								>
									<RedeemIcon fontSize='medium' sx={{ mr: 1, color: '#a8f3c8' }} /> <span style={{ fontSize: '0.9rem', color: '#a8f3c8' }}>Redeem Points</span>
								</MenuItem>
								{user?.role === 'admin' ? (
									<div>
										<MenuItem
											sx={{ '&:hover': { backgroundColor: 'rgb(50, 50, 50)' } }}
											onClick={(e) => {
												handleNavigation(e, `/weekly-features/`);
												handleMenuClose();
											}}
										>
											<StarIcon fontSize='medium' sx={{ mr: 1, color: '#a8f3c8' }} /> <span style={{ fontSize: '0.9rem', color: '#a8f3c8' }}>Weekly Features</span>
										</MenuItem>
										<MenuItem
											sx={{ '&:hover': { backgroundColor: 'rgb(50, 50, 50)' } }}
											onClick={(e) => {
												handleNavigation(e, '/database-terms');
												handleMenuClose();
											}}
										>
											<StorageIcon fontSize='medium' sx={{ mr: 1, color: '#a8f3c8' }} /> <span style={{ fontSize: '0.9rem', color: '#a8f3c8' }}>Manage DB Terms</span>
										</MenuItem>
									</div>
								) : null}

								<MenuItem
									sx={{ '&:hover': { backgroundColor: 'rgb(50, 50, 50)' } }}
									disabled={authUser === null}
									onClick={() => {
										navigate('/account');
										handleMenuClose();
									}}
								>
									<ManageAccountsIcon fontSize='medium' sx={{ mr: 1, color: '#a8f3c8' }} /> <span style={{ fontSize: '0.9rem', color: '#a8f3c8' }}>Manage Subscription</span>
								</MenuItem>

								{!authUser ? (
									<>
										<MenuItem
											sx={{ '&:hover': { backgroundColor: 'rgb(50, 50, 50)' } }}
											onClick={() => {
												navigate('/auth');
												handleMenuClose();
											}}
										>
											<div>
												<div className='arrow-left'></div>
											</div>
											<LoginIcon fontSize='small' sx={{ mr: 1, color: '#a8f3c8' }} /> <span style={{ fontSize: '0.9rem', color: '#a8f3c8' }}>Login</span>
										</MenuItem>
									</>
								) : (
									<MenuItem
										sx={{ '&:hover': { backgroundColor: 'rgb(50, 50, 50)' } }}
										onClick={() => {
											handleLogout();
											handleMenuClose();
										}}
									>
										<LogoutIcon fontSize='small' sx={{ mr: 1, color: '#d20404' }} /> <span style={{ fontSize: '0.9rem', color: '#d20404' }}>Logout</span>
									</MenuItem>
								)}
							</Menu>
						</div>
					</Box>
				</Box>
			</Toolbar>
		</AppBar>
	);
}

export default Header;

import React from 'react';
import { Box, Drawer, List, ListItem, ListItemButton, ListItemText, ListItemIcon, IconButton, Grid } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LoginAndSubscribeDialog from './LoginAndSubscribeDialog';
import SportsKabaddiIcon from '@mui/icons-material/SportsKabaddi';
import SchoolIcon from '@mui/icons-material/School';
import BarChartIcon from '@mui/icons-material/BarChart';
import TimelineIcon from '@mui/icons-material/Timeline';

import LIMIBJJ from '../assets/TransparentLogo.svg';
import '../App.css';

export default function MobileHeaderLinks({
	user,
	drawerOpen,
	handleDrawerToggle,
	darkMode,
	authUser,
	handleLogout,
	handleNavigation,
	open,
	handleClose,
	handleCloseAndLogin,
	handleCloseAndSubscribe,
}) {
	const MobileNavList = () => (
		<>
			{!user && !authUser && (
				<List>
					<ListItem disablePadding>
						<ListItemButton onClick={(e) => handleNavigation(e, '/')}>
							<img className='header-logo' src={LIMIBJJ} alt='logo' style={{ height: '60px', width: '60px', borderRadius: '50%', margin: '0 auto' }} />
						</ListItemButton>
					</ListItem>
					{[
						{ name: 'Matches', path: '/matches', icon: <SportsKabaddiIcon /> },
						{ name: 'Sequences', path: '/sequences', icon: <TimelineIcon /> },
						{ name: 'Resources', path: '/training-resources', icon: <SchoolIcon /> },
						{ name: 'Statistics', path: '/statistics', icon: <BarChartIcon /> },
					].map((item, index) => (
						<ListItem key={index} disablePadding>
							<ListItemButton onClick={(e) => handleNavigation(e, item.path)} disabled={item.name !== 'Featured'}>
								<ListItemIcon sx={{ marginRight: '-1.25rem' }}>{React.cloneElement(item.icon, { sx: { color: '#a8f3c8' } })}</ListItemIcon>
								<ListItemText primary={item.name} />
							</ListItemButton>
						</ListItem>
					))}
				</List>
			)}
			{user?.subscriptionStatus !== 'active' && authUser && (
				<>
					<List>
						<ListItem disablePadding>
							<ListItemButton onClick={(e) => handleNavigation(e, '/')}>
								<img className='header-logo' src={LIMIBJJ} alt='logo' style={{ height: '60px', width: '60px', borderRadius: '50%', margin: '0 auto' }} />
							</ListItemButton>
						</ListItem>
						{[
							{ name: 'Matches', path: '/matches', icon: <SportsKabaddiIcon /> },
							{ name: 'Sequences', path: '/sequences', icon: <TimelineIcon /> },
							{ name: 'Resources', path: '/training-resources', icon: <SchoolIcon /> },
							{ name: 'Statistics', path: '/statistics', icon: <BarChartIcon /> },
						].map((item, index) => (
							<ListItem key={index} disablePadding>
								<ListItemButton onClick={(e) => handleNavigation(e, item.path)} disabled={item.name !== 'Featured'}>
									<ListItemIcon sx={{ marginRight: '-1.25rem' }}>{React.cloneElement(item.icon, { sx: { color: '#a8f3c8' } })}</ListItemIcon>
									<ListItemText primary={item.name} />
								</ListItemButton>
							</ListItem>
						))}
					</List>
				</>
			)}
			{user?.subscriptionStatus === 'active' && authUser && (
				<>
					<List>
						<ListItem disablePadding>
							<ListItemButton onClick={(e) => handleNavigation(e, '/')}>
								<img className='header-logo' src={LIMIBJJ} alt='logo' style={{ height: '60px', width: '60px', borderRadius: '50%', margin: '0 auto' }} />
							</ListItemButton>
						</ListItem>
						{[
							{ name: 'Matches', path: '/matches', icon: <SportsKabaddiIcon /> },
							{ name: 'Sequences', path: '/sequences', icon: <TimelineIcon /> },
							{ name: 'Resources', path: '/training-resources', icon: <SchoolIcon /> },
							{ name: 'Statistics', path: '/statistics', icon: <BarChartIcon /> },
						].map((item, index) => (
							<ListItem key={index} disablePadding>
								<ListItemButton onClick={(e) => handleNavigation(e, item.path)}>
									<ListItemIcon sx={{ marginRight: '-1.25rem' }}>{React.cloneElement(item.icon, { sx: { color: '#a8f3c8' } })}</ListItemIcon>
									<ListItemText primary={item.name} />
								</ListItemButton>
							</ListItem>
						))}
					</List>
				</>
			)}
		</>
	);

	return (
		<>
			<Grid container justifyContent='flex-end'>
				<IconButton color='inherit' aria-label='open drawer' onClick={() => handleDrawerToggle()}>
					<MenuIcon />
				</IconButton>
				<Drawer
					anchor='left'
					open={drawerOpen}
					onClose={handleDrawerToggle}
					sx={{
						'& .MuiDrawer-paper': {
							backgroundColor: darkMode ? '#000' : 'rgb(30, 30, 30)',
							color: '#a8f3c8',
						},
					}}
				>
					<Box role='presentation' sx={{ width: 175 }}>
						<MobileNavList />
					</Box>
				</Drawer>
			</Grid>

			<LoginAndSubscribeDialog open={open} handleClose={handleClose} handleCloseAndLogin={handleCloseAndLogin} handleCloseAndSubscribe={handleCloseAndSubscribe} user={user} authUser={authUser} />
		</>
	);
}

import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { NavLink } from 'react-router-dom';
import LoginAndSubscribeDialog from './LoginAndSubscribeDialog';
import LIMIBJJ from '../assets/TransparentLogo.svg';
import '../App.css';

export default function HeaderLinks({ user, authUser, handleNavigation, open, handleClose, handleCloseAndLogin, handleCloseAndSubscribe }) {
	const defaultGreen = '#a8f3c8';

	const isMobile = useMediaQuery('(max-width:600px)');
	return (
		<>
			{user?.subscriptionStatus !== 'active' ? (
				<Grid sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
					<NavLink
						to='/'
						style={{
							textDecoration: 'none',
							color: 'rgb(47, 225, 112)',
							fontSize: isMobile ? '0.8rem' : '0.9rem',
							display: 'flex',
							alignItems: 'center',
							marginTop: isMobile ? '0' : '0.3rem',
						}}
					>
						<img className='header-logo' src={LIMIBJJ} alt='logo' style={{ height: isMobile ? '30px' : '60px', width: isMobile ? '30px' : '60px', borderRadius: '50%' }} />
					</NavLink>
					<NavLink
						onClick={(e) => handleNavigation(e, '/matches')}
						className={user?.subscriptionStatus !== 'active' ? 'disabled' : 'navLink'}
						style={({ isActive }) => ({
							marginLeft: '1rem',
							color: user?.subscriptionStatus !== 'active' ? 'grey' : isActive ? 'white' : defaultGreen,
						})}
					>
						<Box component='span' sx={{ display: 'inline-flex', alignItems: 'center' }}>
							<Typography sx={{ fontSize: '0.9rem', letterSpacing: '0.5px' }} component='span'>
								Matches
							</Typography>
						</Box>
					</NavLink>

					<NavLink
						onClick={(e) => handleNavigation(e, '/sequences')}
						className={user?.subscriptionStatus !== 'active' ? 'disabled' : 'navLink'}
						style={({ isActive }) => ({
							marginLeft: '1rem',
							color: user?.subscriptionStatus !== 'active' ? 'grey' : isActive ? 'white' : defaultGreen,
						})}
					>
						<Box component='span' sx={{ display: 'inline-flex', alignItems: 'center' }}>
							<Typography sx={{ fontSize: '0.9rem', letterSpacing: '0.5px' }} component='span'>
								Sequences
							</Typography>
						</Box>
					</NavLink>

					<NavLink
						onClick={(e) => handleNavigation(e, '/training-resources')}
						className={user?.subscriptionStatus !== 'active' ? 'disabled' : 'navLink'}
						style={({ isActive }) => ({
							marginLeft: '1rem',
							color: user?.subscriptionStatus !== 'active' ? 'grey' : isActive ? 'white' : defaultGreen,
						})}
					>
						<Box component='span' sx={{ display: 'inline-flex', alignItems: 'center' }}>
							<Typography sx={{ fontSize: '0.9rem', letterSpacing: '0.5px' }} component='span'>
								Resources
							</Typography>
						</Box>
					</NavLink>

					<NavLink
						className={user?.subscriptionStatus !== 'active' ? 'disabled' : 'navLink'}
						style={({ isActive }) => ({
							marginLeft: '1rem',
							color: user?.subscriptionStatus !== 'active' ? 'grey' : isActive ? 'white' : defaultGreen,
						})}
						onClick={(e) => handleNavigation(e, '/statistics')}
					>
						<Box component='span' sx={{ display: 'inline-flex', alignItems: 'center' }}>
							<Typography sx={{ fontSize: '0.9rem', letterSpacing: '0.5px' }} component='span'>
								Statistics
							</Typography>
						</Box>
					</NavLink>

					<LoginAndSubscribeDialog
						open={open}
						handleClose={handleClose}
						handleCloseAndLogin={handleCloseAndLogin}
						handleCloseAndSubscribe={handleCloseAndSubscribe}
						user={user}
						authUser={authUser}
					/>
				</Grid>
			) : (
				<Grid sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
					<NavLink
						to='/'
						style={{
							textDecoration: 'none',
							color: 'rgb(47, 225, 112)',
							fontSize: isMobile ? '0.8rem' : '0.9rem',
							display: 'flex',
							alignItems: 'center',
							marginTop: isMobile ? '0' : '0.3rem',
						}}
					>
						<img className='header-logo' src={LIMIBJJ} alt='logo' style={{ height: isMobile ? '30px' : '60px', width: isMobile ? '30px' : '60px', borderRadius: '50%' }} />
					</NavLink>
					<NavLink
						to='/matches'
						onClick={(e) => handleNavigation(e, '/matches')}
						className={user?.subscriptionStatus !== 'active' ? 'disabled' : 'navLink'}
						style={({ isActive }) => ({
							marginLeft: '1rem',
							color: user?.subscriptionStatus !== 'active' ? 'grey' : isActive ? 'white' : defaultGreen,
						})}
					>
						<Box component='span' sx={{ display: 'inline-flex', alignItems: 'center' }}>
							<Typography sx={{ fontSize: '0.9rem', letterSpacing: '0.5px' }} component='span'>
								Matches
							</Typography>
						</Box>
					</NavLink>

					<NavLink
						to='/sequences'
						onClick={(e) => handleNavigation(e, '/sequences')}
						className={user?.subscriptionStatus !== 'active' ? 'disabled' : 'navLink'}
						style={({ isActive }) => ({
							marginLeft: '1rem',
							color: user?.subscriptionStatus !== 'active' ? 'grey' : isActive ? 'white' : defaultGreen,
						})}
					>
						<Box component='span' sx={{ display: 'inline-flex', alignItems: 'center' }}>
							<Typography sx={{ fontSize: '0.9rem', letterSpacing: '0.5px' }} component='span'>
								Sequences
							</Typography>
						</Box>
					</NavLink>

					<NavLink
						to='/training-resources'
						onClick={(e) => handleNavigation(e, '/training-resources')}
						className={user?.subscriptionStatus !== 'active' ? 'disabled' : 'navLink'}
						style={({ isActive }) => ({
							marginLeft: '1rem',
							color: user?.subscriptionStatus !== 'active' ? 'grey' : isActive ? 'white' : defaultGreen,
						})}
					>
						<Box component='span' sx={{ display: 'inline-flex', alignItems: 'center' }}>
							<Typography sx={{ fontSize: '0.9rem', letterSpacing: '0.5px' }} component='span'>
								Resources
							</Typography>
						</Box>
					</NavLink>

					<NavLink
						className={user?.subscriptionStatus !== 'active' ? 'disabled' : 'navLink'}
						style={({ isActive }) => ({
							marginLeft: '1rem',
							color: user?.subscriptionStatus !== 'active' ? 'grey' : isActive ? 'white' : defaultGreen,
						})}
						to='/statistics'
						onClick={(e) => handleNavigation(e, '/statistics')}
					>
						<Box component='span' sx={{ display: 'inline-flex', alignItems: 'center' }}>
							<Typography sx={{ fontSize: '0.9rem', letterSpacing: '0.5px' }} component='span'>
								Statistics
							</Typography>
						</Box>
					</NavLink>

					<LoginAndSubscribeDialog
						open={open}
						handleClose={handleClose}
						handleCloseAndLogin={handleCloseAndLogin}
						handleCloseAndSubscribe={handleCloseAndSubscribe}
						user={user}
						authUser={authUser}
					/>
				</Grid>
			)}
		</>
	);
}

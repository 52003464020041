import { useEffect } from 'react';
import { Triangle } from 'react-loader-spinner';
import { Container, Grid, Box, Typography, useMediaQuery } from '@mui/material';
import Head from '../components/Head/Head';
import Result from '../components/Result/Result';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';

const SearchResults = ({ user, authUser, loading, darkMode, matches, sequences, resources, handleSearch, setSearch, popularSearches }) => {
	const navigate = useNavigate();
	const isMobile = useMediaQuery('(max-width:600px)');

	const updateSearch = (e, search) => {
		e.preventDefault();
		setSearch(search);
		handleSearch(e, search);
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<Head title='Insights Search' />
			<Container className='search' style={{ background: darkMode ? '#042E21' : '#F1FFEB', height: '100%', minWidth: '100%' }}>
				<Box mt={3}>
					<Grid container spacing={3}>
						{loading ? (
							<Grid item xs={12}>
								<Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
									<Triangle height='100' width='100' color='rgb(47, 199, 112)' ariaLabel='triangle-loading' />
									<Typography variant='h6' style={{ color: 'rgb(47,199,112)' }}>
										Loading...
									</Typography>
								</Box>
							</Grid>
						) : (
							<Grid item xs={12}>
								<Box className='search-result-div'>
									<Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-between' }}>
										<Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', px: isMobile ? 1 : 2 }}>
											<Typography
												gutterBottom
												variant='p'
												onClick={(e) => navigate('/suggestions-form', e)}
												sx={{
													fontFamily: 'monospace',
													textDecoration: 'underline',
													ml: isMobile ? 'none' : 'auto',
													fontWeight: 300,
													mb: 2,
													width: 'fit-content',
													color: darkMode ? 'rgb(0,130,60)' : 'rgb(0, 109, 56)',
													':hover': { cursor: 'pointer', color: darkMode ? 'rgb(0,150,70)' : 'rgb(0,130,60)' },
												}}
											>
												Can't find something? Let us know!
											</Typography>
											<Typography variant='body1'>Popular Searches (Last 7 Days)</Typography>
											{popularSearches.length > 0 && (
												<Box my={2} sx={{ display: 'flex', width: '100%', overflowX: 'auto' }}>
													{popularSearches.map((search, index) => (
														<Box
															key={index}
															onClick={(e) => updateSearch(e, search.searchedFor)}
															sx={{
																mx: 1,
																mb: 2,
																background: darkMode ? '#22674e' : '#2FB370',
																minWidth: '200px',
																p: 1,
																borderRadius: '5rem',
																height: '40px',
																display: 'flex',
																alignItems: 'center',
																':hover': { background: darkMode ? '#2f7f61' : '#2fbe77', cursor: 'pointer' },
															}}
														>
															<SearchIcon sx={{ mr: 1, color: darkMode ? 'white' : 'black' }} />
															<Typography sx={{ fontFamily: 'monospace', fontSize: '.8rem' }} variant='body2'>
																{search.searchedFor.toLowerCase()}
															</Typography>
														</Box>
													))}
												</Box>
											)}
										</Box>
									</Box>
									<Box className='search-results' style={{ paddingBottom: '75px' }}>
										<Result matches={matches} sequences={sequences} resources={resources} darkMode={darkMode} user={user} authUser={authUser} />
									</Box>
								</Box>
							</Grid>
						)}
					</Grid>
				</Box>
			</Container>
		</>
	);
};

export default SearchResults;

import React, { useState, useEffect } from 'react';
import { Triangle } from 'react-loader-spinner';
import { Container, Card, Typography, Box, Button, useMediaQuery } from '@mui/material';
import Head from '../components/Head/Head';
import CircleIcon from '@mui/icons-material/Circle';
import axios from 'axios';
import '../App.css';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import MatchDetailsCard from '../components/MatchDetailsCard';
import SequenceDetailsCard from '../components/SequenceDetailsCard';
import '../App.css';

const API_URL = process.env.REACT_APP_API_URL;

function MatchAndSequencesDetails({ user, darkMode, authUser, isChatbotOpen }) {
	const isMobile = useMediaQuery('(max-width:1024px)');
	const navigate = useNavigate();
	const handleNavigate = (path) => {
		navigate(path);
	};
	const { id } = useParams();
	const { search } = useLocation();
	const [loading, setLoading] = useState(true);
	const [matchData, setMatchData] = useState(null);
	const [error, setError] = useState('');
	const [sequenceTimestamp, setSequenceTimestamp] = useState(null);
	const params = new URLSearchParams(search);
	const sequenceIdToScroll = params.get('sequence_id');
	// Callback to update YouTube URL
	const handleTimestampChange = (timestamp) => {
		setSequenceTimestamp(timestamp);
	};

	useEffect(() => {
		if (sequenceIdToScroll && matchData) {
			const element = document.getElementById(sequenceIdToScroll);
			if (element) {
				element.scrollIntoView({ behavior: 'smooth', block: 'center' });
			}
		}
	}, [sequenceIdToScroll, matchData]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				// Fetch data object to show
				const matchData = await axios.get(`${API_URL}/data/${id}`);
				setMatchData(matchData.data);
			} catch (error) {
				setError('Error retrieving data.');
			} finally {
				setLoading(false);
			}
		};
		fetchData();
	}, [user, error, id]);

	if (loading) {
		return (
			<>
				<Head title='Match Page' />
				<Container maxWidth='md' style={{ display: 'flex', justifyContent: 'center' }}>
					<Box className='loader'>
						<Triangle color='rgb(47, 199, 112)' size={100} />
						<Typography variant='h5' sx={{ color: 'rgb(47, 199, 112)', marginTop: '1rem' }}>
							Loading...
						</Typography>
					</Box>
				</Container>
			</>
		);
	}

	return (
		<>
			<Head title='Match Page' />
			<Container>
				{authUser && user?.subscriptionStatus !== 'active' ? (
					<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
						<Typography variant='h6' sx={{ color: 'red', mt: 2 }}>
							You must have an active subscription to view this page.
						</Typography>

						<Button
							variant='contained'
							sx={{
								borderRadius: '5rem',
								mt: 2,
								background: 'rgb(47, 160, 112)',
								color: darkMode ? '#000' : '#fff',
								'&:hover': { background: 'rgb(47,180,112)' },
							}}
							onClick={() => navigate('/account')}
						>
							Subscribe
						</Button>
					</Box>
				) : (
					<>
						<>
							<Typography variant='h4' sx={{ mt: 2, mb: 1, fontSize: isMobile ? '1.75rem' : '2.25rem' }}>
								Match & Sequences Details
							</Typography>
							{matchData && (
								<Box>
									<MatchDetailsCard id={id} sequenceTimestamp={sequenceTimestamp} matchData={matchData} user={user} darkMode={darkMode} />

									{matchData.sequences.length > 0 && (
										<Box style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', background: 'inherit' }}>
											<Typography
												variant='body2'
												sx={{
													mt: 2,
													fontSize: isMobile ? '.8rem' : '1rem',
													textAlign: 'right',
													display: 'flex',
													justifyContent: 'space-between',
													alignItems: 'center',
												}}
											>
												<span style={{ display: 'flex', alignItems: 'center' }}>
													<CircleIcon sx={{ color: 'green', fontSize: '1rem' }} /> <span>&nbsp;Training Resource</span>
												</span>
											</Typography>
										</Box>
									)}
									<Card
										id='sequence-card'
										sx={{
											minWidth: '250px', // Ensure minimum width for readability
											position: 'relative',
											my: isMobile ? 2 : 1,
											background: darkMode ? 'rgb(4, 46, 33)' : '#fff',
										}}
									>
										<Box
											style={{
												display: 'flex',
												flexDirection: 'column',
											}}
										>
											{matchData.sequences.length ? (
												<Box>
													<SequenceDetailsCard
														sequenceIdToScroll={sequenceIdToScroll}
														isChatbotOpen={isChatbotOpen}
														onTimestampChange={handleTimestampChange}
														sequences={matchData.sequences}
														darkMode={darkMode}
														disableTimeStamp={false}
														isMobile={isMobile}
														user={user}
														authUser={authUser}
														resources={matchData.resources.length ? matchData.resources : null}
														youtubeLink={matchData.youtubeLink}
													/>
													{user?.role === 'admin' && (
														<Typography
															onClick={() => handleNavigate(`/add-sequences/${matchData._id}`)}
															variant='body2'
															sx={{
																cursor: 'pointer',
																fontWeight: 300,
																color: 'rgb(47,150,112)',
																':hover': { color: 'rgb(47,180,112)' },
																textDecoration: 'underline',
																fontFamily: 'monospace',
																m: 1,
																p: 1,
																fontSize: '1rem',
															}}
														>
															Add Sequences
														</Typography>
													)}
												</Box>
											) : (
												<>
													<Typography variant='body2' sx={{ fontWeight: 300, fontFamily: 'monospace', m: 1, p: 1, fontSize: '1rem' }}>
														No sequences for this match.
													</Typography>
													{user?.role === 'admin' && (
														<Typography
															onClick={() => handleNavigate(`/add-sequences/${matchData._id}`)}
															variant='body2'
															sx={{ cursor: 'pointer', fontWeight: 300, color: 'green', textDecoration: 'underline', fontFamily: 'monospace', m: 1, p: 1, fontSize: '1rem' }}
														>
															Add Sequences
														</Typography>
													)}
												</>
											)}
										</Box>
									</Card>
								</Box>
							)}
						</>
					</>
				)}
			</Container>
		</>
	);
}

export default MatchAndSequencesDetails;

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Triangle } from 'react-loader-spinner';
import { Container, Card, Typography, IconButton, Box, Button, useMediaQuery, Tooltip, List, Modal, TextField } from '@mui/material';
import Head from '../components/Head/Head';
import axios from 'axios';
import '../App.css';
import { useNavigate } from 'react-router-dom';
import { useParams, useLocation } from 'react-router-dom';
import FolderHierarchy from '../components/FolderHierarchy';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import FeaturedSequenceCard from '../components/FeaturedSequenceCard';
import HexagonIcon from '@mui/icons-material/Hexagon';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import DeleteIcon from '@mui/icons-material/Delete';

const API_URL = process.env.REACT_APP_API_URL;

function ResourcePage({ user, darkMode, authUser }) {
	const isMobile = useMediaQuery('(max-width:600px)');
	const youtubeRef = useRef();
	const resourceRefs = useRef({});
	const navigate = useNavigate();
	const location = useLocation();
	const [resourceData, setResourceData] = useState([]);
	const [youTubeEmbedUrl, setYouTubeEmbedUrl] = useState('');
	const [embedType, setEmbedType] = useState('');
	const [iframeKey, setIframeKey] = useState(Date.now());
	const [selectedResource, setSelectedResource] = useState(null);
	const [visibleResourceId, setVisibleResourceId] = useState(null);
	const [showFolderModal, setShowFolderModal] = useState(false);
	const [likes, setLikes] = useState(selectedResource?.totalLikes || 0);
	const [dislikes, setDislikes] = useState(selectedResource?.totalDislikes || 0);
	const [sequenceId, setSequenceId] = useState(null);
	const { id } = useParams();
	const [loading, setLoading] = useState(true);
	const [folders, setFolders] = useState([]);

	// Modal style
	const modalStyle = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,
		bgcolor: darkMode ? 'rgb(30,30,30)' : 'rgb(225, 225, 225)',
		color: darkMode ? '#fff' : '#000',
		boxShadow: 24,
		height: '85%',
		overflowY: 'auto',
		p: 4,
	};

	const handleNewFolder = () => {
		navigate(`/user-folders/${user._id}`);
	};

	const handleFolderSelect = (folderId) => {
		saveItemToFolder(selectedResource._id, 'Resource', folderId);
		setShowFolderModal(false);
	};

	const handleDelete = async (dataId) => {
		if (window.confirm('Are you sure you want to delete this resource and associated sequences?')) {
			try {
				setLoading(true);
				await axios.delete(`${API_URL}/training-resource/${dataId}`);
				navigate('/');
			} catch (error) {
				console.error('Error deleting data:', error);
			}
		}
	};

	const addSequenceToResource = async (resourceId, sequenceId) => {
		try {
			const response = await axios.put(`${API_URL}/training-resource/${resourceId}/add-sequence`, { sequence_id: sequenceId });
			if (response.data) {
				window.location.reload();
			}
		} catch (error) {
			console.error('Error adding sequence to resource:', error);
			alert('Failed to add sequence to resource.');
		}
	};

	const copyToClipboard = (id) => {
		localStorage.setItem('copiedResourceId', id);
		alert('Resource ID copied');
	};

	const handleAddSequence = async (resource) => {
		try {
			await addSequenceToResource(resource._id, sequenceId);
		} catch (error) {
			console.error('Error adding sequence:', error);
		}
	};

	const handleRemoveResource = async (resource, sequence) => {
		try {
			await removeResourceFromSequence(resource, sequence);
		} catch (error) {
			console.error('Error removing resource:', error);
		}
	};

	const removeResourceFromSequence = async (resource, sequence) => {
		try {
			const response = await axios.put(`${API_URL}/training-resource/${resource._id}/remove-sequence`, { sequence_id: sequence._id });
			if (response.data) {
				alert('Resource removed from sequence successfully!');
				window.location.reload();
			}
		} catch (error) {
			console.error('Error removing resource from sequence:', error);
			alert('Failed to remove resource from sequence.');
		}
	};

	const handleLike = async () => {
		try {
			const response = await axios.patch(`${API_URL}/training-resource/like/${selectedResource._id}`, { userId: user?._id });
			setLikes(response.data.totalLikes);
			setDislikes(response.data.totalDislikes);
		} catch (error) {
			console.error('Error liking match:', error);
		}
	};

	const handleDislike = async () => {
		try {
			const response = await axios.patch(`${API_URL}/training-resource/dislike/${selectedResource._id}`, { userId: user?._id });
			setLikes(response.data.totalLikes);
			setDislikes(response.data.totalDislikes);
		} catch (error) {
			console.error('Error disliking match:', error);
		}
	};

	useEffect(() => {
		if (showFolderModal) {
			axios
				.get(`${API_URL}/folders/${user?._id}`)
				.then((response) => {
					const folderTree = buildFolderTree(response.data);
					setFolders(folderTree);
				})
				.catch((error) => {
					console.error('Error fetching folders:', error);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showFolderModal, user?._id]);

	function buildFolderTree(folders, parentId = null) {
		return folders
			.filter((folder) => folder.parentFolder === parentId)
			.map((folder) => ({
				...folder,
				subFolders: buildFolderTree(folders, folder._id),
			}));
	}

	function saveItemToFolder(itemId, itemType, folderId) {
		fetch(`${API_URL}/folders/${folderId}/addItem`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ itemId, itemType }),
		})
			.then((response) => response.json())
			.then((data) => {})
			.catch((error) => {
				console.error('Error:', error.message);
			});
	}

	const handleNavigate = (e, resource) => {
		e.preventDefault();

		localStorage.setItem('resourceBeingEdited', JSON.stringify(resource));
		navigate(`/edit-training-resource/${resource._id}`);
	};

	// Function to parse timestamp string to seconds
	const timestampToSeconds = (timestamp) => {
		const parts = timestamp.split(':').map(Number); // Convert all parts to numbers
		let seconds = 0;

		if (parts.length === 3) {
			// Format is hh:mm:ss
			seconds = parts[0] * 3600 + parts[1] * 60 + parts[2];
		} else if (parts.length === 2) {
			// Format is mm:ss
			seconds = parts[0] * 60 + parts[1];
		}
		return seconds;
	};

	const updateYouTubeEmbedUrl = (resource, timestamp) => {
		if (resource?.url) {
			const youtubeRegExp = /^.*(youtu\.be\/|v\/|e\/|u\/\w+\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
			const instagramRegExp = /(?:https?:\/\/)?(?:www\.)?(instagram\.com\/(p|reel)\/([A-Za-z0-9-_]+))/;

			const youtubeMatch = resource.url.match(youtubeRegExp);
			const instagramMatch = resource.url.match(instagramRegExp);

			if (youtubeMatch && youtubeMatch[2].length === 11) {
				const timestampInSeconds = timestamp ? timestampToSeconds(timestamp) : timestampToSeconds(resource.timestamp);
				const embedUrl = `https://www.youtube.com/embed/${youtubeMatch[2]}?start=${timestampInSeconds}&autoplay=1`;
				setYouTubeEmbedUrl(embedUrl);
				setEmbedType('youtube');
			} else if (instagramMatch) {
				const embedUrl = `https://www.instagram.com/${instagramMatch[2]}/${instagramMatch[3]}/embed`;
				setYouTubeEmbedUrl(embedUrl);
				setEmbedType('instagram');
			} else {
				setYouTubeEmbedUrl('');
				setEmbedType('');
			}

			if (youtubeRef.current) {
				youtubeRef.current.scrollIntoView({ behavior: 'smooth' });
			}
		}
	};

	const displayedVolumes = new Set();

	const addToUserRecentResources = useCallback(
		async (resourceId) => {
			try {
				const userId = user?._id;
				await axios.put(`${API_URL}/user/add-recent-resource`, { userId, resourceId });
			} catch (error) {
				console.error('Error adding match to user recent resources:', error);
			}
		},
		[user]
	);

	useEffect(() => {
		// see if user is present
		if (user !== null && user !== undefined) {
			addToUserRecentResources(id);
		}
	}, [id, user, addToUserRecentResources]);

	useEffect(() => {
		if (visibleResourceId && resourceRefs.current[visibleResourceId]) {
			resourceRefs.current[visibleResourceId].scrollIntoView({ behavior: 'smooth' });
		}
	}, [visibleResourceId]);

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const timestamp = queryParams.get('timestamp');

		const fetchData = async () => {
			try {
				const resourceData = await axios.get(`${API_URL}/training-resource/${id}`);
				setResourceData(resourceData.data);
				setLikes(resourceData.data[0].totalLikes);
				setDislikes(resourceData.data[0].totalDislikes);

				// Check if there is a resource being edited in localStorage
				const resourceBeingEdited = JSON.parse(localStorage.getItem('resourceBeingEdited'));
				const matchingResource = resourceData.data.find((resource) => resource._id === resourceBeingEdited?._id);
				if (resourceBeingEdited && matchingResource) {
					setSelectedResource(resourceBeingEdited);
					updateYouTubeEmbedUrl(resourceBeingEdited, resourceBeingEdited.timestamp);
					handleResourceSelection(resourceBeingEdited);
					localStorage.removeItem('resourceBeingEdited');
				} else if (timestamp) {
					const resourceWithTimestamp = resourceData.data.find((resource) => resource.timestamp === timestamp);
					if (resourceWithTimestamp) {
						setSelectedResource(resourceWithTimestamp);
						updateYouTubeEmbedUrl(resourceWithTimestamp, timestamp);
						handleResourceSelection(resourceWithTimestamp);
					} else {
						setSelectedResource(resourceData.data[0]);
						updateYouTubeEmbedUrl(resourceData.data[0]);
					}
				} else {
					setSelectedResource(resourceData.data[0]);
					updateYouTubeEmbedUrl(resourceData.data[0]);
				}
			} catch (error) {
				console.error('Error retrieving data:', error);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
		// eslint-disable-next-line
	}, [id, location.search]);

	const handlePasteSequence = () => {
		const sequenceId = localStorage.getItem('copiedSequenceId');
		if (sequenceId) {
			setSequenceId(sequenceId);
		} else {
			alert('No sequence ID to paste.');
		}
	};

	const handleResourceSelection = (resource) => {
		setSelectedResource(resource);
		setLikes(resource.totalLikes);
		setDislikes(resource.totalDislikes);
		if (visibleResourceId === resource._id) {
			setVisibleResourceId(null); // Toggle off if the same resource is clicked again
		} else {
			setVisibleResourceId(resource._id); // Show sequences for the clicked resource
		}
		updateYouTubeEmbedUrl(resource);
		// update url with proper timestamp
		const newUrl = `/resource/${id}?timestamp=${resource.timestamp}`;
		window.history.replaceState(null, '', newUrl);
	};

	if (loading) {
		return (
			<>
				<Head title='Match Page' />
				<Container maxWidth='md' style={{ display: 'flex', justifyContent: 'center' }}>
					<Box className='loader'>
						<Triangle color='rgb(47, 199, 112)' size={100} />
						<Typography variant='h5' sx={{ color: 'rgb(47, 199, 112)', marginTop: '1rem' }}>
							Loading...
						</Typography>
					</Box>
				</Container>
			</>
		);
	}

	return (
		<>
			<Head title='Resource Page' />
			<Container>
				<Typography variant='h4' sx={{ mt: 2, mb: 1 }}>
					Resource Details
				</Typography>
				{embedType === 'instagram' && (
					<Typography
						variant='body2'
						onClick={() => setIframeKey(Date.now())}
						sx={{
							fontWeight: 300,
							width: 'fit-content',
							color: darkMode ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.5)',
							cursor: 'pointer',
							'&:hover': { textDecoration: 'underline', color: darkMode ? 'rgba(255,255,255,0.7)' : 'rgba(0,0,0,0.7)' },
							fontFamily: 'monospace',
							mb: 1.5,
							fontSize: isMobile ? '.8rem' : '1rem',
						}}
					>
						Reload IG Post?
					</Typography>
				)}
				{resourceData.length && selectedResource && (
					<Card
						id='resource-card'
						sx={{
							maxWidth: '100%',
							minWidth: '250px', // Ensure minimum width for readability
							position: 'relative',
							my: isMobile ? 2 : 1,
							background: darkMode ? '#12392C' : '#fff',
							overflowX: 'auto', // Enable horizontal scrolling
							'&::-webkit-scrollbar': {
								height: '6px',
							},
							'&::-webkit-scrollbar-thumb': {
								backgroundColor: 'rgba(0,0,0,0.3)',
								borderRadius: '10px',
							},
						}}
					>
						<Box
							style={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'space-between',
								alignItems: 'flex-start',
								minWidth: '350px',
							}}
						>
							<Box style={{ display: 'flex', justifyContent: 'space-between', flexDirection: isMobile ? 'column' : 'row', width: '100%' }}>
								<Box ref={youtubeRef} style={{ display: 'flex', flexDirection: 'column', width: isMobile ? '100%' : '60%', position: 'relative' }}>
									{embedType === 'youtube' && (
										<iframe
											width='100%'
											height='350'
											style={{ borderRadius: '.75rem' }}
											src={youTubeEmbedUrl}
											title='YouTube video player'
											allow='accelerometer;  clipboard-write; encrypted-media; gyroscope;  '
											allowFullScreen
										></iframe>
									)}

									{embedType === 'instagram' && (
										<iframe
											key={iframeKey}
											width='100%'
											height='350'
											style={{ borderRadius: '.75rem' }}
											src={youTubeEmbedUrl}
											title='Instagram video player'
											allow='encrypted-media'
											allowFullScreen
										></iframe>
									)}

									{!embedType && (
										<>
											<iframe
												width='100%'
												height='350'
												style={{ borderRadius: '.75rem', background: 'black', position: 'relative' }}
												title='Video player'
												allow='accelerometer;  clipboard-write; encrypted-media; gyroscope;  '
												allowFullScreen
											></iframe>
											<Tooltip title='Watch on external site?' placement='top' arrow>
												<IconButton
													sx={{
														position: 'absolute',
														top: '50%',
														left: '50%',
														transform: 'translate(-50%, -50%)',
														color: darkMode ? '#fff' : 'rgb(100,100,100)',
														bgcolor: 'transparent',
														'&:hover': {
															bgcolor: 'background.default',
														},
														size: 'large',
													}}
													onClick={() => window.open(selectedResource.url, '_blank')}
												>
													<PlayCircleOutlineIcon sx={{ fontSize: '3rem' }} />
												</IconButton>
											</Tooltip>
										</>
									)}
								</Box>

								<Box sx={{ p: 2, width: isMobile ? '100%' : '40%' }}>
									<Typography variant='h6' sx={{ fontWeight: 600, fontSize: isMobile ? '1.15rem' : '1.3rem' }}>
										{selectedResource.title}
									</Typography>

									<Box sx={{ display: 'flex', width: '100%', pt: 1 }}>
										<Box sx={{ display: 'flex', flexDirection: 'column', width: '25%' }}>
											<Typography variant='body2' sx={{ fontWeight: 600, mb: 1.5, fontFamily: 'monospace', fontSize: isMobile ? '.8rem' : '1rem', color: 'rgb(0, 109, 56)' }}>
												INSTRUCTOR
											</Typography>
											<Typography variant='body2' sx={{ fontWeight: 600, fontFamily: 'monospace', mb: 1.5, fontSize: isMobile ? '.8rem' : '1rem' }}>
												TYPE
											</Typography>
											<Typography variant='body2' sx={{ fontWeight: 600, fontFamily: 'monospace', mb: 1.5, fontSize: isMobile ? '.8rem' : '1rem' }}>
												ACCESS
											</Typography>

											<Typography variant='body2' sx={{ fontWeight: 600, fontFamily: 'monospace', mb: 1.5, fontSize: isMobile ? '.8rem' : '1rem' }}>
												CHAPTER
											</Typography>
										</Box>
										<Box sx={{ display: 'flex', flexDirection: 'column', pl: isMobile ? 1 : 3, width: '75%' }}>
											<Typography
												variant='body2'
												sx={{
													fontWeight: 600,
													color: 'rgb(0, 109, 56)',
													fontFamily: 'monospace',
													mb: 1.5,
													ml: isMobile ? 3 : 0,
													fontSize: selectedResource.instructor.length > 20 ? '.9rem' : isMobile ? '.8rem' : '1rem',
												}}
											>
												{selectedResource.instructor}
											</Typography>
											<Typography variant='body2' sx={{ fontWeight: 300, fontFamily: 'monospace', mb: 1.5, ml: isMobile ? 3 : 0, fontSize: isMobile ? '.8rem' : '1rem' }}>
												{selectedResource.type}
											</Typography>
											<Typography variant='body2' sx={{ fontWeight: 300, fontFamily: 'monospace', mb: 1.5, ml: isMobile ? 3 : 0, fontSize: isMobile ? '.8rem' : '1rem' }}>
												{selectedResource.free ? 'Free' : 'Paid'}
											</Typography>

											<Typography variant='body2' sx={{ fontWeight: 300, fontFamily: 'monospace', mb: 1.5, ml: isMobile ? 3 : 0, fontSize: isMobile ? '.8rem' : '1rem' }}>
												{selectedResource.chapterTitle ? selectedResource.chapterTitle : 'N/A'}
											</Typography>
										</Box>
									</Box>
								</Box>
							</Box>
							<Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', marginTop: '-1rem', mb: 2 }}>
								{(user?.role === 'admin' || user?.role === 'data') && (
									<Button
										onClick={() => copyToClipboard(selectedResource._id)}
										size='small'
										color='success'
										sx={{
											mr: 1,
										}}
									>
										Copy ID
									</Button>
								)}
								{/* // save icon */}
								<Box onClick={() => setShowFolderModal(true)} sx={{ display: 'flex', alignItems: 'center', mr: isMobile ? 1 : 2, ':hover': { cursor: 'pointer' } }}>
									<Tooltip title='Save Resource to Folder' placement='top' arrow>
										<SaveIcon sx={{ color: 'rgb(255, 153, 0)', fontSize: '1.5rem' }} />
									</Tooltip>
								</Box>
								<Box onClick={(e) => handleNavigate(e, selectedResource)} sx={{ display: 'flex', alignItems: 'center', mr: isMobile ? 1 : 2, ':hover': { cursor: 'pointer' } }}>
									<Tooltip title='Edit Resource' placement='top' arrow>
										<EditIcon sx={{ color: darkMode ? '#249C75' : '#008001' }} />
									</Tooltip>
								</Box>
								{/* // thumbs up icon */}
								<Box onClick={() => handleLike()} sx={{ display: 'flex', alignItems: 'center', mr: isMobile ? 1 : 2, ':hover': { cursor: 'pointer' } }}>
									<ThumbUpIcon sx={{ color: 'rgb(47,160,112)', fontSize: '1.5rem' }} />
									<Typography variant='body2' sx={{ ml: 1, fontSize: '1rem' }}>
										{likes}
									</Typography>
								</Box>
								{/* // thumbs down icon */}
								<Box onClick={() => handleDislike()} sx={{ display: 'flex', alignItems: 'center', mr: isMobile ? 1 : 2, ':hover': { cursor: 'pointer' } }}>
									<ThumbDownIcon sx={{ color: 'rgb(47,160,112)', fontSize: '1.5rem' }} />
									<Typography variant='body2' sx={{ ml: 1, fontSize: '1rem' }}>
										{dislikes}
									</Typography>
								</Box>

								{/* // delete icon */}
								{(user?.role === 'admin' || user?._id === selectedResource.createdByUser) && (
									<Box onClick={() => handleDelete(selectedResource._id)} sx={{ display: 'flex', alignItems: 'center', ':hover': { cursor: 'pointer' } }}>
										<DeleteIcon sx={{ color: 'red', fontSize: '1.5rem' }} />
									</Box>
								)}
							</Box>
						</Box>
						<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2, p: 2, background: darkMode ? '#12392C' : '#fff' }}>
							<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
								<Typography variant='h6' sx={{ fontWeight: 600, fontSize: '1.3rem' }}>
									NOTES:
								</Typography>
								<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
									<HexagonIcon sx={{ color: darkMode ? 'rgb(191, 64, 191)' : 'rgb(93, 63, 211)', mr: 0.5, fontSize: '1rem' }} />
									<Typography variant='body2' sx={{ fontWeight: 300, fontSize: '1rem' }}>
										Sequences
									</Typography>
								</Box>
							</Box>
							{resourceData
								.sort((a, b) => {
									const volumeA = a.volume || 0;
									const volumeB = b.volume || 0;
									if (volumeA !== volumeB) {
										return volumeA - volumeB;
									} else {
										const aSeconds = timestampToSeconds(a.timestamp);
										const bSeconds = timestampToSeconds(b.timestamp);
										return aSeconds - bSeconds;
									}
								})
								.map((resource, index) => {
									let displayVolume = false;
									if (resource.volume && !displayedVolumes.has(resource.volume)) {
										displayedVolumes.add(resource.volume);
										displayVolume = true;
									}
									return (
										<>
											{displayVolume && (
												<Typography
													key={resource._id}
													variant='body2'
													sx={{
														fontFamily: 'monospace',
														fontSize: '1rem',
														color: darkMode ? '#269c75' : 'rgb(1, 108, 56)',
														marginTop: index === 0 ? 0 : 2,
													}}
												>
													Volume: {resource.volume}
												</Typography>
											)}
											<Card
												ref={(el) => (resourceRefs.current[resource._id] = el)}
												key={index}
												sx={{
													cursor: 'pointer',
													maxWidth: '100%',

													backgroundColor: visibleResourceId === resource._id ? (darkMode ? ' rgb(34, 82, 66)' : 'rgb(234, 255, 227)') : darkMode ? 'rgb(18, 57, 44)' : 'transparent',
												}}
											>
												<Box
													onClick={() => handleResourceSelection(resource)}
													sx={{
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'space-between',
														p: isMobile ? 1 : 2,
														overflowX: 'auto',
														borderBottom: '2px solid rgba(0,0,0,0.1)',
													}}
												>
													<Typography
														variant='body2'
														onClick={(e) => updateYouTubeEmbedUrl(resource)}
														style={{
															textDecoration: youTubeEmbedUrl ? 'underline' : 'none',
															border: 'none',
															fontFamily: 'monospace',
															color: darkMode ? '#269c75' : 'rgb(1, 108, 56)',
															cursor: 'pointer',
															':hover': { color: 'rgb(4, 131, 70)' },

															minWidth: '75px',
														}}
													>
														{resource.timestamp}
													</Typography>
													<Typography variant='body2' sx={{ px: 1, minWidth: '80%', mr: 'auto' }}>
														{resource.note}
													</Typography>
													<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
														<HexagonIcon sx={{ color: darkMode ? 'rgb(191, 64, 191)' : 'rgb(93, 63, 211)', fontSize: '1rem', mr: -0.5 }} />
														<Typography variant='body2' sx={{ minWidth: '30px', display: 'flex', justifyContent: 'flex-end' }}>
															x {resource.sequences.length}
														</Typography>
													</Box>
												</Box>
												{visibleResourceId === resource._id && (
													<Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', flexWrap: 'wrap', gap: 2, p: isMobile ? 1 : 2 }}>
														{resource.sequences.map((sequence, idx) => (
															<Box key={idx} sx={{ display: 'flex', flexDirection: 'column', gap: 2, justifyContent: 'space-between', alignItems: 'center' }}>
																<FeaturedSequenceCard user={user} authUser={authUser} darkMode={darkMode} key={idx} sequence={sequence} />
																<Button
																	onClick={() => handleRemoveResource(resource, sequence)}
																	sx={{ mt: 1, background: 'darkRed', color: darkMode ? '#000' : '#fff', ':hover': { background: 'red' } }}
																>
																	Remove
																</Button>
															</Box>
														))}
														{(user?.role === 'admin' || user?.role === 'data') && (
															<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
																<TextField label=' Sequence ID' variant='outlined' color='success' value={sequenceId} onChange={(e) => setSequenceId(e.target.value)} />
																{/* paste sequence id from localstorage */}
																<Button variant='contained' color='success' onClick={() => handlePasteSequence()}>
																	Paste
																</Button>
																<Button
																	sx={{ background: 'rgb(47,150,112)', color: darkMode ? '#000' : '#fff', ':hover': { background: 'rgb(47,180,112)' } }}
																	onClick={() => handleAddSequence(resource)}
																>
																	Add
																</Button>
															</Box>
														)}
													</Box>
												)}
											</Card>
										</>
									);
								})}
						</Box>
					</Card>
				)}
				{showFolderModal && (
					<Modal open={showFolderModal} onClose={() => setShowFolderModal(false)}>
						<Box sx={modalStyle}>
							<Box style={{ display: 'flex', justifyContent: 'space-between' }}>
								<Typography>Select a Folder</Typography>
								<Typography
									variant='button'
									onClick={() => handleNewFolder()}
									sx={{
										fontFamily: 'monospace',
										textDecoration: 'underline',
										pr: isMobile ? 1 : 0,
										fontWeight: 300,
										color: darkMode ? 'rgb(0,130,60)' : 'rgb(0, 109, 56)',
										':hover': { cursor: 'pointer', color: darkMode ? 'rgb(0,150,70)' : 'rgb(0,130,60)' },
									}}
								>
									New Folder
								</Typography>
							</Box>
							<List>
								{folders.map((folder) => {
									return <FolderHierarchy darkMode={darkMode} key={folder._id} folder={folder} onSelect={handleFolderSelect} />;
								})}
							</List>
						</Box>
					</Modal>
				)}
			</Container>
		</>
	);
}

export default ResourcePage;

import React, { useState, useEffect } from 'react';
import Head from '../components/Head/Head';
import {
	TextField,
	Button,
	Link,
	FormControl,
	FormLabel,
	Radio,
	RadioGroup,
	InputLabel,
	Select,
	Slider,
	MenuItem,
	Container,
	Grid,
	Typography,
	Checkbox,
	FormControlLabel,
	Tabs,
	Tab,
	Box,
	createTheme,
	ThemeProvider,
	Autocomplete,
} from '@mui/material';
import { SportsKabaddi } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL;

const theme = localStorage.getItem('theme') || 'light';

const customTheme = createTheme({
	palette: {
		primary: {
			main: theme === 'dark' ? '#fff' : '#808080',
		},
	},
});

const Dropdown = ({ label, options, darkMode, value, handleChange, name }) => (
	<FormControl
		sx={{
			// Change outline color when hovered but not focused
			'&:hover .MuiOutlinedInput-notchedOutline:not(.Mui-focused)': {
				borderColor: darkMode ? 'white!important' : 'grey',
			},
			// Change label color when hovered but not focused
			'&:hover .MuiInputLabel-root:not(.Mui-focused)': {
				color: darkMode ? 'white' : '#000',
			},
			// For text color
			'&& input': {
				color: darkMode ? '#fff' : '#000',
			},
			// Default outline color
			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: darkMode ? 'grey!important' : 'grey',
			},
			// Focused outline color
			'& .MuiOutlinedInput-notchedOutline.Mui-focused': {
				borderColor: darkMode ? 'rgb(47,199,112)' : '#000',
			},
			// Background color
			'& .MuiOutlinedInput-root': {
				backgroundColor: darkMode ? '#000' : '#f7f7f7',
			},
			'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
				borderColor: 'rgb(47, 199, 112)!important',
			},
			// For label text color
			'& .MuiInputLabel-root': {
				color: darkMode ? 'grey' : 'grey',
			},
			// Focused label color
			'& .MuiInputLabel-outlined.Mui-focused': {
				color: 'rgb(47, 180, 112)',
			},
		}}
		fullWidth
		variant='outlined'
		style={{ marginBottom: '20px' }}
	>
		<InputLabel>{label}</InputLabel>
		<Select label={label} value={value} onChange={handleChange} name={name}>
			{options.map((option) => (
				<MenuItem key={option} value={option || ''}>
					{option}
				</MenuItem>
			))}
		</Select>
	</FormControl>
);

function DataEntry({ data, user, darkMode }) {
	const navigate = useNavigate();
	const [submissionOptions, setSubmissionOptions] = useState([]);
	const [submissionPositionVariations, setSubmissionPositionVariations] = useState([]);
	const isMobile = window.innerWidth < 600;
	const [formData, setFormData] = useState({
		match: '',
		matchWinner: '',
		age: '',
		matchLength: 0,
		experience: '',
		overtimeLength: 0,
		floLink: '',
		floEmbed: '',
		youtubeLink: '',
		youtubeEmbed: '',
		ufcLink: '',
		ufcEmbed: '',
		enigmaLink: '',
		enigmaEmbed: '',
		sourceOfTimestamps: '',
		sport: 'No Gi BJJ',
		round: '',
		player1: '',
		player1Affiliation: '',
		player1Height: 0,
		player1Wingspan: 0,
		player1Inseam: 0,
		player1Chest: 0,
		player2: '',
		player2Affiliation: '',
		player2Height: 0,
		player2Wingspan: 0,
		player2Inseam: 0,
		player2Chest: 0,
		ruleSet: '',
		gender: '',
		organization: '',
		weightDivision: '',
		dateOfMatch: '',
		year: 0,
		region: '',
		finish: false,
		pointDiscrepancy: 0,
		matchEndedBeforePoints: false,
		firstToScore: 'N/A',
		overtime: false,
		winByDecision: false,
		winByPoints: false,
		submissionType: '',
		submissionVariation: '',
		submissionSide: '',
		submissionPosition: '',
		submissionPositionInitiation: '',
		submissionGrip: '',
		submissionMechanics: '',
		submissionResult: '',
		positionVariationUpper: '',
		positionVariationLower: '',
		timeOfFinish: 0,
		winnerInitiation: '',
		notes: [],
		player1Points: 0,
		player1Advantages: 0,
		player1Penalties: 0,
		player2Points: 0,
		player2Advantages: 0,
		player2Penalties: 0,
		organizationDetail: '',
		winByAdvantage: false,
		user: '',
	});
	const [existingMatches, setExistingMatches] = useState('');

	const [dateError, setDateError] = useState(false);
	const [timeError, setTimeError] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleDateInput = (e) => {
		const regex = /^\d{2}\/\d{2}\/\d{4}$/; // Checks for mm/dd/yyyy format
		if (!regex.test(e.target.value)) {
			setDateError(true);
		} else {
			setDateError(false);
		}
		handleChange(e);
	};

	const handleTimeChange = (e) => {
		const value = e.target.value;
		const isValidTime = /^([0-5]?\d):([0-5]?\d)$/.test(value); // Checks for mm:ss format

		if (isValidTime) {
			setFormData((prev) => ({ ...prev, timeOfFinish: value }));
			setTimeError(false);
		} else {
			setTimeError(true);
		}
	};

	const [initialCheckDone, setInitialCheckDone] = useState(false);
	const [matchesExist, setMatchesExist] = useState(false);

	useEffect(() => {
		async function fetchData() {
			try {
				// Fetch submission options
				const responseOptions = await fetch(`${API_URL}/submission-options`, {
					method: 'GET',
					headers: { 'Content-Type': 'application/json' },
				});
				const submissionOptionsData = await responseOptions.json();
				setSubmissionOptions(submissionOptionsData);

				// Fetch submission position variations
				const responsePositionVariations = await fetch(`${API_URL}/submission-position-variations`, {
					method: 'GET',
					headers: { 'Content-Type': 'application/json' },
				});
				const submissionPositionVariationsData = await responsePositionVariations.json();
				setSubmissionPositionVariations(submissionPositionVariationsData);
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		}

		fetchData();
	}, []);

	useEffect(() => {
		async function fetchPlayerStats() {
			if (formData.player1 && formData.player2) {
				// Check if both player names are entered
				try {
					// Query for player1
					const response1 = await fetch(`${API_URL}/data/check-for-player-stats`, {
						method: 'POST',
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify({ player: formData.player1 }),
					});
					const data1 = await response1.json();

					// Query for player2
					const response2 = await fetch(`${API_URL}/data/check-for-player-stats`, {
						method: 'POST',
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify({ player: formData.player2 }),
					});
					const data2 = await response2.json();

					// Update form state based on fetched data
					setFormData((prevState) => ({
						...prevState,
						player1Affiliation: data1.exists ? data1.playerData.affiliation : '',
						player1Height: data1.exists ? data1.playerData.height : 0,
						player1Chest: data1.exists ? data1.playerData.chest : 0,
						player1Inseam: data1.exists ? data1.playerData.inseam : 0,
						player1Wingspan: data1.exists ? data1.playerData.wingspan : 0,
						player2Affiliation: data2.exists ? data2.playerData.affiliation : '',
						player2Height: data2.exists ? data2.playerData.height : 0,
						player2Chest: data2.exists ? data2.playerData.chest : 0,
						player2Inseam: data2.exists ? data2.playerData.inseam : 0,
						player2Wingspan: data2.exists ? data2.playerData.wingspan : 0,
					}));
				} catch (error) {
					console.error('Error checking player data:', error);
				}
			}
		}

		fetchPlayerStats();
	}, [formData.player1, formData.player2]);

	const checkMatch = async () => {
		try {
			const response = await fetch(`${API_URL}/data/check-for-match`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					player1: formData.player1,
					player2: formData.player2,
				}),
			});

			const data = await response.json();
			if (data?.exists) {
				setMatchesExist(true);
				setExistingMatches(data?.matches);
			} else {
				if (matchesExist === true) setMatchesExist(false);
				setInitialCheckDone(true);
				setExistingMatches('');
			}
		} catch (error) {
			console.error('Error checking match:', error);
		}
	};

	const [showLinksAndEmbeds, setShowLinksAndEmbeds] = useState({
		showFlo: false,
		showYoutube: false,
		showUFC: false,
		showEnigma: false,
	});

	const toggleFieldVisibility = (field) => {
		setShowLinksAndEmbeds((prevState) => ({
			...prevState,
			[field]: !prevState[field],
		}));
	};

	// Function to render side options
	const renderSideOptions = () => {
		// Find the selected submission type object
		const selectedSubmissionOption = submissionOptions.find((option) => option.name === formData.submissionType);

		// Check if the selected submission option exists and has side options
		if (selectedSubmissionOption && selectedSubmissionOption.side) {
			return selectedSubmissionOption.side.map((sideOption) => (
				<MenuItem
					sx={{
						backgroundColor: darkMode ? '#2E2E2E' : '#fff',
						color: darkMode ? '#fff' : '#000',
						'&.Mui-selected': { backgroundColor: darkMode ? '#3E474F' : '#f7f7f7' },
						'&:hover': { backgroundColor: darkMode ? 'grey' : '#f7f7f7' },
						'&.Mui-selected:hover': {
							backgroundColor: darkMode ? 'grey' : 'grey',
							color: darkMode ? '#fff' : '#000',
						},
					}}
					key={sideOption}
					value={sideOption}
				>
					{sideOption}
				</MenuItem>
			));
		} else {
			// Optionally handle the case where there are no side options
			return null;
		}
	};

	const handleChange = async (event, newValue) => {
		let name, value;

		if (event.target) {
			// This is a TextField or Select change
			if (event.target.type === 'checkbox') {
				// This is a Checkbox sx={{ color: 'grey', '&.Mui-checked': { color: darkMode ? '#fff' : 'black' } }} change
				name = event.target.name;
				value = event.target.checked; // Here, value is either true or false based on checkbox status
			} else {
				event.target.name === 'submission' ? (name = 'submissionType') : (name = event.target.name);
				value = event.target.value;
			}
		} else {
			// This is a Slider change
			name = newValue.name;
			value = newValue.value;
		}

		if (name === 'organization') {
			if (value === 'ADCC') {
				setFormData((prev) => ({ ...prev, ruleSet: 'ADCC', organization: value }));
				return;
			} else if (value === 'IBJJF') {
				setFormData((prev) => ({ ...prev, ruleSet: 'Points', organization: value }));
				return;
			} else if (value === 'Other') {
				setFormData((prev) => ({ ...prev, ruleSet: '', organization: value }));
				return;
			}
		}

		if (name === 'timeOfFinish') {
			handleTimeChange(event);
		}

		// Check if the name of the field is 'notes'
		if (name === 'notes') {
			value = event.target.value;
		}

		setFormData({
			...formData,
			[name]: value,
		});
	};

	const toggleForm = (e) => {
		e.preventDefault();
		if (matchesExist === true) setMatchesExist(false);
		setInitialCheckDone(true);
		setExistingMatches('');
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		setLoading(true);
		// Convert timeOfFinish to seconds
		if (formData.timeOfFinish !== 0) {
			if (typeof formData.timeOfFinish === 'string') {
				const [minutes, seconds] = formData.timeOfFinish.split(':').map(Number);
				const totalSeconds = minutes * 60 + seconds;
				formData.timeOfFinish = totalSeconds;
			}
		}

		// if ruleSet === ADCC & timeOfFinish === < 1/2 of matchLength, matchEndedBeforePoints = true
		if (formData.ruleSet === 'ADCC' && formData.timeOfFinish !== 0 && formData.timeOfFinish < (formData.matchLength * 60) / 2) {
			formData.matchEndedBeforePoints = true;
		}
		// if matchEnededBeforePoints === true, firstToScore = 'N/A'
		if (formData.matchEndedBeforePoints === true) {
			formData.firstToScore = 'N/A';
		}

		// if organizationDetail.length > 0, organization = organizationDetail && delete organizationDetail
		// if formData.organization !== "Other" delete organizationDetail
		if (formData.organizationDetail && formData.organizationDetail.length > 0) {
			formData.organization = formData.organizationDetail;
			delete formData.organizationDetail;
		} else if (formData.organization !== 'Other') {
			delete formData.organizationDetail;
		}

		// calculate point discrepancy
		const player1Points = formData.player1Points;
		const player1Penalties = formData.player1Penalties;
		const player1Advantages = formData.player1Advantages;
		const player2Points = formData.player2Points;
		const player2Penalties = formData.player2Penalties;
		const player2Advantages = formData.player2Advantages;

		const player1Total = player1Points - player1Penalties;
		const player2Total = player2Points - player2Penalties;

		const pointDiscrepancy = Math.abs(player1Total - player2Total);
		const advantageDiscrepancy = Math.abs(player1Advantages - player2Advantages);

		if (player1Total === player2Total && advantageDiscrepancy > 0) {
			formData.winByAdvantage = true;
		} else {
			formData.winByAdvantage = false;
		}

		formData.pointDiscrepancy = pointDiscrepancy;

		if (formData.organization !== 'ADCC') formData.region = '';

		// if note length
		if (formData.notes.length !== 0) {
			const notesArray = formData.notes
				.split(/,|\n/)
				.map((note) => note.trim())
				.filter((note) => note);
			formData.notes = notesArray;
		}

		// post to database
		fetch(`${API_URL}/data`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(formData),
		})
			.then((res) => res.json())
			.then((data) => {
				navigate(`/add-sequences/${data?._id}`);
			})
			.catch((err) => console.error(err));
	};

	const [value, setValue] = useState(0);
	const handleTabChange = (event, newValue) => {
		setValue(newValue);
	};

	useEffect(() => {
		const matchString = `${formData.player1} vs ${formData.player2}`;
		setFormData((prevState) => ({
			...prevState,
			match: matchString,
		}));
	}, [formData.player1, formData.player2]);

	useEffect(() => {
		setFormData((prev) => ({ ...prev, user: user?._id }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (formData.dateOfMatch !== '') {
			const date = new Date(formData.dateOfMatch);
			const year = date.getFullYear();
			setFormData((prev) => ({ ...prev, year: year }));
		}
	}, [formData.dateOfMatch]);

	return (
		<Container maxWidth='md' sx={{ marginBottom: '4rem', marginTop: '2rem' }}>
			<Head title='Match Entry Form' description='Enter a match to be added to the database' />
			<Typography variant='h5' align='center' gutterBottom>
				Data Entry
			</Typography>
			{user?.role !== 'admin' && user?.role !== 'data' && (
				<Box sx={{ marginBottom: '16px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontSize: '1.2rem', mt: '4rem' }}>
					<Typography variant='p' gutterBottom sx={{ marginBottom: '16px', fontSize: '1.2rem', display: 'block' }}>
						Get certified to enter data by Jake
					</Typography>

					{/* turn link to open in new page https://calendly.com/limibjjdata/30min */}
					<Link
						href='https://calendly.com/limibjjdata/30min'
						target='_blank'
						sx={{ color: 'rgb(47, 160, 112)', textDecoration: 'none', fontFamily: 'monospace', '&:hover': { color: 'rgb(47, 180, 112)', textDecoration: 'underline' } }}
					>
						Book your 15 minute call.
					</Link>
				</Box>
			)}

			{(user?.role === 'admin' || user?.role === 'data') && (
				<>
					<Tabs
						value={value}
						onChange={handleTabChange}
						centered
						sx={{
							'& .MuiTab-root.Mui-selected': {
								color: darkMode ? '#e1e1e1' : 'black',
							},
							'& .MuiTabs-indicator': {
								backgroundColor: darkMode ? '#e1e1e1' : 'black',
							},
							marginBottom: '16px',
						}}
					>
						<Tab label='Required Info' />
					</Tabs>
					<form onSubmit={handleSubmit}>
						{value === 0 && (
							<Box>
								<Grid container spacing={3}>
									<Grid item xs={12} sm={6}>
										<Autocomplete
											options={data?.allAthletes}
											getOptionLabel={(option) => option}
											value={formData.player1}
											onInputChange={(event, newValue) => {
												setFormData((prev) => ({ ...prev, player1: newValue }));
											}}
											freeSolo
											renderInput={(params) => (
												<TextField
													autoComplete='off'
													{...params}
													sx={{
														input: {
															color: darkMode ? '#fff' : '#000',
														},
														'& .MuiOutlinedInput-root': {
															backgroundColor: darkMode ? '#000' : '#f7f7f7', // Set default background color
														},
														'& .MuiOutlinedInput-root.Mui-focused': {
															backgroundColor: darkMode ? '#000' : '#f7f7f7', // Set background color when focused
														},
														'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
															borderColor: 'rgb(47, 199, 112)',
														},
														'& .MuiInputLabel-outlined.Mui-focused': {
															color: 'rgb(47, 180, 112)',
														},
													}}
													fullWidth
													required
													id='player1'
													label='Player 1'
												/>
											)}
										/>
									</Grid>

									<Grid item xs={12} sm={6}>
										<Autocomplete
											options={data?.allAthletes}
											getOptionLabel={(option) => option}
											value={formData.player2}
											onInputChange={(event, newValue) => {
												setFormData((prev) => ({ ...prev, player2: newValue }));
											}}
											freeSolo
											renderInput={(params) => (
												<TextField
													autoComplete='off'
													{...params}
													sx={{
														input: {
															color: darkMode ? '#fff' : '#000',
														},
														'& .MuiOutlinedInput-root': {
															backgroundColor: darkMode ? '#000' : '#f7f7f7', // Set default background color
														},
														'& .MuiOutlinedInput-root.Mui-focused': {
															backgroundColor: darkMode ? '#000' : '#f7f7f7', // Set background color when focused
														},
														'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
															borderColor: 'rgb(47, 199, 112)',
														},
														'& .MuiInputLabel-outlined.Mui-focused': {
															color: 'rgb(47, 180, 112)',
														},
													}}
													fullWidth
													required
													id='player2'
													label='Player 2'
												/>
											)}
										/>
									</Grid>

									<Grid item xs={12}>
										<TextField
											autoComplete='off'
											disabled
											sx={{
												input: {
													color: darkMode ? '#fff' : '#000',
												},
												'& .MuiOutlinedInput-root': {
													backgroundColor: darkMode ? '#000' : '#f7f7f7', // Set default background color
												},
												'& .MuiOutlinedInput-root.Mui-focused': {
													backgroundColor: darkMode ? '#000' : '#f7f7f7', // Set background color when focused
												},
												'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
													borderColor: 'rgb(47, 199, 112)',
												},
												'& .MuiInputLabel-outlined.Mui-focused': {
													color: 'rgb(47, 180, 112)',
												},
												marginBottom: '16px',
											}}
											fullWidth
											required
											id='match'
											name='match'
											label='match'
											value={formData.match}
										/>
									</Grid>

									{/* check if match exists before proceeding */}
									{initialCheckDone === false && (
										<Grid item xs={12} sx={{ display: isMobile ? 'column' : 'flex', justifyContent: 'space-between' }}>
											<Box>
												{(!formData.player1.length || !formData.player2.length) && (
													<Typography variant='p' gutterBottom sx={{ marginBottom: '16px', display: 'block', fontSize: '.9rem' }}>
														Enter player names above to proceed with match entry
													</Typography>
												)}
												<Button
													variant='contained'
													sx={{
														backgroundColor: 'rgb(47, 199, 112)',
														color: 'white',
														marginBottom: '16px',
														'&:hover': { backgroundColor: 'rgb(47, 180, 112)', color: 'white' },
													}}
													disabled={formData.player1.length && formData.player2.length ? false : true}
													onClick={checkMatch}
												>
													New Match
												</Button>
											</Box>
											<Box>
												<Typography variant='p' gutterBottom sx={{ marginBottom: '16px', display: 'block', fontSize: '.9rem' }}>
													Or create a new resource
												</Typography>
												<Button
													variant='contained'
													sx={{
														backgroundColor: 'rgb(47, 199, 112)',
														color: 'white',
														marginBottom: '16px',
														'&:hover': { backgroundColor: 'rgb(47, 180, 112)', color: 'white' },
													}}
													onClick={() => navigate('/add-training-resource')}
												>
													New Resource
												</Button>
											</Box>
										</Grid>
									)}

									{matchesExist === false && initialCheckDone === true && (
										<>
											<Grid item xs={12}>
												<TextField
													autoComplete='off'
													sx={{
														input: {
															color: darkMode ? '#fff' : '#000',
														},
														'& .MuiOutlinedInput-root': {
															backgroundColor: darkMode ? '#000' : '#f7f7f7', // Set default background color
														},
														'& .MuiOutlinedInput-root.Mui-focused': {
															backgroundColor: darkMode ? '#000' : '#f7f7f7', // Set background color when focused
														},
														'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
															borderColor: 'rgb(47, 199, 112)',
														},
														'& .MuiInputLabel-outlined.Mui-focused': {
															color: 'rgb(47, 180, 112)',
														},
														flex: 1,
														marginRight: '8px',
													}}
													required
													id='dateOfMatch'
													name='dateOfMatch'
													label='Date of Match'
													value={formData.dateOfMatch}
													onChange={handleChange}
													onInput={handleDateInput}
													error={dateError}
													helperText={dateError ? 'Please use the format mm/dd/yyyy' : 'mm/dd/yyyy'}
												/>
											</Grid>
											<Grid item xs={12} sm={6}>
												<Autocomplete
													options={data?.affiliations}
													getOptionLabel={(option) => option}
													value={formData.player1Affiliation}
													name='player1Affiliation'
													onInputChange={(event, newValue) => {
														setFormData((prev) => ({ ...prev, player1Affiliation: newValue }));
													}}
													freeSolo
													renderInput={(params) => (
														<TextField
															autoComplete='off'
															{...params}
															sx={{
																input: {
																	color: darkMode ? '#fff' : '#000',
																},
																'& .MuiOutlinedInput-root': {
																	backgroundColor: darkMode ? '#000' : '#f7f7f7', // Set default background color
																},
																'& .MuiOutlinedInput-root.Mui-focused': {
																	backgroundColor: darkMode ? '#000' : '#f7f7f7', // Set background color when focused
																},
																'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
																	borderColor: 'rgb(47, 199, 112)',
																},
																'& .MuiInputLabel-outlined.Mui-focused': {
																	color: 'rgb(47, 180, 112)',
																},
															}}
															fullWidth
															required
															id='player1Affiliation'
															label='Player 1 Affiliation'
														/>
													)}
												/>
											</Grid>

											<Grid item xs={12} sm={6}>
												<Autocomplete
													options={data?.affiliations}
													getOptionLabel={(option) => option}
													value={formData.player2Affiliation}
													name='player2Affiliation'
													onInputChange={(event, newValue) => {
														setFormData((prev) => ({ ...prev, player2Affiliation: newValue }));
													}}
													freeSolo
													renderInput={(params) => (
														<TextField
															autoComplete='off'
															{...params}
															sx={{
																input: {
																	color: darkMode ? '#fff' : '#000',
																},
																'& .MuiOutlinedInput-root': {
																	backgroundColor: darkMode ? '#000' : '#f7f7f7', // Set default background color
																},
																'& .MuiOutlinedInput-root.Mui-focused': {
																	backgroundColor: darkMode ? '#000' : '#f7f7f7', // Set background color when focused
																},
																'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
																	borderColor: 'rgb(47, 199, 112)',
																},
																'& .MuiInputLabel-outlined.Mui-focused': {
																	color: 'rgb(47, 180, 112)',
																},
															}}
															fullWidth
															required
															id='player2Affiliation'
															label='Player 2 Affiliation'
														/>
													)}
												/>
											</Grid>

											<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
												<FormControl
													sx={{
														input: {
															color: darkMode ? '#fff' : '#000',
														},
														'& .MuiOutlinedInput-root': {
															backgroundColor: darkMode ? '#000' : '#f7f7f7', // Set default background color
														},
														'& .MuiOutlinedInput-root.Mui-focused': {
															backgroundColor: darkMode ? '#000' : '#f7f7f7', // Set background color when focused
														},
														'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
															borderColor: 'rgb(47, 199, 112)',
														},
														'& .MuiInputLabel-outlined.Mui-focused': {
															color: 'rgb(47, 180, 112)',
														},
														flex: 1,
														marginRight: '8px',
													}}
												>
													<InputLabel required id='matchWinner-label' sx={{ '&.MuiInputLabel-shrink': { transform: 'translate(14px, -16px) scale(0.75)' } }}>
														Match Winner
													</InputLabel>
													<Select required labelId='matchWinner-label' id='matchWinner' name='matchWinner' value={formData.matchWinner} onChange={handleChange}>
														<MenuItem value={`${formData.player1}`}>{formData.player1}</MenuItem>
														<MenuItem value={`${formData.player2}`}>{formData.player2}</MenuItem>
														<MenuItem value='Draw/Tie'>Draw/Tie</MenuItem>
													</Select>
												</FormControl>

												<FormControl
													sx={{
														input: {
															color: darkMode ? '#fff' : '#000',
														},
														'& .MuiOutlinedInput-root': {
															backgroundColor: darkMode ? '#000' : '#f7f7f7', // Set default background color
														},
														'& .MuiOutlinedInput-root.Mui-focused': {
															backgroundColor: darkMode ? '#000' : '#f7f7f7', // Set background color when focused
														},
														'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
															borderColor: 'rgb(47, 199, 112)',
														},
														'& .MuiInputLabel-outlined.Mui-focused': {
															color: 'rgb(47, 180, 112)',
														},
														flex: 1,
													}}
												>
													<InputLabel required id='sport-label' sx={{ '&.MuiInputLabel-shrink': { transform: 'translate(14px, -16px) scale(0.75)' } }}>
														Sport
													</InputLabel>
													<Select required labelId='sport-label' id='sport' name='sport' value={formData.sport} onChange={handleChange}>
														<MenuItem value='No Gi BJJ'>No Gi BJJ</MenuItem>
													</Select>
												</FormControl>
											</Grid>

											{formData.matchWinner !== 'Draw/Tie' && (
												<Grid item xs={12}>
													<FormControl
														fullWidth
														sx={{
															input: {
																color: darkMode ? '#fff' : '#000',
															},
															'& .MuiOutlinedInput-root': {
																backgroundColor: darkMode ? '#000' : '#f7f7f7', // Set default background color
															},
															'& .MuiOutlinedInput-root.Mui-focused': {
																backgroundColor: darkMode ? '#000' : '#f7f7f7', // Set background color when focused
															},
															'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
																borderColor: 'rgb(47, 199, 112)',
															},
															'& .MuiInputLabel-outlined.Mui-focused': {
																color: 'rgb(47, 180, 112)',
															},
														}}
													>
														<InputLabel required id='winnerInitiation-label' sx={{ '&.MuiInputLabel-shrink': { transform: 'translate(14px, -16px) scale(0.75)' } }}>
															Winner Initiation
														</InputLabel>
														<Select required labelId='winnerInitiation-label' id='winnerInitiation' name='winnerInitiation' value={formData.winnerInitiation} onChange={handleChange}>
															<MenuItem value='Guard Pull'>Guard Pull</MenuItem>
															<MenuItem value='Standing vs Guard Pull'>Standing vs Guard Pull</MenuItem>
															<MenuItem value='Takedown Offense'>Takedown Offense</MenuItem>
															<MenuItem value='Takedown Defense'>Takedown Defense</MenuItem>
														</Select>
													</FormControl>
												</Grid>
											)}

											<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
												<TextField
													autoComplete='off'
													sx={{
														input: {
															color: darkMode ? '#fff' : '#000',
														},
														'& .MuiOutlinedInput-root': {
															backgroundColor: darkMode ? '#000' : '#f7f7f7', // Set default background color
														},
														'& .MuiOutlinedInput-root.Mui-focused': {
															backgroundColor: darkMode ? '#000' : '#f7f7f7', // Set background color when focused
														},
														'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
															borderColor: 'rgb(47, 199, 112)',
														},
														'& .MuiInputLabel-outlined.Mui-focused': {
															color: 'rgb(47, 180, 112)',
														},
														flex: 1,
														marginRight: '8px',
													}}
													required
													id='matchLength'
													name='matchLength'
													label='Match Length (scheduled for how many minutes)'
													value={formData.matchLength}
													onChange={(e) => {
														if (e.target.value < 0) e.target.value = 0;
														handleChange(e);
													}}
													type='number'
													inputProps={{ min: 0 }}
												/>
												<TextField
													autoComplete='off'
													sx={{
														input: {
															color: darkMode ? '#fff' : '#000',
														},
														'& .MuiOutlinedInput-root': {
															backgroundColor: darkMode ? '#000' : '#f7f7f7', // Set default background color
														},
														'& .MuiOutlinedInput-root.Mui-focused': {
															backgroundColor: darkMode ? '#000' : '#f7f7f7', // Set background color when focused
														},
														'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
															borderColor: 'rgb(47, 199, 112)',
														},
														'& .MuiInputLabel-outlined.Mui-focused': {
															color: 'rgb(47, 180, 112)',
														},
														flex: 1,
													}}
													required
													id='overtimeLength'
													name='overtimeLength'
													label='Overtime Length (schedule for)'
													value={formData.overtimeLength || 0}
													onChange={(e) => {
														if (e.target.value < 0) e.target.value = 0;
														handleChange(e);
													}}
													type='number'
													inputProps={{ min: 0 }}
												/>
											</Grid>

											<Grid item xs={12}>
												<FormControl fullWidth component='fieldset'>
													<FormLabel
														required
														component='legend'
														sx={{
															color: darkMode ? '#fff' : 'black',
															'&.Mui-focused': {
																color: darkMode ? '#fff' : 'black',
															},
														}}
													>
														Gender
													</FormLabel>
													<RadioGroup row aria-label='gender' name='gender' value={formData.gender} onChange={handleChange} required>
														<FormControlLabel
															value='male'
															control={<Radio sx={{ color: 'grey', '&.Mui-checked': { color: darkMode ? '#fff' : 'black' } }} />}
															label='Male'
															sx={{ color: darkMode ? '#fff' : 'black' }}
														/>
														<FormControlLabel
															value='female'
															control={<Radio sx={{ color: 'grey', '&.Mui-checked': { color: darkMode ? '#fff' : 'black' } }} />}
															label='Female'
															sx={{ color: darkMode ? '#fff' : 'black' }}
														/>
													</RadioGroup>
												</FormControl>
											</Grid>

											<Grid item xs={12}>
												<FormControl fullWidth component='fieldset'>
													<FormLabel
														required
														component='legend'
														sx={{
															color: darkMode ? '#fff' : 'black',
															'&.Mui-focused': {
																color: darkMode ? '#fff' : 'black',
															},
														}}
													>
														Organization
													</FormLabel>
													<RadioGroup row aria-label='organization' name='organization' value={formData.organization} onChange={handleChange} required>
														<FormControlLabel
															value='ADCC'
															control={<Radio sx={{ color: 'grey', '&.Mui-checked': { color: darkMode ? '#fff' : 'black' } }} />}
															label='ADCC'
															sx={{ color: darkMode ? '#fff' : 'black' }}
														/>
														<FormControlLabel
															value='IBJJF'
															control={<Radio sx={{ color: 'grey', '&.Mui-checked': { color: darkMode ? '#fff' : 'black' } }} />}
															label='IBJJF'
															sx={{ color: darkMode ? '#fff' : 'black' }}
														/>
														<FormControlLabel
															value='Other'
															control={<Radio sx={{ color: 'grey', '&.Mui-checked': { color: darkMode ? '#fff' : 'black' } }} />}
															label='Other'
															sx={{ color: darkMode ? '#fff' : 'black' }}
														/>
													</RadioGroup>
												</FormControl>
												{formData.organization === 'Other' && (
													<TextField
														autoComplete='off'
														sx={{
															input: {
																color: darkMode ? '#fff' : '#000',
															},
															'& .MuiOutlinedInput-root': {
																backgroundColor: darkMode ? '#000' : '#f7f7f7', // Set default background color
															},
															'& .MuiOutlinedInput-root.Mui-focused': {
																backgroundColor: darkMode ? '#000' : '#f7f7f7', // Set background color when focused
															},
															'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
																borderColor: 'rgb(47, 199, 112)',
															},
															'& .MuiInputLabel-outlined.Mui-focused': {
																color: 'rgb(47, 180, 112)',
															},
														}}
														style={{ marginTop: '1rem', marginBottom: '8px' }}
														fullWidth
														id='organizationDetail'
														name='organizationDetail'
														label='Specify Organization (EBI...WNO...etc)'
														value={formData.organizationDetail}
														onChange={handleChange}
													/>
												)}
											</Grid>

											{formData.organization === 'ADCC' && (
												<FormControl
													fullWidth
													sx={{
														input: {
															color: darkMode ? '#fff' : '#000',
														},
														'& .MuiOutlinedInput-root': {
															backgroundColor: darkMode ? '#000' : '#f7f7f7', // Set default background color
														},
														'& .MuiOutlinedInput-root.Mui-focused': {
															backgroundColor: darkMode ? '#000' : '#f7f7f7', // Set background color when focused
														},
														'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
															borderColor: 'rgb(47, 199, 112)',
														},
														'& .MuiInputLabel-outlined.Mui-focused': {
															color: 'rgb(47, 180, 112)',
														},
														marginTop: '8px',
														marginBottom: '8px',
													}}
												>
													<InputLabel required id='region-label' sx={{ '&.MuiInputLabel-shrink': { transform: 'translate(14px, -16px) scale(0.75)' } }}>
														Region
													</InputLabel>
													<Select labelId='region-label' id='region' name='region' value={formData.region} onChange={handleChange}>
														<MenuItem key='Opens-Brazil' value='Opens: Brazil'>
															Opens: Brazil
														</MenuItem>
														<MenuItem key='Opens-Canada' value='Opens: Canada'>
															Opens: Canada
														</MenuItem>
														<MenuItem key='Opens-Mexico' value='Opens: Mexico'>
															Opens: Mexico
														</MenuItem>
														<MenuItem key='Opens-USA' value='Opens: USA'>
															Opens: USA
														</MenuItem>
														<MenuItem key='Trials-West Coast' value='Trials: West Coast'>
															Trials: West Coast
														</MenuItem>
														<MenuItem key='Trials-East Coast' value='Trials: East Coast'>
															Trials: East Coast
														</MenuItem>
														<MenuItem key='Trials-South American' value='Trials: South American'>
															Trials: South American
														</MenuItem>
														<MenuItem key='Trials-European Middle East and Africa' value='Trials: European Middle East and Africa'>
															Trials: European Middle East and Africa
														</MenuItem>
														<MenuItem key='Trials-Asia & Oceania' value='Trials: Asia & Oceania'>
															Trials: Asia & Oceania
														</MenuItem>
														<MenuItem key='ADCC-Event' value='ADCC Event'>
															ADCC Event
														</MenuItem>
													</Select>
												</FormControl>
											)}

											<Grid item xs={12}>
												{formData.organization ? (
													<FormControl fullWidth component='fieldset'>
														<FormLabel
															component='legend'
															sx={{
																color: darkMode ? '#fff' : 'black',
																'&.Mui-focused': {
																	color: darkMode ? '#fff' : 'black',
																},
															}}
														>
															Rule set
														</FormLabel>
														<RadioGroup row aria-label='ruleSet' name='ruleSet' value={formData.ruleSet} onChange={handleChange} required>
															{formData.organization === 'ADCC' && (
																<FormControlLabel
																	value='ADCC'
																	control={<Radio sx={{ color: 'grey', '&.Mui-checked': { color: darkMode ? '#fff' : 'black' } }} />}
																	label='ADCC'
																	sx={{ color: darkMode ? '#fff' : 'black' }}
																/>
															)}
															{formData.organization === 'IBJJF' && (
																<FormControlLabel
																	value='Points'
																	control={<Radio sx={{ color: 'grey', '&.Mui-checked': { color: darkMode ? '#fff' : 'black' } }} />}
																	label='Points'
																	sx={{ color: darkMode ? '#fff' : 'black' }}
																/>
															)}
															{formData.organization === 'Other' && (
																<>
																	<FormControlLabel
																		value='ADCC'
																		control={
																			<Radio
																				sx={{
																					color: 'grey',
																					'&.Mui-checked': { color: darkMode ? '#fff' : 'black' },
																				}}
																			/>
																		}
																		label='ADCC'
																		sx={{ color: darkMode ? '#fff' : 'black' }}
																	/>
																	<FormControlLabel
																		value='Points'
																		control={
																			<Radio
																				sx={{
																					color: 'grey',
																					'&.Mui-checked': { color: darkMode ? '#fff' : 'black' },
																				}}
																			/>
																		}
																		label='Points'
																		sx={{ color: darkMode ? '#fff' : 'black' }}
																	/>
																	<FormControlLabel
																		value='Submission Only'
																		control={
																			<Radio
																				sx={{
																					color: 'grey',
																					'&.Mui-checked': { color: darkMode ? '#fff' : 'black' },
																				}}
																			/>
																		}
																		label='Submission Only'
																		sx={{ color: darkMode ? '#fff' : 'black' }}
																	/>
																	<FormControlLabel
																		value='Other'
																		control={
																			<Radio
																				sx={{
																					color: 'grey',
																					'&.Mui-checked': { color: darkMode ? '#fff' : 'black' },
																				}}
																			/>
																		}
																		label='Other'
																		sx={{ color: darkMode ? '#fff' : 'black' }}
																	/>
																</>
															)}
														</RadioGroup>
													</FormControl>
												) : null}
											</Grid>

											<FormControl
												fullWidth
												sx={{
													input: {
														color: darkMode ? '#fff' : '#000',
													},
													'& .MuiOutlinedInput-root': {
														backgroundColor: darkMode ? '#000' : '#f7f7f7', // Set default background color
													},
													'& .MuiOutlinedInput-root.Mui-focused': {
														backgroundColor: darkMode ? '#000' : '#f7f7f7', // Set background color when focused
													},
													'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
														borderColor: 'rgb(47, 199, 112)',
													},
													'& .MuiInputLabel-outlined.Mui-focused': {
														color: 'rgb(47, 180, 112)',
													},
													marginTop: '8px',
													marginBottom: '8px',
												}}
											>
												<InputLabel required id='age-label' sx={{ '&.MuiInputLabel-shrink': { transform: 'translate(14px, -16px) scale(0.75)' } }}>
													Age
												</InputLabel>
												<Select required labelId='age-label' id='age' name='age' value={formData.age} onChange={handleChange}>
													{formData.organization === 'ADCC'
														? [
																<MenuItem key='Adult' value='Adult'>
																	Adult
																</MenuItem>,
																<MenuItem key='Masters' value='Masters'>
																	Masters
																</MenuItem>,
																<MenuItem key='Masters 2' value='Masters 2'>
																	Masters 2
																</MenuItem>,
																<MenuItem key='Masters 3' value='Masters 3'>
																	Masters 3
																</MenuItem>,
														  ]
														: null}

													{formData.organization === 'IBJJF'
														? [
																<MenuItem key='Adult' value='Adult'>
																	Adult
																</MenuItem>,
																<MenuItem key='Master 1' value='Master 1'>
																	Master 1
																</MenuItem>,
																<MenuItem key='Master 2' value='Master 2'>
																	Master 2
																</MenuItem>,
																<MenuItem key='Master 3' value='Master 3'>
																	Master 3
																</MenuItem>,
																<MenuItem key='Master 4' value='Master 4'>
																	Master 4
																</MenuItem>,
																<MenuItem key='Master 5' value='Master 5'>
																	Master 5
																</MenuItem>,
																<MenuItem key='Master 6' value='Master 6'>
																	Master 6
																</MenuItem>,
																<MenuItem key='Master 7' value='Master 7'>
																	Master 7
																</MenuItem>,
														  ]
														: null}
													{formData.organization === 'Other'
														? [
																<MenuItem key='Adult' value='Adult'>
																	Adult
																</MenuItem>,
														  ]
														: null}
												</Select>
											</FormControl>

											<FormControl
												fullWidth
												sx={{
													input: {
														color: darkMode ? '#fff' : '#000',
													},
													'& .MuiOutlinedInput-root': {
														backgroundColor: darkMode ? '#000' : '#f7f7f7', // Set default background color
													},
													'& .MuiOutlinedInput-root.Mui-focused': {
														backgroundColor: darkMode ? '#000' : '#f7f7f7', // Set background color when focused
													},
													'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
														borderColor: 'rgb(47, 199, 112)',
													},
													'& .MuiInputLabel-outlined.Mui-focused': {
														color: 'rgb(47, 180, 112)',
													},
													marginTop: '8px',
													marginBottom: '8px',
												}}
											>
												<InputLabel required id='experience-label' sx={{ '&.MuiInputLabel-shrink': { transform: 'translate(14px, -16px) scale(0.75)' } }}>
													Experience
												</InputLabel>
												<Select labelId='experience-label' id='experience' name='experience' value={formData.experience} onChange={handleChange} required>
													{formData.organization === 'ADCC'
														? [
																<MenuItem key='Beginner' value='Beginner'>
																	Beginner
																</MenuItem>,
																<MenuItem key='Intermediate' value='Intermediate'>
																	Intermediate
																</MenuItem>,
																<MenuItem key='Advanced' value='Advanced'>
																	Advanced
																</MenuItem>,
														  ]
														: [
																<MenuItem key='White' value='White'>
																	White
																</MenuItem>,
																<MenuItem key='Blue' value='Blue'>
																	Blue
																</MenuItem>,
																<MenuItem key='Purple' value='Purple'>
																	Purple
																</MenuItem>,
																<MenuItem key='Brown' value='Brown'>
																	Brown
																</MenuItem>,
																<MenuItem key='Black' value='Black'>
																	Black
																</MenuItem>,
														  ]}
												</Select>
											</FormControl>

											{formData.organization === 'Other' ? null : (
												<FormControl
													fullWidth
													sx={{
														input: {
															color: darkMode ? '#fff' : '#000',
														},
														'& .MuiOutlinedInput-root': {
															backgroundColor: darkMode ? '#000' : '#f7f7f7', // Set default background color
														},
														'& .MuiOutlinedInput-root.Mui-focused': {
															backgroundColor: darkMode ? '#000' : '#f7f7f7', // Set background color when focused
														},
														'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
															borderColor: 'rgb(47, 199, 112)',
														},
														'& .MuiInputLabel-outlined.Mui-focused': {
															color: 'rgb(47, 180, 112)',
														},
														marginTop: '8px',
														marginBottom: '8px',
													}}
												>
													<InputLabel required id='round-label' sx={{ '&.MuiInputLabel-shrink': { transform: 'translate(14px, -16px) scale(0.75)' } }}>
														Round
													</InputLabel>
													<Select labelId='round-label' id='round' name='round' value={formData.round} onChange={handleChange}>
														{formData.organization === 'IBJJF' && [
															<MenuItem key='32' value='32'>
																32
															</MenuItem>,
															<MenuItem key='16' value='16'>
																16
															</MenuItem>,
															<MenuItem key='8' value='8'>
																8
															</MenuItem>,
															<MenuItem key='4' value='4'>
																4
															</MenuItem>,
															<MenuItem key='2' value='2'>
																2
															</MenuItem>,
														]}
														{formData.organization === 'ADCC' && [
															<MenuItem key='256' value='256'>
																256
															</MenuItem>,
															<MenuItem key='128' value='128'>
																128
															</MenuItem>,
															<MenuItem key='64' value='64'>
																64
															</MenuItem>,
															<MenuItem key='32' value='32'>
																32
															</MenuItem>,
															<MenuItem key='16' value='16'>
																16
															</MenuItem>,
															<MenuItem key='8' value='8'>
																8
															</MenuItem>,
															<MenuItem key='4' value='4'>
																4
															</MenuItem>,
															<MenuItem key='2' value='2'>
																2
															</MenuItem>,
														]}
													</Select>
												</FormControl>
											)}

											<FormControl
												fullWidth
												sx={{
													input: {
														color: darkMode ? '#fff' : '#000',
													},
													'& .MuiOutlinedInput-root': {
														backgroundColor: darkMode ? '#000' : '#f7f7f7', // Set default background color
													},
													'& .MuiOutlinedInput-root.Mui-focused': {
														backgroundColor: darkMode ? '#000' : '#f7f7f7', // Set background color when focused
													},
													'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
														borderColor: 'rgb(47, 199, 112)',
													},
													'& .MuiInputLabel-outlined.Mui-focused': {
														color: 'rgb(47, 180, 112)',
													},
													marginTop: '8px',
												}}
											>
												<InputLabel required id='weight-division-label' sx={{ '&.MuiInputLabel-shrink': { transform: 'translate(14px, -16px) scale(0.75)' } }}>
													Weight Division
												</InputLabel>
												<Select required labelId='weight-division-label' id='weight-division' name='weightDivision' value={formData.weightDivision} onChange={handleChange}>
													{formData.organization === 'IBJJF' && [
														<MenuItem key='Rooster' value='Rooster'>
															Rooster
														</MenuItem>,
														<MenuItem key='Light Feather' value='Light Feather'>
															Light Feather
														</MenuItem>,
														<MenuItem key='Feather' value='Feather'>
															Feather
														</MenuItem>,
														<MenuItem key='Light' value='Light'>
															Light
														</MenuItem>,
														<MenuItem key='Middle' value='Middle'>
															Middle
														</MenuItem>,
														<MenuItem key='Medium Heavy' value='Medium Heavy'>
															Medium Heavy
														</MenuItem>,
														<MenuItem key='Heavy' value='Heavy'>
															Heavy
														</MenuItem>,
														<MenuItem key='Super Heavy' value='Super Heavy'>
															Super Heavy
														</MenuItem>,
														<MenuItem key='Ultra Heavy' value='Ultra Heavy'>
															Ultra Heavy
														</MenuItem>,
														<MenuItem key='Open Class' value='Open Class'>
															Open Class
														</MenuItem>,
													]}

													{formData.organization === 'ADCC' &&
														(formData.gender === 'female'
															? formData.region === 'Opens: Brazil'
																? [
																		<MenuItem key='-50KG' value='-50KG'>
																			-50KG
																		</MenuItem>,
																		<MenuItem key='-55KG' value='-55KG'>
																			-55KG
																		</MenuItem>,
																		<MenuItem key='-60KG' value='-60KG'>
																			-60KG
																		</MenuItem>,
																		<MenuItem key='-65KG' value='-65KG'>
																			-65KG
																		</MenuItem>,
																		<MenuItem key='-70KG' value='-70KG'>
																			-70KG
																		</MenuItem>,
																		<MenuItem key='+70KG' value='+70KG'>
																			+70KG
																		</MenuItem>,
																  ]
																: [
																		<MenuItem key='-55KG' value='-55KG'>
																			-55KG
																		</MenuItem>,
																		<MenuItem key='-60KG' value='-60KG'>
																			-60KG
																		</MenuItem>,
																		<MenuItem key='+60KG' value='+60KG'>
																			+60KG
																		</MenuItem>,
																		<MenuItem key='-65KG' value='-65KG'>
																			-65KG
																		</MenuItem>,
																		<MenuItem key='+65KG' value='+65KG'>
																			+65KG
																		</MenuItem>,
																  ]
															: formData.region.includes('Opens')
															? [
																	<MenuItem key='-60KG' value='-60KG'>
																		-60KG
																	</MenuItem>,
																	<MenuItem key='-65KG' value='-65KG'>
																		-65KG
																	</MenuItem>,
																	<MenuItem key='-70KG' value='-70KG'>
																		-70KG
																	</MenuItem>,
																	<MenuItem key='-76KG' value='-76KG'>
																		-76KG
																	</MenuItem>,
																	<MenuItem key='-83KG' value='-83KG'>
																		-83KG
																	</MenuItem>,
																	<MenuItem key='-91KG' value='-91KG'>
																		-91KG
																	</MenuItem>,
																	<MenuItem key='-100KG' value='-100KG'>
																		-100KG
																	</MenuItem>,
																	<MenuItem key='+100KG' value='+100KG'>
																		+100KG
																	</MenuItem>,
																	<MenuItem key='Absolute' value='Absolute'>
																		Absolute
																	</MenuItem>,
															  ]
															: [
																	<MenuItem key='-66KG' value='-66KG'>
																		-66KG
																	</MenuItem>,
																	<MenuItem key='-77KG' value='-77KG'>
																		-77KG
																	</MenuItem>,
																	<MenuItem key='-88KG' value='-88KG'>
																		-88KG
																	</MenuItem>,
																	<MenuItem key='-99KG' value='-99KG'>
																		-99KG
																	</MenuItem>,
																	<MenuItem key='+99KG' value='+99KG'>
																		+99KG
																	</MenuItem>,
																	<MenuItem key='Absolute' value='Absolute'>
																		Absolute
																	</MenuItem>,
															  ])}
													{formData.organization === 'Other' && [
														<MenuItem key='Catch' value='Catch Weight'>
															Catch Weight
														</MenuItem>,
														<MenuItem key='Other' value='Other'>
															Other
														</MenuItem>,
														<MenuItem key='Rooster' value='Rooster'>
															Rooster
														</MenuItem>,
														<MenuItem key='Light Feather' value='Light Feather'>
															Light Feather
														</MenuItem>,
														<MenuItem key='Feather' value='Feather'>
															Feather
														</MenuItem>,
														<MenuItem key='Light' value='Light'>
															Light
														</MenuItem>,
														<MenuItem key='Middle' value='Middle'>
															Middle
														</MenuItem>,
														<MenuItem key='Medium Heavy' value='Medium Heavy'>
															Medium Heavy
														</MenuItem>,
														<MenuItem key='Heavy' value='Heavy'>
															Heavy
														</MenuItem>,
														<MenuItem key='Super Heavy' value='Super Heavy'>
															Super Heavy
														</MenuItem>,
														<MenuItem key='Ultra Heavy' value='Ultra Heavy'>
															Ultra Heavy
														</MenuItem>,
														<MenuItem key='Open Class' value='Open Class'>
															Open Class
														</MenuItem>,
														<MenuItem key='-55KG' value='-55KG'>
															-55KG
														</MenuItem>,
														<MenuItem key='-60KG' value='-60KG'>
															-60KG
														</MenuItem>,
														<MenuItem key='+60KG' value='+60KG'>
															+60KG
														</MenuItem>,
														<MenuItem key='-65KG' value='-65KG'>
															-65KG
														</MenuItem>,
														<MenuItem key='+65KG' value='+65KG'>
															+65KG
														</MenuItem>,
														<MenuItem key='-66KG' value='-66KG'>
															-66KG
														</MenuItem>,
														<MenuItem key='-77KG' value='-77KG'>
															-77KG
														</MenuItem>,
														<MenuItem key='-88KG' value='-88KG'>
															-88KG
														</MenuItem>,
														<MenuItem key='-99KG' value='-99KG'>
															-99KG
														</MenuItem>,
														<MenuItem key='+99KG' value='+99KG'>
															+99KG
														</MenuItem>,
														<MenuItem key='Absolute' value='Absolute'>
															Absolute
														</MenuItem>,
													]}
												</Select>
											</FormControl>

											<ThemeProvider theme={customTheme}>
												<Grid item xs={12} sx={{ paddingTop: '12px!important' }}>
													<FormControlLabel
														sx={{ color: darkMode ? '#fff' : 'grey', '&.Mui-checked': { color: darkMode ? '#fff' : 'black' } }}
														control={
															<Checkbox
																sx={{ color: 'grey', '&.Mui-checked': { color: darkMode ? '#fff' : 'black' } }}
																checked={formData.finish}
																onChange={handleChange}
																name='finish'
															/>
														}
														label='Submission Finish'
														disabled={formData.winByDecision || formData.winByPoints}
													/>
													<FormControlLabel
														sx={{ color: darkMode ? '#fff' : 'grey', '&.Mui-checked': { color: darkMode ? '#fff' : 'black' } }}
														control={
															<Checkbox
																sx={{ color: 'grey', '&.Mui-checked': { color: darkMode ? '#fff' : 'black' } }}
																checked={formData.overtime}
																onChange={handleChange}
																name='overtime'
															/>
														}
														label='Match Went to Overtime'
													/>
													<FormControlLabel
														sx={{ color: darkMode ? '#fff' : 'grey', '&.Mui-checked': { color: darkMode ? '#fff' : 'black' } }}
														control={
															<Checkbox
																sx={{ color: 'grey', '&.Mui-checked': { color: darkMode ? '#fff' : 'black' } }}
																checked={formData.winByPoints}
																onChange={handleChange}
																name='winByPoints'
															/>
														}
														label='Match Won Via Points'
														disabled={formData.finish || formData.winByDecision}
													/>
													<FormControlLabel
														sx={{ color: darkMode ? '#fff' : 'grey', '&.Mui-checked': { color: darkMode ? '#fff' : 'black' } }}
														control={
															<Checkbox
																sx={{ color: 'grey', '&.Mui-checked': { color: darkMode ? '#fff' : 'black' } }}
																checked={formData.winByDecision}
																onChange={handleChange}
																name='winByDecision'
															/>
														}
														label='Ref Decision'
														disabled={formData.winByPoints || formData.finish}
													/>
												</Grid>
												{formData.ruleSet !== 'Submission Only' ? (
													<Grid>
														<FormLabel
															component='legend'
															sx={{
																color: darkMode ? '#fff' : 'black',
																'&.Mui-focused': {
																	color: darkMode ? '#fff' : 'black',
																},
															}}
														>
															First To Score Wins
														</FormLabel>
														<FormControl>
															<RadioGroup row aria-label='firstToScore' name='firstToScore' value={formData.firstToScore} onChange={handleChange}>
																<FormControlLabel
																	value='Yes'
																	control={<Radio sx={{ color: 'grey', '&.Mui-checked': { color: darkMode ? '#fff' : 'black' } }} />}
																	label='Yes'
																	sx={{ color: darkMode ? '#fff' : 'black' }}
																/>
																<FormControlLabel
																	value='No'
																	control={<Radio sx={{ color: 'grey', '&.Mui-checked': { color: darkMode ? '#fff' : 'black' } }} />}
																	label='No'
																	sx={{ color: darkMode ? '#fff' : 'black' }}
																/>
																<FormControlLabel
																	value='Tie'
																	control={<Radio sx={{ color: 'grey', '&.Mui-checked': { color: darkMode ? '#fff' : 'black' } }} />}
																	label='Tie'
																	sx={{ color: darkMode ? '#fff' : 'black' }}
																/>
																<FormControlLabel
																	value='N/A'
																	control={<Radio sx={{ color: 'grey', '&.Mui-checked': { color: darkMode ? '#fff' : 'black' } }} />}
																	label='N/A'
																	sx={{ color: darkMode ? '#fff' : 'black' }}
																/>
															</RadioGroup>
														</FormControl>
													</Grid>
												) : null}
												{formData.finish && (
													<>
														<Grid item xs={12} sx={{ marginBottom: '8px' }}>
															<TextField
																autoComplete='off'
																sx={{
																	// Change outline color when hovered but not focused
																	'&:hover .MuiOutlinedInput-notchedOutline:not(.Mui-focused)': {
																		borderColor: darkMode ? 'white!important' : 'grey',
																	},
																	// Change label color when hovered but not focused
																	'&:hover .MuiInputLabel-root:not(.Mui-focused)': {
																		color: darkMode ? 'white' : '#000',
																	},
																	// For text color
																	'&& input': {
																		color: darkMode ? '#fff' : '#000',
																	},
																	// Default outline color
																	'& .MuiOutlinedInput-notchedOutline': {
																		borderColor: darkMode ? 'grey!important' : 'grey',
																	},
																	// Focused outline color
																	'& .MuiOutlinedInput-notchedOutline.Mui-focused': {
																		borderColor: darkMode ? 'rgb(47,199,112)' : '#000',
																	},
																	// Background color
																	'& .MuiOutlinedInput-root': {
																		backgroundColor: darkMode ? '#000' : '#f7f7f7',
																	},
																	'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
																		borderColor: 'rgb(47, 199, 112)!important',
																	},
																	// For label text color
																	'& .MuiInputLabel-root': {
																		color: darkMode ? 'grey' : 'grey',
																	},
																	// Focused label color
																	'& .MuiInputLabel-outlined.Mui-focused': {
																		color: 'rgb(47, 180, 112)',
																	},
																	marginBottom: '8px',
																}}
																fullWidth
																id='timeOfFinish'
																name='timeOfFinish'
																label='Time Left On Clock (mm:ss)'
																value={formData.timeOfFinish}
																onChange={handleChange}
																error={timeError}
																helperText='Format: mm:ss'
															/>
														</Grid>

														<FormControl
															fullWidth
															sx={{
																// Change outline color when hovered but not focused
																'&:hover .MuiOutlinedInput-notchedOutline:not(.Mui-focused)': {
																	borderColor: darkMode ? 'white!important' : 'grey',
																},
																// Change label color when hovered but not focused
																'&:hover .MuiInputLabel-root:not(.Mui-focused)': {
																	color: darkMode ? 'white' : '#000',
																},
																// For text color
																'&& input': {
																	color: darkMode ? '#fff' : '#000',
																},
																// Default outline color
																'& .MuiOutlinedInput-notchedOutline': {
																	borderColor: darkMode ? 'grey!important' : 'grey',
																},
																// Focused outline color
																'& .MuiOutlinedInput-notchedOutline.Mui-focused': {
																	borderColor: darkMode ? 'rgb(47,199,112)' : '#000',
																},
																// Background color
																'& .MuiOutlinedInput-root': {
																	backgroundColor: darkMode ? '#000' : '#f7f7f7',
																},
																'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
																	borderColor: 'rgb(47, 199, 112)!important',
																},
																// For label text color
																'& .MuiInputLabel-root': {
																	color: darkMode ? 'grey' : 'grey',
																},
																// Focused label color
																'& .MuiInputLabel-outlined.Mui-focused': {
																	color: 'rgb(47, 180, 112)',
																},
																marginTop: '8px',
																marginBottom: '8px',
															}}
														>
															<InputLabel required id='submission-label' sx={{ '&.MuiInputLabel-shrink': { transform: 'translate(14px, -16px) scale(0.75)' } }}>
																Submission
															</InputLabel>
															<Select
																labelId='submission-label'
																id='submission'
																name='submission'
																value={formData.submissionType || ''}
																onChange={handleChange}
																sx={{ color: darkMode ? '#fff' : '#000', backgroundColor: darkMode ? '#2E2E2E' : '#fff' }}
															>
																{submissionOptions.map((submission) => {
																	return (
																		<MenuItem
																			sx={{
																				backgroundColor: darkMode ? '#2E2E2E' : '#fff',
																				color: darkMode ? '#fff' : '#000',
																				'&.Mui-selected': { backgroundColor: darkMode ? '#3E474F' : '#f7f7f7' },
																				'&:hover': { backgroundColor: darkMode ? 'grey' : '#f7f7f7' },
																				'&.Mui-selected:hover': {
																					backgroundColor: darkMode ? 'grey' : 'grey',
																					color: darkMode ? '#fff' : '#000',
																				},
																			}}
																			key={submission._id}
																			value={submission.name}
																		>
																			{submission.name}
																		</MenuItem>
																	);
																})}
															</Select>
														</FormControl>
														<FormControl
															fullWidth
															sx={{
																// Change outline color when hovered but not focused
																'&:hover .MuiOutlinedInput-notchedOutline:not(.Mui-focused)': {
																	borderColor: darkMode ? 'white!important' : 'grey',
																},
																// Change label color when hovered but not focused
																'&:hover .MuiInputLabel-root:not(.Mui-focused)': {
																	color: darkMode ? 'white' : '#000',
																},
																// For text color
																'&& input': {
																	color: darkMode ? '#fff' : '#000',
																},
																// Default outline color
																'& .MuiOutlinedInput-notchedOutline': {
																	borderColor: darkMode ? 'grey!important' : 'grey',
																},
																// Focused outline color
																'& .MuiOutlinedInput-notchedOutline.Mui-focused': {
																	borderColor: darkMode ? 'rgb(47,199,112)' : '#000',
																},
																// Background color
																'& .MuiOutlinedInput-root': {
																	backgroundColor: darkMode ? '#000' : '#f7f7f7',
																},
																'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
																	borderColor: 'rgb(47, 199, 112)!important',
																},
																// For label text color
																'& .MuiInputLabel-root': {
																	color: darkMode ? 'grey' : 'grey',
																},
																// Focused label color
																'& .MuiInputLabel-outlined.Mui-focused': {
																	color: 'rgb(47, 180, 112)',
																},
																marginTop: '8px',
																marginBottom: '8px',
															}}
														>
															<InputLabel required id='submission-position-label' sx={{ '&.MuiInputLabel-shrink': { transform: 'translate(14px, -16px) scale(0.75)' } }}>
																Submission Position
															</InputLabel>
															<Select
																labelId='submission-position-label'
																id='submission-position'
																name='submissionPosition'
																value={formData.submissionPosition}
																onChange={handleChange}
																sx={{ color: darkMode ? '#fff' : '#000', backgroundColor: darkMode ? '#2E2E2E' : '#fff' }}
															>
																{[
																	'Back',
																	'Mount',
																	'Side Control',
																	'Half Guard',
																	'Closed Guard',
																	'Open Guard',
																	'North South',
																	'Cross Ashi',
																	'50/50',
																	'Inside Ashi',
																	'Outside Ashi',
																	'Irimi Ashi',
																	'Clamp',
																	'Front Headlock',
																	'Turtle',
																	'Standing',
																]
																	.sort()
																	.map((position) => (
																		<MenuItem
																			sx={{
																				backgroundColor: darkMode ? '#2E2E2E' : '#fff',
																				color: darkMode ? '#fff' : '#000',
																				'&.Mui-selected': { backgroundColor: darkMode ? '#3E474F' : '#f7f7f7' },
																				'&:hover': { backgroundColor: darkMode ? 'grey' : '#f7f7f7' },
																				'&.Mui-selected:hover': {
																					backgroundColor: darkMode ? 'grey' : 'grey',
																					color: darkMode ? '#fff' : '#000',
																				},
																			}}
																			key={position}
																			value={position}
																		>
																			{position}
																		</MenuItem>
																	))}
															</Select>
														</FormControl>

														{formData.submissionType && (
															<FormControl
																fullWidth
																sx={{
																	// Change outline color when hovered but not focused
																	'&:hover .MuiOutlinedInput-notchedOutline:not(.Mui-focused)': {
																		borderColor: darkMode ? 'white!important' : 'grey',
																	},
																	// Change label color when hovered but not focused
																	'&:hover .MuiInputLabel-root:not(.Mui-focused)': {
																		color: darkMode ? 'white' : '#000',
																	},
																	// For text color
																	'&& input': {
																		color: darkMode ? '#fff' : '#000',
																	},
																	// Default outline color
																	'& .MuiOutlinedInput-notchedOutline': {
																		borderColor: darkMode ? 'grey!important' : 'grey',
																	},
																	// Focused outline color
																	'& .MuiOutlinedInput-notchedOutline.Mui-focused': {
																		borderColor: darkMode ? 'rgb(47,199,112)' : '#000',
																	},
																	// Background color
																	'& .MuiOutlinedInput-root': {
																		backgroundColor: darkMode ? '#000' : '#f7f7f7',
																	},
																	'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
																		borderColor: 'rgb(47, 199, 112)!important',
																	},
																	// For label text color
																	'& .MuiInputLabel-root': {
																		color: darkMode ? 'grey' : 'grey',
																	},
																	// Focused label color
																	'& .MuiInputLabel-outlined.Mui-focused': {
																		color: 'rgb(47, 180, 112)',
																	},
																	marginTop: '8px',
																	marginBottom: '8px',
																}}
															>
																<InputLabel required id='submission-side-label' sx={{ '&.MuiInputLabel-shrink': { transform: 'translate(14px, -16px) scale(0.75)' } }}>
																	Side
																</InputLabel>
																<Select
																	labelId='submission-side-label'
																	id='submission-side'
																	name='submissionSide'
																	value={formData.submissionSide}
																	onChange={handleChange}
																	sx={{ color: darkMode ? '#fff' : '#000', backgroundColor: darkMode ? '#2E2E2E' : '#fff' }}
																>
																	{renderSideOptions()}
																</Select>
															</FormControl>
														)}
													</>
												)}
												{formData.ruleSet === 'Submission Only' ? null : (
													<>
														<Grid item xs={12} sx={{ marginBottom: '8px' }}>
															<TextField
																autoComplete='off'
																sx={{
																	// Change outline color when hovered but not focused
																	'&:hover .MuiOutlinedInput-notchedOutline:not(.Mui-focused)': {
																		borderColor: darkMode ? 'white!important' : 'grey',
																	},
																	// Change label color when hovered but not focused
																	'&:hover .MuiInputLabel-root:not(.Mui-focused)': {
																		color: darkMode ? 'white' : '#000',
																	},
																	// For text color
																	'&& input': {
																		color: darkMode ? '#fff' : '#000',
																	},
																	// Default outline color
																	'& .MuiOutlinedInput-notchedOutline': {
																		borderColor: darkMode ? 'grey!important' : 'grey',
																	},
																	// Focused outline color
																	'& .MuiOutlinedInput-notchedOutline.Mui-focused': {
																		borderColor: darkMode ? 'rgb(47,199,112)' : '#000',
																	},
																	// Background color
																	'& .MuiOutlinedInput-root': {
																		backgroundColor: darkMode ? '#000' : '#f7f7f7',
																	},
																	'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
																		borderColor: 'rgb(47, 199, 112)!important',
																	},
																	// For label text color
																	'& .MuiInputLabel-root': {
																		color: darkMode ? 'grey' : 'grey',
																	},
																	// Focused label color
																	'& .MuiInputLabel-outlined.Mui-focused': {
																		color: 'rgb(47, 180, 112)',
																	},
																	marginBottom: '8px',
																}}
																fullWidth
																required
																id='player1Points'
																name='player1Points'
																label={`${formData.player1} Points`}
																value={formData.player1Points}
																onChange={(e) => {
																	if (e.target.value < 0) e.target.value = 0;
																	handleChange(e);
																}}
																type='number'
																inputProps={{ min: 0 }}
															/>

															<TextField
																autoComplete='off'
																sx={{
																	// Change outline color when hovered but not focused
																	'&:hover .MuiOutlinedInput-notchedOutline:not(.Mui-focused)': {
																		borderColor: darkMode ? 'white!important' : 'grey',
																	},
																	// Change label color when hovered but not focused
																	'&:hover .MuiInputLabel-root:not(.Mui-focused)': {
																		color: darkMode ? 'white' : '#000',
																	},
																	// For text color
																	'&& input': {
																		color: darkMode ? '#fff' : '#000',
																	},
																	// Default outline color
																	'& .MuiOutlinedInput-notchedOutline': {
																		borderColor: darkMode ? 'grey!important' : 'grey',
																	},
																	// Focused outline color
																	'& .MuiOutlinedInput-notchedOutline.Mui-focused': {
																		borderColor: darkMode ? 'rgb(47,199,112)' : '#000',
																	},
																	// Background color
																	'& .MuiOutlinedInput-root': {
																		backgroundColor: darkMode ? '#000' : '#f7f7f7',
																	},
																	'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
																		borderColor: 'rgb(47, 199, 112)!important',
																	},
																	// For label text color
																	'& .MuiInputLabel-root': {
																		color: darkMode ? 'grey' : 'grey',
																	},
																	// Focused label color
																	'& .MuiInputLabel-outlined.Mui-focused': {
																		color: 'rgb(47, 180, 112)',
																	},
																	marginBottom: '8px',
																}}
																fullWidth
																required
																id='player1Penalties'
																name='player1Penalties'
																label={`${formData.player1} Penalties`}
																value={formData.player1Penalties}
																onChange={(e) => {
																	if (e.target.value < 0) e.target.value = 0;
																	handleChange(e);
																}}
																type='number'
																inputProps={{ min: 0 }}
															/>
															{formData.ruleSet !== 'ADCC' ? (
																<TextField
																	autoComplete='off'
																	sx={{
																		// Change outline color when hovered but not focused
																		'&:hover .MuiOutlinedInput-notchedOutline:not(.Mui-focused)': {
																			borderColor: darkMode ? 'white!important' : 'grey',
																		},
																		// Change label color when hovered but not focused
																		'&:hover .MuiInputLabel-root:not(.Mui-focused)': {
																			color: darkMode ? 'white' : '#000',
																		},
																		// For text color
																		'&& input': {
																			color: darkMode ? '#fff' : '#000',
																		},
																		// Default outline color
																		'& .MuiOutlinedInput-notchedOutline': {
																			borderColor: darkMode ? 'grey!important' : 'grey',
																		},
																		// Focused outline color
																		'& .MuiOutlinedInput-notchedOutline.Mui-focused': {
																			borderColor: darkMode ? 'rgb(47,199,112)' : '#000',
																		},
																		// Background color
																		'& .MuiOutlinedInput-root': {
																			backgroundColor: darkMode ? '#000' : '#f7f7f7',
																		},
																		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
																			borderColor: 'rgb(47, 199, 112)!important',
																		},
																		// For label text color
																		'& .MuiInputLabel-root': {
																			color: darkMode ? 'grey' : 'grey',
																		},
																		// Focused label color
																		'& .MuiInputLabel-outlined.Mui-focused': {
																			color: 'rgb(47, 180, 112)',
																		},
																		marginBottom: '8px',
																	}}
																	fullWidth
																	required
																	id='player1Advantages'
																	name='player1Advantages'
																	label={`${formData.player1} Advantages`}
																	value={formData.player1Advantages}
																	onChange={(e) => {
																		if (e.target.value < 0) e.target.value = 0;
																		handleChange(e);
																	}}
																	type='number'
																	inputProps={{ min: 0 }}
																/>
															) : null}
														</Grid>

														<Grid item xs={12} sx={{ marginBottom: '8px' }}>
															<TextField
																autoComplete='off'
																sx={{
																	// Change outline color when hovered but not focused
																	'&:hover .MuiOutlinedInput-notchedOutline:not(.Mui-focused)': {
																		borderColor: darkMode ? 'white!important' : 'grey',
																	},
																	// Change label color when hovered but not focused
																	'&:hover .MuiInputLabel-root:not(.Mui-focused)': {
																		color: darkMode ? 'white' : '#000',
																	},
																	// For text color
																	'&& input': {
																		color: darkMode ? '#fff' : '#000',
																	},
																	// Default outline color
																	'& .MuiOutlinedInput-notchedOutline': {
																		borderColor: darkMode ? 'grey!important' : 'grey',
																	},
																	// Focused outline color
																	'& .MuiOutlinedInput-notchedOutline.Mui-focused': {
																		borderColor: darkMode ? 'rgb(47,199,112)' : '#000',
																	},
																	// Background color
																	'& .MuiOutlinedInput-root': {
																		backgroundColor: darkMode ? '#000' : '#f7f7f7',
																	},
																	'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
																		borderColor: 'rgb(47, 199, 112)!important',
																	},
																	// For label text color
																	'& .MuiInputLabel-root': {
																		color: darkMode ? 'grey' : 'grey',
																	},
																	// Focused label color
																	'& .MuiInputLabel-outlined.Mui-focused': {
																		color: 'rgb(47, 180, 112)',
																	},
																	marginBottom: '8px',
																}}
																fullWidth
																required
																id='player2Points'
																name='player2Points'
																label={`${formData.player2} Points`}
																value={formData.player2Points}
																onChange={(e) => {
																	if (e.target.value < 0) e.target.value = 0;
																	handleChange(e);
																}}
																type='number'
																inputProps={{ min: 0 }}
															/>
															<TextField
																autoComplete='off'
																sx={{
																	// Change outline color when hovered but not focused
																	'&:hover .MuiOutlinedInput-notchedOutline:not(.Mui-focused)': {
																		borderColor: darkMode ? 'white!important' : 'grey',
																	},
																	// Change label color when hovered but not focused
																	'&:hover .MuiInputLabel-root:not(.Mui-focused)': {
																		color: darkMode ? 'white' : '#000',
																	},
																	// For text color
																	'&& input': {
																		color: darkMode ? '#fff' : '#000',
																	},
																	// Default outline color
																	'& .MuiOutlinedInput-notchedOutline': {
																		borderColor: darkMode ? 'grey!important' : 'grey',
																	},
																	// Focused outline color
																	'& .MuiOutlinedInput-notchedOutline.Mui-focused': {
																		borderColor: darkMode ? 'rgb(47,199,112)' : '#000',
																	},
																	// Background color
																	'& .MuiOutlinedInput-root': {
																		backgroundColor: darkMode ? '#000' : '#f7f7f7',
																	},
																	'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
																		borderColor: 'rgb(47, 199, 112)!important',
																	},
																	// For label text color
																	'& .MuiInputLabel-root': {
																		color: darkMode ? 'grey' : 'grey',
																	},
																	// Focused label color
																	'& .MuiInputLabel-outlined.Mui-focused': {
																		color: 'rgb(47, 180, 112)',
																	},
																	marginBottom: '8px',
																}}
																fullWidth
																required
																id='player2Penalties'
																name='player2Penalties'
																label={`${formData.player2} Penalites`}
																value={formData.player2Penalties}
																onChange={(e) => {
																	if (e.target.value < 0) e.target.value = 0;
																	handleChange(e);
																}}
																type='number'
																inputProps={{ min: 0 }}
															/>

															{formData.ruleSet !== 'ADCC' ? (
																<TextField
																	autoComplete='off'
																	sx={{
																		// Change outline color when hovered but not focused
																		'&:hover .MuiOutlinedInput-notchedOutline:not(.Mui-focused)': {
																			borderColor: darkMode ? 'white!important' : 'grey',
																		},
																		// Change label color when hovered but not focused
																		'&:hover .MuiInputLabel-root:not(.Mui-focused)': {
																			color: darkMode ? 'white' : '#000',
																		},
																		// For text color
																		'&& input': {
																			color: darkMode ? '#fff' : '#000',
																		},
																		// Default outline color
																		'& .MuiOutlinedInput-notchedOutline': {
																			borderColor: darkMode ? 'grey!important' : 'grey',
																		},
																		// Focused outline color
																		'& .MuiOutlinedInput-notchedOutline.Mui-focused': {
																			borderColor: darkMode ? 'rgb(47,199,112)' : '#000',
																		},
																		// Background color
																		'& .MuiOutlinedInput-root': {
																			backgroundColor: darkMode ? '#000' : '#f7f7f7',
																		},
																		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
																			borderColor: 'rgb(47, 199, 112)!important',
																		},
																		// For label text color
																		'& .MuiInputLabel-root': {
																			color: darkMode ? 'grey' : 'grey',
																		},
																		// Focused label color
																		'& .MuiInputLabel-outlined.Mui-focused': {
																			color: 'rgb(47, 180, 112)',
																		},
																		marginBottom: '8px',
																	}}
																	fullWidth
																	required
																	id='player2Advantages'
																	name='player2Advantages'
																	label={`${formData.player2} Advantages`}
																	value={formData.player2Advantages}
																	onChange={(e) => {
																		if (e.target.value < 0) e.target.value = 0;
																		handleChange(e);
																	}}
																	type='number'
																	inputProps={{ min: 0 }}
																/>
															) : null}
														</Grid>
													</>
												)}
											</ThemeProvider>
										</>
									)}
								</Grid>

								{matchesExist === false && initialCheckDone === true && (
									<>
										<Grid item xs={12}>
											<Typography variant='h6' gutterBottom>
												Links and Embeds
											</Typography>
											<Typography variant='body2' sx={{ color: '#d20404' }} gutterBottom>
												* At least one link is required
											</Typography>
											<FormControl
												fullWidth
												sx={{
													input: {
														color: darkMode ? '#fff' : '#000',
													},
													'& .MuiOutlinedInput-root': {
														backgroundColor: darkMode ? '#000' : '#f7f7f7', // Set default background color
													},
													'& .MuiOutlinedInput-root.Mui-focused': {
														backgroundColor: darkMode ? '#000' : '#f7f7f7', // Set background color when focused
													},
													'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
														borderColor: 'rgb(47, 199, 112)',
													},
													'& .MuiInputLabel-outlined.Mui-focused': {
														color: 'rgb(47, 180, 112)',
													},
													marginBottom: '8px',
													marginTop: '8px',
												}}
											>
												<InputLabel required id='sourceOfTimestamps-label' sx={{ '&.MuiInputLabel-shrink': { transform: 'translate(14px, -16px) scale(0.75)' } }}>
													Source of Timestamps
												</InputLabel>
												<Select required labelId='sourceOfTimestamps-label' id='sourceOfTimestamps' name='sourceOfTimestamps' value={formData.sourceOfTimestamps} onChange={handleChange}>
													<MenuItem value='Flo'>FloGrappling</MenuItem>
													<MenuItem value='Youtube'>Youtube</MenuItem>
													<MenuItem value='UFC'>UFC</MenuItem>
													<MenuItem value='Enigma'>Enigma</MenuItem>
												</Select>
											</FormControl>

											<div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px', flexWrap: 'wrap' }}>
												<Button
													sx={{
														backgroundColor: 'grey',
														color: 'white',
														marginBottom: '16px',
														'&:hover': { backgroundColor: 'rgb(47, 180, 112)', color: 'white' },
													}}
													onClick={() => toggleFieldVisibility('showFlo')}
												>
													Add Flo Link
												</Button>
												<Button
													sx={{
														backgroundColor: 'grey',
														color: 'white',
														marginBottom: '16px',
														'&:hover': { backgroundColor: 'rgb(47, 180, 112)', color: 'white' },
													}}
													onClick={() => toggleFieldVisibility('showYoutube')}
												>
													Add Youtube Link
												</Button>
												<Button
													sx={{
														backgroundColor: 'grey',
														color: 'white',
														marginBottom: '16px',
														'&:hover': { backgroundColor: 'rgb(47, 180, 112)', color: 'white' },
													}}
													onClick={() => toggleFieldVisibility('showUFC')}
												>
													Add UFC Link
												</Button>
												<Button
													sx={{
														backgroundColor: 'grey',
														color: 'white',
														marginBottom: '16px',
														'&:hover': { backgroundColor: 'rgb(47, 180, 112)', color: 'white' },
													}}
													onClick={() => toggleFieldVisibility('showEnigma')}
												>
													Add Enigma Link
												</Button>
											</div>
											{showLinksAndEmbeds.showFlo && (
												<>
													<TextField
														autoComplete='off'
														sx={{
															input: {
																color: darkMode ? '#fff' : '#000',
															},
															'& .MuiOutlinedInput-root': {
																backgroundColor: darkMode ? '#000' : '#f7f7f7', // Set default background color
															},
															'& .MuiOutlinedInput-root.Mui-focused': {
																backgroundColor: darkMode ? '#000' : '#f7f7f7', // Set background color when focused
															},
															'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
																borderColor: 'rgb(47, 199, 112)',
															},
															'& .MuiInputLabel-outlined.Mui-focused': {
																color: 'rgb(47, 180, 112)',
															},
															marginBottom: '8px',
														}}
														fullWidth
														id='floLink'
														name='floLink'
														label='FloGrappling Link'
														value={formData.floLink}
														onChange={handleChange}
													/>
												</>
											)}
											{showLinksAndEmbeds.showYoutube && (
												<>
													<TextField
														autoComplete='off'
														sx={{
															input: {
																color: darkMode ? '#fff' : '#000',
															},
															'& .MuiOutlinedInput-root': {
																backgroundColor: darkMode ? '#000' : '#f7f7f7', // Set default background color
															},
															'& .MuiOutlinedInput-root.Mui-focused': {
																backgroundColor: darkMode ? '#000' : '#f7f7f7', // Set background color when focused
															},
															'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
																borderColor: 'rgb(47, 199, 112)',
															},
															'& .MuiInputLabel-outlined.Mui-focused': {
																color: 'rgb(47, 180, 112)',
															},
															marginBottom: '8px',
														}}
														fullWidth
														id='youtubeLink'
														name='youtubeLink'
														label='Youtube Link'
														value={formData.youtubeLink}
														onChange={handleChange}
													/>
												</>
											)}
											{showLinksAndEmbeds.showUFC && (
												<>
													<TextField
														autoComplete='off'
														sx={{
															input: {
																color: darkMode ? '#fff' : '#000',
															},
															'& .MuiOutlinedInput-root': {
																backgroundColor: darkMode ? '#000' : '#f7f7f7', // Set default background color
															},
															'& .MuiOutlinedInput-root.Mui-focused': {
																backgroundColor: darkMode ? '#000' : '#f7f7f7', // Set background color when focused
															},
															'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
																borderColor: 'rgb(47, 199, 112)',
															},
															'& .MuiInputLabel-outlined.Mui-focused': {
																color: 'rgb(47, 180, 112)',
															},
															marginBottom: '8px',
														}}
														fullWidth
														id='ufcLink'
														name='ufcLink'
														label='UFC Fight Pass Link'
														value={formData.ufcLink}
														onChange={handleChange}
													/>
												</>
											)}
											{showLinksAndEmbeds.showEnigma && (
												<>
													<TextField
														autoComplete='off'
														sx={{
															input: {
																color: darkMode ? '#fff' : '#000',
															},
															'& .MuiOutlinedInput-root': {
																backgroundColor: darkMode ? '#000' : '#f7f7f7', // Set default background color
															},
															'& .MuiOutlinedInput-root.Mui-focused': {
																backgroundColor: darkMode ? '#000' : '#f7f7f7', // Set background color when focused
															},
															'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
																borderColor: 'rgb(47, 199, 112)',
															},
															'& .MuiInputLabel-outlined.Mui-focused': {
																color: 'rgb(47, 180, 112)',
															},
															marginBottom: '8px',
														}}
														fullWidth
														id='enigmaLink'
														name='enigmaLink'
														label='Enigma Link'
														value={formData.enigmaLink}
														onChange={handleChange}
													/>
												</>
											)}
										</Grid>
									</>
								)}
							</Box>
						)}

						{matchesExist === false && initialCheckDone === true && (
							<>
								<TextField
									autoComplete='off'
									name='notes'
									label='Enter match notes (e.g. 03:33 - standing foot-sweep leads to front-headlock, 03:40 - guillotine attempt fails,)'
									multiline
									rows={4}
									sx={{
										input: {
											color: darkMode ? '#fff' : '#000',
										},
										'& .MuiOutlinedInput-root': {
											backgroundColor: darkMode ? '#000' : '#f7f7f7', // Set default background color
										},
										'& .MuiOutlinedInput-root.Mui-focused': {
											backgroundColor: darkMode ? '#000' : '#f7f7f7', // Set background color when focused
										},
										'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
											borderColor: 'rgb(47, 199, 112)',
										},
										'& .MuiInputLabel-outlined.Mui-focused': {
											color: 'rgb(47, 180, 112)',
										},
									}}
									variant='outlined'
									fullWidth
									onChange={handleChange}
									value={formData.notes}
									helperText='Separate each note with a comma.'
								/>
								<Typography sx={{ marginBottom: '16px' }} variant='h6' gutterBottom>
									Optional Info
								</Typography>
								<Box>
									<Grid container spacing={3}>
										{formData.submissionPosition && (
											<Grid item xs={12}>
												{submissionPositionVariations
													.filter((variation) => variation.position.toLowerCase() === formData.submissionPosition.toLowerCase())
													.map((filteredVariation) => (
														<Box key={filteredVariation._id}>
															<Grid item xs={12}>
																<Typography variant='h6' style={{ marginBottom: '20px' }}>
																	{filteredVariation.position} Submission Position Variations
																</Typography>
																<Dropdown
																	darkMode={darkMode}
																	label='Upper body'
																	options={filteredVariation.positionVariationUpper}
																	handleChange={handleChange}
																	name='positionVariationUpper'
																	value={formData.positionVariationUpper}
																/>
																<Dropdown
																	darkMode={darkMode}
																	label='Lower body'
																	options={filteredVariation.positionVariationLower}
																	handleChange={handleChange}
																	name='positionVariationLower'
																	value={formData.positionVariationLower}
																/>
															</Grid>
														</Box>
													))}
											</Grid>
										)}

										{formData.submissionType &&
											submissionOptions
												.find((option) => option.name === formData.submissionType)
												?.options.map((option) => (
													<FormControl
														key={option.label}
														fullWidth
														sx={{
															input: {
																color: darkMode ? '#fff' : '#000',
															},
															'& .MuiOutlinedInput-root': {
																backgroundColor: darkMode ? '#000' : '#f7f7f7', // Set default background color
															},
															'& .MuiOutlinedInput-root.Mui-focused': {
																backgroundColor: darkMode ? '#000' : '#f7f7f7', // Set background color when focused
															},
															'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
																borderColor: 'rgb(47, 199, 112)',
															},
															'& .MuiInputLabel-outlined.Mui-focused': {
																color: 'rgb(47, 180, 112)',
															},
															marginBottom: '8px',
															marginTop: '8px',
														}}
													>
														<InputLabel
															id={`${option.label}-label`}
															sx={{
																transform: 'translate(12px, 32px)',
																'&.MuiInputLabel-shrink': { transform: 'translate(4px, -2px) scale(0.75)' },
															}}
														>
															{option.label}
														</InputLabel>
														<Select
															sx={{ marginTop: '16px' }}
															labelId={`${option.label}-label`}
															id={option.label}
															name={
																option.label.replace(/\s+/g, '') // Remove spaces to match formData keys if necessary
															}
															value={formData[option.label.replace(/\s+/g, '')]}
															onChange={handleChange}
														>
															{option.values.map((value) => (
																<MenuItem key={value} value={value}>
																	{value}
																</MenuItem>
															))}
														</Select>
													</FormControl>
												))}

										<Grid item xs={12} sm={6}>
											<Typography gutterBottom>
												{formData.player1} Height: {Math.floor(formData.player1Height / 12)} feet {formData.player1Height % 12} inches
											</Typography>
											<Slider
												sx={{
													color: '#000000', // Set the color to black
													'& .MuiSlider-thumb': {
														color: darkMode ? 'rgb(47,199,112)' : '#000000', // Thumb color
													},
													'& .MuiSlider-track': {
														color: darkMode ? 'rgb(47,199,112)' : '#000000', // Track color
													},
													'& .MuiSlider-rail': {
														color: darkMode ? 'rgb(47,199,112)' : '#000000', // Rail color
													},
												}}
												value={formData.player1Height}
												name='player1Height'
												onChange={(event, value) => handleChange({ target: { name: 'player1Height', value } })}
												aria-labelledby='player1-height-slider'
												step={1}
												min={48}
												max={84}
												valueLabelDisplay='auto'
												valueLabelFormat={(value) => `${Math.floor(value / 12)}' ${value % 12}"`}
											/>
										</Grid>

										<Grid item xs={12} sm={6}>
											<Typography gutterBottom>
												{formData.player2} Height: {Math.floor(formData.player2Height / 12)} feet {formData.player2Height % 12} inches
											</Typography>
											<Slider
												sx={{
													color: '#000000', // Set the color to black
													'& .MuiSlider-thumb': {
														color: darkMode ? 'rgb(47,199,112)' : '#000000', // Thumb color
													},
													'& .MuiSlider-track': {
														color: darkMode ? 'rgb(47,199,112)' : '#000000', // Track color
													},
													'& .MuiSlider-rail': {
														color: darkMode ? 'rgb(47,199,112)' : '#000000', // Rail color
													},
												}}
												value={formData.player2Height}
												name='player2Height'
												onChange={(event, value) => handleChange({ target: { name: 'player2Height', value } })}
												aria-labelledby='player2-height-slider'
												step={1}
												min={48}
												max={84}
												valueLabelDisplay='auto'
												valueLabelFormat={(value) => `${Math.floor(value / 12)}' ${value % 12}"`}
											/>
										</Grid>

										<Grid item xs={12} sm={6}>
											<Typography gutterBottom>
												{formData.player1} Wingspan: {formData.player1Wingspan} inches
											</Typography>
											<Slider
												sx={{
													color: '#000000', // Set the color to black
													'& .MuiSlider-thumb': {
														color: darkMode ? 'rgb(47,199,112)' : '#000000', // Thumb color
													},
													'& .MuiSlider-track': {
														color: darkMode ? 'rgb(47,199,112)' : '#000000', // Track color
													},
													'& .MuiSlider-rail': {
														color: darkMode ? 'rgb(47,199,112)' : '#000000', // Rail color
													},
												}}
												value={formData.player1Wingspan}
												name='player1Wingspan'
												onChange={(event, value) => handleChange({ target: { name: 'player1Wingspan', value } })}
												aria-labelledby='player1-wingspan-slider'
												step={1}
												min={50}
												max={100}
												valueLabelDisplay='auto'
											/>
										</Grid>

										<Grid item xs={12} sm={6}>
											<Typography gutterBottom>
												{formData.player2} Wingspan: {formData.player2Wingspan} inches
											</Typography>
											<Slider
												sx={{
													color: '#000000', // Set the color to black
													'& .MuiSlider-thumb': {
														color: darkMode ? 'rgb(47,199,112)' : '#000000', // Thumb color
													},
													'& .MuiSlider-track': {
														color: darkMode ? 'rgb(47,199,112)' : '#000000', // Track color
													},
													'& .MuiSlider-rail': {
														color: darkMode ? 'rgb(47,199,112)' : '#000000', // Rail color
													},
												}}
												value={formData.player2Wingspan}
												name='player2Wingspan'
												onChange={(event, value) => handleChange({ target: { name: 'player2Wingspan', value } })}
												aria-labelledby='player2-wingspan-slider'
												step={1}
												min={50}
												max={100}
												valueLabelDisplay='auto'
											/>
										</Grid>

										<Grid item xs={12} sm={6}>
											<Typography gutterBottom>
												{formData.player1} Inseam: {formData.player1Inseam} inches
											</Typography>
											<Slider
												sx={{
													color: '#000000', // Set the color to black
													'& .MuiSlider-thumb': {
														color: darkMode ? 'rgb(47,199,112)' : '#000000', // Thumb color
													},
													'& .MuiSlider-track': {
														color: darkMode ? 'rgb(47,199,112)' : '#000000', // Track color
													},
													'& .MuiSlider-rail': {
														color: darkMode ? 'rgb(47,199,112)' : '#000000', // Rail color
													},
												}}
												value={formData.player1Inseam}
												name='player1Inseam'
												onChange={(event, value) => handleChange({ target: { name: 'player1Inseam', value } })}
												aria-labelledby='player1-inseam-slider'
												step={1}
												min={20}
												max={40}
												valueLabelDisplay='auto'
											/>
										</Grid>

										<Grid item xs={12} sm={6}>
											<Typography gutterBottom>
												{formData.player2} Inseam: {formData.player2Inseam} inches
											</Typography>
											<Slider
												sx={{
													color: '#000000', // Set the color to black
													'& .MuiSlider-thumb': {
														color: darkMode ? 'rgb(47,199,112)' : '#000000', // Thumb color
													},
													'& .MuiSlider-track': {
														color: darkMode ? 'rgb(47,199,112)' : '#000000', // Track color
													},
													'& .MuiSlider-rail': {
														color: darkMode ? 'rgb(47,199,112)' : '#000000', // Rail color
													},
												}}
												value={formData.player2Inseam}
												name='player2Inseam'
												onChange={(event, value) => handleChange({ target: { name: 'player2Inseam', value } })}
												aria-labelledby='player2-inseam-slider'
												step={1}
												min={20}
												max={40}
												valueLabelDisplay='auto'
											/>
										</Grid>

										<Grid item xs={12} sm={6}>
											<Typography gutterBottom>
												{formData.player1} Chest: {formData.player1Chest} inches
											</Typography>
											<Slider
												sx={{
													color: '#000000', // Set the color to black
													'& .MuiSlider-thumb': {
														color: darkMode ? 'rgb(47,199,112)' : '#000000', // Thumb color
													},
													'& .MuiSlider-track': {
														color: darkMode ? 'rgb(47,199,112)' : '#000000', // Track color
													},
													'& .MuiSlider-rail': {
														color: darkMode ? 'rgb(47,199,112)' : '#000000', // Rail color
													},
												}}
												value={formData.player1Chest}
												name='player1Chest'
												onChange={(event, value) => handleChange({ target: { name: 'player1Chest', value } })}
												aria-labelledby='player1-chest-slider'
												step={1}
												min={20}
												max={60}
												valueLabelDisplay='auto'
											/>
										</Grid>

										<Grid item xs={12} sm={6}>
											<Typography gutterBottom>
												{formData.player2} Chest: {formData.player2Chest} inches
											</Typography>
											<Slider
												sx={{
													color: '#000000', // Set the color to black
													'& .MuiSlider-thumb': {
														color: darkMode ? 'rgb(47,199,112)' : '#000000', // Thumb color
													},
													'& .MuiSlider-track': {
														color: darkMode ? 'rgb(47,199,112)' : '#000000', // Track color
													},
													'& .MuiSlider-rail': {
														color: darkMode ? 'rgb(47,199,112)' : '#000000', // Rail color
													},
												}}
												value={formData.player2Chest}
												name='player2Chest'
												onChange={(event, value) => handleChange({ target: { name: 'player2Chest', value } })}
												aria-labelledby='player2-chest-slider'
												step={1}
												min={20}
												max={60}
												valueLabelDisplay='auto'
											/>
										</Grid>
									</Grid>
								</Box>
							</>
						)}

						{matchesExist === false && initialCheckDone === true && (
							<>
								<Button
									type='submit'
									variant='contained'
									color='primary'
									size='large'
									startIcon={<SportsKabaddi />}
									sx={{ backgroundColor: 'rgb(47, 199, 112)', '&:hover': { backgroundColor: 'rgb(47, 180, 112)' } }}
									style={{ marginTop: '20px' }}
									disabled={loading}
								>
									Submit
								</Button>
							</>
						)}

						{matchesExist === true && (
							<Grid item xs={12}>
								<Typography variant='h6' sx={{ color: '#d20404' }} gutterBottom>
									Existing Match(es) Found
								</Typography>
								{existingMatches?.map((match) => (
									<div key={match._id}>
										<Typography variant='body1' gutterBottom>
											{match.player1} vs {match.player2} at {match.region} on {match.dateOfMatch}
										</Typography>
										<Grid item xs={12}>
											<Button
												sx={{
													backgroundColor: 'rgb(47, 199, 112)',
													color: 'white',
													marginBottom: '16px',
													marginRight: '8px',
													'&:hover': { backgroundColor: 'rgb(47, 180, 112)', color: 'white' },
												}}
												onClick={() => navigate(`/match/${match._id}`)}
											>
												View Match
											</Button>
											<Button
												sx={{
													backgroundColor: 'rgb(47, 199, 112)',
													color: 'white',
													marginBottom: '16px',
													marginLeft: '8px',
													'&:hover': { backgroundColor: 'rgb(47, 180, 112)', color: 'white' },
												}}
												onClick={() => navigate(`/edit-match/${match._id}`)}
											>
												Edit Match
											</Button>
										</Grid>
									</div>
								))}

								<Typography variant='h6' gutterBottom>
									If the match you are trying to add is not listed, click the button below to continue.
								</Typography>
								<Button
									sx={{
										backgroundColor: 'rgb(47, 199, 112)',
										color: 'white',
										marginBottom: '16px',
										marginRight: '8px',
										'&:hover': { backgroundColor: 'rgb(47, 180, 112)', color: 'white' },
									}}
									onClick={(e) => toggleForm(e)}
								>
									Enter New Match
								</Button>
							</Grid>
						)}
					</form>
				</>
			)}
		</Container>
	);
}

export default DataEntry;

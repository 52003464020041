import React, { useState, useRef, useEffect } from 'react';
import { Card, CardContent, Typography, IconButton, Box, useMediaQuery, Grid, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { useNavigate } from 'react-router-dom';
import LoginAndSubscribeDialog from './LoginAndSubscribeDialog';
import YouTubeLiteEmbed from './YouTubeLiteEmbed';

const ResourceCard = React.memo(({ resource, darkMode, user, authUser }) => {
	const navigate = useNavigate();

	const [open, setOpen] = useState(false);
	const handleNavigation = (event, path, resource) => {
		if (user?.subscriptionStatus !== 'active' && path !== '/account' && path !== '/auth' && path !== '/' && path !== '/logout') {
			event.preventDefault();
			setOpen(true); // Open dialog
		} else {
			event.preventDefault();
			navigate(`/resource/${resource._id}?timestamp=${resource.timestamp.toString()}`);
		}
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleCloseAndLogin = () => {
		setOpen(false);
		navigate('/auth');
	};

	const handleCloseAndSubscribe = () => {
		setOpen(false);
		navigate('/account');
	};

	const getFontSize = (text) => {
		const length = text.length;
		if (length < 200) return '1rem'; // Short text
		if (length < 300) return '0.9rem'; // Moderately long text
		if (length < 500) return '0.8rem'; // Long text
		if (length < 700) return '0.7rem'; // Very long text
		if (length > 700) return '0.6rem'; // Very long text
		if (length > 1000) return '0.5rem'; // Very long text
		return '0.7rem'; // Very long text
	};

	const fontSize = getFontSize(resource.note);
	const isMobile = useMediaQuery('(max-width:1275px)');
	const [showOverlay, setShowOverlay] = useState(false);
	const titleRef = useRef(null);
	const [isOverflowing, setIsOverflowing] = useState(false);

	useEffect(() => {
		const checkOverflow = () => {
			const element = titleRef.current;
			if (element) {
				const isOverflow = element.scrollWidth > element.clientWidth;
				setIsOverflowing(isOverflow);
			}
		};
		checkOverflow();
		window.addEventListener('resize', checkOverflow);
		return () => window.removeEventListener('resize', checkOverflow);
	}, [resource.title]);

	const timestampToSeconds = (timestamp) => {
		const parts = timestamp.split(':').map(Number); // Convert all parts to numbers
		let seconds = 0;

		if (parts.length === 3) {
			// Format is hh:mm:ss
			seconds = parts[0] * 3600 + parts[1] * 60 + parts[2];
		} else if (parts.length === 2) {
			// Format is mm:ss
			seconds = parts[0] * 60 + parts[1];
		}
		return seconds;
	};

	const createEmbedDetailsFromLink = (url, timestamp) => {
		const youtubeRegExp = /^.*(youtu\.be\/|v\/|e\/|u\/\w+\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
		const instagramRegExp = /(?:https?:\/\/)?(?:www\.)?(instagram\.com\/(p|reel)\/([A-Za-z0-9-_]+))/;

		const youtubeMatch = url.match(youtubeRegExp);
		if (youtubeMatch && youtubeMatch[2].length === 11) {
			const videoId = youtubeMatch[2];
			const start = timestampToSeconds(timestamp);
			return { type: 'youtube', videoId, start };
		}

		const instagramMatch = url.match(instagramRegExp);
		if (instagramMatch) {
			const embedUrl = `https://www.instagram.com/${instagramMatch[2]}/${instagramMatch[3]}/embed`;
			return { type: 'instagram', embedUrl };
		}

		return { type: 'unknown', embedUrl: resource.url };
	};

	const { type: embedType, videoId, start, embedUrl } = createEmbedDetailsFromLink(resource.url, resource.timestamp);

	return (
		<Card sx={{ maxWidth: 350, minWidth: 350, position: 'relative', my: isMobile ? 2 : 0, background: darkMode ? 'rgb(18, 57, 44)' : 'rgb(229, 247, 221)' }}>
			{embedType === 'youtube' && (
				<Box style={{ position: 'relative', width: '100%' }}>
					<YouTubeLiteEmbed videoId={videoId} start={start} />
				</Box>
			)}

			{embedType === 'instagram' && (
				<Box style={{ position: 'relative', width: '100%', paddingTop: '56.25%' /* 16:9 Aspect Ratio */ }}>
					<iframe
						style={{
							position: 'absolute',
							top: 0,
							left: 0,
							width: '100%',
							height: '100%',
							border: 0,
						}}
						src={embedUrl}
						allow='encrypted-media'
						allowFullScreen
						title='Instagram embed'
					/>
				</Box>
			)}

			{embedType === 'unknown' && (
				<Box style={{ position: 'relative', width: '100%', paddingTop: '56.25%' /* 16:9 Aspect Ratio */ }}>
					<iframe
						style={{
							position: 'absolute',
							top: 0,
							left: 0,
							width: '100%',
							height: '100%',
							border: 0,
							backgroundColor: 'black',
						}}
						allow='accelerometer; autoplay; encrypted-media; gyroscope; '
						allowFullScreen
						title='Video player'
					/>
					<Tooltip title='Watch on external site?' placement='top' arrow>
						<IconButton
							sx={{
								position: 'absolute',
								top: '50%',
								left: '50%',
								transform: 'translate(-50%, -50%)',
								color: darkMode ? '#fff' : 'rgb(100,100,100)',
								bgcolor: 'transparent',
								'&:hover': {
									bgcolor: 'background.default',
								},
								size: 'large',
							}}
							onClick={() => window.open(resource.url, '_blank')}
						>
							<PlayCircleOutlineIcon sx={{ fontSize: '3rem' }} />
						</IconButton>
					</Tooltip>
				</Box>
			)}

			{showOverlay && (
				<Box
					sx={{
						position: 'absolute',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						bgcolor: darkMode ? 'rgba(19, 56, 44,.95)' : 'rgba(241, 255, 235, 0.9)',
						color: darkMode ? 'rgb(200,200,200)' : 'rgb(30,30,30)',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						p: 2,
						boxSizing: 'border-box',
						border: '3px solid rgb(0, 109, 56)',
					}}
				>
					<Typography variant='body1' sx={{ mb: 1, fontFamily: 'monospace', fontSize: fontSize }}>
						{resource.note}
					</Typography>
				</Box>
			)}

			<CardContent>
				<Grid container justifyContent='space-between' alignItems='center'>
					<Grid item mb={1} style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
						<Typography variant='subtitle1' style={{ fontFamily: 'monospace' }}>
							{resource.type.toUpperCase()}
						</Typography>
						<Tooltip title={resource.free ? '' : 'Requires Purchase'} placement='top' arrow>
							<Typography variant='subtitle1' style={{ fontFamily: 'monospace' }}>
								{resource.free ? 'FREE' : '$$$'}
							</Typography>
						</Tooltip>
					</Grid>
				</Grid>
				{isOverflowing ? (
					<Tooltip title={resource.title} placement='top' arrow>
						<Typography ref={titleRef} variant='subtitle2' component='p' mb={1} sx={{ fontWeight: '600', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
							{resource.title}
						</Typography>
					</Tooltip>
				) : (
					<Typography ref={titleRef} variant='subtitle2' component='p' mb={1} sx={{ fontWeight: '600', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
						{resource.title}
					</Typography>
				)}
				<Typography variant='body2' color='text.secondary' sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					{resource.instructor}
					<span>
						{user?.subscriptionStatus === 'active' ? (
							<>
								<a
									href={`/resource/${resource._id}?timestamp=${resource.timestamp.toString()}`}
									onClick={(e) => handleNavigation(e, `/resource/${resource._id}`, resource)}
									style={{ textDecoration: 'none', cursor: 'pointer' }}
								>
									<Typography
										variant='button'
										sx={{
											fontFamily: 'monospace',
											position: 'absolute',
											bottom: '5px',
											right: '50px',
											textDecoration: 'underline',
											fontWeight: 300,
											color: darkMode ? 'rgb(0,130,60)' : 'rgb(0, 109, 56)',
											':hover': { cursor: 'pointer', color: darkMode ? 'rgb(0,150,70)' : 'rgb(0,130,60)' },
										}}
									>
										View
									</Typography>
								</a>
								<IconButton
									style={{
										position: 'absolute',
										bottom: '-2px',
										right: '0',
									}}
									onMouseEnter={() => setShowOverlay(!showOverlay)}
									onMouseLeave={() => setShowOverlay(!showOverlay)}
								>
									<InfoIcon style={{ color: darkMode ? 'rgb(200,200,200)' : 'rgb(30,30,30)' }} />
								</IconButton>
							</>
						) : (
							<>
								<Typography
									variant='button'
									onClick={(e) => handleNavigation(e, `/resource/${resource._id}`, resource)}
									sx={{
										fontFamily: 'monospace',
										position: 'absolute',
										bottom: '5px',
										right: '50px',
										textDecoration: 'underline',
										fontWeight: 300,
										color: darkMode ? 'rgb(0,130,60)' : 'rgb(0, 109, 56)',
										':hover': { cursor: 'pointer', color: darkMode ? 'rgb(0,150,70)' : 'rgb(0,130,60)' },
									}}
								>
									View
								</Typography>

								<IconButton
									style={{
										position: 'absolute',
										bottom: '-2px',
										right: '0',
									}}
									onMouseEnter={() => setShowOverlay(!showOverlay)}
									onMouseLeave={() => setShowOverlay(!showOverlay)}
								>
									<InfoIcon style={{ color: darkMode ? 'rgb(200,200,200)' : 'rgb(30,30,30)' }} />
								</IconButton>
							</>
						)}
					</span>
				</Typography>
			</CardContent>
			<LoginAndSubscribeDialog open={open} handleClose={handleClose} authUser={authUser} user={user} handleCloseAndLogin={handleCloseAndLogin} handleCloseAndSubscribe={handleCloseAndSubscribe} />
		</Card>
	);
});

export default ResourceCard;

import React, { useEffect, useRef, useState } from 'react';
import { Button, Typography, Checkbox, FormControlLabel, Box, useMediaQuery } from '@mui/material';
import '../../App.css';
import { useNavigate } from 'react-router-dom';
import FeaturedMatchCard from '../FeaturedMatchCard';
import FeaturedSequenceCard from '../FeaturedSequenceCard';
import FeaturedResourceCard from '../FeaturedResourceCard';

function Result({ matches, sequences, resources, totalPages, darkMode, user, authUser, lastClickedId, handlePageChange, page }) {
	const isMobile = useMediaQuery('(max-width:600px)');
	const scrollContainerStyle = {
		display: 'flex',
		flexDirection: 'row',
		overflowX: 'scroll',
		overflowY: 'hidden',
		WebkitOverflowScrolling: 'touch',
		scrollbarWidth: 'thin', // For Firefox
		scrollbarColor: '#90A4AE #CFD8DC', // Scroll thumb and track for Firefox
		'&::-webkit-scrollbar': {
			width: '8px', // Increase the scrollbar width for visibility
			height: '8px', // For horizontal scroll
			backgroundColor: '#CFD8DC', // Background of the scrollbar track
		},
		'&::-webkit-scrollbar-track': {
			boxShadow: 'inset 0 0 6px rgba(0,0,0,0.1)', // Slightly darker track shadow to make it visible
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: 'rgba(0,0,0,.2)', // Darker thumb for better visibility
			outline: '1px solid slategrey',
			borderRadius: '4px', // Optional: Rounded corners for the scrollbar thumb
		},
		padding: '10px 0',
		gap: '10px',
		width: '100%',
		marginBottom: '20px',
	};

	const navigate = useNavigate();
	const lastClickedRef = useRef(null);
	const scrollContainerRef = useRef(null);

	const viewSequence = (e, sequence) => {
		e.preventDefault();
		navigate(`/match/${sequence.match_id}?sequence_id=${sequence._id}`);
	};

	const viewResource = (e, resource) => {
		e.preventDefault();

		navigate(`/match/${resource.match_id}?sequence_id=${resource.sequence_id}`);
	};

	const [filterOption, setFilterOption] = useState(localStorage.getItem('filterOption') || 'All');

	useEffect(() => {
		if (lastClickedRef.current) {
			lastClickedRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	}, [lastClickedId]);

	const filteredMatches = matches.filter((result) => filterOption === 'All' || (filterOption === 'YouTube' && result.youtubeLink !== ''));

	const handleCheckboxChange = (event) => {
		localStorage.setItem('filterOption', event.target.value);
		setFilterOption(event.target.value);
	};

	return (
		<>
			{totalPages && (
				<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<Button
						onClick={() => handlePageChange(page - 1)}
						disabled={page === 1}
						sx={{
							color: 'grey',
							borderColor: 'grey',
							'&:hover': {
								backgroundColor: 'rgba(128, 128, 128, 0.1)', // Lighten on hover for visual feedback
								borderColor: 'darkgrey', // Optional: if you want the border color to change on hover
							},
							'&.Mui-disabled': {
								color: 'darkgrey',
							},
						}}
					>
						Previous
					</Button>
					<Typography sx={{ margin: '0 16px', textAlign: 'center' }}>Page {page}</Typography>

					<Button
						onClick={() => handlePageChange(page + 1)}
						disabled={page === totalPages}
						sx={{
							color: 'grey',
							borderColor: 'grey',
							'&:hover': {
								backgroundColor: 'rgba(128, 128, 128, 0.1)', // Lighten on hover for visual feedback
								borderColor: 'darkgrey', // Optional
							},
							'&.Mui-disabled': {
								color: 'darkgrey',
							},
						}}
					>
						Next
					</Button>
				</Box>
			)}
			{matches.length > 0 && (
				<>
					<Typography variant='h6' component='h3' sx={{ fontSize: isMobile ? '1.5rem' : '2rem', fontWeight: 600 }}>
						Matches
					</Typography>

					<Box sx={{ ...scrollContainerStyle }} ref={scrollContainerRef}>
						{matches.length > 0 && (
							<Box sx={{ display: 'flex', justifyContent: 'center', marginLeft: '1rem' }}>
								<FormControlLabel
									control={
										<Checkbox
											sx={{
												color: filterOption === 'All' ? 'darkgrey' : 'grey',
												'&.Mui-checked': {
													color: 'darkgrey',
												},
											}}
											checked={filterOption === 'All'}
											onChange={handleCheckboxChange}
											value='All'
										/>
									}
									label='All'
								/>
								<FormControlLabel
									control={
										<Checkbox
											sx={{
												color: filterOption === 'All' ? 'darkgrey' : 'grey',
												'&.Mui-checked': {
													color: 'darkgrey',
												},
											}}
											checked={filterOption === 'YouTube'}
											onChange={handleCheckboxChange}
											value='YouTube'
										/>
									}
									label='YouTube'
								/>
							</Box>
						)}
						{filteredMatches.map((match, idx) => (
							<FeaturedMatchCard key={idx} match={match} darkMode={darkMode} user={user} />
						))}
					</Box>
				</>
			)}
			{sequences.length > 0 && (
				<>
					<Typography variant='h6' component='h3' sx={{ fontSize: isMobile ? '1.5rem' : '2rem', fontWeight: 600 }}>
						Sequences
					</Typography>

					<Box sx={scrollContainerStyle} ref={scrollContainerRef}>
						{sequences.map((sequence, idx) => (
							<FeaturedSequenceCard key={idx} sequence={sequence} darkMode={darkMode} viewSequence={viewSequence} user={user} authUser={authUser} />
						))}
					</Box>
				</>
			)}
			{resources.length > 0 && (
				<>
					<Typography variant='h6' component='h3' sx={{ fontSize: isMobile ? '1.5rem' : '2rem', fontWeight: 600 }}>
						Resources
					</Typography>

					<Box sx={scrollContainerStyle} ref={scrollContainerRef}>
						{resources.map((resource, idx) => (
							<FeaturedResourceCard key={idx} resource={resource} darkMode={darkMode} viewResource={viewResource} user={user} authUser={authUser} />
						))}
					</Box>
				</>
			)}
		</>
	);
}

export default Result;

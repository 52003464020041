import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useMediaQuery } from '@mui/material';

const headCells = [
	{ id: 'sequenceType', numeric: false, disablePadding: true, label: 'Sequence Type' },
	{ id: 'count', numeric: true, disablePadding: false, label: 'Count' },
	{ id: 'startingPosition', numeric: false, disablePadding: false, label: 'Starting Position' },
	{ id: 'endingPosition', numeric: false, disablePadding: false, label: 'Ending Position' },
];

function descendingComparator(a, b) {
	if (b.count < a.count) {
		return -1;
	}
	if (b.count > a.count) {
		return 1;
	}
	return 0;
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead() {
	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => (
					<TableCell sx={{ p: 1 }} key={headCell.id} align={'left'} padding={headCell.disablePadding ? 'none' : 'normal'}>
						{headCell.label}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

function EnhancedTableToolbar() {
	return (
		<Toolbar
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
			}}
		>
			<Typography sx={{ flex: '1 1 100%' }} variant='h6' id='tableTitle' component='div'>
				Grouped Sequences
			</Typography>
		</Toolbar>
	);
}

export default function EnhancedTable({ groups, onGroupClick, wiki }) {
	const [page, setPage] = React.useState(0);
	const [dense, setDense] = React.useState(false);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);
	const isMobile = useMediaQuery('(max-width:768px)');

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleChangeDense = (event) => {
		setDense(event.target.checked);
	};

	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - groups.length) : 0;

	const visibleRows = React.useMemo(() => stableSort(groups, descendingComparator).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage), [groups, page, rowsPerPage]);

	return (
		<Box sx={{ width: '100%' }}>
			<Paper sx={{ width: '100%', mb: 2 }}>
				{!wiki && <EnhancedTableToolbar />}
				<TableContainer>
					<Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle' size={dense ? 'small' : 'medium'}>
						<EnhancedTableHead />
						<TableBody>
							{visibleRows.map((group, index) => {
								const labelId = `enhanced-table-checkbox-${index}`;
								const uniqueKey = `${group._id.sequenceType}-${group._id.startingPosition}-${group._id.endingPosition}-${index}`;

								return (
									<TableRow hover onClick={() => onGroupClick(group)} role='checkbox' tabIndex={-1} key={uniqueKey} sx={{ cursor: 'pointer' }}>
										<TableCell component='th' id={labelId} scope='row' sx={{ p: 1, fontFamily: 'monospace', fontWeight: 600 }}>
											{group._id.sequenceType}
										</TableCell>
										<TableCell sx={{ fontFamily: 'monospace', fontWeight: 600 }} align='left'>
											{group.count}
										</TableCell>
										<TableCell sx={{ fontFamily: 'monospace', fontWeight: 600 }} align='left'>
											{group._id.startingPosition}
										</TableCell>
										<TableCell sx={{ fontFamily: 'monospace', fontWeight: 600 }} align='left'>
											{group._id.endingPosition}
										</TableCell>
									</TableRow>
								);
							})}
							{emptyRows > 0 && (
								<TableRow
									style={{
										height: (dense ? 33 : 53) * emptyRows,
									}}
								>
									<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[5, 10]}
					component='div'
					count={groups.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
				<FormControlLabel
					sx={{
						ml: 1,
						mt: isMobile ? 0 : -9,
						'& .MuiSwitch-switchBase.Mui-checked': {
							color: 'grey', // Thumb color when checked
							'&:hover': {
								backgroundColor: 'rgba(128, 128, 128, 0.1)', // Ripple color when hovered
							},
						},
						'& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
							backgroundColor: 'grey', // Track color when checked
						},
						'& .MuiSwitch-switchBase': {
							color: 'lightgrey', // Thumb color when not checked
						},
						'& .MuiSwitch-track': {
							backgroundColor: 'lightgrey', // Track color when not checked
						},
					}}
					control={<Switch checked={dense} onChange={handleChangeDense} />}
					label='Dense padding'
				/>
			</Paper>
		</Box>
	);
}

EnhancedTable.propTypes = {
	groups: PropTypes.array.isRequired,
	onGroupClick: PropTypes.func.isRequired,
};
